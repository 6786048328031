import { gql } from '@apollo/client';

export const CREATE_FEED_SUBSCRIPTION = gql`
  subscription CreateFeedSunscription($connection: String!) {
    createFeedSubscription(connection: $connection) {
      feedId
      title
      description
      createdAt
      image
      neoSpace {
        title
        neoSpaceId
      }
      dataFace {
        title
        dataFaceId
      }
    }
  }
`;

export const DELETE_FEED_SUBSCRIPTION = gql`
  subscription DeleteFeedSunscription($connection: String!) {
    deleteFeedSubscription(connection: $connection)
  }
`;

export const CHANGE_FEED_LIKES_SUBSCRIPTION = gql`
  subscription ChangeFeedLikesSubscription($connection: String!) {
    changeFeedLikesSubscription(connection: $connection) {
      feedId
      userId
      data {
        totalCount
        liked
      }
    }
  }
`;
