import { Actions, StatusExecute, StatusExecuteColor } from 'modules/project-flow-solution/board-ui/enums';
import { FlowSolutionBoardThread, FlowSolutionBoardTracking } from 'modules/project-flow-solution/types';
import { CSSProperties, useEffect, useState } from 'react';
import { isFlowExecuting } from '../utils';

const getStylesByStatus = (status: StatusExecute): CSSProperties | undefined => {
  if (status === StatusExecute.ERROR) {
    return { backgroundColor: '#ff707e', borderColor: StatusExecuteColor.ERROR };
  }

  if (status === StatusExecute.SUCCESS) {
    return { backgroundColor: 'rgb(167 231 185)', borderColor: StatusExecuteColor.SUCCESS };
  }

  if (status === StatusExecute.RUNNING) {
    return { backgroundColor: '#FFD580', borderColor: StatusExecuteColor.RUNNING };
  }
  return undefined;
};

const useHookStyles = (
  flowSelection: string,
  flowId: string,
  currentState: Actions | string,
  threads: FlowSolutionBoardThread[],
  statusExecute: FlowSolutionBoardTracking[],
  viewInHistory = false,
) => {
  const [style, setStyle] = useState<CSSProperties | undefined>(undefined);
  useEffect(() => {
    if (viewInHistory) {
      const currentStatus = statusExecute.find((el) => el.shapeId === flowId || `${el.shapeId}_shape` === flowId);
      if (currentStatus) {
        setStyle(getStylesByStatus(currentStatus.status));
        return;
      }
      setStyle(undefined);
      return;
    }
    if (currentState === Actions.FINISH) {
      if (flowSelection === flowId) {
        setStyle({ outline: '#b2b2b2 solid 10px' });
      } else {
        const currentStatus = statusExecute.find((el) => el.shapeId === flowId);
        setStyle(currentStatus ? getStylesByStatus(currentStatus.status) : undefined);
      }
      return;
    }
    const hasFlow = isFlowExecuting(threads, flowId);
    if (flowSelection === flowId && !currentState) {
      setStyle({ outline: '#b2b2b2 solid 10px' });
      return;
    }
    const currentStatus = statusExecute.find((el) => el.shapeId === flowId);
    if (currentState === Actions.PLAY && !hasFlow) {
      if (currentStatus) {
        setStyle(getStylesByStatus(currentStatus.status));
        return;
      }
      setStyle({ backgroundColor: '#b2b2b2', pointerEvents: 'none', cursor: 'not-allowed' });
      return;
    }
    const lastStatusExecute = statusExecute[statusExecute.length - 1];
    if (!currentStatus) {
      if (hasFlow) {
        if (
          (currentState && [Actions.PAUSE, Actions.STOP].includes(currentState as Actions)) ||
          lastStatusExecute?.status === StatusExecute.ERROR
        ) {
          if (currentState === Actions.STOP) {
            setStyle(undefined);
            return;
          }
          setStyle({ outline: '#FFD580 solid 10px' });
          return;
        }
        if (currentState === Actions.PLAY) {
          setStyle({ outline: '#b2b2b2 solid 10px' });
          return;
        }
      }
      setStyle(undefined);
      return;
    }
    setStyle(getStylesByStatus(currentStatus.status));
  }, [currentState, flowId, flowSelection, statusExecute, threads, viewInHistory]);
  return style;
};

export default useHookStyles;
