import { memo, useCallback, useContext, useMemo } from 'react';
import { Dialog, Title, Content } from 'modules/shared/components/Dialog';
import Table from 'modules/shared/components/Table';
import { Button as MuiButton } from '@mui/material';
import { noop } from 'modules/shared/utils';
import { Button } from 'devextreme-react/data-grid';
import { useStyles } from './styles';
import { DataStoresState, DataStoresStateHandlers, MAIN_CONNECTION } from '../context/DataStoresState';
import { DataStoreNames } from '../contants';
import { useDataStoreConnectionActions } from '../hooks/useDataStoreConnectionActions';

const MODAL_ID = 'CONNECTIONS_INFO_MODAL';

const ConnectionsInfoModalUi = () => {
  const classes = useStyles();

  const {
    connectionsInfoModalOpen,
    dataStoreConnectionsLoading,
    dataStoreConnections,
    dataStoreConnection,
    connection,
    projectId,
  } = useContext(DataStoresState);
  const { closeConnectionsInfoModal, setConnection, setUpdateConnection, openUpdateModal, openCreateModal } =
    useContext(DataStoresStateHandlers);

  const { remove } = useDataStoreConnectionActions({ projectId, connection });

  const handleDelete = useCallback(
    ({ data: { connectionId } }) => {
      if (dataStoreConnection === connectionId) {
        setConnection(MAIN_CONNECTION);
      }

      remove(connectionId);
    },
    [remove, dataStoreConnection, setConnection],
  );

  const copy = useMemo(() => dataStoreConnections.slice(), [dataStoreConnections]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeConnectionsInfoModal}
      aria-labelledby={MODAL_ID}
      open={connectionsInfoModalOpen}
    >
      <Title id={MODAL_ID} onClose={closeConnectionsInfoModal}>
        Connections Info
      </Title>

      <Content className={classes.dialogContent}>
        <div className={classes.createButton}>
          <MuiButton variant="contained" color="primary" onClick={openCreateModal}>
            Create
          </MuiButton>
        </div>

        <Table
          data={copy}
          loading={dataStoreConnectionsLoading}
          onDeleteRow={handleDelete}
          onUpdateRow={noop}
          growToFullHeight
          height="100%"
        >
          <Table.Column dataField="name" caption="Name" minWidth={120} />
          <Table.Column
            dataField="type"
            caption="Type"
            cellRender={(args) => {
              return <div>{DataStoreNames[args.data.type]}</div>;
            }}
            minWidth={100}
          />
          <Table.Column key="buttons" type="buttons" width={110} caption="Actions">
            <Button
              name="edit"
              onClick={(args) => {
                setUpdateConnection(args.row.data.connectionId);
                openUpdateModal();
              }}
            />
            <Button name="delete" />
          </Table.Column>
        </Table>
      </Content>
    </Dialog>
  );
};

export default memo(ConnectionsInfoModalUi);
