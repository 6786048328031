import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from 'configure/browserHistory';

import { createReducer as createAppReducer, path as appPath } from 'modules/app';
import { createReducer as createUserReducer, path as authPath } from 'modules/auth';

const reducers = {
  router: connectRouter(history),
  [appPath]: createAppReducer(),
  [authPath]: createUserReducer(),
};

export default combineReducers(reducers);
