import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  page: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  content: {
    marginTop: 67,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // height: '80vh',
  },
  filters: { position: 'absolute', top: 0, left: 0, right: 0 },
}));
