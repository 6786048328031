import { FieldsType } from 'modules/flow-control/shared/FlowModal/types';
import { useEffect, useState } from 'react';
import { parseJson } from '../utils';

export const useBodyFieldsHook = (flowExecute, flowExecuteCustomize) => {
  const [bodyFields, setBodyFields] = useState<FieldsType>({});
  const [originalBodyFields, setOriginalBodyFields] = useState<FieldsType>({});

  useEffect(() => {
    const listKeys = {};

    const flowFields = parseJson(flowExecute.bodyFields);

    const customFields = flowExecuteCustomize ? parseJson(flowExecuteCustomize.bodyFields) : {};

    Object.keys(flowFields).forEach((k) => {
      listKeys[k] = {
        key: k,
        value: customFields[k] || flowFields[k] || '',
        default: true,
      };
      return undefined;
    });

    Object.keys(customFields).forEach((k) => {
      listKeys[k] = {
        key: k,
        value: customFields[k],
        default: listKeys[k]?.default ?? false,
      };
      return undefined;
    });
    if (JSON.stringify(listKeys) !== JSON.stringify(bodyFields)) {
      setBodyFields({ ...listKeys });
      setOriginalBodyFields({ ...listKeys });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowExecuteCustomize, flowExecute]);
  return {
    bodyFields,
    setBodyFields,
    originalBodyFields,
    setOriginalBodyFields,
  };
};
