import React, { forwardRef, PropsWithChildren, useRef } from 'react';
import DataGrid from 'devextreme-react/data-grid';
import dxDataGrid from 'devextreme/ui/data_grid';

import { useToolbarOptions } from '../hooks/useToolbarOptions';

import { GridType, GridProps } from '../types';
import { DEFAULT_GRID_TOOLBAR_ITEMS } from '../helpers';

const ToolbarWrapper = <A extends PropsWithChildren<GridProps>>(WrappedGrid: GridType<any>) => {
  return forwardRef<DataGrid, A>((props, ref) => {
    const gridRef = useRef<dxDataGrid>();
    const { gridOptions, ...rest } = props;
    const { onInitialized = undefined } = gridOptions ?? {};
    const { toolbarItems = () => DEFAULT_GRID_TOOLBAR_ITEMS } = gridOptions ?? {};

    const { onToolbarPreparing, templates } = useToolbarOptions(toolbarItems, gridRef, gridOptions);

    return (
      <>
        <WrappedGrid
          ref={ref}
          gridOptions={{
            ...gridOptions,
            onToolbarPreparing,
            onInitialized: (e) => {
              gridRef.current = e.component;
              onInitialized?.(e);
            },
          }}
          {...(rest as A)}
        >
          {templates}
          {props.children}
        </WrappedGrid>
      </>
    );
  });
};

export default ToolbarWrapper;
