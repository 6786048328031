import { replace } from 'connected-react-router';
import { showGlobalSnackbar } from 'main/globals';
import { appThunks } from 'modules/app';
import { Auth } from 'modules/rest';

import Storage from 'modules/tokens-storage';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';

import msalInstance from 'configure/azure-ad-b2c';
import { resetPasswordAuthority } from 'configure/azure-ad-b2c/config';
import * as actions from '../actions';
import { authSelectors } from '..';

export const resetPassword = () => async () => {
  setTimeout(() => {
    msalInstance.loginRedirect({
      authority: resetPasswordAuthority,
      scopes: ['openid', 'profile', 'email'],
    });
  }, 200);
};

export const ssoLogin = () => async (dispatch, getState) => {
  const isLoggedIn = authSelectors.getIsLoggedIn(getState());

  if (isLoggedIn) {
    return;
  }

  dispatch(actions.authLoginStart());

  try {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      throw new Error('No account found');
    }

    if (account.idToken) {
      Storage.setIdToken(account.idToken);
    }

    const { data: user } = await Auth.ssoLogin();

    await dispatch(appThunks.preloadData());

    dispatch(
      actions.authLoginSuccess({
        id: user.id,
        username: user.username,
        email: user.email,
        role: user.role,
        connections: user.connections,
        adminConnections: user.adminConnections,
      }),
    );
  } catch (error) {
    dispatch(replace(''));
    showGlobalSnackbar('Error while trying to SSO', BASE_ERROR_NOTIFICATION_OPTIONS);
    Storage.removeTokens();
    dispatch(actions.authLoginFailure('Something went wrong, please try again later.'));
  }
};

export const ssoUserFriendlyError = (msg?: string) => async (dispatch) => {
  dispatch(replace(''));

  if (msg) {
    showGlobalSnackbar(msg, BASE_ERROR_NOTIFICATION_OPTIONS);
  }
};
