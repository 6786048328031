import { FieldsType } from 'modules/flow-control/shared/FlowModal/types';

export const parseJson = (dataParse: string) => {
  return dataParse && dataParse.replace(/'/g, '') ? JSON.parse(dataParse) : {};
};

export const cleanFields = (data: FieldsType) => {
  const list = {};
  Object.keys(data).forEach((k) => {
    list[data[k].key] = data[k].value;
    return undefined;
  });
  return list;
};
