import { useCallback, useRef } from 'react';
import { useToggle } from './useToggle';

export function useToggleWithValue<T>(init: T) {
  const state = useRef({
    value: init,
  });

  const [isOpen, { activate, deactivate }] = useToggle(false);

  const handleActivate = useCallback(
    (val) => {
      state.current.value = val;

      activate();
    },
    [activate],
  );

  const handleDeactivate = useCallback(() => {
    state.current.value = init;
    deactivate();
  }, [deactivate, init]);

  return {
    value: state.current.value,
    isOpen,

    activate: handleActivate,
    deactivate: handleDeactivate,
  };
}
