import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    lineHeight: 1.1,
  },
  picker: {
    margin: 0,
    fontSize: 16,
    '& > div': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      border: '1px solid #ced4da',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    '& > div:before': {
      display: 'none',
    },
    '& > div:after': {
      display: 'none',
    },
    flex: 1,

    '& button': {
      padding: 6,
    },
  },
  withLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    fontSize: 16,
    padding: '3px 0 4px 12px',
    textOverflow: 'ellipsis',
  },
}));
