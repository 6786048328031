import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import { history } from 'configure/browserHistory';

import reducers from './reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    __PRELOADED_STATE__?: { [key: string]: any };
  }
}

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const middleware = [thunk, routerMiddleware(history)];

export default (initialState = {}) => {
  const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middleware)));

  if (module.hot) {
    module.hot.accept();
  }

  return store;
};
