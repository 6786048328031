import SharepointFields from './sharepoint-fields-ui';
import S3Fields from './s3-fields-ui';
import DropboxFields from './dropbox-fields-ui';
import SftpFieldsUi from './sftp-fields-ui';
import AdlsFieldsUi from './adls-key-ui';

export enum DataStoreType {
  Sharepoint = 'sharepoint',
  AWS_S3 = 'aws_s3',
  DROPBOX = 'dropbox',
  SFTP = 'sftp',
  ADLS_KEY = 'adls_key',
}

export const DataStoreNames = {
  [DataStoreType.AWS_S3]: 'S3',
  [DataStoreType.Sharepoint]: 'SharePoint',
  [DataStoreType.DROPBOX]: 'Dropbox',
  [DataStoreType.SFTP]: 'SFTP',
  [DataStoreType.ADLS_KEY]: 'Azure Data Lake Storage Gen2 (using account key)',
};

export const DataStores = [
  {
    value: DataStoreType.Sharepoint,
    label: DataStoreNames[DataStoreType.Sharepoint],
  },
  {
    value: DataStoreType.AWS_S3,
    label: DataStoreNames[DataStoreType.AWS_S3],
  },
  {
    value: DataStoreType.DROPBOX,
    label: DataStoreNames[DataStoreType.DROPBOX],
  },
  {
    value: DataStoreType.SFTP,
    label: DataStoreNames[DataStoreType.SFTP],
  },
  {
    value: DataStoreType.ADLS_KEY,
    label: DataStoreNames[DataStoreType.ADLS_KEY],
  },
];

export const Fields = {
  [DataStoreType.Sharepoint]: SharepointFields,
  [DataStoreType.AWS_S3]: S3Fields,
  [DataStoreType.DROPBOX]: DropboxFields,
  [DataStoreType.SFTP]: SftpFieldsUi,
  [DataStoreType.ADLS_KEY]: AdlsFieldsUi,
};
