import { createContext } from 'react';

export const FlagsContext = createContext<{
  deleteIds: string[];
  updateIds: string[];
  registered: string;
}>({
  deleteIds: [],
  updateIds: [],
  registered: '',
});
