import { ALL_ITEM } from '../constants';

export const generateFilterItems = (
  baseItems: { value: string; label: string }[],
  isLoading: boolean,
  value: string,
  capilatizeFirstLetter = false,
  withAllValue = true,
) => {
  const mockItem = {
    value,
    label: capilatizeFirstLetter ? value.charAt(0).toUpperCase() + value.slice(1) : value,
  };

  const allItemArray = withAllValue ? [ALL_ITEM] : [];

  return allItemArray.concat(isLoading ? [mockItem] : []).concat(baseItems);
};
