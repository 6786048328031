import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  box: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    margin: '5px',
    cursor: 'pointer',
    boxShadow: '0 3px 3px 0 #8f8f8f',
    boxSizing: 'border-box',
  },
  tooltip: {
    position: 'relative',
    '&:before': {
      display: 'block',
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      background: 'rgba(0,0,0,.75)',
      borderRadius: '2px',
      color: '#fff',
      content: 'attr(data-title)',
      fontSize: '14px',
      padding: '6px 10px',
      top: '25px',
      whiteSpace: 'nowrap',
      zIndex: 9999,
    },
    '&:after': {
      display: 'block',
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      borderRight: '6px solid transparent',
      borderBottom: '6px solid rgba(0,0,0,.75)',
      borderLeft: '6px solid transparent',
      content: '',
      height: 0,
      top: '20px',
      left: '20px',
      width: 0,
    },
  },
  /* the animations */
  /* fade */
  fade: {
    '&:after': {
      transform: 'translate3d(0,-10px,0)',
      transition: 'all .15s ease-in-out',
    },
    '&:before': {
      transform: 'translate3d(0,-10px,0)',
      transition: 'all .15s ease-in-out',
    },
    '&:hover:after': {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
    '&:hover:before': {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
  },
}));
