import { ReactNode, useCallback } from 'react';
import classnames from 'classnames';

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';

import { useStyles } from './styles';

export type MenuButtonProps = {
  path: string;
  isSelected: boolean;
  isHighlighted: boolean;
  icon: any;
  label: string;
  isOpen: boolean;
  disableClick?: boolean;
  additionalIcon?: ReactNode;
  onClick?: () => void;
};

const MenuButton = ({
  path,
  isSelected,
  isHighlighted,
  icon,
  label,
  isOpen,
  additionalIcon,
  onClick,
  disableClick,
}: MenuButtonProps) => {
  const IconComponent = icon;
  const classes = useStyles();

  const onItemClick = useCallback(() => {
    if (onClick && !disableClick) {
      onClick();
    }
  }, [onClick, disableClick]);

  return (
    <Tooltip title={label}>
      <ListItem
        button
        key={path}
        disableRipple
        onClick={onItemClick}
        className={classnames(classes.listItem, {
          [classes.highlighted]: isHighlighted,
          [classes.selected]: isSelected,
        })}
      >
        <ListItemIcon className={classes.iconWrapper}>
          <IconComponent className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          className={classnames(classes.text, {
            [classes.visible]: isOpen,
            [classes.hidden]: !isOpen,
          })}
          primary={label}
        />
        {additionalIcon}
      </ListItem>
    </Tooltip>
  );
};

export default MenuButton;
