import { memo, useCallback, useRef, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SelectMUI from '@material-ui/core/Select';

import SelectInput from './SelectInput';
import Loader from './Loader';
import { useStyles } from './styles';

export type SelectItem = {
  value: string;
  label: string;
};
export type SelectProps = {
  label: string;
  value: string | string[];
  items: SelectItem[];
  onChange: (value: string | string[]) => void;
  disableOpen: boolean;
  loading: boolean;
  className?: string;
  multiple?: boolean;
  placeholder?: string;
};

const Select = ({
  label,
  value,
  items,
  onChange,
  disableOpen,
  loading,
  className,
  multiple,
  placeholder,
}: SelectProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const id = useRef(++Select.instanceCount);
  const labelId = `select-label-${id.current}`;

  const handleOpen = useCallback(() => {
    if (!disableOpen) {
      setOpen(true);
    }
  }, [disableOpen]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const loadingProps = loading ? { IconComponent: Loader } : {};

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
    getContentAnchorEl: null,
  };

  const showPlaceholder = multiple ? !value.length : !value;

  return (
    <FormControl fullWidth className={className}>
      {label && (
        <InputLabel id={labelId} shrink className={classes.label}>
          {label}
        </InputLabel>
      )}
      <SelectMUI
        labelId={labelId}
        id={`select-${id.current}`}
        value={value}
        onChange={handleChange}
        input={<SelectInput placeholder={showPlaceholder ? placeholder : ''} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        multiple={multiple}
        MenuProps={MenuProps}
        {...loadingProps}
      >
        {items.length ? (
          items.map((item) => {
            return (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            );
          })
        ) : (
          <div style={{ padding: '4px 10px' }}>No Items to display</div>
        )}
      </SelectMUI>
    </FormControl>
  );
};

Select.defaultProps = {
  value: '',
  onChange: () => {},
  items: [],
  label: '',
  disableOpen: false,
  loading: false,
  className: '',
  multiple: false,
  placeholder: '',
};

Select.instanceCount = 0;

export default memo(Select);
