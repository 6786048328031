import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { authSelectors } from 'modules/auth';
import { useLoadDataTemplateNotes } from 'modules/digital-threads/hooks';
import { useDataTemplateNotesActions } from 'modules/digital-threads/hooks/useDataTemplateNotesActions';
import Table, { TableType } from 'modules/shared/components/Table';
import { useStyles } from './styles';

const ModalDiary = ({ selectedId, projectId, connection }) => {
  const classes = useStyles();
  const { email } = useSelector(authSelectors.getUser);
  const startEditingIdRef = useRef('');

  const { loading: templatesNotesLoading, dataTemplateNotes } = useLoadDataTemplateNotes(
    connection,
    selectedId,
    projectId,
  );

  const { create, update, remove } = useDataTemplateNotesActions({ connection, selectedId, projectId });

  const onAddRow = useCallback(
    (newRow) => {
      const {
        data: { addedDate, userEmail, notes },
      } = newRow;

      create(addedDate, userEmail, notes);
    },
    [create],
  );

  const onUpdateRow = useCallback(
    (newRow) => {
      const {
        data: { addedDate, userEmail, notes },
      } = newRow;

      update(startEditingIdRef.current, addedDate, userEmail, notes);

      startEditingIdRef.current = '';
    },
    [update],
  );

  const onDeleteRow = useCallback(
    (newRow) => {
      remove(newRow.data.templateNotesId);
    },
    [remove],
  );

  const onNewRow = (e) => {
    e.data.addedDate = format(new Date(), 'yyyy-MM-dd');
    e.data.userEmail = email;
  };

  const copy = useMemo(() => JSON.parse(JSON.stringify(dataTemplateNotes)), [dataTemplateNotes]);

  const datagrid = useRef<TableType>();

  useEffect(() => {
    datagrid.current?.instance.refresh();
  }, [copy]);

  return (
    <div className={classes.container}>
      {selectedId ? (
        <>
          <div className={classes.contentWrap}>
            <Table
              tableRef={datagrid}
              data={copy}
              loading={templatesNotesLoading}
              height="100%"
              onAddNewRow={onAddRow}
              onInitNewRow={onNewRow}
              onUpdateRow={onUpdateRow}
              onDeleteRow={onDeleteRow}
              onEditingStart={({ data }) => {
                startEditingIdRef.current = data.templateNotesId;
              }}
            >
              <Table.Column dataField="addedDate" caption="Date" minWidth={100} width="20%">
                <Table.Validations.RequiredRule />
              </Table.Column>
              <Table.Column dataField="notes" caption="Notes" minWidth={100} width="50%">
                <Table.Validations.RequiredRule />
              </Table.Column>
              <Table.Column dataField="userEmail" caption="User Email" minWidth={150} />
            </Table>
          </div>
        </>
      ) : (
        <div className={classes.loader}>
          <CircularProgress size={50} />
        </div>
      )}
    </div>
  );
};

export default memo(ModalDiary);
