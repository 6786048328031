import { FlowSolutionBoardTracking, UnparsedFlowSolutionBoard } from '../types';

export const addFlowSolutionBoardTracking = (
  flows: FlowSolutionBoardTracking[],
  newFlow: FlowSolutionBoardTracking,
) => {
  const newFlows =
    flows?.map((fl) => {
      return {
        boardId: fl.boardId,
        shapeId: fl.shapeId,
        status: fl.status,
        state: fl.state,
        createdAt: fl.createdAt,
        trackingId: fl.trackingId,
        completedAt: fl.completedAt,
        metadata: fl.metadata,
        checkedChecklistItems: fl.checkedChecklistItems || [],
      };
    }) ?? [];
  newFlows.push({
    boardId: newFlow.boardId,
    shapeId: newFlow.shapeId,
    status: newFlow.status,
    state: newFlow.state,
    createdAt: newFlow.createdAt,
    trackingId: newFlow.trackingId,
    completedAt: newFlow.completedAt,
    metadata: newFlow.metadata,
    checkedChecklistItems: newFlow.checkedChecklistItems || [],
  });

  return newFlows;
};
export const updateFlowSolutionBoardTracking = (
  flows: FlowSolutionBoardTracking[],
  newFlow: FlowSolutionBoardTracking,
  fields: string[],
) => {
  const updateFields = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field]: newFlow[field],
    };
  }, {});

  const newFlows =
    flows?.map((fl) => {
      const newData = {
        boardId: fl.boardId,
        shapeId: fl.shapeId,
        status: fl.status,
        state: fl.state,
        createdAt: fl.createdAt,
        trackingId: fl.trackingId,
        completedAt: fl.completedAt,
        metadata: fl.metadata,
        checkedChecklistItems: fl.checkedChecklistItems || [],
      };
      if (fl.trackingId === newFlow.trackingId) {
        return {
          ...newData,
          ...updateFields,
        };
      }
      return newData;
    }) ?? [];
  return newFlows;
};

export const updateFlowSolutionBoard = (
  flow: UnparsedFlowSolutionBoard,
  newFlow: UnparsedFlowSolutionBoard,
  fields: string[],
) => {
  const updateFields = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field]: newFlow[field],
    };
  }, {});

  return {
    ...flow,
    ...updateFields,
  };
};
