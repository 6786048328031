import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import { useStyles } from './styles';

const Loader = ({ className, ...props }) => {
  const classes = useStyles();
  return <CircularProgress {...props} size={15} className={classnames(className, classes.loader)} />;
};

export default Loader;
