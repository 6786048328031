import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  contentContainer: {
    flexGrow: 1,
    position: 'relative',
    marginTop: 20,
    minHeight: 400,
  },
  contentWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  containerBtn: {
    padding: '12px 15px 13px',
    background: BorNeoColors.primaryColor3,
    color: BorNeoColors.accentColor1,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      color: BorNeoColors.accentColor1,
      background: BorNeoColors.primaryColor2,
    },
    borderRadius: 4,
  },
  selected: {
    color: BorNeoColors.primaryColor6,
    background: BorNeoColors.primaryColor1,
  },
  disabled: {
    background: grey[400],
    color: grey[600],
    cursor: 'default',
    pointerEvents: 'none',
  },
}));
