import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback, useState } from 'react';
import { UPDATE_ACTIVE_STATE } from '../gql';

export const useFlowActiveState = ({ connection }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [optimisticState, setOptimisticState] = useState({});

  const [update] = useMutation(UPDATE_ACTIVE_STATE);

  const changeActiveState = useCallback(
    (updateActiveState: (newIsActive: boolean) => void, flowId: string, newIsActive: boolean) => {
      updateActiveState(newIsActive);

      setOptimisticState((prev) => ({
        ...prev,
        [flowId]: newIsActive,
      }));

      update({
        variables: {
          flowId,
          isActive: newIsActive,
          connection,
        },
      })
        .catch((error) => {
          updateActiveState(!newIsActive);
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        })
        .finally(() => {
          setOptimisticState((prev) => ({
            ...prev,
            [flowId]: false,
          }));
        });
    },
    [update, enqueueSnackbar, connection],
  );

  return {
    changeActiveState,
    optimisticState,
  };
};
