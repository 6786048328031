import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  divider: {
    width: 10,
  },
}));
