import { gql } from '@apollo/client';

export const LIKE_FEED = gql`
  mutation LikeFeed($feedId: String!, $connection: String!) {
    likeFeed(feedId: $feedId, connection: $connection) {
      feedId
      data {
        totalCount
        liked
      }
    }
  }
`;

export const REMOVE_LIKE_FEED = gql`
  mutation RemoveLikeFeed($feedId: String!, $connection: String!) {
    removeLikeFeed(feedId: $feedId, connection: $connection) {
      feedId
      data {
        totalCount
        liked
      }
    }
  }
`;
