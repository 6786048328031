export function findInArray(array: Array<any> | TouchList, callback): any {
  for (let i = 0, { length } = array; i < length; i++) {
    if (callback.apply(callback, [array[i], i, array])) return array[i];
  }
}

export function isFunction(func: any) {
  return typeof func === 'function' || Object.prototype.toString.call(func) === '[object Function]';
}

export function isNum(num: any) {
  return typeof num === 'number' && !Number.isNaN(num);
}

export function int(a: string): number {
  return parseInt(a, 10);
}

export function dontSetMe(props, propName: string, componentName: string) {
  if (props[propName]) {
    return new Error(`Invalid prop ${propName} passed to ${componentName} - do not set this, set it on the child.`);
  }
}
