import { gql } from '@apollo/client';

export const LOAD_VALIDATION_TYPES = gql`
  query LoadValidationTypes($connection: String!) {
    dataValidationTypes(connection: $connection) {
      id
      label
    }
  }
`;

export const LOAD_ISSUE_STATUSES = gql`
  query LoadIssueStatuses($connection: String!) {
    dataValidationStatuses(connection: $connection) {
      id
      value
    }
  }
`;

export const LOAD_VALIDATION_ISSUES = gql`
  query DataValidationIssues(
    $connection: String!
    $search: String
    $project: String
    $order: String
    $desc: Boolean
    $limit: Int
    $offset: Int
  ) {
    dataValidationIssues(
      connection: $connection
      search: $search
      project: $project
      order: $order
      desc: $desc
      limit: $limit
      offset: $offset
    ) {
      totalCount
      nodes {
        issueId
        label
        description
        added
        alerted
        escalated
        resolved
        verified
        assignedTo
        check {
          category
          subCategory
          type {
            id
            label
          }
        }
      }
    }
  }
`;

export const LOAD_VALIDATION_ISSUES_GROUPED = gql`
  query DataValidationIssuesGrouped(
    $connection: String!
    $search: String
    $project: String
    $group: String
    $limit: Int
    $offset: Int
  ) {
    dataValidationIssuesGrouped(
      group: $group
      project: $project
      connection: $connection
      search: $search
      limit: $limit
      offset: $offset
    )
  }
`;

export const LOAD_NOTES = gql`
  query loadNotesByIssueId($connection: String!, $projectId: String!, $issueId: String!, $limit: Int, $offset: Int) {
    dataValidationNotesByIssue(
      connection: $connection
      project_id: $projectId
      issue_id: $issueId
      limit: $limit
      offset: $offset
    ) {
      totalCount
      nodes {
        id
        added
        label
        text
        contact {
          name
        }
      }
    }
  }
`;

export const LOAD_VALIDATION_RESULT = gql`
  query dataValidationResults(
    $connection: String!
    $projectId: String!
    $issueId: String!
    $limit: Int
    $offset: Int
    $order: String
    $desc: Boolean
  ) {
    dataValidationResults(
      connection: $connection
      issue_id: $issueId
      project_id: $projectId
      limit: $limit
      offset: $offset
      order: $order
      desc: $desc
    ) {
      totalCount
      nodes {
        id
        value
        description
      }
    }
  }
`;

export const ADD_VALIDATION_NOTE = gql`
  mutation createValidationNote(
    $connection: String!
    $projectId: String!
    $issueId: String!
    $label: String!
    $text: String!
  ) {
    createValidationNote(
      connection: $connection

      project_id: $projectId
      issue_id: $issueId
      label: $label
      text: $text
    ) {
      id
    }
  }
`;

export const UPDATE_VALIDATION_ISSUE = gql`
  mutation updateDataValidationIssue(
    $connection: String!
    $projectId: String!
    $issueId: String!
    $issuePayload: ValidationIssueParamsDto!
  ) {
    updateDataValidationIssue(
      connection: $connection
      project_id: $projectId
      issueId: $issueId
      issuePayload: $issuePayload
    ) {
      issueId
      label
      description
      added
      alerted
      escalated
      resolved
      verified
      assignedTo
      check {
        category
        subCategory
      }
    }
  }
`;

export const LOAD_NOT_LINKED_VALIDATION_ISSUES = gql`
  query ValidationIssuesWithoutNeoboardRelation($connection: String!, $project: String!) {
    validationIssuesWithoutNeoboardRelation(connection: $connection, project: $project) {
      issueId
      label
    }
  }
`;
