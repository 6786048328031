import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  card: {
    width: '400px',
    height: '200px',
    borderColor: '#8f8f8f',
    borderRadius: 8,
    boxShadow: '0 8px 8px 0 #8f8f8f',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    color: '#000000',
    display: 'inline',
    float: 'left',
  },
  headerItem: {
    color: '#000000',
  },
  headerItemStatus: {
    flex: '0 0 30px',
    width: '30px',
    height: '30px',
  },
  headerItemText: {
    padding: '5px',
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
  headerItemMenu: {
    flex: '0 0 20px',
    width: '20px',
    height: '20px',
  },
  cardActionArea: {
    height: 60,
  },
  planPicker: {
    float: 'left',
    width: '150px',
    margin: '2px',
  },
  actualPicker: {
    float: 'right',
    width: '150px',
    margin: '2px',
  },
  spacer: {
    height: '10px',
    width: '10px',
  },
  cardActions: {
    height: 45,
    bottom: 0,
    left: 0,
    marginLeft: '2%',
    position: 'absolute',
  },
  cardAction: {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    borderRadius: '4px',
    borderColor: 'darkGrey',
    border: '2px solid',
    height: '95%',
    padding: '2px',
    marginRight: '5px',
  },
  datepickerPopover: {
    transition: 'none !important',
  },
}));
