import { getApiBaseUrl } from './getApiBaseUrl';

const FS_URL_PATTERN = '{fs-url}';

export const getImagePath = (path: string, fsUrl = '') => {
  if (path.startsWith('http')) {
    return path;
  }

  if (path.startsWith(FS_URL_PATTERN)) {
    return path.replace(FS_URL_PATTERN, fsUrl.endsWith('/') ? fsUrl : `${fsUrl}/`);
  }

  return `${getApiBaseUrl()}${path}`;
};
