import * as authConstants from './constants';
import * as authSelectors from './selectors';
import * as authActions from './actions';
import * as authThunks from './thunks';
import * as authTools from './tools';

import reducers from './reducers';

const createReducer = () => {
  return reducers;
};

const { PATH: path } = authConstants;

export { authConstants, authSelectors, authActions, authThunks, authTools, createReducer, path };
