import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  avatar: {
    marginRight: 10,
  },
  primary: {
    fontSize: '1rem',
    color: BorNeoColors.primaryColor1,
  },
  secondary: {
    fontSize: '0.875rem',
    color: BorNeoColors.accentColor1,
  },
}));
