import { gql } from '@apollo/client';

export const CREATE_DATA_TEMPLATE_NOTES = gql`
  mutation CreateDtTemplateNote(
    $connection: String!
    $dtTemplateId: String!
    $notes: String!
    $addedDate: String!
    $userEmail: String!
    $projectId: String!
  ) {
    createDtTemplateNote(
      connection: $connection
      dt_template_id: $dtTemplateId
      notes: $notes
      added_date: $addedDate
      user_email: $userEmail
      project_id: $projectId
    ) {
      dtTemplateIdFk
      notes
      addedDate
      userEmail
      projectId
    }
  }
`;

export const UPDATE_DATA_TEMPLATE_NOTES = gql`
  mutation UpdateDtTemplateNote(
    $connection: String!
    $templateNotesId: String!
    $notes: String!
    $addedDate: String!
    $userEmail: String!
    $projectId: String!
  ) {
    updateDtTemplateNote(
      connection: $connection
      template_note_id: $templateNotesId
      notes: $notes
      added_date: $addedDate
      user_email: $userEmail
      project_id: $projectId
    ) {
      templateNotesId
      dtTemplateIdFk
      notes
      addedDate
      userEmail
      projectId
    }
  }
`;

export const DELETE_DATA_TEMPLATE_NOTES = gql`
  mutation DeleteDtTemplateNote($connection: String!, $templateNotesId: String!, $projectId: String!) {
    deleteDtTemplateNote(connection: $connection, template_note_id: $templateNotesId, project_id: $projectId)
  }
`;

export const CREATE_DATA_TEMPLATE_VERSION_STATUS = gql`
  mutation CreateDtTemplateVersionStatus(
    $connection: String!
    $dtVersionIdFk: String!
    $status: String!
    $addedDate: String!
  ) {
    createDtTemplateVersionStatus(
      connection: $connection
      dt_version_id_fk: $dtVersionIdFk
      status: $status
      added_date: $addedDate
      updated_date: $addedDate
    ) {
      dtVersionIdFk
      status
    }
  }
`;

export const UPDATE_DATA_TEMPLATE_VERSION_STATUS = gql`
  mutation UpdateDtTemplateVersionStatus(
    $connection: String!
    $versionStatusId: String!
    $status: String!
    $updatedDate: String!
  ) {
    updateDtTemplateVersionStatus(
      connection: $connection
      version_status_id: $versionStatusId
      status: $status
      updated_date: $updatedDate
    ) {
      versionStatusId
      dtVersionIdFk
      status
    }
  }
`;

export const SETUP_CHECKING = gql`
  mutation SetupChecking(
    $connection: String!
    $projectId: String!
    $root: CheckingItemRequest!
    $config: CheckingConfigRequest!
  ) {
    setupChecking(connection: $connection, project_id: $projectId, root: $root, config: $config)
  }
`;
