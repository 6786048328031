import { useMutation } from '@apollo/client';
import { unwrapProject } from 'modules/shared/utils/project';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { DELETE_PROJECT } from '../gql';

export const useDeleteProject = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteProject, { loading }] = useMutation(DELETE_PROJECT, {
    update(cache, { data: { deleteProject: deleteProjectResp } }) {
      cache.modify({
        fields: {
          myProjects(existing) {
            return existing.filter(
              (p) =>
                !(p.project.projectId === deleteProjectResp.id && p.projectConnection === deleteProjectResp.connection),
            );
          },
        },
      });
    },
  });

  const hendleDelete = useCallback(
    (project: string) => {
      const { projectId, connection } = unwrapProject(project);
      return deleteProject({
        variables: {
          projectId,
          connection,
        },
        optimisticResponse: {
          deleteProject: {
            id: projectId,
            connection,
          },
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [deleteProject, enqueueSnackbar],
  );

  return {
    deleteProject: hendleDelete,
    loading,
  };
};
