import { useSubscription } from '@apollo/client';
import { useSelector } from 'react-redux';

import { authSelectors } from 'modules/auth';

import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { DOWNLOAD_SUBSCRIPTION } from './gql';

import { useDownloadSnackbar } from './useDownloadSnackbar';

export const useDownloadSubscription = (skip: boolean) => {
  const userId = useSelector(authSelectors.getUserId);
  const { enqueueSnackbar } = useSnackbar();
  const { renderSnackbar } = useDownloadSnackbar();

  useSubscription(DOWNLOAD_SUBSCRIPTION, {
    skip,
    variables: {
      id: userId,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data.downloadSubscription?.archiveId) {
        renderSnackbar(data.downloadSubscription);
      } else {
        enqueueSnackbar(data.downloadSubscription?.error, BASE_ERROR_NOTIFICATION_OPTIONS);
      }
    },
  });
};
