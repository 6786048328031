import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { COPY_HDFS_FILES } from '../gql';

type Input = {
  connection: string;
  project: string;
};

type Path = {
  from: string;
  to: string;
};

export const useCopyHdfsFiles = () => {
  const [copy, { loading, error }] = useMutation(COPY_HDFS_FILES);

  const handleCopy = useCallback(
    (source: Input, target: Input, pathes: Path[]) => {
      return copy({
        variables: {
          source,
          target,
          pathes,
        },
      });
    },
    [copy],
  );

  return {
    copy: handleCopy,
    loading,
    error,
  };
};
