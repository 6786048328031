import { useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';

import Input from 'modules/shared/components/Input';
import { Delete } from '@material-ui/icons';
import { useStyles } from './styles';

const Fields = ({ fieldKey, fieldValue, id, onChange, onDelete, isDefault }) => {
  const classes = useStyles();
  const handleKeyChange = useCallback(
    ({ target }) => {
      const { value } = target;

      onChange(id, 'key', value);
    },
    [id, onChange],
  );

  const handleValueChange = useCallback(
    ({ target }) => {
      const { value } = target;

      onChange(id, 'value', value);
    },
    [id, onChange],
  );
  return (
    <Grid container spacing={3}>
      <Grid xs={5} item>
        <Input
          labelText="Key"
          id={`key=${id}`}
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
            disabled: true,
            className: classes.formControl,
          }}
          inputProps={{
            value: fieldKey,
            required: true,
            onChange: handleKeyChange,
          }}
        />
      </Grid>
      <Grid xs={5} item>
        <Input
          labelText="Value"
          id={`value=${id}`}
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            value: fieldValue,
            required: true,
            onChange: handleValueChange,
          }}
        />
      </Grid>
      <Grid xs={2} item>
        {isDefault ? null : (
          <IconButton
            onClick={() => {
              onDelete(id);
            }}
            title="Remove"
          >
            <Delete />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default Fields;
