import { useQuery } from '@apollo/client';
import { LOAD_SYNC_PATHES } from '../gql';
import { SyncPath } from '../types';

export const useLoadSyncPathes = ({ connection, dataStoreConnection, projectId, type }) => {
  const { loading, data } = useQuery(LOAD_SYNC_PATHES, {
    variables: {
      connection,
      dataStoreConnection,
      type,
      projectId,
    },
    skip: !connection || !type,
  });

  const pathes: SyncPath[] = data?.loadSyncPathes || [];

  return { loading, pathes };
};
