import React, { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';
import { LinearProgress } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { useStyles } from './styles';

const ExportExcelSnackbar = forwardRef<HTMLDivElement, { message: string | React.ReactNode }>((props, ref) => {
  const classes = useStyles();

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div className={classes.snackbarRoot}>
        <div className={classes.content}>
          <InfoIcon className={classes.icon} />
          {props.message}
        </div>
        <LinearProgress
          className={classes.progress}
          classes={{
            root: classes.progressRoot,
            bar: classes.progressBar,
          }}
        />
      </div>
    </SnackbarContent>
  );
});

export default ExportExcelSnackbar;
