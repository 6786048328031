import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { LOAD_DIGITAL_THREADS } from '../gql';
import { Digitalthread } from '../types';

export const useLoadDigitalthreads = (connection: string, skip = false) => {
  const client = useApolloClient();
  const { data, loading } = useQuery(LOAD_DIGITAL_THREADS, {
    variables: {
      connection,
    },
    fetchPolicy: 'network-only',
    skip: !connection || skip,
    notifyOnNetworkStatusChange: true,
  });

  const digitalThreads: Digitalthread[] = data?.digitalthreads || [];

  const load = useCallback(async () => {
    if (!connection) {
      return {
        data: [],
        totalCount: 0,
      };
    }

    const response = await client.query({
      query: LOAD_DIGITAL_THREADS,
      fetchPolicy: 'network-only',
      context: {
        requestId: 'load-digitalthreads-request',
        queryDeduplication: false,
      },
      variables: {
        connection,
      },
    });

    const items = response.data?.digitalthreads || [];

    return { data: JSON.parse(JSON.stringify(items)), totalCount: items.length };
  }, [client, connection]);

  return {
    digitalThreads,
    loading,
    load,
  };
};
