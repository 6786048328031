import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

import { Button, CircularProgress } from '@material-ui/core';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';

import Table from 'modules/shared/components/Table';
import { LayoutContext } from 'modules/shared/components/DraggableLayout/context/LayoutContext';
import { useUpdateFlowSolutionChecklistShapeStatuses } from 'modules/project-flow-solution/hooks';
import { useStyles } from './styles';

const MODAL_ID = 'checklist-card-modal-title';

const ChecklistModal = ({ open, onClose, shapeId }) => {
  const classes = useStyles();

  const {
    additionalData,
    flowRunData: { selectedChecklistItems, projectId, trackingId, connection },
  } = useContext(LayoutContext);

  const items = useMemo(
    () =>
      (additionalData.checklistItems[shapeId] || []).map((item) => {
        const checkedItem = selectedChecklistItems[shapeId]?.find((el) => el.checklistItem.dataId === item.dataId);

        return {
          dataId: item.dataId,
          name: item.name,
          setUp: checkedItem?.setUp ? new Date(checkedItem.setUp) : undefined,
          signOff: checkedItem?.signOff ? new Date(checkedItem.signOff) : undefined,
          status: checkedItem?.status || false,
        };
      }),
    [additionalData, shapeId, selectedChecklistItems],
  );
  const [editingItems, setEditingItems] = useState(items);

  useEffect(() => {
    if (!open) {
      setEditingItems(items);
    }
  }, [open, items]);

  const handleUpdate = useCallback(({ data }) => {
    setEditingItems((prev) =>
      prev.map((item) => {
        if (item.dataId === data.dataId) {
          return data;
        }

        return item;
      }),
    );
  }, []);

  const { saving, update } = useUpdateFlowSolutionChecklistShapeStatuses(trackingId, projectId, connection);

  const handleSubmit = useCallback(() => {
    update(
      onClose,
      editingItems.map((item) => ({
        ...item,
        setUp: item.setUp ? format(item.setUp, 'MM/dd/yyyy') : null,
        signOff: item.signOff ? format(item.signOff, 'MM/dd/yyyy') : null,
      })),
    );
  }, [editingItems, onClose, update]);

  const cellRender = ({ data, column: { dataField } }) => {
    if (data[dataField]) {
      return format(data[dataField], 'MM/dd/yyyy');
    }

    const item = additionalData.checklistItems[shapeId]?.find((el) => {
      return el.dataId === data.dataId;
    });

    if (item?.[dataField]) {
      return <div className={classes.cellPlaceholderValue}>{item[dataField]}</div>;
    }

    return '';
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={onClose}
      aria-labelledby={MODAL_ID}
      open={open}
    >
      <Title id={MODAL_ID} onClose={onClose}>
        Checklist
      </Title>
      <Content dividers style={{ minHeight: 300 }}>
        {saving && (
          <div className={classes.globalLoading}>
            <CircularProgress />
          </div>
        )}
        <Table
          data={editingItems}
          loading={false}
          height="100%"
          editingMode="cell"
          onUpdateRow={saving ? undefined : handleUpdate}
          growToFullHeight
        >
          <Table.Column dataField="status" caption="Status" alignment="center" minWidth={60} />
          <Table.Column dataField="name" caption="Name" minWidth={120} allowEditing={false} />
          <Table.Column dataType="date" dataField="setUp" caption="Set Up" cellRender={cellRender} minWidth={100} />
          <Table.Column dataType="date" dataField="signOff" caption="Sign Off" cellRender={cellRender} minWidth={100} />
        </Table>
      </Content>
      <Actions>
        <Button disabled={saving} onClick={onClose} color="default">
          Cancel
        </Button>
        <Button disabled={saving} onClick={handleSubmit} color="primary">
          Save
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(ChecklistModal);
