export const STORAGE_PROJECT_KEY = 'borneo-project-id';

export enum Permissions {
  'USER' = 'user',
  'END_USER' = 'end_user',
  'SUPER_USER' = 'super_user',
  'CONNECTION_ADMIN' = 'connection_admin',
  'GLOBAL_ADMIN' = 'global_admin',
}

export const SUPER_USER_ACCESS_ROLES = [Permissions.SUPER_USER, Permissions.CONNECTION_ADMIN, Permissions.GLOBAL_ADMIN];
