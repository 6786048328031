import { memo } from 'react';
import { TextField } from '@material-ui/core';
import Select from 'modules/shared/components/Select2';
import { fieldsConfigs } from '../contstants';

const Config = ({ type, config, setConfig, disabled = false }) => {
  const entries = Object.entries(config);

  const fieldsConfig = fieldsConfigs[type];

  if (!entries.length || !fieldsConfig) {
    return null;
  }

  return (
    <>
      {Object.entries(config).map(([key, value]) => {
        const field = fieldsConfig[key];

        if (!field) {
          return null;
        }

        if (field.isSelect) {
          return (
            <div key={key} style={{ marginBottom: 16 }}>
              <Select
                id={key}
                value={[value]}
                labelText={key}
                disabled={disabled}
                onChange={(val) => {
                  setConfig((prev) => ({ ...prev, [key]: val[0] }));
                }}
                options={field.options}
                required
                disableClear
              />
            </div>
          );
        }

        return (
          <TextField
            key={key}
            id={key}
            label={key}
            value={value}
            fullWidth
            onChange={({ target }) => {
              const { value: newValue } = target;

              setConfig((prev) => ({ ...prev, [key]: field.filterValue(newValue) }));
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
            type={fieldsConfig[key].type}
            margin="normal"
            variant="outlined"
          />
        );
      })}
    </>
  );
};

export default memo(Config);
