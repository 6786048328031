import React, { memo, useCallback, useContext, useEffect, useRef } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import { StepExecuteContext } from 'modules/project-flow-solution/board-ui/contexts/StepExecuteContext';
import { useToggle } from 'modules/shared/hooks/base';

import { FlowSolutionConnectionType } from 'modules/project-flow-solution/board-ui/enums';
import { useUpdateFlowSolutionChecklistShape } from 'modules/project-flow-solution/hooks';
import { HistoryContext } from 'modules/project-flow-solution/board-ui/contexts/HistoryContext';
import { useSearch } from 'modules/shared/hooks/url';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import useHookStyles from '../PocCard/hooks/useHookStyles';
import EditModal from './EditModal';
import { DraggableShapeProps } from '../types';
import CardUi from './CardUi';
import ChecklistModal from './ChecklistModal';
import { LayoutContext } from '../../DraggableLayout/context/LayoutContext';
import { isFlowExecuting } from '../PocCard/utils';
import CardTrackingModal from './CardTrackingModal';

const ChecklistShape = ({ id, title, scale, metadata = {}, boardId, onUpdate, onDelete }: DraggableShapeProps) => {
  const [isMenuOpen, { activate, deactivate }] = useToggle(false);

  const [isEditModalOpen, { activate: openEditModal, deactivate: closeEditModal }] = useToggle(false);
  const [isChecklistModalOpen, { activate: openChecklistModal, deactivate: closeChecklistModal }] = useToggle(false);
  const [isShowTrackingModal, { activate: openShowTrackingModal, deactivate: closeShowTrackingModal }] =
    useToggle(false);
  const anchorRef = useRef(null);
  const { set } = useSearch();

  const {
    isBuilder,
    flowRunData: { trackingId, projectId, connection },
    handlers: { updateChecklistItems },
  } = useContext(LayoutContext);

  const {
    statusExecute,
    threads,
    currentState,
    flowSelection,
    setFlowSelection,
    updateStatusOfChecklistCard,
    viewInHistory,
  } = useContext(StepExecuteContext);
  const context = useContext(HistoryContext);

  // eslint-disable-next-line react/destructuring-assignment
  const listTrackingUsing = viewInHistory ? context?.historyTracking : statusExecute;
  const styleCardUI = useHookStyles(flowSelection, id, currentState, threads, listTrackingUsing ?? [], viewInHistory);

  const { dateActual, datePlan } = metadata || {};

  useEffect(() => {
    set({ modalId: isEditModalOpen ? id : '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModalOpen]);
  const handleFlowSelect = () => {
    setFlowSelection?.(flowSelection === id ? '' : id);
  };

  const handleDelete = useCallback(() => {
    onDelete?.(id);
    updateChecklistItems(id, undefined);
  }, [id, onDelete, updateChecklistItems]);

  const handleBuilderUpdate = useCallback(
    (close, data, { checklistItems }) => {
      const { shapeId, ...rest } = data;

      onUpdate?.(shapeId, rest);
      updateChecklistItems(shapeId, checklistItems);

      close();
    },
    [onUpdate, updateChecklistItems],
  );

  const executeFlow = (status: FlowSolutionConnectionType) => {
    updateStatusOfChecklistCard?.(status);
  };

  const actionsDisabled = viewInHistory || isBuilder || !isFlowExecuting(threads, id);

  const { loading, update } = useUpdateFlowSolutionChecklistShape(boardId, projectId, connection);

  const editable = useAdminOrSuperUser(connection, projectId);

  const menuItems: React.ReactNode[] = viewInHistory
    ? [
        <MenuItem
          key="view-tracking-cards"
          onClick={() => {
            openShowTrackingModal();
            deactivate();
          }}
        >
          View Status
        </MenuItem>,
      ]
    : [
        ...(trackingId && editable && isFlowExecuting(threads, id)
          ? [
              <MenuItem
                key="update-checklist-card-item"
                onClick={() => {
                  openChecklistModal();
                  deactivate();
                }}
              >
                Update Checklist
              </MenuItem>,
            ]
          : []),

        ...((isBuilder || editable) && !trackingId
          ? [
              <MenuItem
                key="edit-checklist-card-item"
                onClick={() => {
                  openEditModal();
                  deactivate();
                }}
              >
                Edit
              </MenuItem>,
            ]
          : []),

        ...(isBuilder
          ? [
              <MenuItem key="delete-checklist-card-item" onClick={handleDelete}>
                Delete
              </MenuItem>,
            ]
          : []),
      ];

  const trackings = listTrackingUsing.filter((h) => `${h.shapeId}_shape` === id);
  return (
    <>
      <CardUi
        id={id}
        scale={scale}
        title={title}
        dateActual={dateActual}
        datePlan={datePlan}
        onMenuClick={activate}
        actionsDisabled={actionsDisabled}
        disabledDatePlan={viewInHistory}
        disabledDateActual={viewInHistory}
        menu={
          menuItems.length ? (
            <>
              <div ref={anchorRef} />
              <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={deactivate}>
                {menuItems}
              </Menu>
            </>
          ) : (
            <></>
          )
        }
        containerStyle={styleCardUI}
        onClick={handleFlowSelect}
        onSuccess={() => {
          if (!isBuilder) {
            executeFlow(FlowSolutionConnectionType.SUCCESS);
          }
        }}
        onFail={() => {
          if (!isBuilder) {
            executeFlow(FlowSolutionConnectionType.FAIL);
          }
        }}
        hasActionable={!viewInHistory}
      />
      <EditModal
        key={`edit-${isEditModalOpen}`}
        open={isEditModalOpen}
        title={title}
        shapeId={id}
        boardId={boardId}
        datePlan={datePlan}
        dateActual={dateActual}
        cardSaving={loading}
        onUpdateCard={isBuilder ? handleBuilderUpdate : update}
        onClose={closeEditModal}
      />
      <CardTrackingModal open={isShowTrackingModal} onClose={closeShowTrackingModal} trackings={trackings} />
      {!isBuilder && (
        <ChecklistModal
          key={`checklist-${isChecklistModalOpen}`}
          open={isChecklistModalOpen}
          shapeId={id}
          onClose={closeChecklistModal}
        />
      )}
    </>
  );
};

export default memo(ChecklistShape);
