import { Card, CardMedia, CardContent, Typography, Fade, CardHeader, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { history } from 'configure/browserHistory';
import { useCallback } from 'react';
import { useStyles } from './style';

export const ProjectCard = ({ name, id, image, description, children, clickable, defaultImage }) => {
  const classes = useStyles();

  const handleCardClick = useCallback(() => {
    history.push(`/projects/overview?project=${id}`);
  }, [id]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleCardClick();
      }
    },
    [handleCardClick],
  );

  const timeout = Math.floor(Math.random() * 4500 + 0);

  const clickableProps = clickable
    ? { onClick: handleCardClick, role: 'button', tabIndex: 0, onKeyPress: handleKeyPress }
    : {};

  return (
    <Fade in timeout={timeout} key={id}>
      <Card
        key={id}
        className={classnames(classes.card, {
          [classes.clickableCard]: clickable,
        })}
        {...clickableProps}
      >
        <CardMedia
          className={classes.media}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultImage;
          }}
          component="img"
          src={image}
        />
        <CardHeader
          className={classes.header}
          title={
            <Tooltip title={name}>
              <Typography variant="h5" noWrap>
                {name}
              </Typography>
            </Tooltip>
          }
        />
        <CardContent className={classes.muiContent}>
          <div className={classes.content}>
            <Tooltip title={description} placement="top" arrow>
              <Typography paragraph variant="body1">
                {description}
              </Typography>
            </Tooltip>
          </div>
        </CardContent>
        {children}
      </Card>
    </Fade>
  );
};
