const prefixes = ['Moz', 'Webkit', 'O', 'ms'];

function kebabToTitleCase(str: string): string {
  let out = '';
  let shouldCapitalize = true;
  for (const val of str) {
    if (shouldCapitalize) {
      out += val.toUpperCase();
      shouldCapitalize = false;
    } else if (val === '-') {
      shouldCapitalize = true;
    } else {
      out += val;
    }
  }
  return out;
}

export function browserPrefixToKey(prop: string, prefix: string): string {
  return prefix ? `${prefix}${kebabToTitleCase(prop)}` : prop;
}

export function browserPrefixToStyle(prop: string, prefix: string): string {
  return prefix ? `-${prefix.toLowerCase()}-${prop}` : prop;
}

export function getPrefix(prop = 'transform'): string {
  // Checking specifically for 'window.document' is for pseudo-browser server-side
  // environments that define 'window' as the global context.
  // E.g. React-rails (see https://github.com/reactjs/react-rails/pull/84)
  if (typeof window === 'undefined' || typeof window.document === 'undefined') return '';

  const { style } = window.document.documentElement;

  if (prop in style) return '';

  for (const val of prefixes) {
    if (browserPrefixToKey(prop, val) in style) return val;
  }

  return '';
}

// Default export is the prefix itself, like 'Moz', 'Webkit', etc
// Note that you may have to re-test for certain things; for instance, Chrome 50
// can handle unprefixed `transform`, but not unprefixed `user-select`
export default getPrefix();
