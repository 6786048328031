import { makeStyles } from '@material-ui/core';
import * as BorNEoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  header: {
    padding: 10,
    color: BorNEoColors.primaryColor4,
    background: BorNEoColors.accentColor9,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 14,
    flexShrink: 0,
  },
}));
