import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';

import { UPDATE_FLOW_SOLUTION_SHAPE_DATA } from '../gql';

export const useUpdateFlowSolutionShapeData = (boardId: string, projectId: string, connection: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateShapeData, { loading, error }] = useMutation(UPDATE_FLOW_SOLUTION_SHAPE_DATA, {
    update(cache, { data: { updateFlowSolutionShape } }) {
      cache.modify({
        fields: {
          flowSolutionBoardBySolutionId(currentBoard) {
            if (currentBoard.boardId === boardId) {
              return {
                ...currentBoard,
                shapes: currentBoard.shapes.map((s) => {
                  if (s.shapeId === updateFlowSolutionShape.shapeId) {
                    return updateFlowSolutionShape;
                  }

                  return s;
                }),
              };
            }

            return currentBoard;
          },
        },
      });
    },
  });

  const handleUpdate = useCallback(
    (onSuccess, data) => {
      return updateShapeData({
        variables: {
          boardId,
          shapeId: data.shapeId,
          projectId,
          title: data.title,
          metadata: JSON.stringify(data.metadata),
          connection,
        },
      })
        .then(onSuccess)
        .catch(() => {
          enqueueSnackbar('Unable to update card. Please, try again later', BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [updateShapeData, enqueueSnackbar, boardId, projectId, connection],
  );

  return {
    update: handleUpdate,
    loading,
    error,
  };
};
