import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { DELETE_FLOW_SOLUTION } from '../gql';

export const useDeleteFlowSolution = (projectId: string, connection: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteSolution, { loading }] = useMutation(DELETE_FLOW_SOLUTION, {
    update(cache, { data: { deleteFlowSolution: deletedId } }) {
      cache.modify({
        fields: {
          flowSolutions(existing) {
            return existing.filter((s) => s.solutionId !== deletedId);
          },
        },
      });
    },
  });

  const hendleDelete = useCallback(
    (solutionId: string) =>
      deleteSolution({
        variables: {
          solutionId,
          connection,
          projectId,
        },
        optimisticResponse: {
          deleteFlowSolution: solutionId,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      }),
    [deleteSolution, enqueueSnackbar, connection, projectId],
  );

  return {
    deleteSolution: hendleDelete,
    loading,
  };
};
