import { memo, useCallback, useEffect, useState } from 'react';
import { isValid } from 'date-fns';
import classnames from 'classnames';
import { InputLabel, FormControl, FormControlProps } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { useStyles } from './styles';

export type DatePickerType = {
  id: string;
  label: string;
  date: Date | string | null;
  changeDate: (val: Date | null, id: string) => void;
  formControlProps?: FormControlProps;
  selectTimeType?: 'start' | 'end' | 'default';
  midDaySelection?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
};

const DatePicker = ({
  id,
  label,
  date,
  changeDate,
  formControlProps,
  midDaySelection,
  readOnly,
  disabled,
}: DatePickerType) => {
  const classes = useStyles();

  const [dateVal, setDate] = useState(date);
  const handleChange = useCallback(
    (val) => {
      setDate(val);
      if (val === null || isValid(val)) {
        changeDate(
          val && midDaySelection
            ? new Date(Date.UTC(val.getFullYear(), val.getMonth(), val.getDate(), 12, 0, 0, 0))
            : val,
          id,
        );
      }
    },
    [changeDate, id, midDaySelection],
  );

  useEffect(() => {
    setDate(dateVal);
  }, [dateVal]);

  return (
    <FormControl {...formControlProps}>
      {label && (
        <InputLabel shrink className={classes.label}>
          {label}
        </InputLabel>
      )}
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        value={dateVal}
        id={id}
        onChange={handleChange}
        className={classnames(classes.picker, {
          [classes.withLabel]: label,
        })}
        autoOk
        InputProps={{
          className: classes.input,
          readOnly,
        }}
        disabled={disabled}
        readOnly={readOnly}
      />
    </FormControl>
  );
};

DatePicker.defaultProps = {
  label: '',
};

DatePicker.instanceCount = 0;

export default memo(DatePicker);
