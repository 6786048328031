import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { UPDATE_PROJECT_INFO } from '../gql';

export const useUpdateProject = (projectId: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const [update, { loading }] = useMutation(UPDATE_PROJECT_INFO, {
    update(cache, { data: { updateProjectInfo } }) {
      cache.modify({
        fields: {
          myProjects(existing) {
            return existing.map((p) => {
              if (p.project.projectId === updateProjectInfo.project.projectId) {
                return updateProjectInfo;
              }

              return p;
            });
          },
        },
      });
    },
  });

  const handleUpdate = useCallback(
    (onSuccess, { name, description, code, index, number, stakeholder, site, unit, img, connection }) =>
      update({
        variables: {
          projectId,
          name,
          description,
          code,
          index,
          number,
          stakeholder,
          site,
          unit,
          img,
          connection,
        },
      })
        .then(() => {
          enqueueSnackbar(`Project ${name} was successfully updated.`, BASE_SUCCESS_NOTIFICATION_OPTIONS);
        })
        .then(onSuccess)
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        }),
    [update, enqueueSnackbar, projectId],
  );

  return {
    update: handleUpdate,
    loading,
  };
};
