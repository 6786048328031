import { Permissions } from 'modules/shared/constants';
import { Project } from 'modules/shared/hooks/api/projects';
import { createContext } from 'react';

export const ProjectsContext = createContext<{
  loading: boolean;
  projects: Project[];
  projectsPerEnv: Record<string, Project[]>;
  superUserProjectsPerEnv: Record<string, Project[]>;
  projectsWithSuperUserRole: Project[];
  permissionByProject: Record<string, Permissions>;
}>({
  loading: false,
  projects: [],
  projectsPerEnv: {},
  superUserProjectsPerEnv: {},
  projectsWithSuperUserRole: [],
  permissionByProject: {},
});
