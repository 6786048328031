import instance from 'modules/rest/axios';

export type RegisterData = {
  username: string;
  email: string;
  password: string;
};

export const register = (data: RegisterData) => {
  return instance({
    method: 'post',
    url: '/auth/register',
    data,
  });
};
