import { useQuery } from '@apollo/client';

import { LOAD_DATA_TEMPLATE_NOTES } from '../gql';
import { DataTemplateNotes } from '../types';

export const useLoadDataTemplateNotes = (connection: string, dtTemplateId: string, projectId: string) => {
  const { data, loading } = useQuery(LOAD_DATA_TEMPLATE_NOTES, {
    variables: {
      connection,
      dtTemplateId,
      projectId,
    },
    skip: !connection || !dtTemplateId,
    notifyOnNetworkStatusChange: true,
  });

  const dataTemplateNotes: DataTemplateNotes[] = data?.dataTemplateNotes || [];

  return {
    dataTemplateNotes,
    loading,
  };
};
