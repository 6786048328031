import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LOAD_HDFS_TEMPLATES_PER_CATEGORY } from '../gql';

export const useLoadHdfsTemplatesPerCategory = ({ connection, projectId, isPrivate, category }, skip = false) => {
  const [templates, setTemplates] = useState<string[]>([]);
  const { loading, data } = useQuery(LOAD_HDFS_TEMPLATES_PER_CATEGORY, {
    variables: {
      connection,
      projectId,
      isPrivate,
      category,
    },
    skip: !category || !connection || !projectId || skip,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setTemplates(data?.readHdfsTeplatesPerCategory?.folders || []);
  }, [data]);

  return {
    loading,
    templates,
    updateTemplates: setTemplates,
  };
};
