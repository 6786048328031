import { useQuery } from '@apollo/client';
import { LOAD_DASHBOARD_ITEMS } from '../gql';
import { Category } from '../interfaces/category';

const BASE_CATEGORIES = [
  {
    id: 'usage_stats',
    name: 'Usage Stats',
  },
  {
    id: 'data_stores',
    name: 'Data Stores',
  },
  {
    id: 'digital_threads',
    name: 'Digital Threads',
  },
];

export const BASE_CATEGORIES_IDS = BASE_CATEGORIES.map((it) => it.id);

export const useCategories = ({ connection, projectId }) => {
  const { data, loading } = useQuery(LOAD_DASHBOARD_ITEMS, {
    variables: {
      connection,
      projectId,
    },
    skip: !connection || !projectId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const categories: Category[] = data?.dashboardItems || [];
  return {
    categories: BASE_CATEGORIES.concat(categories),
    loading,
  };
};
