import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { LOAD_CUSTOM_CHECKING_ITEMS } from '../gql';
import { CheckingItem } from '../types';

export const useLoadCustomCheckingItems = (connection: string, projectId: string) => {
  const [customItems, setCustomItems] = useState<CheckingItem[]>([]);
  const { data, loading } = useQuery(LOAD_CUSTOM_CHECKING_ITEMS, {
    variables: {
      connection,
      projectId,
    },
    skip: !connection || !projectId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCustomItems(data?.customCheckingItems || []);
  }, [data]);

  return {
    addCustomItem: useCallback((newItem: CheckingItem) => {
      setCustomItems((prev) => prev.concat(newItem));
    }, []),
    customItems,
    loading,
  };
};
