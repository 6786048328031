import { memo } from 'react';

import LoginUi from 'modules/login-ui';

const Login = () => {
  return (
    <>
      <LoginUi />
    </>
  );
};

export default memo(Login);
