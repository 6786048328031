import * as React from 'react';
import MuiButton from '@material-ui/core/Button';
import { ButtonProps, makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

const useStyles = makeStyles(() => ({
  contained: {
    backgroundColor: BorNeoColors.primaryColor1,
    color: BorNeoColors.primaryColor4,
    '&:hover': {
      backgroundColor: BorNeoColors.primaryColor2,
    },
  },
}));

const Button: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  const { variant, style, ...otherProps } = props;
  return (
    <MuiButton
      className={classes[variant as string] || ''}
      variant={variant || 'outlined'}
      style={{ minWidth: 120, height: 36, ...style }}
      {...otherProps}
    />
  );
};

export default Button;
