import classnames from 'classnames';
import { useStyles } from './styles';

export type LogoProps = {
  className?: string;
};

const Logo = ({ className }: LogoProps) => {
  const classes = useStyles();

  return <div className={classnames(classes.image, className)} />;
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
