import { BoardSchedulerItems } from 'modules/shared/components/Scheduler/constants';

export const POC_MANUAL = 'poc-manual';
export const POC_AUTOMATION = 'poc-automation';
export const CHECKLIST = 'checklist';
export const START = 'start';
export const END = 'end';
export const DATA_TRANSFER_KEY = 'type';

const basePocShapeConfig = {
  type: 'poc-card',
  title: 'Title',
  width: 400,
  height: 200,
};

export const ShapeConfig = {
  [POC_MANUAL]: {
    ...basePocShapeConfig,
    metadata: {
      actionable: false,
      datePlan: null,
      dateActual: null,
    },
  },
  [POC_AUTOMATION]: {
    ...basePocShapeConfig,
    metadata: {
      actionable: true,
      dateActual: null,
      datePlan: null,
      dataspaceLink: '',
      flowLink: '',
      docLink: '',
      flowsToExecute: [],
    },
  },
  [CHECKLIST]: {
    type: CHECKLIST,
    title: 'Title',
    width: 400,
    height: 200,
    metadata: {
      dateActual: null,
      datePlan: null,
    },
  },
  [START]: {
    type: START,
    title: 'Start',
    width: 75,
    height: 75,
    metadata: {},
  },
  [END]: {
    type: END,
    title: 'End',
    width: 75,
    height: 75,
    metadata: {},
  },
};

export const INITIAL_BOARD_CONFIG = { scale: 4, x: 2000, y: 2000 };

export const INITIAL_BOARD_SCHEDULER = {
  scheduler: BoardSchedulerItems[0].key,
  schedulerValue: null,
  schedulerLabel: `@${BoardSchedulerItems[0].value}`,
  schedulerType: '',
  isActive: false,
};

export type AvailableTypes = keyof typeof ShapeConfig;
