import { useCallback, useState } from 'react';

export const useBuilderContextMenuState = () => {
  const [menuConfig, setMenuConfig] = useState<{
    isOpen: boolean;
    top: number;
    left: number;
    meta: Record<string, any>;
  }>({ isOpen: false, top: 0, left: 0, meta: {} });

  const handleMenuClose = useCallback(() => {
    setMenuConfig({ isOpen: false, top: 0, left: 0, meta: {} });
  }, []);

  const openMenu = useCallback(({ x, y, ...meta }) => {
    setMenuConfig({ isOpen: true, top: x, left: y, meta });
  }, []);

  return {
    menuConfig,
    handleMenuClose,
    openMenu,
  };
};
