import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tableWrapper: {
    height: '100%',
  },
  hoverableTable: {
    '& .dx-data-row.dx-state-hover > td': {
      transition: 'background-color 0.2s ease',
      cursor: 'pointer',
      backgroundColor: '#e4e4e4',
    },
  },

  fullHeightContainer: {
    position: 'relative',
    flex: 1,
  },
  fullHeightWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
