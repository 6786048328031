import { memo, ReactNode } from 'react';
import { Button } from '@material-ui/core';
import { noop } from 'modules/shared/utils';
import { useStyles } from './styles';

type Props = {
  submitText?: ReactNode;
  cancelText?: ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  form?: string;
  disabled?: boolean;
};

const Actions = ({ onCancel, onSubmit, submitText, cancelText, form, disabled }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      <Button variant="contained" color="default" type="button" onClick={onCancel} disabled={disabled}>
        {cancelText}
      </Button>
      <div className={classes.divider} />
      <Button
        {...(form ? { form } : {})}
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSubmit}
        disabled={disabled}
      >
        {submitText}
      </Button>
    </div>
  );
};

Actions.defaultProps = {
  form: '',
  onCancel: noop,
  onSubmit: noop,
  cancelText: 'Cancel',
  submitText: 'Submit',
};

export default memo(Actions);
