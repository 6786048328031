import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LOAD_DELTA_TABLES_PER_CATEGORY } from '../gql';

export const useLoadDeltaTablesPerCategory = ({ connection, projectId, category }, skip = false) => {
  const [tables, setTables] = useState<string[]>([]);
  const { loading, data } = useQuery(LOAD_DELTA_TABLES_PER_CATEGORY, {
    variables: {
      connection,
      projectId,
      category,
    },
    skip: !category || !connection || !projectId || skip,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setTables(data?.readDeltaTablesPerCategory || []);
  }, [data]);

  return {
    loading,
    tables,
    updateTables: setTables,
  };
};
