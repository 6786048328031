import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { CREATE_BN_SETTING_CATEGORY, DELETE_BN_SETTING_CATEGORY, UPDATE_BN_SETTING_CATEGORY } from '../gql';

export const useBnSettingsCategoriesActions = ({ connection }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createBnSettingCategory] = useMutation(CREATE_BN_SETTING_CATEGORY, {
    update(cache, { data: { createBnSettingCategory: createBnSettingCategoryData } }) {
      cache.modify({
        fields: {
          allBnSettingsCategories(existing) {
            return [...existing, createBnSettingCategoryData];
          },
        },
      });
    },
  });

  const [updateBnSettingCategory] = useMutation(UPDATE_BN_SETTING_CATEGORY, {
    update(cache, { data: { updateBnSettingCategory: updateBnSettingCategoryData } }) {
      cache.modify({
        fields: {
          allBnSettingsCategories(existing) {
            return existing.map((s) => {
              if (s.id === updateBnSettingCategoryData.id) {
                return updateBnSettingCategoryData;
              }

              return s;
            });
          },
        },
      });
    },
  });

  const [deleteBnSettingCategory] = useMutation(DELETE_BN_SETTING_CATEGORY, {
    update(cache, { data: { deleteBnSettingCategory: deleteBnSettingCategoryData } }) {
      cache.modify({
        fields: {
          allBnSettingsCategories(existing) {
            return existing.filter((s) => s.id !== deleteBnSettingCategoryData);
          },
        },
      });
    },
  });

  const create = useCallback(
    (name: string) => {
      return createBnSettingCategory({
        variables: {
          name,
          connection,
        },
      })
        .then((data) => {
          return data.data.createBnSettingCategory;
        })
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [createBnSettingCategory, enqueueSnackbar, connection],
  );

  const update = useCallback(
    (id: string, name: string) => {
      return updateBnSettingCategory({
        variables: {
          id,
          name,
          connection,
        },
      })
        .then((data) => {
          return data.data.updateBnSettingCategory;
        })
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [updateBnSettingCategory, enqueueSnackbar, connection],
  );

  const remove = useCallback(
    (id: string) => {
      return deleteBnSettingCategory({
        variables: {
          id,
          connection,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [deleteBnSettingCategory, enqueueSnackbar, connection],
  );

  return { create, update, remove };
};
