import { gql } from '@apollo/client';

export const PROJECT_CREATED_SUBSCRIPTION = gql`
  subscription ProjectCreatedSubscription($id: String!) {
    projectCreatedSubscription(user_id: $id) {
      type
      project {
        project {
          projectId
          projectName
          description
          data {
            key
            value
          }
        }
        permission
        projectConnection
      }
    }
  }
`;
