import { memo, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import { useSearch } from 'modules/shared/hooks/url';
import { ReactComponent as DigitalThreadsLogo } from './digitalthreads.svg';
import { useStyles } from './styles';

import { DraggableShapeProps } from '../types';

const SpiderViewItem = ({ id, title, metadata = {}, width, height }: DraggableShapeProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { get } = useSearch();
  const page = get('page');

  const { onClick } = metadata;
  const { onBoxClick } = metadata;

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDtItemClick = (event: any, getDtTitle: any) => {
    event.stopPropagation();
    onClick(getDtTitle);
    setAnchorEl(null);
  };

  const handleBoxDtItemClick = (clickable: boolean, getTitle: any) => {
    if (clickable || page === 'dt-checking') {
      onBoxClick(getTitle);
    }
  };

  const renderMenuShape = () => {
    const dts = metadata?.dtsPerType?.[title] || [];
    const statuses = metadata?.statuses;

    if (dts.length) {
      return (
        <>
          <div role="button" className={classes.dtItemIcon} onClick={handleClick} onKeyDown={handleClick} tabIndex={-1}>
            <DigitalThreadsLogo style={{ width: '30px', height: '30px' }} />
          </div>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {dts.map((dt) => {
              return (
                <MenuItem key={dt} onClick={(e) => handleDtItemClick(e, dt)}>
                  <div className={statuses.includes(dt) ? classes.active : classes.notActive} />
                  {dt}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      );
    }
  };

  const {
    clickable,
    color,
    backgroundColor,
    boxShadow,
    fontSize,
    topConnectionsCount,
    leftConnectionsCount,
    rightConnectionsCount,
    bottomConnectionsCount,
  } = metadata;

  const renderConnectionsShape = (type: string, amount: number, horizontal: boolean) => {
    if (amount) {
      return (
        <div
          className={classnames(classes.connectionsWrapper, {
            [classes.connectionsWrapperHorizontal]: horizontal,
          })}
        >
          {new Array(amount).fill(null).map((_, idx) => {
            // eslint-disable-next-line react/no-array-index-key
            return <div id={`${id}-${type}-${idx + 1}`} key={idx} className={classes.connectionsElement} />;
          })}
        </div>
      );
    }
  };

  return (
    <div
      className={`${classes.container} ${clickable ? 'highlight' : ''}`}
      style={{
        color,
        backgroundColor,
        boxShadow,
        width,
        height,
      }}
      onClick={() => {
        handleBoxDtItemClick(clickable, title);
      }}
      onKeyDown={() => {
        handleBoxDtItemClick(clickable, title);
      }}
      role="button"
      tabIndex={0}
    >
      <span
        className={classes.text}
        style={{
          fontSize,
        }}
      >
        {title}
      </span>

      {renderMenuShape()}

      {renderConnectionsShape('top', topConnectionsCount, false)}
      {renderConnectionsShape('left', leftConnectionsCount, true)}
      {renderConnectionsShape('right', rightConnectionsCount, true)}
      {renderConnectionsShape('bottom', bottomConnectionsCount, false)}
    </div>
  );
};

export default memo(SpiderViewItem);
