import { useQuery, useSubscription } from '@apollo/client';
import { CheckingDataItem } from '../constants';
import { LOAD_CHECKING_DATA, ON_UPDATE_CHECKING_DATA } from '../gql';

export const useLoadCheckingData = (connection: string, projectId: string, root: string, isCategory: boolean) => {
  const { data, loading } = useQuery(LOAD_CHECKING_DATA, {
    variables: {
      connection,
      projectId,
      root,
      isCategory,
    },
    skip: !connection || !projectId || !root,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useSubscription(ON_UPDATE_CHECKING_DATA, {
    variables: {
      connection,
      projectId,
    },
    skip: !connection || !projectId,
    onData: ({ client, data: subscriptionData }) => {
      client.cache.modify({
        fields: {
          checkingData(prev) {
            return prev.map((item) => {
              if (item.itemId === subscriptionData.data.onUpdateCheckingItem.itemId) {
                return {
                  ...item,
                  ...subscriptionData.data.onUpdateCheckingItem,
                };
              }

              return item;
            });
          },
        },
      });
    },
  });

  const checkingData: CheckingDataItem[] = data?.checkingData || [];

  return {
    checkingData,
    loading,
  };
};
