import { useQuery } from '@apollo/client';

import { LOAD_ALL_DATA_TEMPLATES } from '../gql';
import { DataTemplateInfo } from '../types';

export const useLoadAllDataTemplates = (connection: string) => {
  const { data, loading } = useQuery(LOAD_ALL_DATA_TEMPLATES, {
    variables: {
      connection,
    },
    skip: !connection,
    notifyOnNetworkStatusChange: true,
  });

  const allDataTemplates: DataTemplateInfo[] = data?.allDataTemplates || [];

  return {
    allDataTemplates,
    loading,
  };
};
