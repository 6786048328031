import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    flex: 1,
  },
  label: {
    marginBottom: 5,
    transform: 'scale(0.75)',
  },
  input: {
    opacity: 0,
    height: 0,
    width: 20,
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    touchAction: 'none',
    pointerEvents: 'none',
  },
}));
