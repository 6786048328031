import { makeStyles, Theme } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  indexViewModal: {
    height: 'calc(100% - 100px)',
  },
  appBarRoot: {
    background: BorNeoColors.accentColor2,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  modalDtButton: {
    padding: '5px',
    background: BorNeoColors.accentColor2,
    color: BorNeoColors.primaryColor4,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: BorNeoColors.primaryColor1,
      color: BorNeoColors.primaryColor4,
    },
    borderRadius: 4,
  },
  modalDefaultDtButton: {
    padding: '5px',
    background: BorNeoColors.accentColor2,
    color: BorNeoColors.primaryColor4,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: BorNeoColors.primaryColor2,
      color: BorNeoColors.primaryColor4,
    },
    borderRadius: 4,
  },
  selectedDtButton: {
    background: BorNeoColors.primaryColor1,
    '&:hover': {
      color: BorNeoColors.primaryColor4,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
}));
