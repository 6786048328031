import { ReactNode } from 'react';
import classnames from 'classnames';

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';

import { useStyles } from './styles';

export type MenuLinkProps = {
  path: string;
  isSelected: boolean;
  isHighlighted: boolean;
  icon: any;
  label: string;
  isOpen: boolean;
  additionalIcon?: ReactNode;
};

const MenuLink = ({ path, isSelected, isHighlighted, icon, label, isOpen, additionalIcon }: MenuLinkProps) => {
  const IconComponent = icon;
  const classes = useStyles();

  return (
    <Tooltip title={label}>
      <ListItem
        button
        component="a"
        target="_blank"
        href={path}
        key={path}
        disableRipple
        className={classnames(classes.listItem, {
          [classes.highlighted]: isHighlighted,
          [classes.selected]: isSelected,
        })}
      >
        <ListItemIcon className={classes.iconWrapper}>
          <IconComponent className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          className={classnames(classes.text, {
            [classes.visible]: isOpen,
            [classes.hidden]: !isOpen,
          })}
          primary={label}
        />
        {additionalIcon}
      </ListItem>
    </Tooltip>
  );
};

export default MenuLink;
