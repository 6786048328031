import { memo, useMemo } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { Box, Typography } from '@material-ui/core';

import { useLoadDataTemplateFieldsDefinitions } from 'modules/digital-threads/hooks';
import Grid from 'modules/shared/components/Grid';

import DataTypeSelect from '../data-type-select';

import { useStyles } from './styles';

const ModalDefinition = ({ dataTemplates, selectedDataTemplate, onChange, connection }) => {
  const classes = useStyles();
  const { load } = useLoadDataTemplateFieldsDefinitions(selectedDataTemplate, true, connection);

  const dataSource = useMemo(
    () =>
      new CustomStore({
        load,
      }),
    [load],
  );

  return (
    <Box className={classes.definitionContainer}>
      {dataTemplates ? (
        <>
          <DataTypeSelect
            dataTemplates={dataTemplates}
            selectedDataTemplate={selectedDataTemplate}
            onChange={onChange}
          />
          <Box className={classes.wrapperContainer}>
            <Box className={classes.dataContainer}>
              <Grid
                dataSource={dataSource}
                gridName="digital-threads-definition"
                gridOptions={{
                  groupPanel: {
                    visible: false,
                  },
                  height: '100%',
                }}
                columns={[
                  { dataField: 'name', allowSorting: false, width: '20%' },
                  { dataField: 'description', allowSorting: false, alignment: 'left', width: '60%' },
                  {
                    dataField: 'required',
                    allowSorting: false,
                    width: '10%',
                    cellRender: ({ data }) => (data.required ? 'Yes' : 'No'),
                  },
                  {
                    dataField: 'key',
                    allowSorting: false,
                    width: '10%',
                    cellRender: ({ data }) => (data.required ? 'Yes' : 'No'),
                  },
                  { dataField: 'type', allowSorting: false, width: '10%' },
                ]}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Typography>No Data to Display</Typography>
      )}
    </Box>
  );
};

export default memo(ModalDefinition);
