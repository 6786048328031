import { gql } from '@apollo/client';

export const UPDATE_STATE_FLOW_SOLUTION_SUBSCRIPTION = gql`
  subscription ChangeFlowSolutionStateSubscription($userId: String!) {
    changeFlowSolutionStateSubscription(user_id: $userId) {
      boardId
      currentState
    }
  }
`;

export const UPDATE_NEW_FLOW_SOLUTION_BOARD_TRACKING_SUBSCRIPTION = gql`
  subscription NewFlowSolutionBoardTrackingSubscription($userId: String!) {
    newFlowSolutionBoardTrackingSubscription(user_id: $userId) {
      boardId
      shapeId
      status
      state
      createdAt
      trackingId
      isReset
      completedAt
      metadata
      checkedChecklistItems {
        status
        setUp
        signOff
      }
    }
  }
`;

export const MODIFY_FLOW_SOLUTION_BOARD_TRACKING_SUBSCRIPTION = gql`
  subscription ModifyFlowSolutionBoardTrackingSubscription($userId: String!) {
    modifyFlowSolutionBoardTrackingSubscription(user_id: $userId) {
      boardId
      shapeId
      status
      state
      createdAt
      trackingId
      completedAt
      metadata
      checkedChecklistItems {
        status
        setUp
        signOff
      }
    }
  }
`;
