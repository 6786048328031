import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 640px)',
  },
  cellPlaceholderValue: {
    color: 'grey',
  },
  success: {
    backgroundColor: 'rgb(196 241 209) !important',
    fontWeight: 500,
  },
  error: {
    backgroundColor: 'rgb(236 94 142 / 65%) !important',
    fontWeight: 500,
  },
}));
