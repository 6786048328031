import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: BorNeoColors.primaryColor6,
  },
  chartContainer: {
    flexGrow: 1,
    position: 'relative',
    background: BorNeoColors.primaryColor6,
  },
  chartWrapper: {
    background: BorNeoColors.primaryColor6,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
