import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { LOAD_STAKEHOLDERS } from '../gql';
import { Stakeholder, PlantSite, PlantUnit } from '../types';

const mapData = (data) => {
  const sites = {};
  const units = {};

  const stakeholders = data.map((stakeholderData) => {
    const { plantSites, ...rest } = stakeholderData;

    const stStites = plantSites.map((site) => {
      const { plantUnits, ...restSite } = site;

      units[restSite.siteId] = plantUnits.map((u) => {
        const parsed: PlantUnit = {
          unitId: u.unitId,
          name: u.name,
        };

        return parsed;
      });

      const result: PlantSite = {
        label: restSite.label,
        siteId: restSite.siteId,
      };

      return result;
    });

    sites[rest.stakeholderId] = stStites;

    const result: Stakeholder = {
      stakeholderId: rest.stakeholderId,
      orgName: rest.orgName,
    };

    return result;
  });

  return { stakeholders, sites, units };
};

export const useLoadStakeholders = ({ skip }) => {
  const { loading, data, error, refetch } = useQuery(LOAD_STAKEHOLDERS, { skip });

  const { stakeholders, sites, units } = useMemo(
    () => mapData(data?.stakeholdersWithRelations || []),
    [data?.stakeholdersWithRelations],
  );

  return { loading, stakeholders, sites, plants: units, error, refetch };
};
