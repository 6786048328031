import { Status } from '../types';
import getState from './getState';

export const getStatus = (state): Status => {
  return getState(state).status;
};

export const getIsLoggedIn = (state) => {
  return getStatus(state).isLoggedIn;
};

export const getIsLoading = (state) => {
  return getStatus(state).isLoading;
};
