import { useRef } from 'react';
import { ConnectionsContext } from './context';

const ConnectionsProvider = ({ children }) => {
  const data = useRef<{ deactivated: string; updated: string }>({
    deactivated: '',
    updated: '',
  });

  return <ConnectionsContext.Provider value={data.current}>{children}</ConnectionsContext.Provider>;
};

export default ConnectionsProvider;
