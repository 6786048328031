import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { LOAD_DATA_TEMPLATES } from '../gql';
import { DataTemplateInfo } from '../types';

export const useLoadDataTemplates = (connection: string, dtId: string) => {
  const client = useApolloClient();

  const { data, loading } = useQuery(LOAD_DATA_TEMPLATES, {
    variables: {
      connection,
      digitalThreadId: dtId,
    },
    fetchPolicy: 'network-only',
    skip: !connection || !dtId,
    notifyOnNetworkStatusChange: true,
  });

  const dataTemplates: DataTemplateInfo[] = data?.dataTemplates || [];

  const load = useCallback(async () => {
    if (!connection || !dtId) {
      return {
        data: [],
        totalCount: 0,
      };
    }

    const response = await client.query({
      query: LOAD_DATA_TEMPLATES,
      fetchPolicy: 'network-only',
      context: {
        requestId: 'load-datatemplates-request',
        queryDeduplication: false,
      },
      variables: {
        connection,
        digitalThreadId: dtId,
      },
    });

    const items = response.data?.dataTemplates || [];

    return { data: JSON.parse(JSON.stringify(items)), totalCount: items.length };
  }, [client, connection, dtId]);

  return {
    dataTemplates,
    loading,
    load,
  };
};
