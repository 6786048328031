import { StatusColors, StatusDescription } from 'modules/digital-threads/constants';
import { memo } from 'react';

import { useStyles } from './styles';

const LegendBox = ({ status, hideTooltip = false, color = '' }) => {
  const classes = useStyles();

  return (
    <div
      className={hideTooltip ? classes.box : `${classes.box} ${classes.tooltip} ${classes.fade}`}
      data-title={hideTooltip ? '' : StatusDescription[status] || status}
      style={{
        backgroundColor: color || StatusColors[status],
      }}
    />
  );
};

export default memo(LegendBox);
