import { memo, useContext, useMemo } from 'react';
import { Alert, Collapse, Grid } from '@mui/material';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { BnSettingsState } from '../context/DataStoresState';
import { useLoadSharepointDrives } from '../hooks/useLoadSharepointDrives';
import SelectOrCreateBnSettingInput from '../SelectOrCreateBnSettingInput';
import SelectOrCreateBnSettingList from '../SelectOrCreateBnSettingList';

const TENANT_ID = 'tenantId';
const CLIENT_ID = 'clientId';
const CLIENT_SECRET = 'clientSecret';
const SITE = 'site';
const DRIVE = 'drive';

const SharePointFields = ({ values, onValueChange }) => {
  const { settings, settingsLoading } = useContext(BnSettingsState);

  const items = useMemo(
    () =>
      settings.map((s) => ({
        value: s.name,
        label: s.name,
      })),
    [settings],
  );

  const tenantId = values[TENANT_ID];
  const clientId = values[CLIENT_ID];
  const clientSecret = values[CLIENT_SECRET];
  const site = values[SITE];
  const drive = values[DRIVE];

  const { connection, projectId } = useProjectInfoFromSearch();

  const {
    loading: drivesLoading,
    drives,
    error: drivesError,
  } = useLoadSharepointDrives({
    connection,
    projectId,
    clientId,
    clientSecret,
    tenantId,
    site,
  });

  return (
    <>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={TENANT_ID}
          label="Tenant Id"
          value={tenantId}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(TENANT_ID, value);
            onValueChange(SITE, '');
            onValueChange(DRIVE, '');
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={CLIENT_ID}
          label="Client Id"
          value={clientId}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(CLIENT_ID, value);
            onValueChange(SITE, '');
            onValueChange(DRIVE, '');
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={CLIENT_SECRET}
          label="Client Secret"
          value={clientSecret}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(CLIENT_SECRET, value);
            onValueChange(SITE, '');
            onValueChange(DRIVE, '');
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={SITE}
          label="Site"
          value={site}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(SITE, value);
            onValueChange(DRIVE, '');
          }}
        />
        <Collapse in={!!drivesError}>
          <Alert style={{ marginTop: 20 }} severity="error">
            Configuration is not correct
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingList
          id={DRIVE}
          label="Drive"
          value={drive}
          items={items}
          loading={drivesLoading}
          disabled={!clientId || !clientSecret || !tenantId || !site || drivesLoading}
          onChange={(value) => {
            onValueChange(DRIVE, value);
          }}
          suggestions={drives.map((d) => ({ value: d.id, label: d.name }))}
        />
      </Grid>
    </>
  );
};

export default memo(SharePointFields);
