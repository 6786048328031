import instance from 'modules/rest/axios';
import { getApiBaseUrl } from 'modules/shared/utils';

export const changePassword = (token: string, password: string) => {
  return instance({
    method: 'post',
    url: `${getApiBaseUrl()}/auth/change-password`,
    data: {
      token,
      password,
    },
  });
};
