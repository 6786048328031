import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 95vh)',

    minHeight: 'min(calc(100% - 64px), 80vh)',
    overflow: 'initial',

    maxWidth: '80%',
    minWidth: '80%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      minWidth: '90%',
    },
  },
  dialogContent: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
    gap: 20,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  buttonContainerRightSide: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  definitionContainer: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  wrapperContainer: {
    marginTop: '15px',
    position: 'relative',
    flex: 1,
  },
  dataContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  dtCheckLegend: {
    margin: '0 16px',
    fontWeight: 500,
  },
  dtSelectionButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dtSelectionButton: {
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
    backgroundColor: '#1c71b6 !important',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#2d87d5 !important',
    },
  },
  dtSelectionButtonDisabled: {
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
    backgroundColor: '#D3D3D3 !important',
    color: '#000 !important',
    '&:hover': {
      backgroundColor: '#2d87d5 !important',
      color: '#fff !important',
    },
  },
}));
