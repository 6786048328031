import { gql } from '@apollo/client';

export const LOAD_FEEDS = gql`
  query LoadNeoFeeds($connection: String!, $neoSpace: String!) {
    neoFeeds(connection: $connection, neoSpace: $neoSpace) {
      feedId
      title
      description
      createdAt
      image
      likes {
        liked
        totalCount
      }
      neoSpace {
        title
        neoSpaceId
      }
      dataFace {
        title
        dataFaceId
      }
    }
  }
`;
