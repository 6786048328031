import { memo, useState, ReactNode } from 'react';
import ControlledPageSelector from './ControlledPageSelector';
import { PageType } from './types';

type Props = {
  Pages: Array<PageType>;
  children?: ReactNode;
  [key: string]: any;
  withMargin?: boolean;
};

const PageSelector = ({ Pages, children, withMargin, ...componentProps }: Props) => {
  const [page, setPage] = useState(Pages[0]);
  const onClick = (newPage) => {
    setPage(newPage);
  };

  return (
    <ControlledPageSelector
      withMargin={withMargin}
      Pages={Pages}
      selectedPage={page}
      onChangePage={onClick}
      {...componentProps}
    >
      {children}
    </ControlledPageSelector>
  );
};

PageSelector.defaultProps = {
  children: null,
  withMargin: false,
};

export { ControlledPageSelector };
export default memo(PageSelector);
