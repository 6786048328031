import { memo } from 'react';

import { SchedulerComponents } from './constants';

const Scheduler = ({ type, value, label, onChange, disabled }) => {
  const Component = SchedulerComponents[type];

  return Component ? <Component value={value} label={label} onChange={onChange} disabled={disabled} /> : null;
};

export default memo(Scheduler);
