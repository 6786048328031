import { memo } from 'react';
import { CircularProgress } from '@material-ui/core';
import Table from 'modules/shared/components/Table';
import CustomStore from 'devextreme/data/custom_store';
import { useLoadDataTemplateFieldsDefinitions, useLoadDataTemplatesData } from 'modules/digital-threads/hooks';
import { useStyles } from './styles';

const ModalData = ({ selectedId, projectId, connection }) => {
  const classes = useStyles();

  const { fields, loading: fieldsLoading } = useLoadDataTemplateFieldsDefinitions(selectedId, false, connection);
  const { load } = useLoadDataTemplatesData(selectedId, projectId, connection);

  const customStore = new CustomStore({
    load: (options) => {
      return load({
        order: options.sort && options.sort[0].selector,
        desc: options.sort && options.sort[0].desc,
        limit: options.take,
        offset: options.skip,
        search: options.filter ? JSON.stringify(options.filter) : '',
        group: options.group ? JSON.stringify(options.group) : '',
      });
    },
  });

  return (
    <div className={classes.container}>
      {!fieldsLoading ? (
        <Table data={customStore} wordWrapEnabled pagingEnabled remoteOperations growToFullHeight>
          <Table.Column key="row_status" alignment="center" minWidth={100} dataField="row_status" caption="Status" />
          {fields.map((f) => {
            return (
              <Table.Column
                key={f.name}
                alignment="center"
                minWidth={100}
                width="150px"
                dataField={f.name}
                caption={f.name}
                allowSearch={false}
                cellRender={({ value }) => {
                  return <div title={value}>{value}</div>;
                }}
              />
            );
          })}
        </Table>
      ) : (
        <div className={classes.loader}>
          <CircularProgress size={50} />
        </div>
      )}
    </div>
  );
};

export default memo(ModalData);
