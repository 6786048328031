import {
  Widgets,
  Extension,
  AccountTree,
  Ballot,
  DoneAll,
  ListAlt,
  PlaylistPlay,
  Queue,
  TimeToLeave,
  Help,
} from '@material-ui/icons';
import { MENU_BUTTON, MENU_LINK } from './constants';

export const AppBlocks = {
  APP: 'App',
  PROFILE: 'Profile',
  TECH_STACK: 'Tech Stack',
  PROCESS: 'Process',
};

const getTechStackItems = (isAdmin: boolean, isAdminOrSuperUser: boolean, isAssignedAtLeast1Project: boolean) => {
  if (isAdmin || isAdminOrSuperUser || isAssignedAtLeast1Project) {
    return [
      // {
      //   type: MENU_DIVIDER,
      //   key: 'divider-2',
      // },
      // {
      //   type: MENU_TITLE,
      //   key: 'tech-stack',
      //   title: 'Tech stack',
      // },
      // {
      //   type: MENU_BUTTON,
      //   key: 'data-spaces',
      //   path: '/data-spaces',
      //   label: 'Data Spaces',
      //   icon: Apps,
      //   module: AppBlocks.TECH_STACK,
      // },
      // {
      //   type: MENU_BUTTON,
      //   key: 'applications',
      //   path: '/applications',
      //   label: 'App Data',
      //   icon: Apps,
      //   module: AppBlocks.TECH_STACK,
      // },
      {
        type: MENU_BUTTON,
        key: 'data-stores',
        path: '/data-stores',
        label: 'Data Stores',
        icon: Extension,
        module: AppBlocks.TECH_STACK,
      },
      {
        type: MENU_BUTTON,
        key: 'dig-threads',
        path: '/digital-threads',
        label: 'Digital Threads',
        icon: Ballot,
        module: AppBlocks.TECH_STACK,
      },
      // {
      //   type: MENU_BUTTON,
      //   key: 'integrations',
      //   path: '/integrations',
      //   label: 'Integrations',
      //   icon: AccountTree,
      //   module: AppBlocks.TECH_STACK,
      // },
      {
        type: MENU_BUTTON,
        key: 'flow-solutions',
        path: '/flow-solutions',
        label: 'Flow Solutions',
        icon: AccountTree,
        module: AppBlocks.TECH_STACK,
      },
      {
        type: MENU_BUTTON,
        key: 'flow-control',
        path: '/flow-control',
        label: 'Flow Control',
        icon: PlaylistPlay,
        module: AppBlocks.TECH_STACK,
      },
      {
        type: MENU_BUTTON,
        key: 'exec-log',
        path: '/execution-log',
        label: 'Execution Log',
        icon: ListAlt,
        module: AppBlocks.TECH_STACK,
      },
      {
        type: MENU_BUTTON,
        key: 'data-validations',
        path: '/data-validations',
        label: 'Data Validations',
        icon: DoneAll,
        module: AppBlocks.TECH_STACK,
      },
      {
        type: MENU_BUTTON,
        key: 'time-travel',
        path: '/time-travel',
        label: 'Time Travel',
        icon: TimeToLeave,
        module: AppBlocks.TECH_STACK,
      },
      ...(isAdminOrSuperUser
        ? [
            {
              type: MENU_BUTTON,
              key: 'master-data',
              path: '/master-data',
              label: 'Master Data',
              icon: Queue,
              module: AppBlocks.TECH_STACK,
            },
          ]
        : []),
      // {
      //   type: MENU_BUTTON,
      //   key: 'Neo-Space-Templates',
      //   path: '/neo-space-templates',
      //   label: 'Neo Space Templates',
      //   icon: Description,
      //   module: AppBlocks.TECH_STACK,
      // },
    ];
  }

  return [];
};

export default (isAdmin = false, isAdminOrSuperUser = false, isAssignedAtLeast1Project = false) => {
  return [
    // {
    //   type: MENU_BUTTON,
    //   key: 'dashboard',
    //   path: '/dashboard',
    //   label: 'Dashboard',
    //   icon: Dashboard,
    //   module: AppBlocks.APP,
    // },
    ...(isAdmin || isAssignedAtLeast1Project
      ? [
          // {
          //   type: MENU_BUTTON,
          //   key: 'workspace',
          //   path: '/new-dashboard',
          //   label: 'My Workspace',
          //   icon: Work,
          //   module: AppBlocks.APP,
          // },
          // {
          //   type: MENU_BUTTON,
          //   key: 'neo-board',
          //   path: '/neo-board',
          //   label: 'Neo Board',
          //   icon: Business,
          //   module: AppBlocks.APP,
          // },
        ]
      : []),
    ...(isAdmin
      ? [
          // {
          //   type: MENU_BUTTON,
          //   key: 'stakeholders',
          //   path: '/stakeholders',
          //   label: 'Stakeholders',
          //   icon: People,
          //   module: AppBlocks.APP,
          // },
        ]
      : []),
    // {
    //   type: MENU_BUTTON,
    //   key: 'assets',
    //   path: '/assets',
    //   label: 'Assets',
    //   icon: Cached,
    //   module: AppBlocks.APP,
    // },
    // {
    //   type: MENU_BUTTON,
    //   key: 'cap-programs',
    //   path: '/cap-programs',
    //   label: 'Capital Programs',
    //   icon: TabletMac,
    //   module: AppBlocks.APP,
    // },
    {
      type: MENU_BUTTON,
      key: 'projects',
      label: 'Projects',
      path: '/projects',
      icon: Widgets,
      module: AppBlocks.APP,
      // items:
      //   isAdmin || isAssignedAtLeast1Project
      //     ? [
      //         {
      //           type: MENU_BUTTON,
      //           key: 'project-overview',
      //           path: '/cap-projects/project-overviews',
      //           label: 'Project Overviews',
      //           icon: Assessment,
      //           module: AppBlocks.APP,
      //         },
      //         {
      //           type: MENU_BUTTON,
      //           key: 'workscopes',
      //           path: '/cap-projects/workscopes',
      //           label: 'Workscopes',
      //           icon: Storage,
      //         },
      //         {
      //           type: MENU_BUTTON,
      //           key: 'path-of-con',
      //           path: '/cap-projects/poc',
      //           label: 'Path Of Construction',
      //           icon: Build,
      //         },
      //         {
      //           type: MENU_BUTTON,
      //           key: 'bom-mto-con',
      //           path: '/cap-projects/bom-mto-con',
      //           label: 'BOM/MTO Consolidation',
      //           icon: ViewQuilt,
      //         },
      //         {
      //           type: MENU_BUTTON,
      //           key: 'iwp-seq',
      //           path: '/cap-projects/iwp-seq',
      //           label: 'IWP Sequence',
      //           icon: List,
      //         },
      //         {
      //           type: MENU_BUTTON,
      //           key: 'imm',
      //           path: '/cap-projects/imm',
      //           label: 'IMM',
      //           icon: Assignment,
      //         },
      //         {
      //           type: MENU_BUTTON,
      //           key: 'progress',
      //           path: '/cap-projects/progress',
      //           label: 'Progress',
      //           icon: TrendingUp,
      //         },
      //         {
      //           type: MENU_BUTTON,
      //           key: 'sys-comps',
      //           path: '/cap-projects/sys-comps',
      //           label: 'System Completions',
      //           icon: DonutLarge,
      //         },
      //       ]
      //     : [],
    },
    // {
    //   type: MENU_BUTTON,
    //   key: 'neo-spaces',
    //   path: '/neo-spaces',
    //   label: 'Neo Spaces',
    //   icon: Widgets,
    //   module: AppBlocks.APP,
    // },
    // {
    //   type: MENU_BUTTON,
    //   key: 'neo-spaces',
    //   path: '/neo-spaces',
    //   label: 'Neo Spaces',
    //   icon: Apps,
    //   module: AppBlocks.APP,
    // },
    // {
    //   type: MENU_BUTTON,
    //   key: 'users',
    //   path: '/users',
    //   label: 'Users',
    //   icon: Person,
    //   module: AppBlocks.PROFILE,
    // },
    // {
    //   type: MENU_BUTTON,
    //   key: 'companies',
    //   path: '/companies',
    //   label: 'Companies',
    //   icon: Business,
    //   module: AppBlocks.PROFILE,
    // },
    ...getTechStackItems(isAdmin, isAdminOrSuperUser, isAssignedAtLeast1Project),
    // {
    //   type: MENU_DIVIDER,
    //   key: 'divider-3',
    // },
    // {
    //   type: MENU_TITLE,
    //   key: 'process',
    //   title: 'Process',
    // },
    // {
    //   type: MENU_BUTTON,
    //   key: 'play-books',
    //   path: '/play-books',
    //   label: 'Play Books',
    //   icon: LibraryBooks,
    //   module: AppBlocks.PROCESS,
    // },
    // {
    //   type: MENU_BUTTON,
    //   key: 'job-aids',
    //   path: '/job-aids',
    //   label: 'Job Aids',
    //   icon: Note,
    //   module: AppBlocks.PROCESS,
    // },
    // {
    //   type: MENU_BUTTON,
    //   key: 'resources',
    //   path: '/resources',
    //   label: 'Resources',
    //   icon: ImportContacts,
    //   module: AppBlocks.PROCESS,
    // },
    {
      type: MENU_LINK,
      key: 'support',
      path: 'https://support.workpacks.com/hc/en-us/requests/new?ticket_form_id=11667553957785',
      label: 'Support',
      icon: Help,
      module: AppBlocks.PROCESS,
    },
    // {
    //   type: MENU_BUTTON,
    //   key: 'faq',
    //   path: '/faq',
    //   label: 'FAQ',
    //   icon: LiveHelp,
    //   module: AppBlocks.PROCESS,
    // },
  ];
};
