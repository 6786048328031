import { memo, useCallback, useContext, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { Alert, Box, Button, CircularProgress, Collapse, Grid } from '@mui/material';
import Input from 'modules/shared/components/Input';
import { useStyles } from './styles';
import { BnSettingsActions, BnSettingsState } from '../context/DataStoresState';

type Props = {
  id: string;
  label: string;
  value: string;
  items: {
    label: string;
    value: string;
  }[];
  loading: boolean;
  onChange: (val: string) => void;
};

const MODAL_ID = 'CREATE_BN_SETTING';
const FORM_ID = 'CREATE_BN_SETTING_FROM_INPUT';

const initValues = {
  key: '',
  value: '',
  category: '',
};

const SelectOrCreateBnSettingInput = ({ id, label, value: selectValue, items, loading, onChange }: Props) => {
  const { categories } = useContext(BnSettingsState);
  const formId = `${FORM_ID}_${id}`;
  const classes = useStyles();

  const handleSelectChange = useCallback(
    (v: string[]) => {
      onChange(v[0]);
    },
    [onChange],
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [creationError, setCreationError] = useState(false);
  const [creationLoading, setCreationLoading] = useState(false);

  const [{ key, value, category }, setValues] = useState(initValues);

  const handleInputChange = (event) => {
    const { id: inputId, value: inputValue } = event.target;

    setValues((prev) => ({
      ...prev,
      [inputId]: inputValue,
    }));
  };

  const handleCategoryChange = (v: string[]) => {
    setValues((prev) => ({
      ...prev,
      category: v[0],
    }));
  };

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);

    setValues(initValues);
  }, []);

  const { create } = useContext(BnSettingsActions);

  const handleDialogSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setCreationError(false);
      setCreationLoading(true);

      create(key, value, category, () => {
        setCreationError(true);
      })
        .then(() => {
          onChange(key);
          handleDialogClose();
        })
        .finally(() => {
          setCreationLoading(false);
        });
    },
    [create, onChange, key, value, category, handleDialogClose],
  );

  return (
    <>
      <Select
        id={id}
        labelText={label}
        value={[selectValue]}
        options={items}
        loading={loading}
        onChange={handleSelectChange}
        isCreatable
        onCreateOption={(newValue) => {
          setDialogOpen(true);
          setValues((prev) => ({ ...prev, value: newValue }));
        }}
      />
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <Box component="form" id={formId} onSubmit={handleDialogSubmit}>
          <Title id={`${MODAL_ID}_${id}`} onClose={handleDialogClose}>
            Add Setting
          </Title>
          <Content>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  removeMargin
                  labelText="Key"
                  id="key"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    value: key,
                    required: true,
                    onChange: handleInputChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  removeMargin
                  labelText="Value"
                  id="value"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value,
                    required: true,
                    onChange: handleInputChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  id="category"
                  labelText="Category"
                  value={[category]}
                  options={categories.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  loading={loading}
                  onChange={handleCategoryChange}
                />
              </Grid>
            </Grid>
            <Collapse in={!!creationError}>
              <Alert severity="error">Unable to create setting.</Alert>
            </Collapse>
          </Content>
          <Actions>
            <Button variant="contained" color="primary" form={formId} type="submit">
              {creationLoading ? <CircularProgress className={classes.progress} size={24} /> : 'Create'}
            </Button>
          </Actions>
        </Box>
      </Dialog>
    </>
  );
};

export default memo(SelectOrCreateBnSettingInput);
