import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#ffffff',
  },
  stepper: {
    flex: '0 0 200px',
    padding: 10,
    display: 'flex',
  },
  content: {
    flex: 1,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionsWrapper: {
    height: 52,
    boxSizing: 'border-box',
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    gap: 10,
  },
  spinnerWrap: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootChipDraft: {
    position: 'relative',
    marginTop: 5,
    '& .remove': {
      display: 'none',
    },
    '&:hover > .remove': {
      display: 'inline-flex',
      cursor: 'pointer',
      backgroundColor: '#ffba3d',
    },
    '&:hover > .draft': {
      display: 'none',
    },
  },
  chipDraft: {
    alignSelf: 'center',
    marginRight: 10,
    width: 95,
  },

  jsonsLayout: {
    flex: 1,
    display: 'flex',
    gap: 20,
  },
  jsonsContainer: { position: 'relative', flex: 1 },
  jsonWrapper: {
    position: 'absolute',
    inset: 0,
    overflow: 'scroll',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 4,
  },
}));
