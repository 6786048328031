import { gql } from '@apollo/client';

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $name: String!
    $description: String
    $code: String!
    $index: String!
    $number: String
    $stakeholder: String
    $site: String
    $unit: String
    $img: Upload!
    $connection: String!
    $meta: CreateProjectMetaInput!
  ) {
    createProject(
      name: $name
      description: $description
      code: $code
      index: $index
      number: $number
      stakeholder: $stakeholder
      site: $site
      unit: $unit
      img: $img
      connection: $connection
      meta: $meta
    )
  }
`;

export const UPDATE_PROJECT_INFO = gql`
  mutation UpdateProjectInfo(
    $projectId: String!
    $name: String!
    $description: String
    $code: String!
    $index: String!
    $number: String
    $stakeholder: String
    $site: String
    $unit: String
    $img: Upload
    $connection: String!
  ) {
    updateProjectInfo(
      project_id: $projectId
      name: $name
      description: $description
      code: $code
      index: $index
      number: $number
      stakeholder: $stakeholder
      site: $site
      unit: $unit
      img: $img
      connection: $connection
    ) {
      project {
        projectId
        projectName
        description
        data {
          key
          value
        }
      }
      permission
      projectConnection
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: String!, $connection: String!) {
    deleteProject(project_id: $projectId, connection: $connection) {
      id
      connection
    }
  }
`;

export const IMPORT_WP_PROJECT = gql`
  mutation importWpProject($client: String!, $env: String!, $project: String!, $meta: CreateProjectMetaInput!) {
    importWpProject(client: $client, env: $env, project: $project, meta: $meta)
  }
`;
