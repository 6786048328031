import { Connection, Shape } from 'modules/shared/components/DraggableLayout';
import { endConnectionDelimeter, OUTPUT, startConnectionDelimeter, TRANSFORMATION } from '../constants';

const processInputShapeColumn = (shapeId: string, column: string, shapes: Shape[], connections: Connection[]) => {
  const startId = `${shapeId}${startConnectionDelimeter}${column}`;

  const startConnections = connections.filter((c) => c.start === startId);

  if (!startConnections.length) {
    return [];
  }

  const recursiveParse = (c: Connection, d) => {
    const [idToSearch, endConnectionColumn] = c.end.split(endConnectionDelimeter);

    const endShape = shapes.find((s) => s.id === idToSearch);
    if (!endShape) {
      d.source = '';

      return d;
    }

    if (endShape.type === TRANSFORMATION) {
      const { id } = endShape;
      const endConnection = connections.find((c1) => c1.start === id)!;

      d.rules.push({
        [endShape.metadata.type]: endShape.metadata.config,
      });

      return recursiveParse(endConnection, d);
    }

    if (endShape.type === OUTPUT) {
      d.target = endConnectionColumn;

      return d;
    }

    d.source = '';

    return d;
  };

  return startConnections.reduce((acc, startConnection) => {
    const data = {
      source: column,
      target: '',

      rules: [],
    };

    const result = recursiveParse(startConnection, data);

    if (result.source && result.target) {
      const { source, target, rules } = result;
      return acc.concat({
        result: {
          source,
          target,
          defaultValue: '',
          ...(rules.length ? { rules } : {}),
        },
      });
    }

    return acc;
  }, [] as { result: ParsedConnection }[]);
};

type ParsedConnection = {
  source: string;
  target: string;
  defaultValue: string;
  rules?: {
    [key: string]: string;
  }[];
};

export const processInputShape = (shape: Shape, allShapes: Shape[], connections: Connection[]) => {
  const {
    metadata: { columns = [], category, template, item },
    id,
  } = shape;

  if (!columns) {
    throw new Error('Unable to process step. There is no imported source');
  }

  const outputShape: Shape = allShapes.find((s) => s.type === OUTPUT)!;

  const results = columns.reduce((acc, c) => {
    const parsed = processInputShapeColumn(id, c, allShapes, connections);

    if (parsed.length) {
      parsed.forEach((p) => {
        p.result.defaultValue = outputShape.metadata.defaultValues?.[p.result.target] ?? '';
      });
    }

    return acc.concat(parsed);
  }, []);

  const mappings = results.map((r) => r.result);

  return {
    config: {
      category,
      template,
      item,
    },
    mappings,
  };
};
