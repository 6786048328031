import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback, useState } from 'react';
import { CREATE_PROJECT } from '../gql';

export const useCreateProject = ({ onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [create] = useMutation(CREATE_PROJECT);

  const handleCreate = useCallback(
    async ({ name, description, code, index, number, stakeholder, site, unit, img, connection, meta }) => {
      setLoading(true);
      try {
        await create({
          variables: {
            name,
            description,
            code,
            index,
            number,
            stakeholder,
            site,
            unit,
            img,
            connection,
            meta,
          },
        });
        onSuccess();
        enqueueSnackbar('We will notify you when project will be created', BASE_SUCCESS_NOTIFICATION_OPTIONS);
      } catch (e) {
        enqueueSnackbar('Unable to start project creation', BASE_ERROR_NOTIFICATION_OPTIONS);
      } finally {
        setLoading(false);
      }
    },
    [create, enqueueSnackbar, onSuccess],
  );

  return {
    create: handleCreate,
    loading,
  };
};
