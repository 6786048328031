import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useLoadFlowById } from './useLoadFlowById';

export const useFlowInfo = (flowId: string) => {
  const { projectId, connection } = useProjectInfoFromSearch();

  const { loading, flow, refetch } = useLoadFlowById({
    flowId,
    projectId,
    connection,
  });

  return {
    loading,
    flow,
    refetch,
  };
};
