import loadable from '@loadable/component';
import { CircularProgress } from '@material-ui/core';

import { retryLoader } from 'modules/shared/utils/retryLoader';

import Login from './Login';
import Home from './Home';
import Dashboard from './Dashboard';
import Users from './Users';
import CapitalProjects from './CapitalProjects';
import Workspace from './Workspace';
import NeoSpaces from './Neo-Spaces';
import DigitalThreads from './DigitalThreads';
import DataSpaces from './DataSpaces';
import DataStores from './DataStores';
import NewDashboard from './NewDashboard';
import SSO from './SSO';

const Loader = () => {
  return (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={50} />
    </div>
  );
};

const DataValidations = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "DataValidations" */ './DataValidations')),
  {
    fallback: <Loader />,
  },
);
const FlowControl = loadable(() => retryLoader(() => import(/* webpackChunkName: "FlowControl" */ './FlowControl')), {
  fallback: <Loader />,
});
const ExecutionLog = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "ExecutionLog" */ './ExecutionLog')),
  {
    fallback: <Loader />,
  },
);

const Integrations = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "Integrations" */ './Integrations')),
  {
    fallback: <Loader />,
  },
);
const Applications = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "Applications" */ './Applications')),
  {
    fallback: <Loader />,
  },
);
const Assets = loadable(() => retryLoader(() => import(/* webpackChunkName: "Assets" */ './Assets')), {
  fallback: <Loader />,
});
const Stakeholders = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "Stakeholders" */ './Stakeholders')),
  {
    fallback: <Loader />,
  },
);
const FlowSolutions = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "FlowSolutions" */ './FlowSolutions')),
  {
    fallback: <Loader />,
  },
);
const EditFlowSolutionBoard = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "EditFlowSolutionBoard" */ './EditFlowSolutionBoard')),
  {
    fallback: <Loader />,
  },
);
const NeoSpaceTemplates = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "NeoSpaceTemplates" */ './NeoSpaceTemplates')),
  {
    fallback: <Loader />,
  },
);

const Project = loadable(() => retryLoader(() => import(/* webpackChunkName: "Project" */ './Project')), {
  fallback: <Loader />,
});
const NeoBoard = loadable(() => retryLoader(() => import(/* webpackChunkName: "NeoBoard" */ './NeoBoard')), {
  fallback: <Loader />,
});
const TimeTravel = loadable(() => retryLoader(() => import(/* webpackChunkName: "TimeTravel" */ './TimeTravel')), {
  fallback: <Loader />,
});
const ConfigTables = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "ConfigTables" */ './ConfigTables')),
  {
    fallback: <Loader />,
  },
);

const ConnectionManager = loadable(
  () => retryLoader(() => import(/* webpackChunkName: "ConnectionManager" */ './ConnectionManager')),
  {
    fallback: <Loader />,
  },
);

export {
  Login,
  Dashboard,
  Home,
  Users,
  FlowControl,
  DataValidations,
  ExecutionLog,
  CapitalProjects,
  Integrations,
  Applications,
  Workspace,
  Assets,
  Stakeholders,
  NeoSpaces,
  FlowSolutions,
  NeoSpaceTemplates,
  DigitalThreads,
  Project,
  DataSpaces,
  NeoBoard,
  TimeTravel,
  DataStores,
  ConfigTables,
  EditFlowSolutionBoard,
  NewDashboard,
  ConnectionManager,
  SSO,
};
