import { useCallback, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';

import { displayTimeValue } from 'modules/shared/utils/displayTimeValue';
import TimePicker from '../TimePicker';

import { useStyles } from './styles';

const EVERY_DAY_VALUE = ['0', '0', '0', '*/1', '*', '*'];

const createLabel = (val: string[]) => {
  const startLabel = val[3] === '*' || val[3] === '*/1' ? '@Daily' : `@Every ${val[3].split('/')[1]} days`;
  const endLabel = ` At ${displayTimeValue(+val[2])}:${displayTimeValue(+val[1])} GMT`;
  return `${startLabel}${endLabel}`;
};

const Daily = ({ value, onChange, label, disabled }) => {
  const classes = useStyles();
  useEffect(() => {
    if (!value) {
      onChange(EVERY_DAY_VALUE, createLabel(EVERY_DAY_VALUE));
    }
  }, [onChange, value]);

  const onDayChange = useCallback(
    ({ target }) => {
      const { value: newValue } = target;

      if (!newValue || (newValue > 0 && newValue < 32)) {
        const val = [...value];

        if (newValue === '') {
          val[3] = '*';
        } else {
          val[3] = `*/${newValue}`;
        }

        onChange(val, createLabel(val));
      }
    },
    [onChange, value],
  );

  const onAtHourChange = useCallback(
    ({ target: { value: newValue } }) => {
      const val = [...value];
      val[2] = newValue;

      onChange(val, createLabel(val));
    },
    [onChange, value],
  );

  const onAtMinuteChange = useCallback(
    ({ target: { value: newValue } }) => {
      const val = [...value];
      val[1] = newValue;

      onChange(val, createLabel(val));
    },
    [onChange, value],
  );

  if (!value) {
    return null;
  }

  return (
    <div>
      <Typography className={classes.title}>Run: {label}</Typography>
      <div className={classes.container}>
        <TextField
          id="outlined-number"
          label="Every day(s)"
          value={value[3].split('/')[1] ? value[3].split('/')[1] : ''}
          onChange={onDayChange}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          disabled={disabled}
        />
        <div className={classes.picker}>
          <TimePicker
            hour={value[2]}
            minute={value[1]}
            onAtHourChange={onAtHourChange}
            onAtMinuteChange={onAtMinuteChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Daily;
