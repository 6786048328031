import { makeStyles } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: '3px',
    borderRadius: '25px',
    color: '#ffffff',
    '&:hover': {
      borderRadius: '25px',
      backgroundColor: lightBlue[900],
      '& svg': {
        color: '#ffffff',
      },
    },
  },
  highlighted: {
    backgroundColor: lightBlue[800],
  },
  selected: {
    borderRadius: '25px',
    backgroundColor: lightBlue[700],
    color: '#ffffff',
  },
  iconWrapper: {
    height: 22,
    width: 22,
    marginLeft: 8,
    marginRight: 10,
    minWidth: 'auto',
  },
  icon: {
    height: 20,
  },
  text: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    '& span': {
      fontSize: 16,
    },
  },
  hidden: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: 0,
  },
  visible: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: 1,
  },
}));
