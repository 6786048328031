import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  searchLabel: {
    marginRight: 10,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  search: {
    '& input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  },
  amountContainer: {
    color: 'rgba(0, 0, 0, 0.54)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  amountSelect: {
    marginLeft: 5,
    marginRight: 5,
    minWidth: 50,
    textAlign: 'center',
  },
  tableContainer: {
    marginTop: 20,
    borderWidth: 0,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  tableConfig: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  loading: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    width: '100%',
  },
  loadingText: {
    marginLeft: 10,
  },
  noData: {
    display: 'flex',
    padding: 10,
    width: '100%',
  },
  colSize10: {
    width: '20%',
  },
  colSize20: {
    width: '20%',
  },
  colSize30: {
    width: '20%',
  },
}));
