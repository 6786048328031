import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 80vh)',
    overflow: 'initial',

    maxWidth: '50%',
    minWidth: '50%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
      minWidth: '70%',
    },
  },
  dialogContent: {
    overflowY: 'scroll',
    minHeight: 200,
  },

  spinner: {
    margin: '10px 10px 5px',
  },
  progress: {
    color: 'rgba(255, 255, 255, 0)',
  },
  spacing: {
    height: 20,
  },
}));
