import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

export const useSearch = () => {
  const history = useHistory();
  const location = useLocation();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const get = useCallback(
    (key: string) => {
      return decodeURI(search.get(key) || '');
    },
    [search],
  );

  const set = useCallback(
    (searchParams) => {
      for (const key in searchParams) {
        search.set(key, searchParams[key]);
      }

      const newSearch: Record<string, string> = {};

      search.forEach((value, key) => {
        if (value) {
          newSearch[key] = value;
        }
      });

      delete newSearch.redirect;

      history.replace({
        ...history.location,
        search: new URLSearchParams(newSearch).toString(),
      });
    },
    [search, history],
  );

  const clear = useCallback(() => {
    history.replace({
      ...location,
      search: '',
    });
  }, [history, location]);

  return { get, set, clear };
};
