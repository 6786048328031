import { useSubscription } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { useSelector } from 'react-redux';

import { authSelectors } from 'modules/auth';
import { NOTIFICATIONS_SUBSCRIPTION } from './gql';

export const useNotifications = (skip: boolean) => {
  const { enqueueSnackbar } = useSnackbar();

  const userId = useSelector(authSelectors.getUserId);

  useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    skip,
    variables: {
      id: userId,
    },
    onData: ({
      data: {
        data: { notifications },
      },
    }) => {
      enqueueSnackbar(
        notifications.message,
        notifications.type === 'success' ? BASE_SUCCESS_NOTIFICATION_OPTIONS : BASE_ERROR_NOTIFICATION_OPTIONS,
      );
    },
  });
};
