import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: { display: 'flex', alignItems: 'center' },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusSelect: {
    marginRight: 10,
    flex: 1,
    display: 'flex',
    gap: 10,
    '& > *:first-child': { flex: 1 },
    '& > *': { flex: 2 },
  },
}));
