import { memo } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from './styles';

const DataTypeSelect = ({ dataTemplates, selectedDataTemplate, onChange }) => {
  const classes = useStyles();
  const handleDefinitionChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl className={classes.margin}>
      <InputLabel id="data-type-select">Data Type</InputLabel>
      <Select
        labelId="data-type-select"
        className={classes.inputContainer}
        value={selectedDataTemplate}
        onChange={handleDefinitionChange}
      >
        {dataTemplates.map(({ templateId, deliverableProjectData }) => {
          return (
            <MenuItem key={templateId} value={templateId || ''}>
              <b>
                {templateId} ({deliverableProjectData})
              </b>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default memo(DataTypeSelect);
