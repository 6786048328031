import React, { forwardRef, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dxDataGrid, { Editing as dxDataGridEditing } from 'devextreme/ui/data_grid';
import DataGrid, { Editing } from 'devextreme-react/data-grid';

import AlertDialog from '../components/AlertDialog';
import NavigationGuard from '../components/NavigationGuard';

import { GridType, GridProps } from '../types';

type WrapperProps = {
  // used to apply roles and permissions
  availableElements?: string[];
};

const EditingWrapper = <A extends PropsWithChildren<GridProps>>(
  WrappedGrid: GridType<any>,
  editPermissionsProvider?: (editing: dxDataGridEditing, availableElements: string[]) => void,
) => {
  return forwardRef<DataGrid, A & WrapperProps>((props, ref) => {
    const gridRef = useRef<dxDataGrid>();
    const { gridOptions, availableElements = [], ...rest } = props;
    const {
      onInitialized = undefined,
      editing = {
        mode: 'batch',
        allowAdding: false,
        allowUpdating: false,
        allowDeleting: false,
        selectTextOnEditStart: true,
        startEditAction: 'click',
      },
    } = gridOptions ?? {};

    editPermissionsProvider?.(editing, availableElements);

    const [openAskDiscardData, setOpenAskDiscardData] = useState(false);
    const checkHasEditData = useCallback(() => gridRef.current?.hasEditData(), []);
    const discardDataGridChanges = useCallback(() => gridRef.current?.cancelEditData(), []);
    const askUpdateFilterButtons = useMemo(
      () => [
        {
          title: 'No',
          onClick: () => setOpenAskDiscardData(false),
        },
        {
          title: 'Yes',
          onClick: () => {
            discardDataGridChanges();
            setOpenAskDiscardData(false);
            gridRef.current?.refresh();
          },
        },
      ],
      [discardDataGridChanges],
    );

    useEffect(() => {
      return () => {
        if (checkHasEditData()) {
          // eslint-disable-next-line no-restricted-globals, no-alert
          if (confirm('You have unsaved changes in the Table , Press Ok to save or Cancel to discard')) {
            gridRef.current?.saveEditData();
          }
        }
      };
    }, [checkHasEditData]);

    return (
      <>
        <WrappedGrid
          ref={ref}
          gridOptions={{
            ...props.gridOptions,
            onInitialized: (e) => {
              gridRef.current = e.component;
              onInitialized?.(e);
            },
          }}
          {...(rest as A)}
        >
          <Editing {...editing} />
          {props.children}
        </WrappedGrid>
        <AlertDialog
          show={openAskDiscardData}
          onClose={() => setOpenAskDiscardData(false)}
          message="You have unsaved changes on the table. Do you want to discard your changes ?"
          buttons={askUpdateFilterButtons}
        />
        <NavigationGuard when onDiscardData={discardDataGridChanges} checkHasEditData={checkHasEditData} />
      </>
    );
  });
};

export default EditingWrapper;
