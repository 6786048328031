import { memo, useCallback, useContext, useEffect, useMemo } from 'react';

import { AuthRoutesPath } from 'main/routes';
import Storage from 'modules/storage';
import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import Select from 'modules/shared/components/Select2';
import { STORAGE_PROJECT_KEY } from 'modules/shared/constants';
import { useLocation } from 'react-router';
import { unwrapProject, wrapProject } from 'modules/shared/utils/project';
import { useStyles } from './styles';

// export const onlyConnectionPaths = [AuthRoutesPath.Projects as string];
export const allValueEnabled = [AuthRoutesPath.Projects as string, '/'];
export const hiddenPaths = [AuthRoutesPath.ConnectionManager as string];

const ProjectSelect = ({ value, onChange }) => {
  const { pathname } = useLocation();
  const currentPath = pathname.toString();
  const storageProject = Storage.get(STORAGE_PROJECT_KEY);
  const classes = useStyles();

  const selectValue = value || storageProject;

  const { loading: isProjectsLoading, projects, projectsPerEnv } = useContext(ProjectsContext);

  const { connection, projectId } = unwrapProject(selectValue);
  const withAllValue = allValueEnabled.includes(currentPath);

  const parsedProjects = useMemo(
    () =>
      projectsPerEnv[connection]?.map((p) => ({
        value: p.projectId,
        label: p.projectName,
      })) || [],
    [connection, projectsPerEnv],
  );

  const parsedConnections = useMemo(() => {
    return Object.keys(projectsPerEnv).map((c) => ({
      value: c,
      label: c,
    }));
  }, [projectsPerEnv]);

  useEffect(() => {
    if (projects.length) {
      const firstProject = projects.find((p) => p.projectConnection === connection)?.id ?? projects[0].id;

      const isPassedValueExists = projects.find((p) => p.id === value);
      const isStorageProjectExists = projects.find((p) => p.id === storageProject);
      const projectToSet = isStorageProjectExists ? storageProject : firstProject;

      if (value) {
        if (!withAllValue) {
          if (isPassedValueExists && !hiddenPaths.includes(pathname)) {
            onChange(value);
          } else if (!isPassedValueExists) {
            onChange(projectToSet);
          }
        }
      }

      if (!value) {
        if (withAllValue) {
          onChange(wrapProject({ connection, projectId: '' }));
        } else {
          onChange(projectToSet);
        }
      }
    }
  }, [value, connection, withAllValue, projects, onChange, storageProject, pathname]);

  const handleChange = useCallback(
    (val) => {
      onChange(wrapProject({ connection, projectId: val[0] === 'all' ? '' : val[0] }));
    },
    [onChange, connection],
  );

  const handleConnectionChange = useCallback(
    (val) => {
      const allProjectsForConnection = projectsPerEnv[val[0]];

      const nextProjectForAll = wrapProject({ connection: val[0], projectId: '' });
      onChange(withAllValue ? nextProjectForAll : allProjectsForConnection[0].id);
      if (withAllValue) {
        Storage.set(STORAGE_PROJECT_KEY, nextProjectForAll);
      }
    },
    [onChange, projectsPerEnv, withAllValue],
  );

  useEffect(() => {
    if (selectValue !== wrapProject({ connection, projectId: '' })) {
      Storage.set(STORAGE_PROJECT_KEY, selectValue);
    }
  }, [selectValue, connection]);

  if (hiddenPaths.includes(currentPath)) {
    return null;
  }

  if (withAllValue) {
    return (
      <div className={classes.container}>
        <Select
          id="connection-select"
          value={[connection]}
          options={parsedConnections}
          loading={isProjectsLoading}
          disabled={isProjectsLoading}
          onChange={handleConnectionChange}
          className={classes.connection}
          disableClear
        />
        <Select
          id="project-select"
          value={[projectId || 'all']}
          options={[{ label: 'All', value: 'all' }, ...parsedProjects]}
          loading={isProjectsLoading}
          disabled={isProjectsLoading}
          onChange={handleChange}
          className={classes.project}
          disableClear
        />
      </div>
    );
  }

  return (
    <>
      {hiddenPaths.includes(currentPath) ? (
        ''
      ) : (
        <div className={classes.container}>
          <Select
            id="connection-select"
            value={[connection]}
            options={parsedConnections}
            loading={isProjectsLoading}
            disabled={isProjectsLoading}
            onChange={handleConnectionChange}
            className={classes.connection}
            disableClear
          />
          <Select
            id="project-select"
            value={[projectId]}
            options={parsedProjects}
            loading={isProjectsLoading}
            disabled={isProjectsLoading}
            onChange={handleChange}
            className={classes.project}
            disableClear
          />
        </div>
      )}
    </>
  );
};

export default memo(ProjectSelect);
