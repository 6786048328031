import { compareDesc } from 'date-fns';
import { Avatar } from '@material-ui/core';
import { green, red, amber, grey } from '@material-ui/core/colors';
import { Check, PriorityHigh, Timeline } from '@material-ui/icons';
import * as BorNeoColors from 'modules/shared/styles/colors';
import { FlowSolutionBoardThread } from 'modules/project-flow-solution/types';
import { StatusExecute } from 'modules/project-flow-solution/board-ui/enums';

export const generateStatus = (plan, actual) => {
  if (actual) {
    return 'complete';
  }

  if (plan) {
    const planDate = new Date(plan);

    const planDateEodUtc = new Date(
      Date.UTC(planDate.getFullYear(), planDate.getMonth(), planDate.getDate(), 23, 59, 59, 999),
    );

    const currentDate = new Date();

    // eslint-disable-next-line no-bitwise
    if (~compareDesc(planDateEodUtc, currentDate)) {
      return 'late';
    }
  }

  return 'planned';
};

const onGenerateLate = (className) => {
  return (
    <Avatar style={{ background: red[500], color: BorNeoColors.primaryColor4 }} className={className}>
      <PriorityHigh />
    </Avatar>
  );
};

const onGeneratePlan = (className) => {
  return (
    <Avatar style={{ background: amber[700], color: BorNeoColors.primaryColor4 }} className={className}>
      <Timeline />
    </Avatar>
  );
};

const onGenerateComplete = (className) => {
  return (
    <Avatar style={{ background: green[500], color: BorNeoColors.primaryColor4 }} className={className}>
      <Check />
    </Avatar>
  );
};

const onGenerateDefault = (className) => {
  return <Avatar style={{ background: grey[500], color: BorNeoColors.primaryColor4 }} className={className} />;
};

export const onGenStatus = (cardStatus, className) => {
  if (cardStatus.trim().toUpperCase() === 'LATE') {
    return onGenerateLate(className);
  }
  if (cardStatus.trim().toUpperCase() === 'PLANNED') {
    return onGeneratePlan(className);
  }
  if (cardStatus.trim().toUpperCase() === 'COMPLETE') {
    return onGenerateComplete(className);
  }
  return onGenerateDefault(className);
};

export const hasFlowId = (threads: FlowSolutionBoardThread[], flowId: string) => {
  return threads.some((th) => th.executeShapeId === flowId);
};

export const isFlowExecuting = (threads: FlowSolutionBoardThread[], flowId: string) => {
  return threads.some((th) => th.executeShapeId === flowId && th.status === StatusExecute.RUNNING);
};
