import { useCallback, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';

import { displayTimeValue } from 'modules/shared/utils/displayTimeValue';
import TimePicker from '../TimePicker';
import { EVERY_DAY_VALUE } from '../../constants';

import { useStyles } from './styles';

const createLabel = (val) => {
  const startLabel = val[3] === '*' ? '@Daily' : `@On ${val[3]} day of month`;
  const endLabel = ` At ${displayTimeValue(val[2])}:${displayTimeValue(val[1])} GMT`;
  return `${startLabel}${endLabel}`;
};

const Monthly = ({ value, onChange, label, disabled }) => {
  const classes = useStyles();

  useEffect(() => {
    if (!value) {
      onChange(EVERY_DAY_VALUE, createLabel(EVERY_DAY_VALUE));
    }
  }, [onChange, value]);

  const onDayChange = useCallback(
    ({ target }) => {
      const { value: newValue } = target;

      if (!newValue || (newValue > 0 && newValue < 32)) {
        const val = [...value];

        if (newValue === '') {
          val[3] = '*';
        } else {
          val[3] = newValue;
        }

        onChange(val, createLabel(val));
      }
    },
    [onChange, value],
  );

  const onAtHourChange = useCallback(
    ({ target: { value: newValue } }) => {
      const val = [...value];
      val[2] = newValue;

      onChange(val, createLabel(val));
    },
    [onChange, value],
  );

  const onAtMinuteChange = useCallback(
    ({ target: { value: newValue } }) => {
      const val = [...value];
      val[1] = newValue;

      onChange(val, createLabel(val));
    },
    [onChange, value],
  );

  if (!value) {
    return null;
  }

  return (
    <div>
      <Typography className={classes.title}>Run: {label}</Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="outlined-number"
          label="Day of month"
          value={value[3] === '*' ? '' : value[3]}
          onChange={onDayChange}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          disabled={disabled}
        />
        <div className={classes.picker}>
          <TimePicker
            hour={value[2]}
            minute={value[1]}
            onAtHourChange={onAtHourChange}
            onAtMinuteChange={onAtMinuteChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Monthly;
