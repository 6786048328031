import { memo } from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Content, Dialog } from 'modules/shared/components/Dialog';
import ModalData from './modal-data';
import ModalHistory from './modal-history';
import ModalDiary from './modal-diary';
import ModalDtStatus from './modal-dt-status';
import { useStyles } from './styles';

const MODAL_ID = 'index-view-modal-title';

const IndexViewModalUI = ({
  open,
  onClose,
  dataTemplate,
  connection,
  projectId,
  type,
  versionStatusId,
  dtVersionId,
}) => {
  const classes = useStyles();
  const close = onClose;

  return (
    <Dialog
      classes={{
        paper: classes.indexViewModal,
      }}
      fullWidth
      maxWidth={type === 'status' ? 'xs' : 'lg'}
      onClose={close}
      aria-labelledby={MODAL_ID}
      open={open}
      style={type === 'status' ? { height: '300px' } : {}}
    >
      <AppBar position="static" className={classes.appBarRoot}>
        <Toolbar>
          <Typography variant="h6">{dataTemplate}</Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton edge="start" color="inherit" onClick={close} aria-label="close" style={{ display: 'flex' }}>
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Content dividers className={classes.content}>
        <div className={classes.bodyContainer}>
          {type === 'status' && (
            <ModalDtStatus versionStatusId={versionStatusId} dtVersionId={dtVersionId} connection={connection} />
          )}
          {type === 'diary' && <ModalDiary selectedId={dataTemplate} projectId={projectId} connection={connection} />}
          {type === 'data' && <ModalData selectedId={dataTemplate} projectId={projectId} connection={connection} />}
          {type === 'history' && (
            <ModalHistory selectedId={dataTemplate} projectId={projectId} connection={connection} />
          )}
        </div>
      </Content>
    </Dialog>
  );
};

export default memo(IndexViewModalUI);
