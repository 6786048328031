import { useQuery } from '@apollo/client';
import { useLayoutEffect, useMemo } from 'react';

import { useDebounceSearchValue } from 'modules/shared/hooks/base/useDebounceSearchValue';

import { LOAD_USERS } from '../gql';
import { UnparsedUser } from '../types';
import { parseUsers } from '../utils';
import { CHANGE_USER_ACTIVENESS_SUBSCRIPTION } from '../gql/subscriptions';

export const useUsers = ({ limit = 0, offset = 0, search = '', connection = '', loadAll = false } = {}) => {
  const searchToUse = useDebounceSearchValue(search);
  const { loading, data, error, refetch, subscribeToMore } = useQuery(LOAD_USERS, {
    fetchPolicy: 'network-only',
    context: {
      requestId: 'load-users-request',
      queryDeduplication: false,
    },
    variables: { search: searchToUse.trim(), limit, offset, connection, loadAll },
  });

  useLayoutEffect(() => {
    const unsubscribe = subscribeToMore({
      document: CHANGE_USER_ACTIVENESS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const newActiveness = subscriptionData.data.changeUserActivenessSubscription;

        return {
          ...prev,
          usersExpectMe: {
            ...prev.usersExpectMe,
            nodes: prev.usersExpectMe?.nodes.map((node) => {
              if (node.activeness.id === newActiveness.id) {
                return {
                  ...node,
                  activeness: {
                    ...node.activeness,
                    lastActive: newActiveness.lastActive,
                    activenessStatus: newActiveness.activenessStatus,
                  },
                };
              }
              return node;
            }),
          },
        };
      },
    });

    return () => {
      unsubscribe();
    };
  }, [subscribeToMore]);

  const users: UnparsedUser[] = useMemo(() => data?.users?.nodes || [], [data]);
  const totalCount = data?.users?.totalCount;

  const parsedUsers = useMemo(() => parseUsers(users), [users]);

  return { loading, users: parsedUsers, totalCount, error, refetch };
};
