import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    position: 'relative',
    borderColor: '#8f8f8f',
    borderRadius: 8,
    boxShadow: '0 8px 8px 0 #8f8f8f',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
  },
  headerItem: {
    color: '#000000',
  },
  headerItemText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
    fontSize: 16,
    width: '100%',
    textAlign: 'center',
  },
  headerItemMenu: {
    flex: '0 0 20px',
    width: '20px',
    height: '20px',
  },
  headerItemMenuContainer: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}));
