import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_INFO_NOTIFICATION_OPTIONS } from 'project-constants';
import { useHdfs } from 'modules/shared/hooks/api/hdfs';

export const useDownloadSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { remove: removeArchive, getUrl } = useHdfs();

  const renderSnackbar = useCallback(
    (subscription) => {
      const key = enqueueSnackbar(
        subscription.error
          ? 'An error occurred while file has been prepared'
          : `File ${subscription.fileName} is ready to be downloaded`,
        {
          ...(subscription.error
            ? {
                ...BASE_ERROR_NOTIFICATION_OPTIONS,
              }
            : {
                ...BASE_INFO_NOTIFICATION_OPTIONS,
                autoHideDuration: null,
                action: () => {
                  return (
                    <>
                      <Button
                        style={{ color: 'white' }}
                        onClick={() => {
                          closeSnackbar(key);

                          getUrl(subscription.archiveId)
                            .then((response) => {
                              const a = document.createElement('a');
                              a.href = response.data.link;
                              a.download = response.data.filename;
                              document.body.appendChild(a);
                              a.click();
                              document.body.removeChild(a);
                            })
                            .catch(() => {
                              enqueueSnackbar('Unable to download', BASE_ERROR_NOTIFICATION_OPTIONS);
                            })
                            .then(() => {
                              removeArchive({ id: subscription.archiveId, keepFile: true });
                            });
                        }}
                      >
                        Download
                      </Button>
                      <Button
                        style={{ color: 'white' }}
                        onClick={() => {
                          closeSnackbar(key);
                          removeArchive({ id: subscription.archiveId, keepFile: false });
                        }}
                      >
                        Dismiss
                      </Button>
                    </>
                  );
                },
              }),
        },
      );
    },
    [closeSnackbar, enqueueSnackbar, getUrl, removeArchive],
  );

  return {
    renderSnackbar,
  };
};
