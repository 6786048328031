import { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { Build } from '@material-ui/icons';

const AppLinks = () => {
  const ref = useRef(null); // TODO from store
  const [open, setOpen] = useState(false);
  const appData = [
    { name: 'Neo-Flow', link: 'https://flow.bor-neo.com/Primary/StudioH' },
    { name: 'WorkPacks', link: 'https://live.workpacks.com' },
    { name: 'Data Spaces', link: '' },
    { name: 'PostMan', link: 'https://www.postman.com' },
    { name: 'DB Schema', link: '' },
  ];

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  const MenuItemClick = (path) => {
    setOpen(false);
    window.open(path);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleMenuOpen();
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={handleMenuOpen} onKeyPress={handleKeyPress}>
        <Build />
      </IconButton>
      <Menu anchorEl={ref.current} keepMounted open={open} onClose={handleMenuClose}>
        {appData.map((item) => {
          return (
            <MenuItem key={item.name} onClick={() => MenuItemClick(item.link)}>
              {item.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default AppLinks;
