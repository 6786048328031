import { memo, ReactNode, useCallback, useContext, useMemo } from 'react';
import Select from 'modules/shared/components/Select';
import { Button } from '@mui/material';

import {
  DataStoresState,
  DataStoresStateHandlers,
  MAIN_CONNECTION,
} from 'modules/data-stores-custom/context/DataStoresState';
import { DataStoreNames } from 'modules/data-stores-custom/contants';
import FileManagerUi from 'modules/data-stores-custom/file-manager-ui';
import CreateCustomConnectionModalUi from 'modules/data-stores-custom/create-connection-modal-ui';
import UpdateCustomConnectionModalUi from 'modules/data-stores-custom/update-connection-modal-ui';
import ConnectionsInfoModalUi from 'modules/data-stores-custom/connections-info-modal-ui';
import MainDataStoreUI from './main-data-store-ui';
import { useStyles } from './styles';

const DataStoresUI = ({ readOnly = false }) => {
  const classes = useStyles();
  const { dataStoreConnection, dataStoreConnectionsLoading, dataStoreConnections } = useContext(DataStoresState);
  const { setConnection, openConnectionsInfoModal } = useContext(DataStoresStateHandlers);

  const handleChange = useCallback(
    (newItem) => {
      setConnection(newItem);
    },
    [setConnection],
  );

  const connectionsSelect = useMemo(
    () => (
      <Select
        value={dataStoreConnection}
        items={[
          {
            value: MAIN_CONNECTION,
            label: 'Main',
          },
        ].concat(
          dataStoreConnections.map((c) => ({
            value: c.connectionId,
            label: `${c.name} (${DataStoreNames[c.type]})`,
          })),
        )}
        onChange={handleChange}
        disableOpen={dataStoreConnectionsLoading}
        loading={dataStoreConnectionsLoading}
        placeholder="Select Connection"
      />
    ),
    [dataStoreConnection, dataStoreConnections, dataStoreConnectionsLoading, handleChange],
  );

  const renderSelect = useCallback(
    (btns?: ReactNode) => {
      return (
        <div className={classes.filters}>
          <div className={classes.filtersMain}>
            <div className={classes.selectContainer}>{connectionsSelect}</div>

            {btns}
          </div>
          {!readOnly && (
            <Button variant="contained" color="primary" onClick={openConnectionsInfoModal}>
              Configuration
            </Button>
          )}
        </div>
      );
    },
    [
      classes.filters,
      classes.filtersMain,
      classes.selectContainer,
      connectionsSelect,
      openConnectionsInfoModal,
      readOnly,
    ],
  );

  return (
    <>
      {dataStoreConnection === MAIN_CONNECTION ? (
        <MainDataStoreUI readOnly={readOnly} renderButtons={renderSelect} />
      ) : (
        <FileManagerUi renderButtons={renderSelect} readOnly={readOnly} />
      )}
      <ConnectionsInfoModalUi />
      <CreateCustomConnectionModalUi />
      <UpdateCustomConnectionModalUi />
    </>
  );
};

export default memo(DataStoresUI);
