import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  page: {
    margin: '20px 10px',
  },
  header: {
    display: 'flex',
    width: '100vh',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  select: { width: 'min(300px, 100%)' },
  buttons: {
    marginLeft: -10,
    marginTop: -10,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    '& > *': {
      marginTop: 10,
      marginLeft: 10,
    },
  },
}));
