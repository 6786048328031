import { useApolloClient } from '@apollo/client';
import { LOAD_DIGITAL_THREADS_WIDGET_HISTORY } from '../../gql/digitalthreadswidget';

export const useLoadDigitalThreadsWidgetHistory = (projectId: string, connection: string) => {
  const client = useApolloClient();
  const handleLoad = async ({ limit, offset, orderKey, orderType }) => {
    const response = await client.query({
      query: LOAD_DIGITAL_THREADS_WIDGET_HISTORY,
      variables: {
        limit,
        offset,
        connection,
        projectId,
        orderKey,
        orderType,
      },
      fetchPolicy: 'network-only',
    });

    const { nodes, totalCount } = response.data?.loadDigitalThreadsWidgetHistory || { nodes: [], totalCount: 0 };

    return {
      data: nodes,
      totalCount,
    };
  };

  return {
    load: handleLoad,
  };
};
