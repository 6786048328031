import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: 340,
    '& .MuiDataGrid-virtualScroller': {
      overflow: 'unset !important',
      overflowX: 'unset !important',
    },
    '& .MuiDataGrid-row': {
      minHeight: '38px !important',
      height: '38px !important',
    },
    '& .MuiDataGrid-cell': {
      minHeight: '38px !important',
      height: '38px !important',
      borderBottom: 'none !important',
    },
    '& .Mui-row-odd': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .Mui-row-even': {
      backgroundColor: '#FFFFFF',
    },
  },
  cellNonePd: {
    padding: '0 !important',
  },
}));
