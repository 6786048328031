import { gql } from '@apollo/client';

export const ON_UPDATE_CHECKING_DATA = gql`
  subscription onUpdateCheckingItem($connection: String!, $projectId: String!) {
    onUpdateCheckingItem(connection: $connection, project_id: $projectId) {
      itemId
      isCustom
      customType
      item
      status
    }
  }
`;
