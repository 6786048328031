import { gql } from '@apollo/client';

export const LOAD_USERS = gql`
  query LoadUsers($search: String, $limit: Int, $offset: Int, $connection: String, $loadAll: Boolean) {
    users(search: $search, limit: $limit, offset: $offset, connection: $connection, load_all: $loadAll) {
      totalCount
      nodes {
        userId
        email
        company
        joinedDate
        login {
          loginId
          username
        }
        activeness {
          id
          lastActive
          activenessStatus {
            status
            id
          }
        }
      }
    }
  }
`;
