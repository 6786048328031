import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    '& ul': {
      listStyleType: 'disc',
      padding: '15px',
    },
    '& b': {
      fontWeight: 'bold',
    },
    '& li:not(:last-child)': {
      marginBottom: '10px',
    },
  },
}));
