import { gql } from '@apollo/client';

export const CHANGE_FLOW_SUBSCRIPTION = gql`
  subscription ChangeFlowSubscription($userId: String!, $connection: String!) {
    changeFlowSubscription(user_id: $userId, connection: $connection) {
      flowId
      isActive
      status {
        statusId
        statusName
      }
      executedAt
    }
  }
`;

export const REGISTER_FLOW_SUBSCRIPTION = gql`
  subscription RegisterFlowSubscription($userId: String!, $connection: String!) {
    registerFlowSubscription(user_id: $userId, connection: $connection) {
      flowId
      flowName
      flowDescription
      project {
        projectId
      }
      category {
        categoryId
        categoryName
      }
      status {
        statusId
        statusName
      }
      isActive
      executedAt
      scheduler {
        schedulerLabel
      }
      pausable
    }
  }
`;

export const UPDATE_FLOW_SUBSCRIPTION = gql`
  subscription UpdateFlowSubscription($userId: String!, $connection: String!) {
    updateFlowSubscription(user_id: $userId, connection: $connection) {
      flowId
      flowName
      flowDescription
      project {
        projectName
      }
      category {
        categoryId
        categoryName
      }
      status {
        statusId
        statusName
      }
      isActive
      executedAt
      scheduler {
        schedulerLabel
      }
      pausable
      modifiedBy
    }
  }
`;

export const REMOVE_FLOW_SUBSCRIPTION = gql`
  subscription RemoveFlowSubscription($connection: String!, $projectId: String!) {
    removeFlowSubscription(connection: $connection, project_id: $projectId) {
      flowId
      flowName
      flowDescription
      project {
        projectName
      }
      category {
        categoryId
        categoryName
      }
      status {
        statusId
        statusName
      }
      isActive
      executedAt
      scheduler {
        schedulerLabel
      }
      pausable
    }
  }
`;
