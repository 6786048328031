import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { LOAD_BOARD_ADDITIONAL_CONFIG } from '../gql';
import { AdditionalConfigResponse } from '../types';

export const useFlowSolutionBoardAdditionalConfig = (connection: string, projectId: string, boardId?: string) => {
  const { data, loading, refetch } = useQuery(LOAD_BOARD_ADDITIONAL_CONFIG, {
    variables: {
      boardId,
      connection,
      projectId,
    },
    skip: !connection || !boardId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const config = useMemo(() => data?.boardAdditionalConfig || { checklistItems: [] }, [data]);

  const additionalConfig: AdditionalConfigResponse = useMemo(
    () => ({
      checklistItems: config.checklistItems.map(({ __typename, ...rest }) => ({ ...rest })),
    }),
    [config],
  );
  const checklistItems = useMemo(
    () =>
      additionalConfig.checklistItems?.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.fkShapeId]: (acc[cur.fkShapeId] || []).concat({
            ...cur,
            setUp: cur.setUp ? format(+cur.setUp, 'MM/dd/yyyy') : '',
            signOff: cur.signOff ? format(+cur.signOff, 'MM/dd/yyyy') : '',
          }),
        };
      }, {}) || {},
    [additionalConfig],
  );

  return {
    additionalConfig,
    checklistItems,
    loading,
    refetch,
  };
};
