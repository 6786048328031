import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
  wrapper: {
    flex: 1,
  },
  card: {
    padding: 5,
    borderColor: '#8f8f8f',
    borderRadius: 8,
    boxShadow: '0 4px 4px 0 #8f8f8f',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  dragHandle: {
    flex: '0 0 32px',
    marginRight: 10,
  },
  content: {
    flex: 1,
  },
}));
