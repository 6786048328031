import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  chartContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  chartWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
