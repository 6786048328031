import { AxiosRequestConfig } from 'axios';
import Storage from 'modules/tokens-storage';

export default async (config: AxiosRequestConfig) => {
  const idToken = Storage.getInmemoryIdToken();

  const headers = config.headers || {};

  return {
    ...config,
    originalReqConfig: config,
    headers: {
      ...headers,
      Authorization: `Bearer ${idToken}`,
    },
  };
};
