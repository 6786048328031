import { memo, useCallback, useContext, useEffect, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { Alert, Box, Button, CircularProgress, Collapse, Grid, IconButton } from '@mui/material';
import Input from 'modules/shared/components/Input';
import { Add } from '@material-ui/icons';
import { useStyles } from './styles';
import { BnSettingsActions, BnSettingsState } from '../context/DataStoresState';

type Props = {
  id: string;
  label: string;
  value: string;
  items: {
    label: string;
    value: string;
  }[];
  suggestions: {
    label: string;
    value: string;
  }[];
  loading: boolean;
  disabled?: boolean;
  onChange: (val: string) => void;
};

const MODAL_ID = 'CREATE_BN_SETTING';
const FORM_ID = 'CREATE_BN_SETTING_FROM_LIST';

const initValues = {
  key: '',
  value: '',
  category: '',
};

const SelectOrCreateBnSettingList = ({
  id,
  label,
  value: selectValue,
  items,
  loading,
  suggestions,
  onChange,
  disabled,
}: Props) => {
  const { categories } = useContext(BnSettingsState);

  const formId = `${FORM_ID}_${id}`;
  const classes = useStyles();

  const handleSelectChange = useCallback(
    (v: string[]) => {
      onChange(v[0]);
    },
    [onChange],
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [creationError, setCreationError] = useState('');
  const [creationLoading, setCreationLoading] = useState(false);

  const [{ key, value, category }, setValues] = useState(initValues);

  const handleInputChange = (event) => {
    const { id: inputId, value: inputValue } = event.target;

    setValues((prev) => ({
      ...prev,
      [inputId]: inputValue,
    }));
  };

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);

    setValues(initValues);
  }, []);

  const { create } = useContext(BnSettingsActions);

  const handleDialogSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setCreationError('');
      setCreationLoading(true);

      let isError = false;

      create(key, value, category, (message) => {
        setCreationError(message);
        isError = true;
      })
        .then(() => {
          if (!isError) {
            onChange(key);
            handleDialogClose();
          }
        })
        .finally(() => {
          setCreationLoading(false);
        });
    },
    [create, onChange, key, value, category, handleDialogClose],
  );

  const handleCategoryChange = (v: string[]) => {
    setValues((prev) => ({
      ...prev,
      category: v[0],
    }));
  };

  const [suggestionsCopy, setSuggestionsCopy] = useState(suggestions);

  useEffect(() => {
    setSuggestionsCopy(suggestions);
  }, [suggestions]);

  return (
    <>
      <div className={classes.inputWrapper}>
        <Select
          id={id}
          labelText={label}
          value={[selectValue]}
          options={items}
          loading={loading}
          onChange={handleSelectChange}
          disabled={disabled}
        />
        <IconButton
          size="small"
          style={{ marginBottom: 3 }}
          disabled={disabled}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          <Add />
        </IconButton>
      </div>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <Box component="form" id={formId} onSubmit={handleDialogSubmit}>
          <Title id={`${MODAL_ID}_${id}`} onClose={handleDialogClose}>
            Add Setting
          </Title>
          <Content>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  labelText="Key"
                  id="key"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    value: key,
                    required: true,
                    onChange: handleInputChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  id="value"
                  labelText="Value"
                  value={[value]}
                  options={suggestionsCopy}
                  loading={false}
                  onChange={(v) => {
                    setValues((prev) => ({ ...prev, value: v[0] }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  id="category"
                  labelText="Category"
                  value={[category]}
                  options={categories.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  loading={loading}
                  onChange={handleCategoryChange}
                />
              </Grid>
            </Grid>
            <Collapse in={!!creationError}>
              <Alert className={classes.alert} severity="error">
                {creationError}
              </Alert>
            </Collapse>
          </Content>
          <Actions>
            <Button variant="contained" color="primary" type="submit" form={formId}>
              {creationLoading ? <CircularProgress className={classes.progress} size={24} /> : 'Create'}
            </Button>
          </Actions>
        </Box>
      </Dialog>
    </>
  );
};

export default memo(SelectOrCreateBnSettingList);
