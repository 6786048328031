import { useQuery } from '@apollo/client';
import { LOAD_UNLINKED_WP_PROJECTS } from './gql';
import { WpProject } from './types';

export const useLoadUnlinkedWpProjects = ({ client, instance }) => {
  const { loading, data, error, refetch } = useQuery(LOAD_UNLINKED_WP_PROJECTS, {
    variables: {
      client,
      env: instance,
    },
    skip: !client || !instance,
    fetchPolicy: 'network-only',
  });

  const unlinkedWpProjects: WpProject[] = data?.loadUnlinkedWpProjects || [];

  return { loading, unlinkedWpProjects, error, refetch };
};
