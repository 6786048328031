import axios from 'axios';
import { checkCurrentSession } from 'configure/azure-ad-b2c';

import { authThunks } from 'modules/auth';
import { instance } from 'modules/rest';
import Storage from 'modules/tokens-storage';
import { REDIRECT_ERROR_CODE } from 'project-constants';

import { TIME_OUT_EXPIRES_CODE } from '../constants';

export default (dispatch) => {
  return async (error) => {
    if (axios.isCancel(error) || error.code === TIME_OUT_EXPIRES_CODE) {
      throw error;
    }

    const errorStatus = error.response.status;

    const errorMsg = error.response.data?.error || '';
    const [errorCode, meta] = typeof errorMsg === 'string' ? errorMsg.split('|') : ['', ''];
    const idToken = Storage.getInmemoryIdToken();

    if (errorCode === REDIRECT_ERROR_CODE) {
      setTimeout(() => {
        window.location.assign(meta);
      }, 3000);

      throw error;
    }

    if (!idToken || (errorStatus === 401 && error.config.isRepeatedRequest)) {
      await dispatch(authThunks.logout());
      throw error;
    }

    if (errorStatus !== 401 || error.config.isRepeatedRequest) {
      throw error; // Already tried to refresh tokens and error exists
    }

    await checkCurrentSession(dispatch);

    const originalReqConfig = error.config.originalReqConfig || {};

    return instance({ ...originalReqConfig, isRepeatedRequest: true });
  };
};
