import { makeStyles } from '@material-ui/core';
import { accentColor9 } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    position: 'relative',
    borderColor: '#8f8f8f',
    borderRadius: 8,
    boxShadow: '0 8px 8px 0 #8f8f8f',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0',
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: `1px solid ${accentColor9}`,
  },
  headerItem: {
    color: '#000000',
  },
  headerItemText: {
    padding: '5px',
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `10px 0 !important`,
  },
  connectionsWrapper: {
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  connectionsWrapperHorizontal: {
    flexDirection: 'column',
  },
  connectionsElement: {
    flex: '1 0',
  },
}));
