import { ALL_ITEM } from 'modules/shared/constants';

export const ENABLE_STATE_ID = 'on';

export const StateItems = [
  ALL_ITEM,
  {
    label: 'On',
    value: ENABLE_STATE_ID,
  },
  {
    label: 'Off',
    value: 'off',
  },
];

export const REGISTER_HASH_KEY = 'register';
export const UPDATE_HASH_KEY = 'update';
export const MAKE_COPY_HASH_KEY = 'copy';
export const DETAILS_HASH_KEY = 'details';
