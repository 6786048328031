import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  grid: {
    '& .dx-datagrid-pager': {
      border: '1px solid #e0e0e0',
    },
    '& .dx-datagrid-headers': {
      backgroundColor: '#f5f5f5',
    },
  },
  grouping: {
    '& .dx-datagrid-borders > .dx-datagrid-header-panel': {
      borderTop: '1px solid #e0e0e0',
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      backgroundColor: '#f5f5f5',
      '& > .dx-toolbar': {
        backgroundColor: '#f5f5f5',
      },
    },
  },
}));
