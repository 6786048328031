import { useQuery } from '@apollo/client';
import { LOAD_CLIENDS_AND_INSTANCES } from '../gql';

export const useLoadClientsAndInstances = () => {
  const { loading, data, error, refetch } = useQuery(LOAD_CLIENDS_AND_INSTANCES, {});

  const clientsAndInstances: Record<string, string[]> = JSON.parse(data?.clientsAndInstances || '{}');

  return { loading, clientsAndInstances, error, refetch };
};
