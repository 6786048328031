import { useQuery } from '@apollo/client';

import { LOAD_FLOWS_BY_PROJECT_LIST } from './gql';
import { FlowListItem } from './types';

export const useFlowsByProjectsList = (projectId: string, connection: string, notifyOnNetworkStatusChange = false) => {
  const { loading, data, error, refetch } = useQuery(LOAD_FLOWS_BY_PROJECT_LIST, {
    variables: {
      projectId,
      connection,
    },
    skip: !projectId || !connection,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange,
  });

  const flows: FlowListItem[] = data?.flows?.nodes || [];

  return { loading, flows, refetch, error };
};
