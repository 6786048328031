import { client } from 'modules/gql';
import { LOAD_CONNECTIONS_INFO } from 'modules/shared/hooks/api/connections-info/gql';
import { LOAD_PROJECTS } from 'modules/shared/hooks/api/projects/gql';

export const preloadData = () => async () => {
  await Promise.all([
    client.query({
      query: LOAD_PROJECTS,
    }),
    client.query({
      query: LOAD_CONNECTIONS_INFO,
    }),
  ]);
};
