import { AxiosResponse } from 'axios';

import requestSuccess from './requestSuccess';
import responseError from './responseError';

export default (dispatch) => {
  return {
    request: {
      success: requestSuccess,
      error: Promise.reject,
    },
    response: {
      success: (res: AxiosResponse) => res,
      error: responseError(dispatch),
    },
  };
};
