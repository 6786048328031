import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '20px 10px',
  },
}));
