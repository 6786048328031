import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { LOAD_DIGITAL_THREAD_TYPES, LOAD_DIGITAL_THREAD_TYPE } from '../gql';

export const useLoadDigitalthreadTypes = (connection: string, skip = false) => {
  const client = useApolloClient();
  const { data, loading } = useQuery(LOAD_DIGITAL_THREAD_TYPES, {
    variables: {
      connection,
    },
    skip: !connection || skip,
    notifyOnNetworkStatusChange: true,
  });

  const digitalthreadTypes: { type: string; templates: { templateId: string; deliverableProjectData: string }[] }[] =
    data?.digitalthreadTypes || [];

  const load = useCallback(async () => {
    if (!connection) {
      return {
        data: [],
        totalCount: 0,
      };
    }

    const response = await client.query({
      query: LOAD_DIGITAL_THREAD_TYPES,
      context: {
        requestId: 'load-digitalthreads-types-request',
        queryDeduplication: false,
      },
      variables: {
        connection,
      },
    });

    const items = response.data?.digitalthreadTypes || [];

    return { data: JSON.parse(JSON.stringify(items)), totalCount: items.length };
  }, [client, connection]);

  const loadType = useCallback(
    async (type: string) => {
      if (!connection) {
        return null;
      }

      const response = await client.query({
        query: LOAD_DIGITAL_THREAD_TYPE,
        fetchPolicy: 'network-only',
        context: {
          requestId: 'load-digitalthreads-type-request',
          queryDeduplication: false,
        },
        variables: {
          connection,
          type,
        },
      });

      const item = response.data?.digitalthreadType || [];

      return item;
    },
    [client, connection],
  );

  return {
    digitalthreadTypes,
    loading,
    load,
    loadType,
  };
};
