import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    width: 150,
    height: 75,
    borderRadius: '100px / 50px',
    boxSizing: 'border-box',
    position: 'relative',
    border: '1px solid #8f8f8f',
    boxShadow: '1px 3px 6px 0 #8f8f8f',
    backgroundColor: '#e0e0e0',
    color: '#000',
  },
  wrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
  },
}));
