import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    height: 80,
  },
  imgContainer: {
    marginLeft: 4,
    opacity: 0,
    transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  imgContainerOpen: {
    opacity: 1,
    transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  columnCont: {
    float: 'left',
    alignItems: 'center',
  },
  logoCont: {
    height: 70,
    width: 70,
    maxWidth: 70,
    float: 'left',
  },
  logoTxtCont: {
    marginLeft: 5,
    float: 'left',
    alignment: 'left',
  },
}));
