import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  menuItems: {
    maxHeight: '150px',
  },
  select: {
    minWidth: 60,
    marginRight: 15,
  },
}));
