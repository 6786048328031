import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    fontSize: '0.875rem',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.43,
    borderRadius: '4px !important',
    letterSpacing: '0.01071em',
    backgroundColor: '#2196f3',
  },
  snackbarRoot: {
    position: 'relative',
    width: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
    margin: '6px 16px',
  },
  icon: {
    marginRight: 8,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 3,
    borderRadius: '0px 0px 4px 4px',
  },
  progressRoot: {
    backgroundColor: 'transparent',
  },
  progressBar: {
    backgroundColor: '#fff',
  },
}));
