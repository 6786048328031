import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  buttonRoot: {
    display: 'flex',
    gap: 20,
  },
  modalDtButton: {
    padding: '4px 8px',
    textTransform: 'initial',
    background: BorNeoColors.accentColor2,
    color: BorNeoColors.primaryColor4,
    fontWeight: 'normal',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: BorNeoColors.accentColor2,
      color: BorNeoColors.primaryColor4,
    },
    borderRadius: 4,
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  modalDefaultDtButton: {
    padding: '4px 8px',
    textTransform: 'initial',
    fontWeight: 'normal',
    background: BorNeoColors.primaryColor3,
    color: BorNeoColors.accentColor1,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: BorNeoColors.primaryColor2,
      color: BorNeoColors.primaryColor4,
    },
    borderRadius: 4,
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  selectedDtButton: {
    background: BorNeoColors.primaryColor1,
    color: BorNeoColors.primaryColor4,
  },
}));
