import classnames from 'classnames';
import DataGrid, {
  Column,
  Paging,
  Pager,
  SearchPanel,
  Export,
  Editing,
  RequiredRule,
  CustomRule,
  Lookup,
  DataGrid as DataGridType,
} from 'devextreme-react/data-grid';
import { dxDataGridOptions } from 'devextreme/ui/data_grid';

import { noop } from 'modules/shared/utils';
import { useStyles } from './styles';

const allowedPageSizes = [10, 25, 50];
const exportsText = { exportAll: 'Export To Excel' };

type TableProps = {
  children?: React.ReactNode;
  data: Array<any> | any;
  loading?: boolean;
  loadingText?: string;
  noDataText?: string;
  pagingEnabled?: boolean;
  searchEnabled?: boolean;
  exportEnabled?: boolean;
  exportExcelName?: string;
  wordWrapEnabled?: boolean;
  columnResizeDisabled?: boolean;
  editingMode?: string;
  onAddNewRow?: dxDataGridOptions['onRowInserted'];
  onUpdateRow?: dxDataGridOptions['onRowUpdated'];
  onDeleteRow?: dxDataGridOptions['onRowRemoved'];
  onInitNewRow?: dxDataGridOptions['onInitNewRow'];
  onBeforeDeleteRow?: dxDataGridOptions['onRowRemoving'];
  onEditorPreparing?: dxDataGridOptions['onEditorPreparing'];
  onEditingStart?: dxDataGridOptions['onEditingStart'];
  tableRef?: React.RefObject<any>;
  height?: string | number;
  hideHeaders?: boolean;
  onRowClick?: (...args: any[]) => any;
  remoteOperations?: boolean;
  onRowPrepared?: dxDataGridOptions['onRowPrepared'];
  disableRowAlternation?: boolean;
  onCellPrepared?: dxDataGridOptions['onCellPrepared'];
  pageSize?: number[];
  growToFullHeight?: boolean;
  minHeight?: string | number;
  onContextMenuPreparing?: dxDataGridOptions['onContextMenuPreparing'];
  containerStyle?: React.CSSProperties;
};

const Table = ({
  children,
  data,
  loading,
  loadingText,
  noDataText,
  pagingEnabled,
  searchEnabled,
  exportEnabled,
  exportExcelName,
  wordWrapEnabled,
  columnResizeDisabled,
  editingMode,
  onAddNewRow,
  onUpdateRow,
  onDeleteRow,
  onInitNewRow,
  onBeforeDeleteRow,
  onEditorPreparing,
  onEditingStart,
  tableRef,
  height,
  hideHeaders,
  onRowClick,
  remoteOperations,
  onRowPrepared,
  disableRowAlternation,
  onCellPrepared,
  pageSize = allowedPageSizes,
  growToFullHeight,
  minHeight,
  onContextMenuPreparing,
  containerStyle,
}: TableProps) => {
  const hoverable = Boolean(onRowClick);
  const classes = useStyles();

  const usedHeight = growToFullHeight ? '100%' : height;

  const table = (
    <div
      className={classnames(classes.tableWrapper, {
        [classes.hoverableTable]: hoverable,
      })}
    >
      <DataGrid
        ref={tableRef}
        dataSource={loading ? [] : data}
        allowColumnResizing={!columnResizeDisabled}
        columnResizingMode="widget"
        repaintChangesOnly
        rowAlternationEnabled={!disableRowAlternation}
        noDataText={loading ? loadingText : noDataText}
        wordWrapEnabled={wordWrapEnabled}
        onRowInserted={onAddNewRow || noop}
        onRowUpdated={onUpdateRow || noop}
        onRowRemoved={onDeleteRow || noop}
        onInitNewRow={onInitNewRow || noop}
        onRowRemoving={onBeforeDeleteRow || noop}
        onEditorPreparing={onEditorPreparing || noop}
        onEditingStart={onEditingStart || noop}
        onRowPrepared={onRowPrepared || noop}
        height={usedHeight}
        showColumnHeaders={!hideHeaders}
        onRowClick={onRowClick || noop}
        hoverStateEnabled={hoverable}
        width="100%"
        remoteOperations={remoteOperations}
        cacheEnabled={false}
        onCellPrepared={onCellPrepared || noop}
        onContextMenuPreparing={onContextMenuPreparing || noop}
        style={containerStyle}
      >
        <Paging enabled={pagingEnabled} defaultPageSize={pageSize[0]} />
        <Pager
          allowedPageSizes={pageSize}
          showInfo={pagingEnabled}
          showPageSizeSelector={pagingEnabled}
          showNavigationButtons={pagingEnabled}
        />
        <SearchPanel visible={searchEnabled} width={240} />
        <Export enabled={exportEnabled} fileName={exportExcelName} texts={exportsText} />
        <Editing
          mode={editingMode}
          allowAdding={!!onAddNewRow}
          allowUpdating={!!onUpdateRow}
          allowDeleting={!!onDeleteRow}
        />
        {children}
      </DataGrid>
    </div>
  );

  if (growToFullHeight) {
    return (
      <div className={classes.fullHeightContainer} style={{ minHeight }}>
        <div className={classes.fullHeightWrapper}>{table}</div>
      </div>
    );
  }

  return table;
};

Table.defaultProps = {
  loadingText: 'Loading data',
  noDataText: 'No data to Display',
  pagingEnabled: false,
  searchEnabled: false,
  exportEnabled: false,
  exportExcelName: 'report.xlsx',
  wordWrapEnabled: false,
  columnResizeDisabled: false,
  editingMode: 'row',
  onAddNewRow: null,
  onUpdateRow: null,
  onDeleteRow: null,
  onBeforeDeleteRow: null,
  onEditorPreparing: null,
  onEditingStart: null,
  onCellPrepared: null,
  onRowClick: null,
  loading: false,
  tableRef: null,
  height: undefined,
  children: null,
  hideHeaders: false,
  remoteOperations: false,
  onRowPrepared: null,
  disableRowAlternation: false,
  pageSize: allowedPageSizes,
  growToFullHeight: false,
  minHeight: 300,
  onContextMenuPreparing: null,
  containerStyle: {},
};

Table.Column = Column;
Table.Validations = {
  RequiredRule,
  CustomRule,
};

Table.Lookup = Lookup;

export type TableType = DataGridType;

export default Table;
