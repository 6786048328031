import IndexViewUi from 'modules/digital-threads/index-view-ui';
import SpiderViewUi from 'modules/digital-threads/spider-view-ui';
import DataViewUi from 'modules/digital-threads/dt-data-view-ui';
import CheckViewUi from 'modules/digital-threads/dt-data-check-ui';
import MappingWizard from 'modules/digital-threads-mapping-wizzard/wizzard-ui';
import { DTTabId } from './enums';

export const DigitalThreadsPages = [
  {
    id: DTTabId.SPIDER,
    label: 'DT Spider',
    disabled: false,
    item: SpiderViewUi,
  },
  {
    id: DTTabId.INDEX,
    label: 'DT Index',
    disabled: false,
    item: IndexViewUi,
  },
  {
    id: DTTabId.DATA,
    label: 'Reviewing',
    disabled: false,
    item: DataViewUi,
  },
  {
    id: DTTabId.CHECKING,
    label: 'Modeling',
    disabled: false,
    item: CheckViewUi,
  },
  {
    id: DTTabId.MAPPING,
    label: 'Mapping',
    disabled: false,
    item: MappingWizard,
  },
];
