import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: '80vh',
    overflow: 'initial',
    minHeight: 350,

    maxWidth: '50%',
    minWidth: '50%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
      minWidth: '70%',
    },
  },
  dialogContent: {
    overflowY: 'auto',
    paddingTop: 0,
  },
  select: {
    marginBottom: 20,
  },
}));
