import { memo, useRef } from 'react';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import instance from 'modules/rest/axios';
import { useStyles } from './styles';

const XlsPreview = ({ path, filePath, fileName, body }) => {
  const spreadsheet = useRef<SpreadsheetComponent | null>(null);

  const onCreatedXls = () => {
    if (fileName && filePath) {
      instance({
        method: 'post',
        url: `${path}/read`,
        data: {
          ...body,
          filePath,
          fileName,
        },
        responseType: 'blob',
      })
        .then(({ data }) => {
          const file = new File([data as Blob], 'Sample.xlsx');
          spreadsheet.current?.open({ file });
        })
        .catch(() => {});
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <SpreadsheetComponent
          ref={spreadsheet}
          created={onCreatedXls}
          id="ssContainer"
          openUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open"
        />
      </div>
    </div>
  );
};

export default memo(XlsPreview);
