import { memo, useContext, useMemo } from 'react';
import { Alert, Collapse, Grid } from '@mui/material';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';

import { BnSettingsState } from '../context/DataStoresState';
import SelectOrCreateBnSettingInput from '../SelectOrCreateBnSettingInput';
import { useLoadAdlsContainers } from '../hooks/useLoadAdlsContainers';
import SelectOrCreateBnSettingList from '../SelectOrCreateBnSettingList';

const ACCOUNT_NAME = 'accountName';
const ACCOUNT_KEY = 'accountKey';
const CONTAINER = 'container';

const AdlsKeyUI = ({ values, onValueChange }) => {
  const { settings, settingsLoading } = useContext(BnSettingsState);

  const items = useMemo(
    () =>
      settings.map((s) => ({
        value: s.name,
        label: s.name,
      })),
    [settings],
  );

  const accountName = values[ACCOUNT_NAME];
  const accountKey = values[ACCOUNT_KEY];
  const container = values[CONTAINER];

  const { connection, projectId } = useProjectInfoFromSearch();

  const {
    loading: containersLoading,
    containers,
    error: containersError,
  } = useLoadAdlsContainers({
    connection,
    projectId,
    accountKey,
    accountName,
  });

  return (
    <>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={ACCOUNT_NAME}
          label="Account Name"
          value={accountName}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(ACCOUNT_NAME, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={ACCOUNT_KEY}
          label="Account Key"
          value={accountKey}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(ACCOUNT_KEY, value);
          }}
        />
        <Collapse in={!!containersError}>
          <Alert style={{ marginTop: 20 }} severity="error">
            Configuration is not correct
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingList
          id={CONTAINER}
          label="Container"
          value={container}
          items={items}
          loading={containersLoading}
          disabled={!accountKey || !accountName || containersLoading}
          onChange={(value) => {
            onValueChange(CONTAINER, value);
          }}
          suggestions={containers.map((d) => ({ value: d.name, label: d.name }))}
        />
      </Grid>
    </>
  );
};

export default memo(AdlsKeyUI);
