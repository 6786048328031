import { noop } from 'modules/shared/utils';
import { useCallback, useState } from 'react';

export const useBuilderModalState = ({ onOpen } = { onOpen: noop }) => {
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    meta: Record<string, any>;
  }>({
    isOpen: false,
    meta: {},
  });

  const handleModalOpen = useCallback(
    (meta) => {
      setModalConfig({
        isOpen: true,
        meta,
      });
      onOpen();
    },
    [onOpen],
  );

  const handleModalClose = useCallback(() => {
    setModalConfig({
      isOpen: false,
      meta: {},
    });
  }, []);

  return {
    modalConfig,
    handleModalOpen,
    handleModalClose,
  };
};
