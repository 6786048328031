import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 10,
  },
  picker: {
    marginLeft: 15,
  },
}));
