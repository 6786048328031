import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

const drawerWidth = 275;
const closedDrawerWidth = 60;

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& svg': {
      color: '#ffffff',
    },
  },
  actionButton: {
    '& svg': {
      background: '#ffffff',
      color: '#ffffff',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    color: BorNeoColors.primaryColor5,
    background: BorNeoColors.accentColor9,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& + div': {
      marginLeft: drawerWidth,
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    '& svg': {
      color: '#ffffff',
    },
  },
  drawerClose: {
    color: BorNeoColors.primaryColor5,
    background: BorNeoColors.accentColor9,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& svg': {
      color: '#ffffff',
    },
    overflowX: 'hidden',
    width: closedDrawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: closedDrawerWidth,
    },

    '& + div': {
      marginLeft: closedDrawerWidth,
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  menuList: {
    marginLeft: '5px',
    marginRight: '10px',
  },
}));
