import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { LOAD_BN_SETTINGS_CATEGORIES, LOAD_BN_SETTINGS_CATEGORY_BY_ID } from '../gql';
import { BnSettingCategory } from '../types';

export const useLoadBnSettingsCategories = ({ connection, skip }) => {
  const client = useApolloClient();

  const { loading, data } = useQuery(LOAD_BN_SETTINGS_CATEGORIES, {
    variables: {
      connection,
    },
    skip: skip || !connection,
  });

  const categories: BnSettingCategory[] = data?.allBnSettingsCategories || [];

  const load = useCallback(async () => {
    if (!connection) {
      return {
        data: [],
        totalCount: 0,
      };
    }

    const response = await client.query({
      query: LOAD_BN_SETTINGS_CATEGORIES,
      variables: {
        connection,
      },
    });

    const items = response.data?.allBnSettingsCategories || [];

    return { data: JSON.parse(JSON.stringify(items)), totalCount: items.length };
  }, [client, connection]);

  const loadById = useCallback(
    async (id: string) => {
      const response = await client.query({
        query: LOAD_BN_SETTINGS_CATEGORY_BY_ID,
        variables: {
          connection,
          id,
        },
      });

      const item = response.data?.bnSettingsCategoryById;

      return item;
    },
    [client, connection],
  );

  return { loading, categories, load, loadById };
};
