import { makeStyles } from '@material-ui/core';
import { dangerColor, primaryColor1 } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '24px 8px 16px',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    position: 'relative',
    cursor: 'pointer',
    boxShadow: '0 0 6px 2px #8f8f8f',
  },
  actions: { position: 'absolute', top: 8, right: 8, display: 'flex', gap: 4 },
  editButton: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover > *': {
      fill: primaryColor1,
    },
  },
  deleteButton: {
    width: 14,
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover > *': {
      fill: dangerColor,
    },
  },
  icon: {
    width: '100% !important',
    height: '100% !important',
  },
  content: {
    paddingRight: 16,
    position: 'relative',
    flex: 1,
  },
  text: { flex: 1 },
  error: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: 'red',
    width: '14px !important',
    height: '14px !important',
  },
}));
