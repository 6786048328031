import { memo, useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { BnSettingsState } from '../context/DataStoresState';
import SelectOrCreateBnSettingInput from '../SelectOrCreateBnSettingInput';

const URL = 'url';
const REGION = 'region';
const ACCESS_KEY = 'accessKeyId';
const SECRET_KEY = 'secretAccessKey';
const BUCKET = 'bucket';

const S3Fields = ({ values, onValueChange }) => {
  const { settings, settingsLoading } = useContext(BnSettingsState);

  const items = useMemo(
    () =>
      settings.map((s) => ({
        value: s.name,
        label: s.name,
      })),
    [settings],
  );

  const url = values[URL];
  const region = values[REGION];
  const accessKey = values[ACCESS_KEY];
  const secretKey = values[SECRET_KEY];
  const bucket = values[BUCKET];

  return (
    <>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={URL}
          label="Url"
          value={url}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(URL, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={REGION}
          label="Region"
          value={region}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(REGION, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={ACCESS_KEY}
          label="Access Key"
          value={accessKey}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(ACCESS_KEY, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={SECRET_KEY}
          label="Secret Key"
          value={secretKey}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(SECRET_KEY, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={BUCKET}
          label="Bucket"
          value={bucket}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(BUCKET, value);
          }}
        />
      </Grid>
    </>
  );
};

export default memo(S3Fields);
