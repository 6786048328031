import { ALL_ITEM, ALL_VALUE } from 'modules/shared/constants';
import { useCallback } from 'react';
import { useSearch } from '../url';

export const AmountItems = [
  ALL_ITEM,
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
];

export const useTablePaginationWithSearch = () => {
  const { get, set } = useSearch();

  const search = get('q');
  const amount = +get('limit');
  const page = +get('page') || 1;

  const onSearchChange = useCallback(
    (e) => {
      set({
        q: e.target.value,
        page: '',
      });
    },
    [set],
  );

  const onAmountChange = useCallback(
    (e) => {
      const { value } = e.target;

      set({
        page: '',
        limit: value === ALL_VALUE ? '' : value,
      });
    },
    [set],
  );

  const onPageChange = useCallback(
    (_, value) => {
      set({
        page: value === 1 ? '' : value,
      });
    },
    [set],
  );

  return {
    search,
    amount,
    page,
    onSearchChange,
    onAmountChange,
    onPageChange,
  };
};
