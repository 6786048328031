import instance from 'modules/rest/axios';
import { getApiBaseUrl } from 'modules/shared/utils';

export type ArchivePayload = {
  connection: string;
  projectId: string;
  path: string;
  files: string[];
};

export type DeleteArchivePayload = {
  id: string;
  keepFile: boolean;
};

export const archive = (data: ArchivePayload) => {
  return instance({
    method: 'post',
    url: `${getApiBaseUrl()}/hdfs-access/archive`,
    data,
  });
};

export const remove = (data: DeleteArchivePayload) => {
  return instance({
    method: 'post',
    url: `${getApiBaseUrl()}/hdfs-access/archive/delete`,
    data,
  });
};

export const getUrl = (id: string) => {
  return instance({
    method: 'post',
    url: `${getApiBaseUrl()}/hdfs-access/archive/download-url`,
    data: {
      id,
    },
  });
};
