import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Grouping,
  GroupPanel,
  Scrolling,
  RemoteOperations,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  ColumnFixing,
  ColumnChooser,
  RowDragging,
} from 'devextreme-react/data-grid';
import { noop } from 'modules/shared/utils';

import ExportExcelSnackbar from './components/ExportExcelSnackbar';

import { GridProps, GridColumn } from './types';

import { useStyles } from './styles';

const DataGridDefaultSettings = {
  pageSize: 25,
  allowedPageSizes: [10, 25, 50, 100, 250],
};

function customizeHeaderFilter(options) {
  options.dataSource.postProcess = function postProcess(items) {
    items = items.filter((item) => item.value !== null);
    if (this.pageIndex() === 0) {
      items = items.slice(0);
      items.unshift({ value: null, text: '(Blanks)' });
    }
    return items;
  };
}

export const renderColumns = (columns: GridColumn[]) => {
  return columns.map((col) => {
    if (col.component) {
      return col.component;
    }
    const { validations, ...colData } = col;
    return (
      <Column key={col.dataField} {...colData}>
        {validations}
        <HeaderFilter allowSearch dataSource={customizeHeaderFilter} />
      </Column>
    );
  });
};

const colFixing = {
  enabled: true,
  texts: {
    fix: 'Freeze this column',
    leftPosition: 'To the Left',
    rightPosition: 'To the Right',
    unfix: 'Unfreeze',
  },
};

const Grid = React.forwardRef<DataGrid, PropsWithChildren<GridProps>>(
  ({ dataSource, gridName, columns, gridOptions, children, handlersRef, onExport, ...htmlOptions }, ref) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const {
      grouping = { contextMenuEnabled: true },
      groupPanel = { visible: true },
      filterRow = { visible: true },
      searchPanel = { visible: true },
      editing,
      selection = { mode: 'single' },
      columnFixing = colFixing,
      columnChooser = { enabled: true },
      rowDragging = { allowReordering: false, onReorder: noop },
      ...safeGridOptions
    } = gridOptions ?? {};

    return (
      <DataGrid
        {...htmlOptions}
        className={classnames(classes.grid, {
          [classes.grouping]: groupPanel?.visible ?? false,
        })}
        dataSource={dataSource}
        wordWrapEnabled
        hoverStateEnabled
        showBorders
        allowColumnResizing
        columnResizingMode="widget"
        columnAutoWidth
        ref={ref}
        onExporting={(e) => {
          if (onExport) {
            e.cancel = true;
            onExport();
          } else {
            enqueueSnackbar(`Preparing ${gridName ?? ''} data to export...`, {
              key: `export-excel-${gridName ?? ''}`,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              persist: true,
              preventDuplicate: true,
              content: (key, message) => {
                return <ExportExcelSnackbar key={key} message={message} />;
              },
            });
          }
        }}
        onExported={() => {
          closeSnackbar(`export-excel-${gridName ?? ''}`);
        }}
        {...safeGridOptions}
      >
        <Selection {...selection} />
        <Scrolling mode="standard" />
        <Grouping autoExpandAll={false} {...grouping} />
        <RemoteOperations sorting paging />
        <Paging enabled defaultPageSize={DataGridDefaultSettings.pageSize} />
        <Pager
          visible
          allowedPageSizes={DataGridDefaultSettings.allowedPageSizes}
          showInfo
          showPageSizeSelector
          showNavigationButtons={false}
        />
        {groupPanel ? <GroupPanel {...groupPanel} /> : null}
        {columns && renderColumns(columns)}
        {children}
        <FilterRow {...filterRow} />
        <SearchPanel {...searchPanel} />
        <FilterPanel {...filterRow} />
        <FilterBuilderPopup />
        <HeaderFilter dataSource={customizeHeaderFilter} {...filterRow} />
        <ColumnFixing {...columnFixing} />

        <ColumnChooser enabled allowSearch mode="select" {...columnChooser} />
        <RowDragging dropFeedbackMode="push" {...rowDragging} />
      </DataGrid>
    );
  },
);

export default Grid;
