import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const appInitStart = createAction(`${NAMESPACE}INIT_START`);
export const appInitSuccess = createAction(`${NAMESPACE}INIT_SUCCESS`);
export const appInitFailure = createAction(`${NAMESPACE}INIT_FAILURE`);

export const appLoadingStart = createAction(`${NAMESPACE}APP_LOADING_START`);
export const appLoadingFinish = createAction(`${NAMESPACE}APP_LOADING_FINISH`);

// Notifications
export const notificationEnqueue = createAction(`${NAMESPACE}NOTIFICATION_ENQUEUE`);
