import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from '../../styles/colors';

export const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    background: BorNeoColors.primaryColor5,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  layout: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    overflow: 'hidden',
  },
  pageWrap: {
    padding: '15px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    padding: '12px 20px',
    display: 'flex',
    alignItems: 'flex-end',
    background: BorNeoColors.primaryColor5,
  },
  toolbar: {
    display: 'flex',
    gap: 10,
    [breakpoints.down('sm')]: {
      flexFlow: 'column-reverse',
      padding: '0 5px',
    },
  },
  control: {
    display: 'flex',
    flexFlow: 'row nowrap',
    [breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-end',
    },
  },
  select: {
    marginRight: 15,
    [breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
    },
  },
}));
