import { gql } from '@apollo/client';

export const LOAD_FLOW_CUSTOMIZE = gql`
  query LoadFlowCustomize(
    $flowId: String!
    $shapeId: String!
    $projectId: String!
    $boardId: String!
    $connection: String!
  ) {
    getFlowCustomizeByFlowIdAndBoardId(
      flow_id: $flowId
      shape_id: $shapeId
      project_id: $projectId
      board_id: $boardId
      connection: $connection
    ) {
      id
      fkFlowId
      bodyFields
      headers
    }
  }
`;
