import { Chip } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { FlowSolutionBoardTracking } from 'modules/project-flow-solution/types';
import { Title, Dialog, Content } from 'modules/shared/components/Dialog';
import Table from 'modules/shared/components/Table';
import { FC, memo, useEffect, useState } from 'react';
import { StatusExecute } from 'modules/project-flow-solution/board-ui/enums';
import { useStyles } from './styles';

const MODAL_ID = 'card-tracking-modal';
type Props = {
  open: boolean;
  onClose: () => void;
  trackings: FlowSolutionBoardTracking[];
};
type DataTable = {
  trackingId: string;
  status: StatusExecute;
  completedAt: string;
  checkedStatus?: boolean;
  checkedSetUp?: string;
  checkedSignOff?: string;
  checkedName?: string;
  index: number;
};
const CardTrackingModal: FC<Props> = ({ open, onClose, trackings }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState<DataTable[]>([]);

  useEffect(() => {
    const listData: DataTable[] = [];
    let index = 0;
    trackings.forEach((tracking) => {
      if (tracking.checkedChecklistItems?.length) {
        tracking.checkedChecklistItems.forEach((c) => {
          listData.push({
            trackingId: tracking.trackingId,
            status: tracking.status,
            completedAt: tracking.completedAt as string,
            checkedStatus: c.status,
            checkedSetUp: c.setUp,
            checkedSignOff: c.signOff,
            checkedName: c.checklistItem?.name,
            index,
          });
        });
        index++;
      } else {
        listData.push({
          trackingId: tracking.trackingId,
          status: tracking.status,
          completedAt: tracking.completedAt as string,
          index,
        });
        index++;
      }
    });
    setDataTable([...listData]);
  }, [trackings]);
  const cellRender = ({ data, column: { dataField } }) => {
    switch (dataField) {
      case 'checkedSetUp':
      case 'checkedSignOff':
        return data[dataField] ? format(parseISO(data[dataField]), 'MM/dd/yyyy') : 'Not fill';
      case 'checkedStatus':
        return data[dataField] ? 'Checked' : 'Not fill';
      default:
        return data[dataField] || '';
    }
  };

  const groupCellTemplate = ({ data }) => {
    const tracking = data.items[0];
    if (tracking) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <div>Completed at: {format(parseISO(new Date(tracking.completedAt).toISOString()), 'MM/dd/yyyy HH:mm')}</div>
          <Chip label={tracking.status} className={classes[tracking.status]} />
        </div>
      );
    }
    return <div />;
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={onClose}
      aria-labelledby={MODAL_ID}
      open={open}
    >
      <Title id={MODAL_ID} onClose={onClose}>
        Status of Card
      </Title>
      <Content dividers style={{ minHeight: 300 }}>
        <Table data={dataTable} loading={false} height="100%" editingMode="cell" growToFullHeight>
          <Table.Column
            dataField="checkedStatus"
            caption="Status"
            alignment="center"
            minWidth={60}
            cellRender={cellRender}
            allowSorting={false}
          />
          <Table.Column
            dataType="string"
            dataField="checkedName"
            caption="Name"
            format
            minWidth={100}
            allowSorting={false}
          />
          <Table.Column
            dataType="date"
            dataField="checkedSetUp"
            caption="Set Up"
            format
            cellRender={cellRender}
            minWidth={100}
            allowSorting={false}
          />
          <Table.Column
            dataType="date"
            dataField="checkedSignOff"
            caption="Sign Off"
            cellRender={cellRender}
            minWidth={100}
            allowSorting={false}
          />
          <Table.Column dataField="index" groupCellRender={groupCellTemplate} groupIndex={0} allowSorting={false} />
        </Table>
      </Content>
    </Dialog>
  );
};

export default memo(CardTrackingModal);
