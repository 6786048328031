import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  diagramWrapper: {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
  },
  loading: {
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    top: 0,
    height: '100%',
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  diagram: {
    flex: 1,
    '& #diagramcontent': { overflow: 'hidden !important' },
  },
}));
