import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  image: {
    position: 'fixed',
    top: '20px',
    left: '60px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    width: '250px',
    height: '150px',
    backgroundImage: `url('${process.env.PUBLIC_URL}/assets/wp_logo_01.png')`,
  },
}));
