import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';

import { UPDATE_FLOW } from '../gql';
import { FlagsContext } from '../context/FlagsContext/context';

export const useUpdateFlow = () => {
  const { enqueueSnackbar } = useSnackbar();
  const flags = useContext(FlagsContext);

  const [update, { loading, error }] = useMutation(UPDATE_FLOW);

  const handleUpdate = useCallback(
    (onSuccess, flowId, ...args) => {
      flags.updateIds = [...flags.updateIds, flowId];
      return update(...args)
        .then(onSuccess)
        .catch((e) => {
          flags.updateIds = flags.updateIds.filter((f) => f !== flowId);

          if (e.networkError?.statusCode === 400) {
            return enqueueSnackbar('Please, fill all fields properly', BASE_ERROR_NOTIFICATION_OPTIONS);
          }

          enqueueSnackbar('Something went wrong. Please, try again later', BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [update, enqueueSnackbar, flags],
  );

  return {
    update: handleUpdate,
    loading,
    error,
  };
};
