import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import {
  CREATE_SYNC_PATHES,
  DELETE_SYNC_PATH,
  LOAD_SYNC_PATHES,
  RESET_SYNC_PATH_SCHEDULER,
  UPDATE_SYNC_PATH_SCHEDULER,
} from '../gql';
import { SyncPathInput } from '../types';

export const useSyncPathActions = ({ connection, projectId, type }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createSyncPathes, { loading: creating }] = useMutation(CREATE_SYNC_PATHES, {
    refetchQueries: [LOAD_SYNC_PATHES],
  });

  const [updateSyncPathScheduler, { loading: updatingScheduler }] = useMutation(UPDATE_SYNC_PATH_SCHEDULER, {
    update(cache, { data: { updateSyncPathScheduler: updateSyncPathSchedulerData } }) {
      cache.modify({
        fields: {
          loadSyncPathes(existing) {
            return existing.map((e) => {
              if (e.pathId === updateSyncPathSchedulerData.pathId) {
                return updateSyncPathSchedulerData;
              }
              return e;
            });
          },
        },
      });
    },
  });
  const [resetSyncPathScheduler, { loading: resettingScheduler }] = useMutation(RESET_SYNC_PATH_SCHEDULER, {
    update(cache, { data: { resetSyncPathScheduler: resetSyncPathSchedulerData } }) {
      cache.modify({
        fields: {
          loadSyncPathes(existing) {
            return existing.map((e) => {
              if (e.pathId === resetSyncPathSchedulerData.pathId) {
                return resetSyncPathSchedulerData;
              }
              return e;
            });
          },
        },
      });
    },
  });

  const [deleteSyncPath] = useMutation(DELETE_SYNC_PATH, {
    update(cache, { data: { deleteSyncPath: deleteSyncPathData } }) {
      cache.modify({
        fields: {
          loadSyncPathes(existing) {
            return existing.filter((s) => s.pathId !== deleteSyncPathData);
          },
        },
      });
    },
  });

  const create = useCallback(
    (onSuccess, dataStoreConnection: string, name: string, custom, pathes: SyncPathInput[], scheduler) => {
      return createSyncPathes({
        variables: {
          connection,
          projectId,
          dataStoreConnection,
          type,
          name,
          filter: custom.filter,
          unzip: custom.unzip,
          loadDeltaLake: custom.loadDeltaLake,
          pathes,
          schedulerType: scheduler.scheduler,
          schedulerPattern: scheduler.schedulerValue ? scheduler.schedulerValue.join(' ') : '',
          schedulerLabel: scheduler.schedulerLabel,
        },
      })
        .then(onSuccess)
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [createSyncPathes, enqueueSnackbar, connection, projectId, type],
  );

  const updateScheduler = useCallback(
    (onSuccess, dataStoreConnection: string, pathId: string, name: string, custom, scheduler) => {
      return updateSyncPathScheduler({
        variables: {
          connection,
          pathId,
          name,
          dataStoreConnection,
          filter: custom.filter,
          unzip: custom.unzip,
          loadDeltaLake: custom.loadDeltaLake,
          schedulerType: scheduler.scheduler,
          schedulerPattern: scheduler.schedulerValue ? scheduler.schedulerValue.join(' ') : '',
          schedulerLabel: scheduler.schedulerLabel,
          projectId,
        },
      })
        .then(onSuccess)
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [updateSyncPathScheduler, projectId, enqueueSnackbar, connection],
  );

  const resetScheduler = useCallback(
    (onSuccess, pathId: string) => {
      return resetSyncPathScheduler({
        variables: {
          connection,
          pathId,
          projectId,
        },
      })
        .then(onSuccess)
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [resetSyncPathScheduler, projectId, enqueueSnackbar, connection],
  );

  const remove = useCallback(
    (pathId: string) => {
      return deleteSyncPath({
        variables: {
          connection,
          pathId,
          projectId,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [deleteSyncPath, projectId, enqueueSnackbar, connection],
  );

  return { remove, create, creating, updateScheduler, updatingScheduler, resetScheduler, resettingScheduler };
};
