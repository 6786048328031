import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LOAD_HDFS_CATEGORIES } from '../gql';

export const useLoadHdfsCategories = ({ connection, projectId, isPrivate }, skip = false) => {
  const [categories, setCategories] = useState<string[]>([]);

  const { loading, data } = useQuery(LOAD_HDFS_CATEGORIES, {
    variables: {
      connection,
      projectId,
      isPrivate,
    },
    skip: !connection || !projectId || skip,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setCategories(data?.readHdfsCategories?.folders || []);
  }, [data]);

  return {
    loading,
    categories,
    updateCategories: setCategories,
  };
};
