import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    background: BorNeoColors.primaryColor6,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  selectsContaner: {
    padding: 10,
    marginBottom: 10,
    background: BorNeoColors.primaryColor6,
    display: 'grid',
    gridGap: 15,
    gridTemplateColumns: '1fr 1fr',
  },

  tableContainer: {
    position: 'relative',
    background: BorNeoColors.primaryColor6,
    flex: 1,
  },
  tableWrapper: {
    background: BorNeoColors.primaryColor6,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '0 10px 10px',
    overflowY: 'auto',
  },
  loadingContainer: {
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(2px)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
