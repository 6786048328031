import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { Box, Button, CircularProgress } from '@mui/material';
import { memo, useEffect, useState } from 'react';

import { noop } from 'modules/shared/utils';
import { useStyles } from './styles';
import ModalContent from './ModalContent';

const MODAL_ID = 'create-connection-modal';
const FORM_ID = 'create-connection-modal-form';

const SyncPathSchedulerModal = ({
  isOpen,
  close,
  executing,
  onSubmit,
  initialValues,
  customFields = { unzip: false, filter: false, loadDeltaLake: false },
}) => {
  const classes = useStyles();

  const [scheduler, setScheduler] = useState<{
    name: string;
    dataStoreConnection: string;
    filter: string;
    unzip: boolean;
    loadDeltaLake: boolean;
    scheduler: string;
    schedulerValue: null | string[];
    schedulerLabel: string;
  }>(initialValues);

  useEffect(() => {
    if (isOpen) {
      setScheduler(initialValues);
    }
  }, [isOpen, initialValues]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={executing ? noop : close}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={close}>
        Sync schedule
      </Title>

      <Content className={classes.dialogContent}>
        <Box
          component="form"
          id={FORM_ID}
          onSubmit={(e) => {
            e.preventDefault();

            onSubmit(
              scheduler.name,
              {
                filter: scheduler.filter,
                unzip: scheduler.unzip,
                loadDeltaLake: scheduler.loadDeltaLake,
                dataStoreConnection: scheduler.dataStoreConnection,
              },
              scheduler,
            );
          }}
        >
          <ModalContent
            customFields={customFields}
            scheduler={scheduler}
            onChange={setScheduler}
            disabled={executing}
          />
        </Box>
      </Content>

      <Actions>
        <Button variant="contained" color="primary" onClick={close} disabled={executing}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={executing}>
          {executing ? <CircularProgress className={classes.progress} size={24} /> : 'Sync'}
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(SyncPathSchedulerModal);
