import { gql } from '@apollo/client';

export const LOAD_HDFS_CATEGORIES = gql`
  query ReadHdfsCategories($connection: String!, $projectId: String!, $isPrivate: Boolean!) {
    readHdfsCategories(connection: $connection, project_id: $projectId, is_private: $isPrivate) {
      folders
    }
  }
`;

export const LOAD_HDFS_TEMPLATES_PER_CATEGORY = gql`
  query ReadHdfsTeplatesPerCategory(
    $connection: String!
    $projectId: String!
    $isPrivate: Boolean!
    $category: String!
  ) {
    readHdfsTeplatesPerCategory(
      connection: $connection
      project_id: $projectId
      is_private: $isPrivate
      category: $category
    ) {
      folders
    }
  }
`;

export const LOAD_HDFS_TEMPLATE_DATA = gql`
  query ReadHdfsDataTemplateCurrentItems(
    $connection: String!
    $projectId: String!
    $isPrivate: Boolean!
    $category: String!
    $dataTemplate: String!
  ) {
    readHdfsDataTemplateCurrentItems(
      connection: $connection
      project_id: $projectId
      is_private: $isPrivate
      category: $category
      data_template: $dataTemplate
    ) {
      folders
    }
  }
`;

export const LOAD_DELTA_CATEGORIES = gql`
  query ReadDeltaCategories($connection: String!, $projectId: String!) {
    readDeltaCategories(connection: $connection, project_id: $projectId)
  }
`;

export const LOAD_DELTA_TABLES_PER_CATEGORY = gql`
  query ReadDeltaTablesPerCategory($connection: String!, $projectId: String!, $category: String!) {
    readDeltaTablesPerCategory(connection: $connection, project_id: $projectId, category: $category)
  }
`;
