import { memo, ReactNode, useState } from 'react';

import { Button } from '@material-ui/core';

import { useStyles } from './styles';

type Item = {
  title: string;
  component: ReactNode;
};

type Props = {
  items: Item[];
  renderButtons?: (btns: ReactNode) => ReactNode;
};

const Swiper = ({ items, renderButtons }: Props) => {
  const classes = useStyles();

  const [selected, setSelected] = useState(items[0].title);

  const selectedIdx = items.findIndex((i) => i.title === selected);
  const btns = items.map((i) => {
    return (
      <Button
        key={i.title}
        variant="outlined"
        color="primary"
        className={
          i.title === selected ? `${classes.selectedDtButton} ${classes.modalDtButton}` : classes.modalDefaultDtButton
        }
        onClick={() => {
          setSelected(i.title);
        }}
      >
        {i.title}
      </Button>
    );
  });

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      {renderButtons ? renderButtons(btns) : <div className={classes.buttonRoot}>{btns}</div>}
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          flexWrap: 'nowrap',
          flex: 1,
          transform: `translateX(calc(${selectedIdx * -100}% - ${5 * selectedIdx}px)`,
          transition: 'transform 0.3s ease',
        }}
      >
        {items.map((i) => {
          return (
            <div
              key={i.title}
              style={{
                flex: '1 0 100%',
                display: 'flex',
                marginRight: 5,
              }}
            >
              {i.component}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Swiper);
