import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

const GRID_GAP = 20;

export const useStyles = makeStyles(() => ({
  page: {
    margin: '0 10px 20px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    borderRadius: 4,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    background: BorNeoColors.primaryColor4,
  },

  row: {
    marginLeft: -GRID_GAP,
    marginTop: -GRID_GAP,

    display: 'flex',
    flexWrap: 'wrap',
  },
  firstRowCell: {
    marginLeft: GRID_GAP,
    marginTop: GRID_GAP,

    height: 260,
    minWidth: 250,

    flex: '1 0',

    background: BorNeoColors.primaryColor4,
  },
  profileCell: {
    boxSizing: 'border-box',
    flex: '0 0 27%',
    minWidth: 300,
    padding: 20,
  },
  secondRowWrap: {
    position: 'relative',
    flex: 1,
    minHeight: 400,
  },
  secondRow: {
    marginTop: 0,
    flex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  secondRowCell: {
    marginLeft: GRID_GAP,
    marginTop: GRID_GAP,

    minWidth: 250,

    flex: '1 0',

    background: BorNeoColors.primaryColor4,

    height: '100%',
  },
  feedCell: {
    boxSizing: 'border-box',
    flex: '0 0 27%',
    minWidth: 300,
  },
  secondRowLastCell: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0',
    height: `calc(100% + ${GRID_GAP}px)`,
  },
  secondRowLastCellOneCol: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  twoCols: {
    flex: `0 0 calc(50% - ${GRID_GAP}px)`,
    minHeight: 300,
    height: 'auto',
    minWidth: '-webkit-fill-available',
  },
  oneCol: {
    flex: `0 0 calc(100% - ${GRID_GAP}px)`,
    minHeight: 300,
    height: 'auto',
    minWidth: 'auto',
  },
  lastRowOneCol: {
    flex: `0 0 calc(100% - ${GRID_GAP}px)`,
    minHeight: 300,
    maxHeight: 300,
    height: 'auto',
    minWidth: 'auto',

    '&:first-child': {
      minHeight: 500,
      maxHeight: 500,
    },
  },
}));
