import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { LOAD_FLOW_SOLUTIONS_BY_PROJECT } from '../gql';
import { FlowSolution } from '../types';

export const useFlowSolutionsByProject = (projectId: string, connection: string) => {
  const { loading, data, refetch } = useQuery(LOAD_FLOW_SOLUTIONS_BY_PROJECT, {
    variables: {
      projectId,
      connection,
    },
    skip: !connection || !projectId,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const flowSolutions: FlowSolution[] = data?.flowSolutions || [];

  return {
    flowSolutions,
    loading,
    refetch,
  };
};
