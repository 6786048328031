import { useQuery } from '@apollo/client';
import { LOAD_ADLS_CONTAINERS } from '../gql';
import { AdlsContainer } from '../types';

export const useLoadAdlsContainers = ({ connection, projectId, accountName, accountKey }) => {
  const { loading, data, error } = useQuery(LOAD_ADLS_CONTAINERS, {
    variables: {
      accountName,
      accountKey,
      connection,
      projectId,
    },
    skip: !accountName || !accountKey,
  });

  const containers: AdlsContainer[] = data?.adlsContainers || [];

  return { loading, containers, error };
};
