import { useQuery } from '@apollo/client';

import { useMemo } from 'react';

import { LOAD_DIGITAL_THREADS_HISTORY } from '../gql';

export const useLoadDigitalThreadsHistory = (labelName: string, projectId: string, connection: string) => {
  const { data, loading } = useQuery(LOAD_DIGITAL_THREADS_HISTORY, {
    variables: {
      labelName,
      connection,
      projectId,
    },
    skip: !labelName || !projectId || !connection,
    notifyOnNetworkStatusChange: true,
  });

  const digitalThreadsHistory = useMemo(() => data?.digitalThreadsHistory || [], [data?.digitalThreadsHistory]);

  return {
    digitalThreadsHistory,
    loading,
  };
};
