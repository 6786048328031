import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: 15,
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -10,
    marginTop: -10,
    '& > *': {
      marginLeft: 10,
      marginTop: 10,
      userSelect: 'none',
    },
  },
  dtSelector: {
    width: '250px',
    marginRight: 10,
  },
}));
