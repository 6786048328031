import Select from 'modules/shared/components/Select2';
import { memo } from 'react';
import { CheckingItem } from 'modules/digital-threads/types';
import Row from '../Row';

type Props = {
  id: string;
  connection: string;
  projectId: string;
  items: CheckingItem[];
  onChange: (item: CheckingItem, itemUpdates: Partial<CheckingItem>) => void;
  availableItems: { value: string; label: string }[];
  onEditItems: (items: string[]) => void;
  onCreateItem: (item: string) => void;
  disabled: boolean;
};

const RelationsSetup = ({
  id,
  items,
  onChange,
  connection,
  projectId,
  availableItems,
  onEditItems,
  onCreateItem,
  disabled,
}: Props) => {
  return (
    <div style={{ flex: 1 }}>
      <div style={{ marginBottom: 16 }}>
        <Select
          id={`dt-relations-selector-${id}`}
          value={items.map((item) => item.item)}
          options={availableItems}
          onChange={(val) => {
            onEditItems(val);
          }}
          isMulti
          isCreatable
          onCreateOption={onCreateItem}
          disabled={disabled}
        />
      </div>
      {items.map((item) => {
        return (
          <Row
            key={item.item}
            isCustom={item.isCustom}
            customType={item.customType}
            connection={connection}
            projectId={projectId}
            id={item.itemId}
            name={item.item}
            disabled={disabled}
            status={item.status}
            onItemChange={(itemUpdates) => {
              onChange(item, itemUpdates);
            }}
          />
        );
      })}
    </div>
  );
};

export default memo(RelationsSetup);
