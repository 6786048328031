import { Button, Box } from '@mui/material';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useEffect, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { useStyles } from './styles';
import Config from '../Config';
import { Types, TypesListOptions } from '../../constants';
import { configPerType } from '../contstants';

const MODAL_ID = 'create-transformation-modal';

const FORM_ID = 'transormation-modal';

const Modal = ({ isOpen, close, onSubmit }) => {
  const classes = useStyles();
  const [type, setType] = useState<Types | ''>('');
  const [config, setConfig] = useState({});

  useEffect(() => {
    if (isOpen) {
      setType('');
    }
  }, [isOpen]);

  useEffect(() => {
    setConfig(configPerType[type] || {});
  }, [type]);

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(type, config);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={close}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={close}>
        Transformation configuration
      </Title>
      <Content className={classes.dialogContent}>
        <Box component="form" id={FORM_ID} onSubmit={handleSubmit}>
          <Select
            id="category"
            value={[type]}
            options={TypesListOptions}
            className={classes.select}
            disableClear
            onChange={(val) => {
              setType(val[0]);
            }}
            required
          />
          <Config type={type} config={config} setConfig={setConfig} />
        </Box>
      </Content>
      <Actions>
        <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={false}>
          Set
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(Modal);
