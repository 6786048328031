/* eslint-disable max-len */
import { useCreateProjectSubscription } from 'modules/shared/hooks/api/create-project-subscription/useCreateProjectSubscription';
import { useDeactivateConnectionListener } from 'modules/shared/hooks/api/deactivate-connection/useDeactivateConnectionListener';
import { useDeleteProjectSubscription } from 'modules/shared/hooks/api/delete-project-subscription/useDeleteProjectSubscription';
import { useDownloadSubscription } from 'modules/shared/hooks/api/download-subscription/useDownloadSubscription';
import { useNotifications } from 'modules/shared/hooks/api/notifications/useNotifications';
import { useUpdateConnectionListener } from 'modules/shared/hooks/api/update-connection/useUpdateConnectionListener';

export const useGlobalSubscriptions = (skip: boolean) => {
  useDeleteProjectSubscription(skip);
  useDownloadSubscription(skip);
  useDeactivateConnectionListener(skip);
  useUpdateConnectionListener(skip);
  useCreateProjectSubscription(skip);
  useNotifications(skip);
};
