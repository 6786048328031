import { useApolloClient } from '@apollo/client';
import { LOAD_CHECKING_ITEM_WITHOUT_RELATIONS } from '../gql';

export const useLoadCheckingItemWithoutRelations = (connection: string, projectId: string) => {
  const client = useApolloClient();

  const load = async (root: string) => {
    try {
      const { data } = await client.query({
        query: LOAD_CHECKING_ITEM_WITHOUT_RELATIONS,
        variables: {
          connection,
          projectId,
          root,
        },
      });

      return data?.checkingItemWithoutRelations || null;
    } catch {
      return null;
    }
  };

  return {
    load,
  };
};
