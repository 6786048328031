import { useQuery } from '@apollo/client';

import { LOAD_STATUSES } from '../gql';
import { Status } from '../types';

export const useLoadStatuses = ({ connection }) => {
  const { loading, data, error, refetch } = useQuery(LOAD_STATUSES, {
    variables: {
      connection,
    },
    skip: !connection,
  });

  const statuses: Status[] = data?.flowStatuses || [];

  return { loading, statuses, error, refetch };
};
