import { memo, useContext } from 'react';
import DraggableLayout from 'modules/shared/components/DraggableLayout';
import Menu from 'modules/flow-solution-builder/builder-board-ui/Menu';
import { useBuilderContextMenuState, useBuilderModalState } from 'modules/shared/hooks/base';
import {
  WizzardContext,
  WizzardHandlersContext,
} from 'modules/digital-threads-mapping-wizzard/wizzard-context/context';

import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import CreateTransformationModalUi from '../../../transformation-modal-ui/create-transformation-modal';
import UpdateTransformationModalUi from '../../../transformation-modal-ui/update-transformation-modal';
import EditDefaultValueModalUi from '../../../edit-default-value-modal-ui';
import ImportSourceModalUi from '../../../import-source-modal-ui';

const TransformationStep = () => {
  const { stepData, layoutRef, connection, projectId } = useContext(WizzardContext);
  const { updateShape, addTransformation, deleteConnectionChain } = useContext(WizzardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const editable = useAdminOrSuperUser(connection, projectId);

  const { menuConfig, handleMenuClose, openMenu } = useBuilderContextMenuState();

  const { modalConfig, handleModalClose, handleModalOpen } = useBuilderModalState({ onOpen: handleMenuClose });

  return (
    <>
      <DraggableLayout
        bgColour="rgb(255, 255, 255)"
        loadingBgColor="rgba(255, 255, 255, 0.2)"
        isLoading={false}
        boardData={stepData!.config.board}
        disableAdaptLayout
        adaptOnMount
        cardDragEnabled={!insidePreview}
        initial={stepData!.config.position}
        layoutRef={layoutRef}
        shapeProps={
          insidePreview || !editable
            ? {}
            : {
                onUpdate: updateShape,
              }
        }
        editableConnectionProps={
          insidePreview || !editable
            ? {}
            : {
                passProps: {
                  computedProps: (id) => {
                    return {
                      style: {
                        cursor: 'context-menu',
                      },
                      onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const { clientX, clientY } = e;
                        const layoutDimensions = layoutRef.current.containerEl!.getBoundingClientRect();

                        openMenu({
                          x: clientY,
                          y: clientX,
                          id,
                          shapeX: clientX - layoutDimensions.x,
                          shapeY: clientY - layoutDimensions.y,
                        });
                      },
                    };
                  },
                },
              }
        }
      />
      {!insidePreview && editable && (
        <>
          <ImportSourceModalUi />
          <CreateTransformationModalUi
            isOpen={modalConfig.isOpen}
            close={handleModalClose}
            onSubmit={(type, c) => {
              addTransformation({
                x: modalConfig.meta.x,
                y: modalConfig.meta.y,
                connectionId: modalConfig.meta.connectionId,
                type,
                config: c,
              });
              handleModalClose();
            }}
          />

          <UpdateTransformationModalUi />
          <EditDefaultValueModalUi />

          <Menu
            {...menuConfig}
            onClose={handleMenuClose}
            items={[
              {
                title: 'Add Transformation',
                onClick: () => {
                  handleModalOpen({
                    x: menuConfig.meta.shapeX,
                    y: menuConfig.meta.shapeY,
                    connectionId: menuConfig.meta.id,
                  });

                  handleMenuClose();
                },
              },
              {
                title: 'Delete Chain',
                onClick: () => {
                  deleteConnectionChain(menuConfig.meta.id);

                  handleMenuClose();
                },
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default memo(TransformationStep);
