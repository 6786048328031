import { gql } from '@apollo/client';

export const UPDATE_ACTIVE_STATE = gql`
  mutation UpdateActiveState($flowId: String!, $isActive: Boolean!, $connection: String!) {
    changeFlowActiveState(flow_id: $flowId, is_active: $isActive, connection: $connection) {
      flowId
      isActive
    }
  }
`;

export const START_FLOW = gql`
  mutation StartFlow($flowId: String!, $connection: String!) {
    startFlow(flow_id: $flowId, connection: $connection) {
      flowId
      status {
        statusId
        statusName
      }
      executedAt
    }
  }
`;

export const PAUSE_FLOW = gql`
  mutation PauseFlow($flowId: String!, $connection: String!) {
    pauseFlow(flow_id: $flowId, connection: $connection) {
      flowId
      status {
        statusId
        statusName
      }
    }
  }
`;

export const STOP_FLOW = gql`
  mutation StopFlow($flowId: String!, $connection: String!) {
    stopFlow(flow_id: $flowId, connection: $connection) {
      flowId
      status {
        statusId
        statusName
      }
    }
  }
`;

export const REGISTER_FLOW = gql`
  mutation RegisterFlow($flow: RegisterFlowDto!, $connection: String!, $baseConnection: String!) {
    registerFlow(flow: $flow, connection: $connection, base_connection: $baseConnection) {
      flowId
      flowName
      flowDescription
      project {
        projectId
        projectName
      }
      category {
        categoryId
      }
      status {
        statusId
        statusName
      }
      isActive
      executedAt
      scheduler {
        schedulerId
        schedulerLabel
        schedulerPattern
        schedulerType
      }
      pausable
      endpoint
      bodyFields
      headers
    }
  }
`;

export const UPDATE_FLOW = gql`
  mutation UpdateFlow($flow: UpdateFlowDto!, $connection: String!) {
    updateFlow(flow: $flow, connection: $connection) {
      flowId
      flowName
      flowDescription
      project {
        projectId
        projectName
      }
      category {
        categoryId
      }
      status {
        statusId
        statusName
      }
      isActive
      executedAt
      scheduler {
        schedulerId
        schedulerLabel
        schedulerPattern
        schedulerType
      }
      pausable
      endpoint
      bodyFields
      headers
    }
  }
`;

export const DELETE_FLOW = gql`
  mutation DeleteFlow($flowId: String!, $connection: String!) {
    deleteFlow(flow_id: $flowId, connection: $connection) {
      flowId
      flowName
    }
  }
`;

export const COPY_FLOW = gql`
  mutation CopyFlow($flow: RegisterFlowDto!, $connection: String!, $baseConnection: String!) {
    copyFlow(flow: $flow, connection: $connection, base_connection: $baseConnection) {
      flowId
      flowName
      flowDescription
      project {
        projectId
        projectName
      }
      category {
        categoryId
      }
      status {
        statusId
        statusName
      }
      isActive
      executedAt
      scheduler {
        schedulerId
        schedulerLabel
        schedulerPattern
        schedulerType
      }
      pausable
      endpoint
      bodyFields
      headers
    }
  }
`;

export const COPY_FLOWS = gql`
  mutation CopyFlows($source: ProjectDetailsInput!, $target: ProjectDetailsInput!, $flows: [String!]!) {
    copyFlows(source: $source, target: $target, flows: $flows)
  }
`;
