import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    background: BorNeoColors.primaryColor6,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chartContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  chartWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  selectsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 10,
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tab: {
    minWidth: 'auto',
  },
}));
