import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { CREATE_BN_SETTING, DELETE_BN_SETTING, UPDATE_BN_SETTING } from '../gql';

export const useBnSettingsActions = ({ connection, projectId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createBnSetting] = useMutation(CREATE_BN_SETTING, {
    update(cache, { data: { createBnSetting: createBnSettingData } }) {
      cache.modify({
        fields: {
          allBnSettings(existing) {
            return [...existing, createBnSettingData];
          },
        },
      });
    },
  });

  const [updateBnSetting] = useMutation(UPDATE_BN_SETTING, {
    update(cache, { data: { updateBnSetting: updateBnSettingData } }) {
      cache.modify({
        fields: {
          allBnSettings(existing) {
            return existing.map((s) => {
              if (s.name === updateBnSettingData.name) {
                return updateBnSettingData;
              }

              return s;
            });
          },
        },
      });
    },
  });

  const [deleteBnSetting] = useMutation(DELETE_BN_SETTING, {
    update(cache, { data: { deleteBnSetting: deleteBnSettingData } }) {
      cache.modify({
        fields: {
          allBnSettings(existing) {
            return existing.filter((s) => s.name !== deleteBnSettingData);
          },
        },
      });
    },
  });

  const create = useCallback(
    (name: string, value: string, category: string | null, onError?: (error) => void) => {
      return createBnSetting({
        variables: {
          category,
          name,
          connection,
          value,
          projectId,
        },
      })
        .then((data) => {
          return data.data.createBnSetting;
        })
        .catch((error) => {
          if (typeof onError === 'function') {
            onError(error.message);
          } else {
            enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
          }
        });
    },
    [createBnSetting, enqueueSnackbar, connection, projectId],
  );

  const update = useCallback(
    (name: string, value: string, category: string | null) => {
      return updateBnSetting({
        variables: {
          category,
          name,
          value,
          connection,
          projectId,
        },
      })
        .then((data) => {
          return data.data.updateBnSetting;
        })
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [updateBnSetting, enqueueSnackbar, connection, projectId],
  );

  const remove = useCallback(
    (name: string) => {
      return deleteBnSetting({
        variables: {
          name,
          connection,
          projectId,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [deleteBnSetting, enqueueSnackbar, connection, projectId],
  );

  return { create, update, remove };
};
