import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { LOAD_CHECKING_ITEM } from '../gql';
import { CheckingItem } from '../types';

export const useLoadCheckingItem = (connection: string, projectId: string, root: string) => {
  const { data, loading } = useQuery(LOAD_CHECKING_ITEM, {
    variables: {
      connection,
      projectId,
      root,
    },
    skip: !connection || !projectId || !root,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const item: CheckingItem = useMemo(() => data?.checkingItem?.item || null, [data?.checkingItem]);
  const sources: CheckingItem[] = useMemo(() => data?.checkingItem?.sources || [], [data?.checkingItem]);
  const targets: CheckingItem[] = useMemo(() => data?.checkingItem?.targets || [], [data?.checkingItem]);

  return {
    item,
    sources,
    targets,
    loading,
  };
};
