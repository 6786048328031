import { useQuery } from '@apollo/client';
import { LOAD_SHAREPOINT_DRIVES } from '../gql';
import { SharepointDrive } from '../types';

export const useLoadSharepointDrives = ({ connection, projectId, clientId, clientSecret, tenantId, site }) => {
  const { loading, data, error } = useQuery(LOAD_SHAREPOINT_DRIVES, {
    variables: {
      clientId,
      clientSecret,
      tenantId,
      site,
      connection,
      projectId,
    },
    skip: !clientId || !clientSecret || !tenantId || !site,
  });

  const drives: SharepointDrive[] = data?.loadSharepointDrives || [];

  return { loading, drives, error };
};
