import { gql } from '@apollo/client';

export const LOAD_DIGITAL_THREADS_WIDGET_HISTORY = gql`
  query LoadDigitalThreadsWidgetHistory(
    $connection: String!
    $projectId: String!
    $limit: Int
    $offset: Int
    $orderKey: String
    $orderType: String
  ) {
    loadDigitalThreadsWidgetHistory(
      connection: $connection
      project_id: $projectId
      limit: $limit
      offset: $offset
      order_key: $orderKey
      order_type: $orderType
    ) {
      nodes {
        label
        version
        comments
        timeLogEntry
      }
      totalCount
    }
  }
`;
