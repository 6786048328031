import { memo, useMemo } from 'react';
import { Card, CardContent, Typography, Divider, Tooltip, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { noop } from 'modules/shared/utils';
import { generateStatus, onGenStatus } from '../utils';
import POCCardActions from '../CardActions';
import { useStyles } from './styles';

const PocCardUi = ({
  id,
  title,
  datePlan = null,
  dateActual = null,
  menu = <></>,
  onMenuClick = noop,
  scale = 1,
  actionable = false,
  actionableProps = {
    dataspaceLink: '',
    flowLink: '',
    docLink: '',
    disableExecFlows: true,
    onFlowsExecute: noop,
  },
  containerStyle = {},
  onClick = null as any,
  disabledDatePlan = false,
  disabledDateActual = false,
}) => {
  const classes = useStyles();

  const additionalPickerProps = useMemo(
    () => ({
      PopoverProps: {
        onMouseDown: (e) => {
          e.stopPropagation();
        },
        PaperProps: {
          className: classes.datepickerPopover,
          style: {
            transform: `scale(${scale})`,
          },
        },
      },
    }),
    [scale, classes],
  );

  return (
    <Card className={classes.card} style={containerStyle} variant="outlined" onClick={() => onClick?.()}>
      <CardContent>
        <div className={classes.cardHeader}>
          {onGenStatus(generateStatus(datePlan, dateActual), `${classes.headerItem} ${classes.headerItemStatus}`)}
          <Tooltip title={title}>
            <Typography variant="h6" className={`${classes.headerItem} ${classes.headerItemText}`}>
              {title}
            </Typography>
          </Tooltip>
          <IconButton disableRipple className={classes.headerItemMenu} size="small" onClick={onMenuClick}>
            <MoreVertIcon className={`${classes.headerItem} ${classes.headerItemMenu}`} />
          </IconButton>
          {menu}
        </div>
        <div className={classes.spacer} />
        <Divider />
        <div className={classes.spacer} />
        <div>
          <KeyboardDatePicker
            className={classes.planPicker}
            disableToolbar
            variant="inline"
            margin="normal"
            value={datePlan}
            label="Plan Date"
            id={`${id}-plan-date-picker`}
            onChange={noop}
            disabled={disabledDatePlan}
            {...additionalPickerProps}
          />
          <KeyboardDatePicker
            className={classes.actualPicker}
            disableToolbar
            variant="inline"
            margin="normal"
            value={dateActual}
            label="Actual Date"
            id={`${id}-actual-date-picker`}
            onChange={noop}
            disabled={disabledDateActual}
            {...additionalPickerProps}
          />
        </div>
      </CardContent>
      {actionable && <POCCardActions {...actionableProps} />}
    </Card>
  );
};

export default memo(PocCardUi);
