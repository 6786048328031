import { useSubscription } from '@apollo/client';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'modules/auth';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { NOTIFY_FILE_DOWNLOAD } from './gql/subscriptions';
import DownloadCards from './download-cards-ui/download-cards';

const FilesDownload = () => {
  const { enqueueSnackbar } = useSnackbar();

  const userId = useSelector(authSelectors.getUserId);

  const { connection } = useProjectInfoFromSearch();
  const snackbarRef = useRef<SnackbarKey>('');
  useSubscription(NOTIFY_FILE_DOWNLOAD, {
    variables: {
      connection,
      userId,
    },
    skip: !connection || !userId,
    onSubscriptionData: ({ subscriptionData }) => {
      const { notifyFilesDownloadSubscription } = subscriptionData.data;
      snackbarRef.current = enqueueSnackbar(`You're report is ready`, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        style: {
          maxWidth: 450,
          width: 450,
        },
        persist: true,
        content: (key, message) => (
          <DownloadCards
            id={key}
            message={message}
            fileName={notifyFilesDownloadSubscription.name}
            path={notifyFilesDownloadSubscription.path}
          />
        ),
      });
    },
  });
  return null;
};

export default memo(FilesDownload);
