import { wrapProject } from 'modules/shared/utils/project';
import { v4 as uuid } from 'uuid';
import { INITIAL_FIELDS, INITIAL_VALUES } from '../shared/FlowModal/constants';
import { FlowDetails } from '../types';

export const getInitialValues = (flow?: FlowDetails, connection?: string) => {
  if (flow) {
    return {
      flowName: flow.flowName,
      flowDescription: flow.flowDescription,
      project: wrapProject({ connection: connection || '', projectId: flow.project.projectId }),
      category: flow.category.categoryId,
      endpoint: flow.endpoint,
      scheduler: flow.scheduler.schedulerType,
      schedulerValue: flow.scheduler.schedulerPattern ? flow.scheduler.schedulerPattern.split(' ') : null,
      schedulerLabel: flow.scheduler.schedulerLabel,
      pausable: flow.pausable,
    };
  }

  return INITIAL_VALUES;
};

export const getInitialFields = (flow: FlowDetails, type: 'bodyFields' | 'headers', bnFlowIdOverwrite = '') => {
  if (flow) {
    const parsed = flow[type] ? JSON.parse(flow[type]) : {};

    return Object.keys(parsed).reduce((acc, key) => {
      const isBNFlowId = key.toLowerCase().trim() === 'borneoflowid';
      return {
        ...acc,
        [uuid()]: {
          key,
          value: bnFlowIdOverwrite && isBNFlowId ? bnFlowIdOverwrite : parsed[key],
        },
      };
    }, {});
  }

  return INITIAL_FIELDS;
};
