import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Notification } from '../types';

const defaultState: Notification = {
  value: [],
};

const reducerMap: ReducerMap<Notification, any> = {
  [`${actions.notificationEnqueue}`]: (state, action) => {
    return { ...state, value: action.payload };
  },
};

export default handleActions(reducerMap, defaultState);
