import { FC, memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import CustomStore from 'devextreme/data/custom_store';

import { AuthRoutesPath } from 'main/routes';
import CardsHeader from 'modules/shared/components/CardsHeader';
import Table from 'modules/shared/components/Table';

import { useLoadDigitalThreadsWidgetHistory } from '../hooks/digitalthreads';

import { useStyles } from './styles';

const title = 'Digital Threads';

interface Props {
  projectId: string;
  connection: string;
}
const DigitalThreadsWidget: FC<Props> = ({ projectId, connection }) => {
  const classes = useStyles();
  const history = useHistory();
  const { load } = useLoadDigitalThreadsWidgetHistory(projectId, connection);

  const handleRowClick = useCallback(
    () => history.push({ pathname: AuthRoutesPath.DigitalThreads, search: `?project=${projectId}` }),
    [history, projectId],
  );

  const customStore = new CustomStore({
    load: ({ take, skip, sort }) => {
      const sortData = sort ? sort[0] : { desc: false, selector: '' };

      const orderKey = sortData.selector;
      const orderType = sortData.desc ? 'DESC' : 'ASC';

      return load({ limit: take, offset: skip, orderKey, orderType });
    },
  });

  return (
    <div className={classes.container}>
      <CardsHeader text={title} />
      <Table
        data={customStore}
        growToFullHeight
        wordWrapEnabled
        pagingEnabled
        remoteOperations
        onRowClick={handleRowClick}
        minHeight={50}
      >
        <Table.Column dataField="label" caption="Label" width="80" />
        <Table.Column dataField="version" caption="Version" width="80" />
        <Table.Column dataField="comments" caption="Comments" />
        <Table.Column dataField="timeLogEntry" caption="Time Log Entry" />
      </Table>
    </div>
  );
};

export default memo(DigitalThreadsWidget);
