import { ChecklistCheckedItem, ChecklistItem } from 'modules/project-flow-solution/types';
import { noop } from 'modules/shared/utils';
import { createContext } from 'react';

export const LayoutContext = createContext<{
  isBuilder: boolean;
  additionalData: {
    checklistItems: Record<string, ChecklistItem[]>;
  };
  handlers: { updateChecklistItems: (shapeId: string, items: ChecklistItem[] | undefined) => void };
  flowRunData: {
    connection: string;
    projectId: string;
    trackingId: string;
    selectedChecklistItems: Record<string, ChecklistCheckedItem[]>;
  };
}>({
  isBuilder: false,
  additionalData: {
    checklistItems: {},
  },
  handlers: {
    updateChecklistItems: noop,
  },
  flowRunData: {
    connection: '',
    projectId: '',
    trackingId: '',
    selectedChecklistItems: {},
  },
});
