import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 80vh)',
    overflow: 'initial',
    minHeight: 'min(calc(100% - 64px), 70vh)',

    maxWidth: '70%',
    minWidth: '70%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
      minWidth: '70%',
    },
  },
  dialogContent: {
    overflowY: 'scroll',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  select: {
    marginBottom: 20,
  },
  remarks: {
    lineHeight: 1.1,
    padding: 5,
    fontSize: 14,
    color: '#707070',
  },
  progress: {
    color: 'rgba(255, 255, 255, 0)',
  },
}));
