import { gql } from '@apollo/client';

export const CHANGE_USER_ACTIVENESS_SUBSCRIPTION = gql`
  subscription ChangeUserActivenessSubscription {
    changeUserActivenessSubscription {
      id
      lastActive
      activenessStatus {
        id
        status
      }
    }
  }
`;
