import { memo, useCallback, useState } from 'react';
import { CardActions as MuiCardActions, Tooltip, Button, Modal } from '@material-ui/core';
import { Description, Info, PlayArrow } from '@material-ui/icons';
import { noop } from 'modules/shared/utils';

import { useStyles } from './styles';

const CardActions = ({ dataspaceLink, flowLink, docLink, disableExecFlows, onFlowsExecute }) => {
  const classes = useStyles();

  const [dataspaceModalOpen, setDataspaceModalOpen] = useState(false);

  const toggleDataSpaceModal = useCallback(() => {
    setDataspaceModalOpen(!dataspaceModalOpen);
  }, [dataspaceModalOpen]);

  const wrapWithToptip = (wrap, child, title) => {
    if (wrap) {
      return <Tooltip title={title}>{child}</Tooltip>;
    }

    return child;
  };

  return (
    <MuiCardActions className={classes.cardActions}>
      {wrapWithToptip(
        false,
        <Button
          disabled
          classes={{ disabled: classes.cardActionDisabled }}
          className={classes.cardAction}
          onClick={noop}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/borneo-logo.png`} alt="" />
        </Button>,
        'Not Implemented',
      )}
      {wrapWithToptip(
        dataspaceLink,
        <Button
          disabled={!dataspaceLink}
          classes={{ disabled: classes.cardActionDisabled }}
          className={classes.cardAction}
          onClick={toggleDataSpaceModal}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/Data Lake View.png`} alt="" />
          <Modal className={classes.modal} open={dataspaceModalOpen} onClose={toggleDataSpaceModal}>
            <div className={classes.paper}>
              <iframe title="Data Space" src={dataspaceLink} height="600" width="800" />
            </div>
          </Modal>
        </Button>,
        'View the Associated Data Space(s)',
      )}
      {wrapWithToptip(
        flowLink,
        <Button
          disabled={!flowLink}
          classes={{ disabled: classes.cardActionDisabled }}
          className={classes.cardAction}
          target="_blank"
          href={flowLink}
        >
          <Info color="secondary" fontSize="large" />
        </Button>,
        'View this Flow',
      )}
      {wrapWithToptip(
        docLink,
        <Button
          disabled={!docLink}
          classes={{ disabled: classes.cardActionDisabled }}
          className={classes.cardAction}
          target="_blank"
          href={docLink}
        >
          <Description color="primary" fontSize="large" />
        </Button>,
        'View the Documentation for this Flow',
      )}
      {wrapWithToptip(
        !disableExecFlows,
        <Button
          disabled={disableExecFlows}
          classes={{ disabled: classes.cardActionDisabled }}
          className={classes.cardAction}
          onClick={(e) => {
            e.stopPropagation();
            onFlowsExecute();
          }}
        >
          <PlayArrow />
        </Button>,
        'Execute This Flow',
      )}
    </MuiCardActions>
  );
};

export default memo(CardActions);
