import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';

import { REGISTER_FLOW } from '../gql';
import { FlagsContext } from '../context/FlagsContext/context';

export const useRegisterFlow = () => {
  const { enqueueSnackbar } = useSnackbar();
  const flags = useContext(FlagsContext);

  const [register, { loading, error }] = useMutation(REGISTER_FLOW);

  const handledRegister = useCallback(
    (onSuccess, ...args) => {
      flags.registered = args[0].variables.flow.project;
      return register(...args)
        .then(onSuccess)
        .catch((e) => {
          flags.registered = '';
          if (e.networkError?.statusCode === 400) {
            return enqueueSnackbar('Please, fill all fields properly', BASE_ERROR_NOTIFICATION_OPTIONS);
          }

          enqueueSnackbar(
            (e as Error).message || 'Something went wrong. Please, try again later',
            BASE_ERROR_NOTIFICATION_OPTIONS,
          );
        });
    },
    [register, enqueueSnackbar, flags],
  );

  return {
    register: handledRegister,
    loading,
    error,
  };
};
