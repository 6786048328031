import { Card, CardContent, CircularProgress, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { memo } from 'react';

import { DraggableShapeProps } from '../../types';
import ColumnCard, { Types } from '../ColumnCard';
import { useStyles } from './styles';

export const MIN_CARD_HEIGHT = 63;
export const ITEM_HEIGHT = 32;
export const PADDING_TOP = 5;

const renderConnectionsShape = (id: string, type: string, cols, horizontal: boolean, classes) => {
  if (cols.length) {
    return (
      <div
        className={classNames(classes.connectionsWrapper, {
          [classes.connectionsWrapperHorizontal]: horizontal,
        })}
      >
        {cols.map((col) => {
          return <div id={`${id}-${type}-${col.name}`} key={col.name} className={classes.connectionsElement} />;
        })}
      </div>
    );
  }
};

const DigitalThreadColumns = ({ width, height, title, metadata, id }: DraggableShapeProps) => {
  const classes = useStyles();

  const columns = metadata?.columns || [];
  const loading = metadata?.loading || false;

  return (
    <Card className={classes.container} style={{ width, height }}>
      <CardContent className={classes.content}>
        <div className={classes.cardHeader}>
          <Tooltip title={title}>
            <Typography variant="h6" className={`${classes.headerItem} ${classes.headerItemText}`}>
              {title}
            </Typography>
          </Tooltip>
        </div>

        {loading && (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={30} />
          </div>
        )}

        {Boolean(columns.length) && !loading && (
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, position: 'relative' }}>
            {columns.map((col) => {
              return <ColumnCard type={Types.End} key={`${id}-${col.name}`} parentId={id} title={col.name} />;
            })}

            {renderConnectionsShape(id, 'end', columns, true, classes)}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(DigitalThreadColumns);
