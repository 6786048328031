import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type AlertDialogProps = {
  onClose: () => void;
  title?: string;
  message: string;
  buttons: { title: string; onClick: () => void }[];
  show: boolean;
};

const AlertDialog = (props: AlertDialogProps) => {
  const { onClose, title = '', message = '', show = false, buttons = [] } = props;
  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.length > 0 ? (
          buttons.map(({ title: label, onClick }) => (
            <Button key={label} onClick={onClick} color="primary">
              {label}
            </Button>
          ))
        ) : (
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
