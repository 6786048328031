export enum BoardType {
  CATEGORY = 'category',
  TEMPLATE = 'template',
}

export const DATASOURCE_COLOR = '#1c71b6';

export enum CustomCheckingItemType {
  DATA_SOURCE = 'datasource',
}

export const CustomCheckingItemTypeText = {
  [CustomCheckingItemType.DATA_SOURCE]: 'Data Source',
};

export enum CheckingItemType {
  Source = 'source',
  Target = 'target',
  Category = 'category',
  Template = 'template',
}

export type CheckingDataItem = {
  itemId: string;
  item: string;
  isCustom: boolean;
  lvl: number;
  status: StatusKeys;
  parentId: string;
  isCategory: boolean;
  type: CheckingItemType;
  customType: CustomCheckingItemType | '';
};

export enum StatusKeys {
  NO_ISSUES = 'no-issues',
  MINOR = 'minor',
  SIGNIFICANT = 'significant',
  BLOCKING = 'blocking',
  NO_DATA = 'no-data',
}

export const StatusDescription = {
  [StatusKeys.NO_ISSUES]: 'No Issues',
  [StatusKeys.MINOR]: 'Minor Data Issues',
  [StatusKeys.SIGNIFICANT]: 'Significant Data Issues',
  [StatusKeys.BLOCKING]: 'Blocking Data Issues',
  [StatusKeys.NO_DATA]: 'No Data Provided',
};

export const StatusColors = {
  [StatusKeys.NO_ISSUES]: 'green',
  [StatusKeys.MINOR]: 'yellow',
  [StatusKeys.SIGNIFICANT]: 'brown',
  [StatusKeys.BLOCKING]: 'red',
  [StatusKeys.NO_DATA]: 'gray',
};

export const TextColors = {
  [StatusKeys.NO_ISSUES]: 'white',
  [StatusKeys.MINOR]: 'black',
  [StatusKeys.SIGNIFICANT]: 'white',
  [StatusKeys.BLOCKING]: 'white',
  [StatusKeys.NO_DATA]: 'white',
};

export const statusItems = Object.values(StatusKeys).map((key) => ({
  status: key,
  description: StatusDescription[key],
  color: StatusColors[key],
}));

export const CHECKING_EDIT_HANDLE_ID = 'edit';
export const CHECKING_VIEW_HANDLE_ID = 'view';
