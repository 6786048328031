import { useCallback, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';

import {
  LOAD_BOARD_ADDITIONAL_CONFIG,
  LOAD_FLOW_SOLUTION_CHECKED_CHECKLIST,
  UPDATE_FLOW_SOLUTION_CHECKLIST_ITEMS_WITH_STATE,
} from '../gql';

export const useUpdateFlowSolutionChecklistShapeStatuses = (
  trackingId: string,
  projectId: string,
  connection: string,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const [saving, setSaving] = useState(false);

  const client = useApolloClient();

  const [update] = useMutation(UPDATE_FLOW_SOLUTION_CHECKLIST_ITEMS_WITH_STATE);

  const handleUpdate = useCallback(
    async (onSuccess, checklistItems) => {
      setSaving(true);

      try {
        await update({
          variables: {
            connection,
            projectId,
            trackingId,
            checklistItems,
          },
        });

        await client.refetchQueries({
          include: [LOAD_BOARD_ADDITIONAL_CONFIG, LOAD_FLOW_SOLUTION_CHECKED_CHECKLIST],
        });
        onSuccess();
      } catch {
        enqueueSnackbar('Unable to update. Please, try again later', BASE_ERROR_NOTIFICATION_OPTIONS);
      } finally {
        setSaving(false);
      }
    },
    [update, client, enqueueSnackbar, trackingId, connection, projectId],
  );

  return {
    update: handleUpdate,
    saving,
  };
};
