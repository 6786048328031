import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 80vh)',
    overflow: 'initial',
    minHeight: 100,

    maxWidth: '50%',
    minWidth: '50%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
      minWidth: '70%',
    },
  },
  dialogContent: {
    overflowY: 'scroll',
    paddingTop: 0,
  },
  select: {
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  marginTop: {
    marginTop: 20,
  },
}));
