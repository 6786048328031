import { AxiosError } from 'axios';
import { checkCurrentSession } from 'configure/azure-ad-b2c';
import { authThunks } from 'modules/auth';
import Storage from 'modules/tokens-storage';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS, REDIRECT_ERROR_CODE } from 'project-constants';

import * as actions from '../actions';
import { preloadData } from './preloadData';

export const initApp =
  (enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey) =>
  async (dispatch) => {
    dispatch(actions.appInitStart());
    try {
      const isLoggedIn = Storage.isLoggedIn();

      if (isLoggedIn) {
        await checkCurrentSession(dispatch);
        await dispatch(authThunks.loadProfile());
        await dispatch(preloadData());
      }

      dispatch(actions.appInitSuccess({ isInitialized: true }));
    } catch (error) {
      const [errorCode] = ((error as AxiosError)?.response?.data?.error || '').split('|');

      if (errorCode === REDIRECT_ERROR_CODE) {
        enqueueSnackbar((error as AxiosError)?.response?.data.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      }

      dispatch(actions.appInitFailure(error));
    }
  };
