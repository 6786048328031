import {
  DOMAttributes,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CircularProgress } from '@material-ui/core';
import Xarrow, { anchorType } from 'react-xarrows';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { noop } from 'modules/shared/utils';
import { useSearch } from 'modules/shared/hooks/url';
import ShapesRenderer, { SelectedConnectionType } from './ShapesRenderer';

import { Shape, Connection, Board, Path, InitialValues } from './types';

import { useStyles } from './styles';
import { useMounted } from './hooks/useMounted';
import {
  DEFAULT_CANVAS_SIZE,
  DOUBLE_CLICK,
  INITIAL_SCALE,
  PAN_CONFIG,
  PINCH_CONFIG,
  SCALE_PADDING_CONFIG,
  TRANSFORM_OPTIONS,
} from './constants';

type ShapeProps = {
  onUpdate?: (id: string, shape: Partial<Shape>) => void;
  onDelete?: (id: string) => void;
};

type LayoutRef = {
  scale: number;
  x: number;
  y: number;
  containerEl: HTMLDivElement | null;
  adaptLayout: (board: Board) => void;
};

type Props = {
  boardData: Board | undefined;
  noDataToDisplay?: boolean;
  isLoading?: boolean;
  bgColour?: string;
  loadingBgColor?: string;
  path?: Path;
  disableAdaptLayout?: boolean;
  adaptOnMount?: boolean;
  initial?: InitialValues;
  cardDragEnabled?: boolean;
  layoutRef?: MutableRefObject<LayoutRef>;
  shapeProps?: ShapeProps;
  containerProps?: DOMAttributes<HTMLDivElement>;
  editableConnections?: boolean;
  selectedConnectionDotProps?: SelectedConnectionType;
  editableConnectionProps?: {
    selectedConnections?: string[];
    passProps?: {
      computedProps?: (id: string, c: Connection) => void;
      [key: string]: any;
    };
  };
};

const DraggableLayout = ({
  boardData,
  noDataToDisplay = false,
  isLoading = false,
  bgColour = 'transparent',
  loadingBgColor = 'rgba(255, 255, 255, 0)',
  path = 'smooth',
  disableAdaptLayout = false,
  adaptOnMount = false,
  initial = {
    scale: INITIAL_SCALE,
    x: 1,
    y: 1,
  },
  cardDragEnabled = false,
  layoutRef,
  shapeProps = {},
  containerProps = {},
  editableConnections = false,
  selectedConnectionDotProps,
  editableConnectionProps = {},
}: Props) => {
  const classes = useStyles();
  const { get } = useSearch();
  const ref = useRef<HTMLDivElement>(null);

  const mounted = useMounted();

  const [scale, setScale] = useState(initial.scale);
  const [currentMinScale, setMinScale] = useState(TRANSFORM_OPTIONS.minScale);
  const [currentMaxScale, setMaxScale] = useState(TRANSFORM_OPTIONS.maxScale);

  const [shapes, setShapes] = useState<Shape[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);

  const currentPosition = useRef({ positionX: initial.x * -1, positionY: initial.y * -1 });

  const containerRef = useRef<HTMLDivElement>(null);

  const handlers = useRef({
    setPositionX: noop,
    setPositionY: noop,
    setTransform: noop,
  });

  const rerenderConnections = useCallback(() => {
    setConnections((prev) => prev.map((c) => ({ ...c, uniqKey: Math.random() })));
  }, []);

  const rerenderShapeConnections = useCallback((id: string) => {
    setConnections((prev) =>
      prev.map((c) => {
        if (c.start === id || c.end === id) {
          return { ...c, uniqKey: Math.random() };
        }

        return c;
      }),
    );
  }, []);

  const handleZoomChange = useCallback(({ scale: newScale, positionX, positionY }) => {
    setScale(newScale);
    ref.current!.style.transform = `translate(${positionX}px, ${positionY}px) scale(${newScale})`;
    currentPosition.current = {
      positionX,
      positionY,
    };
  }, []);

  const handlePanning = useCallback(
    ({ positionX, positionY }) => {
      rerenderConnections();
      ref.current!.style.transform = `translate(${positionX}px, ${positionY}px) scale(${scale})`;
      currentPosition.current = {
        positionX,
        positionY,
      };
    },
    [rerenderConnections, scale],
  );

  const updateShapePosition = useCallback(
    (idx, { x, y }) => {
      if (shapeProps.onUpdate) {
        const prevShape = shapes[idx];
        shapeProps.onUpdate(prevShape.id, {
          xCoordinate: x,
          yCoordinate: y,
        });
      } else {
        setShapes((prev) => {
          prev[idx] = {
            ...prev[idx],
            xCoordinate: x,
            yCoordinate: y,
          };

          return [...prev];
        });

        rerenderShapeConnections(shapes[idx].id);
      }
    },
    [rerenderShapeConnections, shapes, shapeProps],
  );

  useEffect(() => {
    if (boardData && !noDataToDisplay) {
      const { shapes: newShapes, connections: newConnections } = boardData;

      setShapes(newShapes);
      setConnections(newConnections);
    }
  }, [boardData, noDataToDisplay]);

  useEffect(() => {
    if (!boardData) {
      setShapes([]);
      setConnections([]);
    }
  }, [boardData]);

  useEffect(() => {
    if (noDataToDisplay) {
      setShapes([]);
      setConnections([]);
    }
  }, [noDataToDisplay]);

  const adaptLayout = useCallback(
    (data: Board) => {
      const { shapes: boardShapes, maxScale } = data;

      if (boardShapes.length) {
        const { minX, maxX, minY, maxY } = boardShapes.reduce(
          (acc, item) => {
            return {
              minX: Math.min(acc.minX, item.xCoordinate),
              maxX: Math.max(acc.maxX, item.xCoordinate + (item.width / maxScale) * INITIAL_SCALE),
              minY: Math.min(acc.minY, item.yCoordinate),
              maxY: Math.max(acc.maxY, item.yCoordinate + (item.height / maxScale) * INITIAL_SCALE),
            };
          },
          {
            minX: boardShapes[0].xCoordinate,
            maxX: boardShapes[0].xCoordinate + (boardShapes[0].width / maxScale) * INITIAL_SCALE,
            minY: boardShapes[0].yCoordinate,
            maxY: boardShapes[0].yCoordinate + (boardShapes[0].height / maxScale) * INITIAL_SCALE,
          },
        );

        const rectX = maxX - minX;
        const rectY = maxY - minY;

        const layoutBoundings = containerRef.current?.getBoundingClientRect();

        if (layoutBoundings) {
          const availableWidth = layoutBoundings.width - 100;
          const availableHeight = layoutBoundings.height - 100;

          const scaleX = availableWidth / rectX;
          const scaleY = availableHeight / rectY;

          const scaleToUse = Math.min(+scaleX.toFixed(2), +scaleY.toFixed(2));

          const realScale = Math.min(Math.max(0.5, scaleToUse), 10);

          const realRectX = rectX * realScale;
          const realRectY = rectY * realScale;

          const startX = minX * realScale;
          const startY = minY * realScale;

          const shiftX = Math.max(layoutBoundings.width - realRectX, 10) / 2;
          const shiftY = Math.max(layoutBoundings.height - realRectY, 10) / 2;

          const maxTranslateX = (DEFAULT_CANVAS_SIZE * realScale - layoutBoundings.width) * -1;
          const maxTranslateY = (DEFAULT_CANVAS_SIZE * realScale - layoutBoundings.height) * -1;

          const x = Math.max(Math.min(0, (startX - shiftX) * -1), maxTranslateX);
          const y = Math.max(Math.min(0, (startY - shiftY) * -1), maxTranslateY);

          currentPosition.current = {
            positionX: x,
            positionY: y,
          };

          setScale(realScale);
          ref.current!.style.transform = `translate(${x}px, ${y}px) scale(${realScale})`;
          handlers.current.setTransform(x, y, realScale, 0);

          rerenderConnections();
        }
      }
    },
    [rerenderConnections],
  );

  useEffect(() => {
    ref.current!.style.transform = `translate(${currentPosition.current.positionX}px,
      ${currentPosition.current.positionY}px) scale(${initial.scale})`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (boardData && mounted && !disableAdaptLayout) {
      adaptLayout(boardData);
    }
  }, [boardData, adaptLayout, mounted, disableAdaptLayout]);

  const state = useRef({
    adaptedOnce: false,
  });

  useEffect(() => {
    if (boardData && mounted) {
      if (adaptOnMount && !state.current.adaptedOnce) {
        adaptLayout(boardData);

        state.current.adaptedOnce = true;
      }
      setMaxScale(boardData.maxScale);
      setMinScale(boardData.minScale);
    }
  }, [adaptLayout, adaptOnMount, boardData, mounted]);

  const shapeScale = (1 / currentMaxScale) * scale;
  const canvasSize = boardData?.canvasSize || DEFAULT_CANVAS_SIZE;
  const connectorScale = scale / 1.5;

  useImperativeHandle(layoutRef, () => ({
    x: currentPosition.current.positionX,
    y: currentPosition.current.positionY,
    scale,
    containerEl: containerRef.current,
    adaptLayout,
  }));

  const { selectedConnections, passProps } = editableConnectionProps;

  const isDisableDrag = !cardDragEnabled || Boolean(get('modalId'));

  return (
    <div ref={containerRef} {...containerProps} className={classes.container} style={{ backgroundColor: bgColour }}>
      {isLoading && (
        <div style={{ backgroundColor: loadingBgColor }} className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {noDataToDisplay && !isLoading && <div className={classes.noDataToDisplayContainer}>No data to display</div>}
      {!noDataToDisplay && (
        <>
          <TransformWrapper
            defaultScale={initial.scale}
            defaultPositionX={currentPosition.current.positionX}
            defaultPositionY={currentPosition.current.positionY}
            options={{
              ...TRANSFORM_OPTIONS,
              maxScale: currentMaxScale,
              minScale: currentMinScale,
            }}
            scalePadding={SCALE_PADDING_CONFIG}
            pan={PAN_CONFIG}
            pinch={PINCH_CONFIG}
            doubleClick={DOUBLE_CLICK}
            onPanning={handlePanning}
            onZoomChange={handleZoomChange}
          >
            {({ setPositionX, setPositionY, setTransform }) => {
              handlers.current = {
                setPositionX,
                setPositionY,
                setTransform,
              };

              return (
                <TransformComponent>
                  <div style={{ width: canvasSize, height: canvasSize, pointerEvents: 'none', opacity: 0 }}>
                    <ShapesRenderer
                      boardId=""
                      shapes={shapes}
                      scale={scale}
                      currentMaxScale={currentMaxScale}
                      updateCardPosition={noop}
                      onInitPositionFix={noop}
                      shapeScale={shapeScale}
                      disableDrag
                      editableConnections={false}
                    />
                  </div>
                </TransformComponent>
              );
            }}
          </TransformWrapper>
          {connections.map((c) => {
            const { uniqKey, connectionId, startPosition, endPosition, color, ...rest } = c;
            const { computedProps, ...restProps } = passProps || {};
            const switchTail = rest.metadata?.switchTail || false;
            const hideArrows = rest.metadata?.hideArrows || false;

            return (
              <>
                <Xarrow
                  key={uniqKey}
                  startAnchor={startPosition as anchorType | anchorType[]}
                  endAnchor={endPosition as anchorType | anchorType[]}
                  color={selectedConnections?.includes(connectionId) ? '#4B9FFE' : color}
                  showHead={hideArrows ? false : !switchTail}
                  showTail={hideArrows ? false : switchTail}
                  passProps={{
                    ...(computedProps?.(connectionId, c) || {}),
                    ...restProps,
                    onClick: () => {
                      restProps?.onClick?.(connectionId);
                    },
                  }}
                  {...rest}
                  strokeWidth={connectorScale}
                  path={path as Path}
                />
              </>
            );
          })}
          <div style={{ position: 'absolute', inset: 0, pointerEvents: 'none' }}>
            <div
              ref={ref}
              style={{
                pointerEvents: 'none',
                transformOrigin: '0% 0%',
                display: 'flex',
                flexWrap: 'wrap',
                width: 'fit-content',
                height: 'fit-content',
              }}
            >
              <div style={{ width: canvasSize, height: canvasSize, pointerEvents: 'none' }}>
                <ShapesRenderer
                  boardId={boardData?.boardId || ''}
                  shapes={shapes}
                  scale={scale}
                  currentMaxScale={currentMaxScale}
                  updateCardPosition={updateShapePosition}
                  onInitPositionFix={updateShapePosition}
                  shapeScale={shapeScale}
                  disableDrag={isDisableDrag}
                  editableConnections={editableConnections}
                  selectedConnectionDotProps={selectedConnectionDotProps}
                  {...shapeProps}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export type { Shape, Connection, Board, LayoutRef };

export default memo(DraggableLayout);
