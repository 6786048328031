import { gql } from '@apollo/client';

export const LOAD_FLOWS_BY_PROJECT_LIST = gql`
  query LoadFlowsByProjectList($projectId: String!, $connection: String!) {
    flows(connection: $connection, project_id: $projectId, category_id: "", status_id: "", is_active: null) {
      nodes {
        flowId
        flowName
        isActive
        bodyFields
        headers
      }
    }
  }
`;
