import { CSSProperties, memo } from 'react';
import { useStyles } from './styles';

const Colors = {
  blue: {
    borderTopColor: 'rgb(146, 212, 251)',
    borderRightColor: 'rgb(97, 162, 248)',
    borderBottomColor: 'rgb(93, 156, 244)',
    borderLeftColor: 'rgb(87, 145, 222)',
  },
  green: {
    borderTopColor: 'rgb(137, 240, 88)',
    borderRightColor: 'rgb(95, 187, 56)',
    borderBottomColor: 'rgb(91, 191, 53)',
    borderLeftColor: 'rgb(84, 166, 49)',
  },
  yellow: {
    borderTopColor: 'rgb(255, 246, 95)',
    borderRightColor: 'rgb(246, 194, 68)',
    borderBottomColor: 'rgb(242, 190, 65)',
    borderLeftColor: 'rgb(220, 173, 59)',
  },
  orange: {
    borderTopColor: 'rgb(255,179,102)',
    borderRightColor: 'rgb(255,140,10)',
    borderBottomColor: 'rgb(255,140,20)',
    borderLeftColor: 'rgb(179, 89, 0)',
  },
  red: {
    borderTopColor: 'rgb(238, 121, 120)',
    borderRightColor: 'rgb(212, 78, 75)',
    borderBottomColor: 'rgb(208, 74, 71)',
    borderLeftColor: 'rgb(189, 69, 67)',
  },
  grey: {
    borderTopColor: 'rgb(179, 179, 179)',
    borderRightColor: 'rgb(130, 130, 130)',
    borderBottomColor: 'rgb(125, 125, 125)',
    borderLeftColor: 'rgb(115, 115, 115)',
  },
};

export type StatusDiamondType = 'blue' | 'green' | 'yellow' | 'orange' | 'red' | 'grey';
export type StatusDiamondProps = {
  color: StatusDiamondType;
  style?: CSSProperties;
};

const StatusDiamond = ({ color, style }: StatusDiamondProps) => {
  const classes = useStyles();

  const colors = color ? Colors[color] : {};

  return (
    <>
      {style?.content ? (
        <div
          className={`${classes.tooltip} ${classes.fade}`}
          style={{ display: 'inline-block' }}
          data-title={style?.content}
        >
          <div className={classes.container} style={{ ...style, ...colors }} />
        </div>
      ) : (
        <div className={classes.container} style={{ ...style, ...colors }} />
      )}
    </>
  );
};

export default memo(StatusDiamond);
