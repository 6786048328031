import {
  ChecklistItemResponse,
  FlowSolutionBoardThread,
  FlowSolutionBoardTracking,
} from 'modules/project-flow-solution/types';
import { Shape } from 'modules/shared/components/DraggableLayout';
import { createContext } from 'react';
import { Actions, FlowSolutionConnectionType } from '../enums';

export const StepExecuteContext = createContext<{
  statusExecute: FlowSolutionBoardTracking[];
  currentState: Actions | string;
  flowSelection: string;
  setFlowSelection?: (flow: string) => void;
  updateStatusOfChecklistCard?: (status: FlowSolutionConnectionType) => void;
  threads: FlowSolutionBoardThread[];
  viewInHistory?: boolean;
  boardId: string;
  shapes: Shape[];
  checklistItems: Record<string, ChecklistItemResponse[]>;
  playBoard: (flowId) => void;
}>({
  statusExecute: [],
  currentState: '',
  flowSelection: '',
  threads: [],
  boardId: '',
  shapes: [],
  checklistItems: {},
  playBoard: () => {},
});
