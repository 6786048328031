import { IconButton } from '@material-ui/core';
import { Menu, ChevronLeft } from '@material-ui/icons';
import { Typography } from '@mui/material';
import classnames from 'classnames';

import { useStyles } from './styles';

const Toolbar = ({ isOpen, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <IconButton onClick={onClick}>{isOpen ? <ChevronLeft /> : <Menu />}</IconButton>

      <div
        style={{
          marginLeft: 4,
          opacity: isOpen ? 1 : 0,
          transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        }}
        className={classnames(classes.imgContainer, {
          [classes.imgContainerOpen]: isOpen,
        })}
      >
        <div className={classes.columnCont}>
          <img className={classes.logoCont} src={`${process.env.PUBLIC_URL}/assets/wp_logo.png`} alt="BorNeo ui" />
          <div className={classes.logoTxtCont}>
            <Typography variant="h5">Workpacks</Typography>
            <Typography variant="h5">DELTA</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
