import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

export type DialogTitleProps = {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  className?: string;
  classTypo?: string;
};

const DialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();

  const { children, onClose, className, classTypo, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={`${classes.root} ${className || ''}`} {...other}>
      {children ? (
        <Typography className={classTypo || ''} variant="h6">
          {children}
        </Typography>
      ) : (
        <div aria-hidden />
      )}
      {onClose ? (
        <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
