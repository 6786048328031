import { gql } from '@apollo/client';

export const DELETE_PROJECT_SUBSCRIPTION = gql`
  subscription DeleteProjectSubscription($connections: String!) {
    deleteProjectSubscription(connections: $connections) {
      id
      connection
    }
  }
`;
