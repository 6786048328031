import { useQuery } from '@apollo/client';

import { useMemo } from 'react';

import { LOAD_DIGITAL_THREADS_STATUSES } from '../gql';

export const useLoadDgitalThreadsStatuses = (projectId: string, connection: string) => {
  const { data, loading } = useQuery(LOAD_DIGITAL_THREADS_STATUSES, {
    variables: {
      connection,
      projectId,
    },
    skip: !projectId,
    pollInterval: 20000,
    fetchPolicy: 'network-only',
  });

  const digitalThreadsStatuses: Record<string, Record<string, boolean>> = useMemo(
    () => JSON.parse(data?.digitalThreadsLoadedStatus || '{}'),
    [data?.digitalThreadsLoadedStatus],
  );

  const activeDts = Object.entries(digitalThreadsStatuses)
    .filter((value) => {
      return Object.values(value[1]).some((v) => v);
    })
    .map(([key]) => key);

  const activeDataTemplatesPerDt = Object.entries(digitalThreadsStatuses).reduce((acc, curr) => {
    const [dt, dataTemplates] = curr;

    return {
      ...acc,
      [dt]: Object.entries(dataTemplates)
        .filter((val) => val[1])
        .map(([key]) => key),
    };
  }, {});

  return {
    activeDts,
    loading,
    activeDataTemplatesPerDt,
  };
};
