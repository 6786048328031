import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { PAUSE_FLOW, START_FLOW, STOP_FLOW } from '../gql';
import { Flow } from '../types';

type Config = {
  connection: string;
  updateFlowData?: (flow: Flow, fieldsToUpdate: Array<keyof Flow>) => void;
};

export const useFlowActions = ({ connection, updateFlowData }: Config) => {
  const { enqueueSnackbar } = useSnackbar();
  const [startFlow] = useMutation(START_FLOW);

  const [pauseFlow] = useMutation(PAUSE_FLOW);

  const [stopFlow] = useMutation(STOP_FLOW);

  const start = useCallback(
    (flow: Flow) => {
      const deepCopy: Flow = JSON.parse(JSON.stringify(flow));

      deepCopy.status.statusId = '';
      deepCopy.status.statusName = 'Running';
      deepCopy.executedAt = new Date(Date.now()).toUTCString();

      updateFlowData?.(deepCopy, ['status', 'executedAt']);

      return startFlow({
        variables: {
          flowId: flow.flowId,
          connection,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        updateFlowData?.(flow, ['status', 'executedAt']);
      });
    },
    [connection, enqueueSnackbar, startFlow, updateFlowData],
  );

  const pause = useCallback(
    (flow: Flow) => {
      const deepCopy: Flow = JSON.parse(JSON.stringify(flow));

      deepCopy.status.statusId = '';
      deepCopy.status.statusName = 'Paused';

      updateFlowData?.(deepCopy, ['status']);

      return pauseFlow({
        variables: {
          connection,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [pauseFlow, updateFlowData, enqueueSnackbar, connection],
  );

  const stop = useCallback(
    (flow: Flow) => {
      const deepCopy: Flow = JSON.parse(JSON.stringify(flow));

      deepCopy.status.statusId = '';
      deepCopy.status.statusName = 'Ready';

      updateFlowData?.(deepCopy, ['status']);

      return stopFlow({
        variables: { flowId: flow.flowId, connection },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [stopFlow, updateFlowData, enqueueSnackbar, connection],
  );

  return { start, pause, stop };
};
