const STORAGE: Storage = window.localStorage;

const set = (key: string, value: any) => STORAGE.setItem(key, value);
const get = (key: string) => STORAGE.getItem(key) || '';
const remove = (key: string) => STORAGE.removeItem(key);
const clear = () => STORAGE.clear();

export default {
  get,
  set,
  remove,
  clear,
};
