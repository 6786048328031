import { useCallback, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';

import { EVERY_MINUTE_VALUE } from '../../constants';

import { useStyles } from './styles';

const Minutely = ({ value, onChange, label, disabled }) => {
  const classes = useStyles();

  useEffect(() => {
    if (!value) {
      onChange(EVERY_MINUTE_VALUE, '@Minutely');
    }
  }, [onChange, value]);

  const onMinuteChange = useCallback(
    ({ target }) => {
      const { value: newValue } = target;

      if (!newValue || (newValue > 0 && newValue < 60)) {
        const val = ['0', '*', '*', '*', '*', '*'];

        if (newValue === '') {
          val[1] = '*';
        } else {
          val[1] = `*/${newValue}`;
        }

        onChange(val, newValue && newValue !== '1' ? `@Every ${newValue} minutes` : '@Minutely');
      }
    },
    [onChange],
  );

  if (!value) {
    return null;
  }

  return (
    <div>
      <Typography className={classes.title}>Run: {label}</Typography>
      <TextField
        id="outlined-number"
        label="Every minute(s)"
        value={value[1].split('/')[1] ? value[1].split('/')[1] : ''}
        onChange={onMinuteChange}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
        disabled={disabled}
      />
    </div>
  );
};

export default Minutely;
