import { useCallback, useEffect, useState } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Collapse, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';

import MenuButton from '../MenuButton';
import { Components, PropsExtractors } from '../constants';
import { useStyles } from './styles';

const MenuSelect = ({ item, isOpen, selectedPath }) => {
  const { path, icon, label, items, highlightedKey, openOnClick, redirectPath } = item;
  const history = useHistory();
  const [opened, setOpened] = useState(selectedPath.startsWith(path));
  const classes = useStyles();

  const onIconClick = useCallback(
    (e) => {
      e.stopPropagation();
      setOpened(!opened);
    },
    [opened],
  );

  const additionalIcon = opened ? (
    <IconButton size="small" onClick={onIconClick}>
      <ExpandLess className={isOpen ? classes.visible : classes.hidden} />
    </IconButton>
  ) : (
    <IconButton size="small" onClick={onIconClick}>
      <ExpandMore className={isOpen ? classes.visible : classes.hidden} />
    </IconButton>
  );

  const isSelected = path === selectedPath;
  const shouldBeHighlighted = selectedPath.startsWith(highlightedKey);

  const disableClick = isSelected || redirectPath === selectedPath;

  const onClick = useCallback(() => {
    if (!disableClick) {
      if (!isSelected) {
        history.push(path);
      }

      if (openOnClick) {
        setOpened(true);
      }
    }
  }, [history, path, isSelected, openOnClick, disableClick]);

  useEffect(() => {
    if (!isOpen && !shouldBeHighlighted) {
      setOpened(false);
    }
  }, [isOpen, shouldBeHighlighted]);

  const showCollapse = Boolean(items.length);

  return (
    <>
      <MenuButton
        path={path}
        isSelected={isSelected}
        isHighlighted={shouldBeHighlighted}
        icon={icon}
        label={label}
        isOpen={isOpen}
        additionalIcon={showCollapse ? additionalIcon : null}
        onClick={onClick}
      />
      {showCollapse && (
        <Collapse in={isOpen && opened} timeout="auto" unmountOnExit>
          {items.map((itm) => {
            const Component = Components[itm.type];
            const props = PropsExtractors[itm.type](itm, {
              selectedPath,
              isSidebarOpen: isOpen,
            });

            return <Component key={itm.key} {...props} isHighlighted={shouldBeHighlighted} />;
          })}
        </Collapse>
      )}
    </>
  );
};

export default MenuSelect;
