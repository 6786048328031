import { Typography } from '@mui/material';
import { memo } from 'react';
import { useStyles } from './styles';

const StartFlowUi = ({ id, containerStyle = {}, ...divProps }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={containerStyle} {...divProps}>
      <div className={classes.wrapper}>
        <Typography variant="h6">Start</Typography>
      </div>
    </div>
  );
};

export default memo(StartFlowUi);
