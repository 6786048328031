import { useQuery } from '@apollo/client';

import { LOAD_CONNECTIONS_INFO } from './gql';
import { Connection } from './types';

export const useLoadConnectionsInfo = () => {
  const { loading, data, error } = useQuery(LOAD_CONNECTIONS_INFO);

  const connections: Connection[] = data?.connectionsInfo || [];

  const fsPath = connections.reduce((acc, c) => {
    return {
      ...acc,
      [c.name]: c.fsUrl,
    };
  }, {});

  return { loading, connections, fsPath, error };
};
