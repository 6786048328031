/* eslint-disable no-bitwise */
import {
  RadialTree as RadialTreeModule,
  BpmnDiagrams,
  SelectorConstraints,
  SnapConstraints,
  NodeConstraints,
  ConnectorConstraints,
  DiagramComponent,
  DiagramTools,
  AnnotationConstraints,
  Inject,
  DataBinding,
  DiagramConstraints,
  UserHandleModel,
  LayoutModel,
  DataSourceModel,
} from '@syncfusion/ej2-react-diagrams';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { ForwardedRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { ClickTool } from './click-tool';
import { useStyles } from './styles';

function truncateString(str, num) {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
}

type Props<T> = {
  itemId: keyof T;
  parentId: keyof T;
  items: T[];
  loading: boolean;
  getShapeColor?: (item: T) => string | null;
  getAnnotationMargin?: (item: T) => { top?: number; left?: number; right?: number; bottom?: number };
  getTextColor?: (item: T) => string;
  getTooltip?: (item: T) => string;
  getLvl?: (item: T) => number;
  onHandleClick?: (type: string, item: T) => void;
  getVisibleHandles?: (item: T) => string[];
  getShape?: (item: T) => { type: string; shape: string; ratio: number };
  getArrows?: (targetItem: T) => { target: string; source: string };
  diagramRef: ForwardedRef<RadialTreeRef>;
  itemConfigPerLvl: Record<
    number,
    {
      width: number;
      fontSize: number;
      characters: number;
    }
  >;
  userHandles?: UserHandleModel[];
};

export type RadialTreeRef = {
  centerLayout: () => void;
};

const LayoutConfig: LayoutModel = {
  type: 'RadialTree',
  verticalSpacing: 120,
  horizontalSpacing: 100,
};

function RadialTree<T>({
  itemId,
  items,
  parentId,
  loading,
  getShape = () => ({ type: 'Flow', shape: 'Process', ratio: 1 }),
  getArrows = () => ({ target: 'None', source: 'None' }),
  getAnnotationMargin = () => ({}),
  getShapeColor = () => 'grey',
  getTextColor = () => 'white',
  getTooltip = () => '',
  getLvl = () => 1,
  onHandleClick = () => null,
  getVisibleHandles = () => [],
  diagramRef,
  itemConfigPerLvl,
  userHandles = [],
}: Props<T>) {
  const classes = useStyles();
  const diagramInstance = useRef<DiagramComponent>(null);

  useEffect(() => {
    diagramInstance.current?.fitToPage();
  }, []);

  useImperativeHandle(
    diagramRef,
    () => ({
      centerLayout: () => {
        diagramInstance.current?.fitToPage();
      },
    }),
    [],
  );

  // @ts-ignore
  const settings: DataSourceModel = useMemo(
    () => ({
      id: itemId as string,
      parentId: parentId as string,
      // @ts-ignore
      dataManager: new DataManager(items as JSON[], new Query().take(7)),
    }),
    [items, itemId, parentId],
  );

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.diagramWrapper}>
        <DiagramComponent
          ref={diagramInstance}
          id="diagram"
          className={classes.diagram}
          width="100%"
          height="100%"
          snapSettings={{ constraints: SnapConstraints.None }}
          tool={DiagramTools.SingleSelect | DiagramTools.ZoomPan}
          layout={LayoutConfig}
          constraints={DiagramConstraints.Default | DiagramConstraints.Tooltip}
          selectedItems={useMemo(
            () => ({
              constraints: SelectorConstraints.UserHandle,
              userHandles,
            }),
            [userHandles],
          )}
          dataSourceSettings={settings}
          getNodeDefaults={useCallback(
            (obj) => {
              const itemLvl = getLvl(obj.data);

              const { ratio, ...shape } = getShape(obj.data);

              obj.shape = shape;

              obj.width = itemConfigPerLvl[itemLvl].width;
              obj.height = itemConfigPerLvl[itemLvl].width * ratio;

              obj.borderColor = 'black';
              obj.style = {
                strokeColor: 'black',
                strokeWidth: 2,
                fill: getShapeColor(obj.data),
              };

              obj.constraints = (NodeConstraints.Default & ~NodeConstraints.Resize) | NodeConstraints.Tooltip;

              obj.annotations = [
                {
                  content: truncateString(obj.data.item, itemConfigPerLvl[itemLvl].characters),
                  margin: getAnnotationMargin(obj.data),
                  style: {
                    color: getTextColor(obj.data),
                    fontSize: itemConfigPerLvl[itemLvl].fontSize,
                  },
                  constraints: AnnotationConstraints.ReadOnly,
                },
              ];

              const tooltip = getTooltip(obj.data);

              if (tooltip) {
                obj.tooltip = {
                  content: tooltip,
                  position: 'BottomCenter',
                };
              }

              return obj;
            },
            [getAnnotationMargin, getLvl, getShape, getShapeColor, getTextColor, getTooltip, itemConfigPerLvl],
          )}
          getConnectorDefaults={useCallback(
            (connector, diagram) => {
              connector.type = 'Straight';

              const targetNode = diagram.getObject(connector.targetID);
              const arrows = getArrows(targetNode.properties.data);

              connector.targetDecorator = { shape: arrows.target };
              connector.sourceDecorator = { shape: arrows.source };

              connector.constraints = ConnectorConstraints.None;

              return connector;
            },
            [getArrows],
          )}
          getCustomTool={useCallback(
            (action) => {
              const handle = userHandles?.find((h) => h.name === action);

              if (handle) {
                return new ClickTool(diagramInstance.current!.commandHandler, onHandleClick, handle.name!);
              }

              return undefined;
            },
            [userHandles, onHandleClick],
          )}
          selectionChange={useCallback(
            (arg) => {
              if (arg.state === 'Changing') {
                if (arg.newValue[0]?.propName === 'nodes') {
                  const handles = getVisibleHandles(arg.newValue[0]?.properties.data);

                  if (!handles.length) {
                    arg.cancel = true;
                    return;
                  }

                  for (const handle of diagramInstance.current!.selectedItems?.userHandles || []) {
                    handle.visible = handles.includes(handle.name!);
                  }
                } else {
                  for (const handle of diagramInstance.current!.selectedItems?.userHandles || []) {
                    handle.visible = false;
                  }
                }
              }
            },
            [getVisibleHandles],
          )}
        >
          <Inject services={[DataBinding, RadialTreeModule, BpmnDiagrams]} />
        </DiagramComponent>
      </div>
    </div>
  );
}

export default RadialTree;
