import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    lineHeight: 1.1,
  },
}));
