import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    position: 'absolute',
    height: 'fit-content',
    width: 'fit-content',
    top: 0,
    left: 0,
  },
}));
