import {
  useLoadAllDataTemplates,
  useLoadCheckingItem,
  useLoadCustomCheckingItems,
} from 'modules/digital-threads/hooks';
import ActionsUi from 'modules/shared/components/actions-ui';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';

import Swiper from 'modules/shared/components/Swiper';
import { v4 as uuid } from 'uuid';
import { StatusKeys } from 'modules/digital-threads/constants';
import { useStyles } from './styles';
import { CheckingItem } from '../../types';
import Row from './Row';
import RelationsSetup from './RelationsSetup';
import { useCheckingActions } from '../../hooks';

const MODAL_ID = 'create-card-modal';

const RelationsModal = ({ isOpen, onClose, meta, connection, projectId }) => {
  const classes = useStyles();

  const { allDataTemplates, loading: templatesLoading } = useLoadAllDataTemplates(connection);
  const { customItems, loading: customItemsLoading, addCustomItem } = useLoadCustomCheckingItems(connection, projectId);

  const [currentItem, setCurrentItem] = useState<CheckingItem>({
    itemId: meta.itemId,
    isCustom: meta.isCustom,
    item: meta.item,
    status: meta.status,
    customType: meta.customType,
  });
  const [currentSources, setCurrentSources] = useState<CheckingItem[]>([]);
  const [currentTargets, setCurrentTargets] = useState<CheckingItem[]>([]);

  const { item, sources, targets, loading } = useLoadCheckingItem(connection, projectId, meta.item);

  const setInitial = useCallback(() => {
    setCurrentItem({
      itemId: meta.itemId,
      isCustom: meta.isCustom,
      item: meta.item,
      status: meta.status,
      customType: meta.customType,
    });
    setCurrentSources([]);
    setCurrentTargets([]);
  }, [meta]);

  useEffect(() => {
    if (isOpen) {
      setInitial();
    }
  }, [isOpen, setInitial]);

  useEffect(() => {
    if (!loading && isOpen) {
      if (item) {
        setCurrentItem((prev) => ({ ...prev, ...item }));
        setCurrentSources(sources);
        setCurrentTargets(targets);
      } else {
        setInitial();
      }
    }
  }, [item, loading, setInitial, sources, targets, isOpen]);

  const availableItems = useMemo(() => {
    return allDataTemplates.map((t) => ({ value: t.templateId, label: t.templateId }));
  }, [allDataTemplates]);

  const { setup, setuping } = useCheckingActions({ connection, projectId, isCategory: meta.isCategory });

  const showSpinner = loading || templatesLoading || customItemsLoading || setuping;

  const disabled = setuping;

  const handleSubmit = () => {
    setup(onClose, currentItem, {
      sources: currentSources,
      targets: currentTargets,
    });
  };

  const customItemsAsOptions = useMemo(
    () => customItems.map((el) => ({ value: el.item, label: `${el.item} (Custom)` })),
    [customItems],
  );

  const createCustomItem = (fn: (edit: SetStateAction<CheckingItem[]>) => void) => (value: string) => {
    const customItem: CheckingItem = {
      itemId: uuid(),
      isCustom: true,
      item: value,
      status: StatusKeys.NO_DATA,
      customType: '',
    };

    addCustomItem(customItem);
    fn((prev) => prev.concat(customItem));
  };

  const editItems = (fn: (edit: SetStateAction<CheckingItem[]>) => void) => (selectedItems: string[]) => {
    fn((current) => {
      return selectedItems.map((name) => {
        const currentSource = current.find((s) => s.item === name);

        const loaded = [...sources, ...targets, ...customItems].find((s) => s.item === name);

        return (
          currentSource ||
          loaded || {
            itemId: '',
            item: name,
            status: StatusKeys.NO_DATA,
            isCustom: false,
            customType: '',
          }
        );
      });
    });
  };

  const changeItems =
    (fn: (edit: SetStateAction<CheckingItem[]>) => void) =>
    (itemToUpdate: CheckingItem, itemUpdates: Partial<CheckingItem>) => {
      fn((current) =>
        current.map((s) => {
          if (s.item === itemToUpdate.item) {
            return {
              ...s,
              ...itemUpdates,
            };
          }
          return s;
        }),
      );
    };

  const getAvailableItem = (filterItems: CheckingItem[]) =>
    availableItems
      .filter((el) => {
        if (filterItems.some((t) => t.item === el.value)) {
          return false;
        }
        if (el.value === currentItem.item) {
          return false;
        }

        return true;
      })
      .concat(customItemsAsOptions);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={onClose}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={onClose}>
        Edit {meta.item}
      </Title>
      <Content className={classes.dialogContent}>
        {showSpinner && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        <div style={{ marginBottom: 16 }}>
          {!meta.isCategory && (
            <Row
              id={currentItem.itemId}
              name={currentItem.item}
              isCustom={currentItem.isCustom}
              customType={currentItem.customType}
              connection={connection}
              projectId={projectId}
              status={currentItem.status}
              disabled={disabled}
              onItemChange={(updates) => {
                setCurrentItem((current) => ({
                  ...current,
                  ...updates,
                }));
              }}
            />
          )}
        </div>

        <Swiper
          items={[
            {
              title: 'Sources',
              component: (
                <RelationsSetup
                  id="sources"
                  connection={connection}
                  projectId={projectId}
                  items={currentSources}
                  disabled={disabled}
                  onChange={changeItems(setCurrentSources)}
                  availableItems={getAvailableItem(currentTargets)}
                  onCreateItem={createCustomItem(setCurrentSources)}
                  onEditItems={editItems(setCurrentSources)}
                />
              ),
            },
            {
              title: 'Targets',
              component: (
                <RelationsSetup
                  id="targets"
                  connection={connection}
                  projectId={projectId}
                  disabled={disabled}
                  items={currentTargets}
                  onChange={changeItems(setCurrentTargets)}
                  availableItems={getAvailableItem(currentSources)}
                  onCreateItem={createCustomItem(setCurrentTargets)}
                  onEditItems={editItems(setCurrentTargets)}
                />
              ),
            },
          ]}
        />
      </Content>

      <Actions>
        <ActionsUi onCancel={onClose} submitText="Submit" onSubmit={handleSubmit} disabled={disabled} />
      </Actions>
    </Dialog>
  );
};

export default memo(RelationsModal);
