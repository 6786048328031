import { Grid, Typography } from '@mui/material';
import Select from 'modules/shared/components/Select2';
import { memo, useEffect } from 'react';
import { useLoadCheckingItemWithoutRelations } from 'modules/digital-threads/hooks';
import { v4 as uuid } from 'uuid';
import { CustomCheckingItemTypeText, StatusDescription, StatusKeys } from 'modules/digital-threads/constants';
import { useStyles } from './styles';
import LegendBox from '../../LegendBox';

const options = Object.entries(StatusDescription).map(([value, label]) => ({ value, label }));

const customTypesOptions = Object.entries(CustomCheckingItemTypeText).map(([value, label]) => ({ value, label }));

const Row = ({ id, name, status, isCustom, customType, onItemChange, connection, projectId, disabled }) => {
  const classes = useStyles();

  const { load } = useLoadCheckingItemWithoutRelations(connection, projectId);

  useEffect(() => {
    const fn = async () => {
      if (!id) {
        const newItem = await load(name);

        onItemChange(
          newItem || {
            itemId: uuid(),
            item: name,
            status: StatusKeys.NO_DATA,
            isCustom: false,
          },
        );
      }
    };

    fn();
  }, [onItemChange, name, load, id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} className={classes.title}>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      <Grid item xs={8} className={classes.statusContainer}>
        <div className={classes.statusSelect}>
          {isCustom && (
            <Select
              id={`dt-type-selector-${name}`}
              value={[customType || '']}
              options={customTypesOptions}
              onChange={(val) => {
                onItemChange({ customType: val[0] });
              }}
              disabled={!id || disabled}
              loading={!id}
              placeholder="Default item"
            />
          )}
          <Select
            id="dt-data-set-selector"
            value={[status || '']}
            options={options}
            onChange={(val) => {
              onItemChange({ status: val[0] });
            }}
            disableClear
            disabled={!id || disabled || customType}
            loading={!id}
          />
        </div>
        <LegendBox status={status} hideTooltip />
      </Grid>
    </Grid>
  );
};

export default memo(Row);
