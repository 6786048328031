import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    borderColor: '#8f8f8f',
    borderRadius: 2,
    boxShadow: '4px 7px 12px 0px #3b3a3a',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: 20,
    transition: 'box-shadow .3s',
    '&.highlight': {
      cursor: 'pointer',
    },
    '&:hover&.highlight': {
      boxShadow: '4px 4px 20px 5px #3b3a3a',
    },
  },
  dtItemIcon: {
    color: '#fff',
    position: 'absolute',
    right: '-20px',
    top: '-20px',
    background: '#2aa1c9',
    padding: '5px',
    borderRadius: '25px',
    cursor: 'pointer',
    border: '3px solid #fff',
  },
  text: {
    fontSize: 30,
    lineHeight: 1.3,
    textAlign: 'center',
  },
  connectionsWrapper: {
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  connectionsWrapperHorizontal: {
    flexDirection: 'column',
  },
  connectionsElement: {
    flex: '1 0',
  },
  active: {
    display: 'inline-flex',
    background: '#11539e',
    width: 14,
    height: 14,
    borderRadius: '50%',
    transform: 'translateY(-50%)',
    marginRight: '5px',
    marginTop: 'auto',
  },
  notActive: {
    display: 'inline-flex',
    background: '#8f8f8f',
    width: 14,
    height: 14,
    borderRadius: '50%',
    transform: 'translateY(-50%)',
    marginRight: '5px',
    marginTop: 'auto',
  },
}));
