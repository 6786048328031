import { memo } from 'react';
import { useStyles } from './styles';

const CardsHeader = ({ text }) => {
  const classes = useStyles();

  return (
    <div title={text} className={classes.header}>
      {text}
    </div>
  );
};

export default memo(CardsHeader);
