import { makeStyles } from '@material-ui/core';
import { primaryColor5 } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  center: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: primaryColor5,
  },
}));
