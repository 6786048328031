import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

export const useHash = () => {
  const history = useHistory();
  const location = useLocation();

  const get = useCallback(() => {
    return location.hash.replace('#', '');
  }, [location]);

  const set = useCallback(
    (hash: string) => {
      history.replace({ ...location, hash });
    },
    [location, history],
  );

  const clear = useCallback(() => {
    history.replace({ ...location, hash: '' });
  }, [history, location]);

  return { get, set, clear };
};
