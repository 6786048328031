import { ToolBase, CommandHandler } from '@syncfusion/ej2-react-diagrams';

export class ClickTool extends ToolBase {
  onClick: (type: string, item: any) => void;

  type: string;

  constructor(commandHandler: CommandHandler, onClick: (type: string, item: any) => void, type: string) {
    super(commandHandler);

    this.onClick = onClick;
    this.type = type;
  }

  mouseDown(args) {
    super.mouseDown(args);
    this.inAction = true;
  }

  mouseUp() {
    const selected = this.commandHandler.getSelectedObject();
    if (this.inAction && selected[0]) {
      // @ts-ignore
      this.onClick(this.type, selected[0].properties.data);
    }
  }
}
