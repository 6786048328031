export enum Actions {
  PLAY = 'play',
  PAUSE = 'pause',
  STOP = 'stop',
  FINISH = 'finish',
  REFRESH = 'refresh',
  LOG = 'log',
}

export enum StatusExecute {
  SUCCESS = 'success',
  ERROR = 'error',
  RUNNING = 'running',
}

export enum FlowSolutionConnectionType {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum StatusExecuteColor {
  SUCCESS = 'rgb(76 189 107)',
  ERROR = 'rgb(227 69 84)',
  RUNNING = 'rgb(202 163 85)',
}
