import { makeStyles } from '@material-ui/core';
import { dangerColor } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 640px)',
  },
  formControl: {
    margin: '0 0 17px',
  },
  globalLoading: {
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    top: 0,
    height: '100%',
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    position: 'relative',
  },
  error: {
    color: dangerColor,
  },
  refreshButtonIcon: {
    width: 20,
    height: 20,
  },
}));
