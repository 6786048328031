import { gql } from '@apollo/client';

export const LOAD_STAKEHOLDERS = gql`
  query StakeholdersWithRelations {
    stakeholdersWithRelations {
      stakeholderId
      orgName
      plantSites {
        siteId
        label
        plantUnits {
          unitId
          name
        }
      }
    }
  }
`;

export const LOAD_CLIENDS_AND_INSTANCES = gql`
  query ClientsAndInstances {
    clientsAndInstances
  }
`;
