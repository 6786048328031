import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import cache from '../cache';

let client: ApolloClient<NormalizedCacheObject>;

const configure = (link: ApolloLink) => {
  client = new ApolloClient({
    cache,
    link,
  });
};

export { configure, client as default };
