import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { LOAD_COLUMNS } from '../gql';

export const useLoadFileColumns = ({ connection, projectId }) => {
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);

  const load = async (category: string, dataTemplate: string, file: string) => {
    setLoading(true);
    try {
      const response = await client.query({
        query: LOAD_COLUMNS,
        variables: {
          projectId,
          connection,
          category,
          dataTemplate,
          file,
        },
        fetchPolicy: 'network-only',
      });

      const columns = response.data?.hdfsFileColumns.columns || [];

      return columns;
    } catch (e) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  return {
    load,
    loading,
  };
};
