import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  discipline: {
    opacity: 1,
    transition: 'opacity 300ms ease-in-out',
    '&.semi-opacity': {
      opacity: 0.5,
    },
  },
}));
