import { Collapse } from '@material-ui/core';
import { useStyles } from './styles';

const MenuTitle = ({ title, visible }) => {
  const classes = useStyles();

  return (
    <Collapse in={visible} timeout="auto" unmountOnExit>
      <p className={classes.container}>{title}</p>
    </Collapse>
  );
};

export default MenuTitle;
