import { AxiosPromise } from 'axios';
import instance from 'modules/rest/axios';

export const refreshTokens = (
  refreshToken: string,
): AxiosPromise<{
  accessToken: string;
  refreshToken: string;
}> => {
  return instance({
    method: 'post',
    url: '/auth/refresh',
    headers: {
      'x-refresh-token': refreshToken,
    },
  });
};
