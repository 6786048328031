import { connect, ConnectedProps } from 'react-redux';
import { authSelectors } from 'modules/auth';
import { appSelectors, appThunks } from 'modules/app';

const mapState = (state) => ({
  isLoggedIn: authSelectors.getIsLoggedIn(state),
  isInitialized: appSelectors.getIsInitialized(state),
  isLoading: appSelectors.getIsLoading(state),
});

const mapDispatch = (dispatch) => {
  return {
    onAppInit: (enqueueSnackbar) => dispatch(appThunks.initApp(enqueueSnackbar)),
  };
};

const connector = connect(mapState, mapDispatch);

export type ConnectProps = ConnectedProps<typeof connector>;

export default connector;
