import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { State } from '../types';

const defaultState = {
  error: '',
};

const reducerMap: ReducerMap<State, any> = {
  [`${combineActions(actions.authLoginStart, actions.authLoginSuccess, actions.clearLogin)}`]: () => ({
    error: '',
  }),
  [`${actions.authLoginFailure}`]: (state, { payload }) => {
    return {
      error: payload,
    };
  },
};

export default handleActions(reducerMap, defaultState);
