import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 605px)',
  },
  select: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
  },
}));
