import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardActions: {
    height: 45,
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
  },
  cardAction: {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    borderRadius: '4px',
    borderColor: 'darkGrey',
    border: '2px solid',
    height: '95%',
    padding: '2px',
    marginRight: '5px',
  },
  cardActionDisabled: {
    opacity: 0.5,
  },
  datepickerPopover: {
    transition: 'none !important',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
