import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { LINK_WP_PROJECT, LOAD_LINKED_WP_PROJECT, LOAD_UNLINKED_WP_PROJECTS, UNLINK_PROJECT } from './gql';

export const useLinkWpProject = ({ connection, projectId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data } = useQuery(LOAD_LINKED_WP_PROJECT, {
    variables: {
      connection,
      projectId,
    },
    skip: !connection || !projectId,
    fetchPolicy: 'network-only',
  });

  const wpProjectName: string = data?.linkedWpProject?.project?.projectName || '';

  const [linkWpProject, { loading: linking }] = useMutation(LINK_WP_PROJECT, {
    refetchQueries: [LOAD_LINKED_WP_PROJECT, LOAD_UNLINKED_WP_PROJECTS],
  });

  const link = useCallback(
    (onSuccess, wpProjectId: string) => {
      return linkWpProject({
        variables: {
          connection,
          projectId,
          wpProjectId,
        },
      })
        .then(onSuccess)
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [linkWpProject, enqueueSnackbar, connection, projectId],
  );

  const [unlinkWpProjects, { loading: unlinking }] = useMutation(UNLINK_PROJECT, {
    refetchQueries: [LOAD_LINKED_WP_PROJECT, LOAD_UNLINKED_WP_PROJECTS],
  });

  const unlink = useCallback(
    (onSuccess) => {
      return unlinkWpProjects({
        variables: {
          connection,
          projectId,
        },
      })
        .then(onSuccess)
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [unlinkWpProjects, enqueueSnackbar, connection, projectId],
  );

  return { loading, wpProjectName, link, linking, unlink, unlinking };
};
