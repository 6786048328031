import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { appSelectors } from 'modules/app';

import { USER_DOWNLOADS } from './gql';
import { useDownloadSnackbar } from './useDownloadSnackbar';

export const useDownloadUserArchives = () => {
  const isInitialized = useSelector(appSelectors.getIsInitialized);
  const { renderSnackbar } = useDownloadSnackbar();

  const { loading: loadingDownloads, data: dataDownloads } = useQuery(USER_DOWNLOADS);

  useEffect(() => {
    if (!loadingDownloads && dataDownloads?.userDownloads?.length > 0 && isInitialized) {
      dataDownloads.userDownloads.forEach((info) => renderSnackbar(info));
    }
  }, [dataDownloads?.userDownloads, loadingDownloads, renderSnackbar, isInitialized]);
};
