import { FetchResult } from '@apollo/client';
import { useBnSettingsActions, useLoadBnSettings } from 'modules/config-tables/config-tables-ui/hooks';
import { useLoadBnSettingsCategories } from 'modules/config-tables/config-tables-ui/hooks/useLoadBnSettingsCategories';
import { BnSetting, BnSettingCategory } from 'modules/config-tables/config-tables-ui/types';
import { useLoadDataStoresConnections } from 'modules/data-stores-custom/hooks/useLoadDataStoresConnections';
import { DataStoreConnection } from 'modules/data-stores-custom/types';
import { useToggle } from 'modules/shared/hooks/base';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { noop } from 'modules/shared/utils';
import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

export const MAIN_CONNECTION = 'main';

export enum SubTabs {
  FileManager = 'FileManager',
  Uploads = 'Uploads',
}

export const DataStoresState = createContext<{
  dataStoreConnection: string;
  dataStoreConnections: DataStoreConnection[];
  dataStoreConnectionsLoading: boolean;
  projectId: string;
  connection: string;
  createModalOpen: boolean;
  connectionsInfoModalOpen: boolean;
  updateModalOpen: boolean;
  updateConnectionId: string;
  activeSubTab: SubTabs;
}>({
  dataStoreConnection: '',
  projectId: '',
  connection: '',
  dataStoreConnections: [],
  dataStoreConnectionsLoading: false,
  createModalOpen: false,
  updateModalOpen: false,
  connectionsInfoModalOpen: false,
  updateConnectionId: '',
  activeSubTab: SubTabs.FileManager,
});

export const DataStoresStateHandlers = createContext<{
  setConnection: (connection: string) => void;
  openCreateModal: () => void;
  closeCreateModal: () => void;
  openConnectionsInfoModal: () => void;
  closeConnectionsInfoModal: () => void;
  openUpdateModal: () => void;
  closeUpdateModal: () => void;
  setUpdateConnection: Dispatch<SetStateAction<string>>;
  setActiveSubTab: Dispatch<SetStateAction<SubTabs>>;
}>({
  setConnection: noop,
  openCreateModal: noop,
  closeCreateModal: noop,
  openConnectionsInfoModal: noop,
  closeConnectionsInfoModal: noop,
  openUpdateModal: noop,
  closeUpdateModal: noop,
  setUpdateConnection: noop,
  setActiveSubTab: noop,
});

export const BnSettingsState = createContext<{
  settings: BnSetting[];
  categories: BnSettingCategory[];
  settingsLoading: boolean;
}>({
  settings: [],
  categories: [],
  settingsLoading: false,
});

export const BnSettingsActions = createContext<{
  create: (
    name: string,
    value: string,
    category: string | null,
    onError?: (error: string) => void,
  ) => Promise<void | FetchResult>;
}>({
  create: noop,
});

const DataStoresStateProvider = ({ children }) => {
  const [dataStoreConnection, setConnection] = useState(MAIN_CONNECTION);
  const [activeSubTab, setActiveSubTab] = useState<SubTabs>(SubTabs.FileManager);

  const { projectId, connection } = useProjectInfoFromSearch();

  const [createModalOpen, { deactivate: closeCreateModal, activate: openCreateModal }] = useToggle();
  const [updateModalOpen, { deactivate: closeUpdateModal, activate: openUpdateModal }] = useToggle();
  const [updateConnectionId, setUpdateConnection] = useState('');
  const [connectionsInfoModalOpen, { deactivate: closeConnectionsInfoModal, activate: openConnectionsInfoModal }] =
    useToggle();

  useEffect(() => {
    setConnection(MAIN_CONNECTION);
  }, [projectId]);

  useEffect(() => {
    setActiveSubTab(SubTabs.FileManager);
  }, [dataStoreConnection]);

  const { loading: settingsLoading, settings } = useLoadBnSettings({ connection, projectId, skip: false });
  const { loading: settingsCategoriesLoading, categories: settingsCategories } = useLoadBnSettingsCategories({
    connection,
    skip: false,
  });
  const { create } = useBnSettingsActions({ connection, projectId });

  const { connections, loading: dataStoreConnectionsLoading } = useLoadDataStoresConnections({ connection, projectId });

  return (
    <DataStoresStateHandlers.Provider
      value={useMemo(
        () => ({
          setConnection,
          closeCreateModal,
          openCreateModal,
          openConnectionsInfoModal,
          closeConnectionsInfoModal,
          openUpdateModal,
          closeUpdateModal,
          setUpdateConnection,
          setActiveSubTab,
        }),
        [
          closeConnectionsInfoModal,
          closeCreateModal,
          closeUpdateModal,
          openConnectionsInfoModal,
          openCreateModal,
          openUpdateModal,
        ],
      )}
    >
      <BnSettingsActions.Provider value={useMemo(() => ({ create }), [create])}>
        <DataStoresState.Provider
          value={useMemo(
            () => ({
              createModalOpen,
              dataStoreConnection,
              projectId,
              connection,
              dataStoreConnections: connections,
              dataStoreConnectionsLoading,
              connectionsInfoModalOpen,
              updateModalOpen,
              updateConnectionId,
              activeSubTab,
            }),
            [
              activeSubTab,
              connection,
              connections,
              connectionsInfoModalOpen,
              createModalOpen,
              dataStoreConnection,
              dataStoreConnectionsLoading,
              projectId,
              updateConnectionId,
              updateModalOpen,
            ],
          )}
        >
          <BnSettingsState.Provider
            value={useMemo(
              () => ({
                settingsLoading: settingsLoading || settingsCategoriesLoading,
                settings,
                categories: settingsCategories,
              }),
              [settings, settingsCategories, settingsCategoriesLoading, settingsLoading],
            )}
          >
            {children}
          </BnSettingsState.Provider>
        </DataStoresState.Provider>
      </BnSettingsActions.Provider>
    </DataStoresStateHandlers.Provider>
  );
};

export default DataStoresStateProvider;
