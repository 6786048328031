import { memo } from 'react';
import { useStyles } from './styles';

const RichTextRenderer = ({ data }) => {
  const classes = useStyles();

  // eslint-disable-next-line react/no-danger
  return <div className={classes.container} dangerouslySetInnerHTML={{ __html: data }} />;
};

export default memo(RichTextRenderer);
