import { memo, useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { BnSettingsState } from '../context/DataStoresState';

import SelectOrCreateBnSettingInput from '../SelectOrCreateBnSettingInput';

const HOST = 'host';
const USERNAME = 'username';
const PASSWORD = 'password';

const SftpFieldsUi = ({ values, onValueChange }) => {
  const { settings, settingsLoading } = useContext(BnSettingsState);

  const items = useMemo(
    () =>
      settings.map((s) => ({
        value: s.name,
        label: s.name,
      })),
    [settings],
  );

  const host = values[HOST];
  const username = values[USERNAME];
  const password = values[PASSWORD];

  return (
    <>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={HOST}
          label="Host"
          value={host}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(HOST, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={USERNAME}
          label="Username"
          value={username}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(USERNAME, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={PASSWORD}
          label="Password"
          value={password}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(PASSWORD, value);
          }}
        />
      </Grid>
    </>
  );
};

export default memo(SftpFieldsUi);
