import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { LOAD_BN_SETTINGS } from '../gql';
import { BnSetting } from '../types';

export const useLoadBnSettings = ({ connection, projectId, skip }) => {
  const client = useApolloClient();

  const { loading, data } = useQuery(LOAD_BN_SETTINGS, {
    variables: {
      connection,
      projectId,
    },
    skip: skip || !connection,
  });

  const settings: BnSetting[] = data?.allBnSettings || [];

  const load = useCallback(async () => {
    if (!connection) {
      return {
        data: [],
        totalCount: 0,
      };
    }

    const response = await client.query({
      query: LOAD_BN_SETTINGS,
      fetchPolicy: 'network-only',
      context: {
        requestId: 'load-settings-request',
        queryDeduplication: false,
      },
      variables: {
        connection,
        projectId,
      },
    });

    const items = response.data?.allBnSettings || [];

    return { data: JSON.parse(JSON.stringify(items)), totalCount: items.length };
  }, [client, connection, projectId]);

  return { loading, settings, load };
};
