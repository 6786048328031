import { memo, useState, useCallback, useMemo } from 'react';

import { useSearch } from 'modules/shared/hooks/url';
import DraggableLayout from 'modules/shared/components/DraggableLayout';

import { DTTabId } from 'pages/DigitalThreads/enums';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import SpiderViewModalUi from '../spider-view-modal-ui';

import { useLoadDgitalThreadsStatuses, useLoadDigitalthreads, useLoadSpiderView } from '../hooks';

const SpiderView = () => {
  const [dtId, setDtId] = useState('');
  const { set } = useSearch();
  const { projectId, connection } = useProjectInfoFromSearch();

  const handleBoxClick = useCallback(
    (type) => {
      set({
        page: DTTabId.DATA,
        type,
      });
    },
    [set],
  );

  const handleOpenModal = useCallback((id) => {
    setDtId(id);
  }, []);

  const handleCloseModal = useCallback(() => {
    setDtId('');
  }, []);

  const { loading: dtsLoading, digitalThreads } = useLoadDigitalthreads(connection);
  const dtsPerType = useMemo(() => {
    return digitalThreads.reduce((acc, dt) => {
      const typeValues = Array.isArray(acc[dt.type]) ? [...acc[dt.type], dt.dtId] : [dt.dtId];

      return {
        ...acc,
        [dt.type]: typeValues,
      };
    }, {});
  }, [digitalThreads]);

  const { activeDts, loading: activeDtsLoading } = useLoadDgitalThreadsStatuses(projectId, connection);

  const { board, loading } = useLoadSpiderView(connection, {
    'dt-spider-item': {
      onClick: handleOpenModal,
      statuses: activeDts,
      dtsPerType,
      onBoxClick: handleBoxClick,
    },
  });
  return (
    <>
      <DraggableLayout
        boardData={board}
        isLoading={loading || dtsLoading || activeDtsLoading}
        noDataToDisplay={false}
        disableAdaptLayout
        adaptOnMount
      />
      <SpiderViewModalUi
        key={dtId}
        open={Boolean(dtId)}
        onClose={handleCloseModal}
        modalId={dtId}
        connection={connection}
        projectId={projectId}
      />
    </>
  );
};

export default memo(SpiderView);
