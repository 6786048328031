import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import Input from 'modules/shared/components/Input';
import DatePicker from 'modules/shared/components/DatePicker';
import Table from 'modules/shared/components/Table';

import { LayoutContext } from 'modules/shared/components/DraggableLayout/context/LayoutContext';
import { format } from 'date-fns';
import { noop } from 'modules/shared/utils';
import { useStyles } from './styles';

const MODAL_ID = 'edit-checklist-card-modal-title';

const EditCardModal = ({
  open,
  onClose,
  title,
  shapeId,
  boardId,

  datePlan,
  dateActual,

  cardSaving,
  onUpdateCard,
}) => {
  const classes = useStyles();

  const { additionalData } = useContext(LayoutContext);

  const formId = `${title}-id`;

  const checklistItems = useMemo(
    () =>
      additionalData.checklistItems[shapeId]?.map((item) => ({
        ...item,
        setUp: item.setUp ? new Date(item.setUp) : undefined,
        signOff: item.signOff ? new Date(item.signOff) : undefined,
      })) || [],
    [additionalData, shapeId],
  );

  const [values, setValues] = useState({
    title,
    datePlan,
    dateActual,
    checklistItems,
  });

  useEffect(() => {
    if (!open) {
      setValues({
        title,
        datePlan,
        dateActual,
        checklistItems,
      });
    }
  }, [open, title, datePlan, dateActual, checklistItems]);

  const handleChange = useCallback(
    ({ target: { id, value } }) => {
      setValues({
        ...values,
        [id]: value,
      });
    },
    [values],
  );

  const handlePickerChange = useCallback(
    (value, id) => {
      setValues({
        ...values,
        [id]: value,
      });
    },
    [values],
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    onUpdateCard(
      onClose,
      {
        shapeId,
        title: values.title,
        metadata: {
          datePlan: values.datePlan,
          dateActual: values.dateActual,
        },
      },
      {
        checklistItems: values.checklistItems.map((item) => ({
          ...item,
          setUp: item.setUp ? format(item.setUp, 'MM/dd/yyyy') : '',
          signOff: item.signOff ? format(item.signOff, 'MM/dd/yyyy') : '',
        })),
      },
    );
  };

  const close = cardSaving ? noop : onClose;
  const contentStyles = cardSaving ? { overflow: 'hidden' } : {};

  const onAddNewRow = useCallback(
    (newRow) => {
      const {
        data: { __KEY__: key, name, setUp, signOff },
      } = newRow;

      setValues((prev) => ({
        ...prev,
        checklistItems: prev.checklistItems.concat({
          dataId: key,
          name,
          setUp,
          signOff,
          fkShapeId: shapeId,
          fkBoardId: boardId,
        }),
      }));
    },
    [shapeId, boardId],
  );

  const onUpdateRow = useCallback(
    (newRow) => {
      const {
        data: { dataId, name, setUp, signOff },
      } = newRow;

      setValues((prev) => ({
        ...prev,
        checklistItems: prev.checklistItems.map((item) => {
          if (item.dataId === dataId) {
            return {
              dataId,
              name,
              setUp,
              signOff,
              fkShapeId: shapeId,
              fkBoardId: boardId,
            };
          }

          return item;
        }),
      }));
    },
    [shapeId, boardId],
  );

  const onDeleteRow = useCallback((newRow) => {
    const {
      data: { dataId },
    } = newRow;

    setValues((prev) => {
      return {
        ...prev,
        checklistItems: prev.checklistItems.filter((item) => item.dataId !== dataId),
      };
    });
  }, []);

  const tableProps = {
    data: values.checklistItems.slice(),
    onAddNewRow,
    onDeleteRow,
    onUpdateRow,
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={close}
      aria-labelledby={MODAL_ID}
      open={open}
    >
      <Title id={MODAL_ID} onClose={close}>
        Edit
      </Title>
      <Content dividers style={contentStyles}>
        <form className={classes.form} id={formId} onSubmit={handleSubmit}>
          {cardSaving && (
            <div className={classes.globalLoading}>
              <CircularProgress />
            </div>
          )}
          <Input
            labelText="Title"
            id="title"
            labelProps={{
              shrink: true,
            }}
            formControlProps={{
              disabled: cardSaving,
              fullWidth: true,
              className: classes.formControl,
              required: true,
            }}
            inputProps={{
              value: values.title,
              onChange: handleChange,
            }}
          />
          <DatePicker
            formControlProps={{
              disabled: cardSaving,
              fullWidth: true,
              className: classes.formControl,
            }}
            id="datePlan"
            label="Date Plan"
            date={values.datePlan}
            changeDate={handlePickerChange}
            midDaySelection
            disabled={cardSaving}
          />
          <DatePicker
            formControlProps={{
              disabled: cardSaving,
              fullWidth: true,
              className: classes.formControl,
            }}
            id="dateActual"
            label="Date Actual"
            date={values.dateActual}
            changeDate={handlePickerChange}
            midDaySelection
            disabled={cardSaving}
          />
          <Table loading={false} height="100%" {...tableProps}>
            <Table.Column dataField="name" caption="Name" minWidth={120} />
            <Table.Column dataType="date" dataField="setUp" caption="Set Up" minWidth={100} />
            <Table.Column dataType="date" dataField="signOff" caption="Sign Off" minWidth={100} />
          </Table>
        </form>
      </Content>
      <Actions>
        <Button disabled={cardSaving} onClick={close} color="default">
          Cancel
        </Button>
        <Button disabled={cardSaving} type="submit" form={formId} color="primary">
          Save
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(EditCardModal);
