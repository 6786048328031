export const statusCode = [
  {
    color: 'green',
    description: 'No Issues',
  },
  {
    color: 'yellow',
    description: 'Minor Issues',
  },
  {
    color: 'orange',
    description: 'Major Issues',
  },
  {
    color: 'red',
    description: 'Escalated',
  },
  {
    color: 'grey',
    description: 'No Data',
  },
];
