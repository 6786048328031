import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { SETUP_CHECKING, LOAD_CHECKING_DATA, LOAD_CUSTOM_CHECKING_ITEMS } from '../gql';

const removeTypename = (obj) => {
  const { __typename: typename, ...rest } = obj;

  return rest;
};

export const useCheckingActions = ({ connection, projectId, isCategory }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [setupFn, { loading }] = useMutation(SETUP_CHECKING, {
    refetchQueries: [LOAD_CHECKING_DATA, LOAD_CUSTOM_CHECKING_ITEMS],
  });

  const setup = useCallback(
    (onSuccess, root, config) => {
      return setupFn({
        variables: {
          connection,
          projectId,
          isCategory,
          root: removeTypename(root),
          config: {
            sources: config.sources.map(removeTypename),
            targets: config.targets.map(removeTypename),
          },
        },
      })
        .then(onSuccess)
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [connection, enqueueSnackbar, projectId, setupFn, isCategory],
  );

  return { setup, setuping: loading };
};
