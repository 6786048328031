/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import classnames from 'classnames';
import { useStyles } from './styles';

const PageSelectorButton = ({ id, label, disabled, onClick, selected }) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick(id);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick(id);
    }
  };

  const additionalProps = disabled
    ? {}
    : {
        role: 'button',
        tabIndex: 0,
        onClick: handleClick,
        onKeyPress: handleKeyPress,
      };

  return (
    <div
      className={classnames(classes.container, {
        [classes.disabled]: disabled,
        [classes.selected]: selected,
      })}
      {...additionalProps}
    >
      {label}
    </div>
  );
};

export default memo(PageSelectorButton);
