import { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

import RichTextRenderer from 'modules/shared/components/RichTextRenderer';
import { useStyles } from './styles';

const ModalInformation = ({
  overviewData,
  rolesData,
  workProcessesData,
  isLoading,
  loadingBgColor = 'rgba(255, 255, 255, 0)',
}) => {
  const classes = useStyles();

  const bestPractices = false;

  return (
    <>
      {isLoading && (
        <div style={{ backgroundColor: loadingBgColor }} className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.modalBody}>
        <div className={classes.accordionContainer}>
          <div aria-controls="panelOverview-content" id="panelOverview-header" className={classes.overviewTitle}>
            <Typography style={{ padding: '1px 5px' }}>Overview</Typography>
          </div>
          {overviewData ? (
            <div className={classes.mainData}>
              <RichTextRenderer data={overviewData} />
            </div>
          ) : (
            <div className={classes.mainData}>
              <Typography>No Data to Display</Typography>
            </div>
          )}
        </div>
        <div className={classes.accordionContainer}>
          <div aria-controls="panelTeamRoles-content" id="panelTeamRoles-header" className={classes.overviewTitle}>
            <Typography style={{ padding: '1px 5px' }}>Team Roles</Typography>
          </div>
          {rolesData ? (
            <div className={classes.mainData}>
              <RichTextRenderer data={rolesData} />
            </div>
          ) : (
            <div className={classes.mainData}>
              <Typography>No Data to Display</Typography>
            </div>
          )}
        </div>
      </div>
      <div className={classes.modalBody}>
        <div className={classes.accordionContainer}>
          <div
            aria-controls="panelWorkProcesses-content"
            id="panelWorkProcesses-header"
            className={classes.overviewTitle}
          >
            <Typography style={{ padding: '1px 5px' }}>Associated Work Processes</Typography>
          </div>
          {workProcessesData ? (
            <div className={classes.mainData}>
              <RichTextRenderer data={workProcessesData} />
            </div>
          ) : (
            <div className={classes.mainData}>
              <Typography>No Data to Display</Typography>
            </div>
          )}
        </div>
        <div className={classes.accordionContainer}>
          <div
            aria-controls="panelBestPractices-content"
            id="panelBestPractices-header"
            className={classes.overviewTitle}
          >
            <Typography style={{ padding: '1px 5px' }}>Best Practices</Typography>
          </div>
          {bestPractices ? (
            <div className={classes.mainData} key="bestPractices">
              <RichTextRenderer data="" />
            </div>
          ) : (
            <div className={classes.mainData}>
              <Typography>No data to display...</Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default memo(ModalInformation);
