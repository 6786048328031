import { FlowSolutionBoardTracking } from 'modules/project-flow-solution/types';
import { Connection } from 'modules/shared/components/DraggableLayout';
import { createContext } from 'react';

export const HistoryContext = createContext<{
  historyTracking: FlowSolutionBoardTracking[];
  connections: Connection[];
}>({
  historyTracking: [],
  connections: [],
});
