import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tooltip: {
    position: 'absolute',
    display: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 3,
    boxShadow: '-3px 3px 15px #888',
    color: '#FFFFFF',
    padding: 6,
  },
}));
