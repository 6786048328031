import { memo, useMemo } from 'react';
import { Box, Chip, CircularProgress, Typography } from '@material-ui/core';
import CustomStore from 'devextreme/data/custom_store';

import { useLoadDataTemplateFieldsDefinitions, useLoadDataTemplatesData } from 'modules/digital-threads/hooks';
import EditingWrapper from 'modules/shared/components/Grid/wrappers/EditingWrapper';
import ToolbarWrapper from 'modules/shared/components/Grid/wrappers/ToolbarWrapper';
import Grid from 'modules/shared/components/Grid';

import { upperFirst } from 'lodash';
import { GridColumn } from 'modules/shared/components/Grid/types';
import DataTypeSelect from '../data-type-select';

import { useStyles } from './styles';

const DataGrid = ToolbarWrapper(EditingWrapper(Grid));

const ModalData = ({ dataTemplates, selectedDataTemplate, onChange, connection, projectId }) => {
  const classes = useStyles();

  const { fields, loading: fieldsLoading } = useLoadDataTemplateFieldsDefinitions(
    selectedDataTemplate,
    false,
    connection,
  );
  const { load } = useLoadDataTemplatesData(selectedDataTemplate, projectId, connection);

  const dataSource = new CustomStore({
    load: (options) => {
      return load({
        order: options.sort && options.sort[0].selector,
        desc: options.sort && options.sort[0].desc,
        limit: options.take,
        offset: options.skip,
        search: options.filter ? JSON.stringify(options.filter) : '',
        group: options.group ? JSON.stringify(options.group) : '',
      });
    },
  });

  const columns: GridColumn[] = useMemo(() => {
    return [
      {
        dataField: 'row_status',
        caption: 'Row Status',

        cellRender: ({ value }) => {
          if (!value) {
            return null;
          }
          let className = '';
          switch (value) {
            case 'Added':
              className = classes.added;
              break;
            case 'Deleted':
              className = classes.deleted;
              break;
            case 'Revised':
              className = classes.revised;
              break;
            case 'Not Changed':
              className = classes.notchanged;
              break;
            default:
              break;
          }
          return (
            <div style={{ width: 100 }}>
              <Chip style={{ display: 'flex' }} label={value} className={className} />
            </div>
          );
        },
      },
      ...fields.map((f) => ({
        dataField: f.name,
        caption: upperFirst(f.name.replace(/_/g, ' ')),
        key: f.name,
        cellRender: ({ value }) => {
          return (
            <div style={{ position: 'relative', height: 16 }}>
              <div style={{ position: 'absolute' }} title={value}>
                {value}
              </div>
            </div>
          );
        },
      })),
    ];
  }, [fields, classes]);

  return (
    <Box className={classes.definitionContainer}>
      {dataTemplates ? (
        <>
          <DataTypeSelect
            dataTemplates={dataTemplates}
            selectedDataTemplate={selectedDataTemplate}
            onChange={onChange}
          />
          <div className={classes.wrapperContainer}>
            <Box className={classes.dataContainer}>
              {!fieldsLoading ? (
                <DataGrid
                  dataSource={dataSource}
                  key={`${connection}-${projectId}`}
                  gridName="digital-threads-data"
                  gridOptions={{
                    height: '100%',
                    remoteOperations: {
                      filtering: true,
                      paging: true,
                      sorting: true,
                      grouping: true,
                    },
                    wordWrapEnabled: false,
                  }}
                  columns={columns}
                />
              ) : (
                <div className={classes.loader}>
                  <CircularProgress size={50} />
                </div>
              )}
            </Box>
          </div>
        </>
      ) : (
        <Typography>No Data to Display</Typography>
      )}
    </Box>
  );
};

export default memo(ModalData);
