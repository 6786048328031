import * as constants from './constants';

const STORAGE: Storage = window.localStorage;

const set = (key: string, value: any) => STORAGE.setItem(key, value);
const get = (key: string) => STORAGE.getItem(key) || '';
const remove = (key: string) => STORAGE.removeItem(key);

let inmemoryToken = '';

const isLoggedIn = () => {
  return get(constants.IS_LOGGED_IN) === 'true';
};

const getInmemoryIdToken = () => {
  return inmemoryToken;
};

const setIdToken = (token: string) => {
  inmemoryToken = token;
  set(constants.IS_LOGGED_IN, 'true');
};

const removeTokens = () => {
  inmemoryToken = '';
  remove(constants.IS_LOGGED_IN);
};

export default {
  removeTokens,
  isLoggedIn,
  setIdToken,
  getInmemoryIdToken,
};
