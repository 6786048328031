import { makeStyles } from '@material-ui/core';
import { blue, grey, orange } from '@material-ui/core/colors';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 0',
    borderBottom: `1px solid ${blue[300]}`,
    '&:first-child': {
      borderTop: `1px solid ${blue[300]}`,
    },
  },

  mainData: {
    display: 'flex',
  },

  imageContainer: {
    flex: '0 0 min(25%, 150px)',
    marginRight: 10,
  },
  dataWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  data: {
    display: 'flex',
    marginBottom: 10,
    flex: 1,
  },

  information: {
    flex: 1,
  },

  likes: {
    flex: 0,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginLeft: 10,
    color: grey[800],
  },
  title: {
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 16,
    color: grey[800],
  },
  description: {
    fontSize: 14,
    color: grey[800],
  },
  date: {
    fontSize: 10,
    textAlign: 'end',
    color: grey[800],
  },

  additionalData: {
    marginTop: 10,
    background: '#d5c8b8',
    padding: '8px 10px',

    display: 'flex',
    justifyContent: 'space-between',
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    color: grey[800],
    fontSize: 14,
    transition: 'all 0.3s ease',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: grey[900],
    },
    '&:hover > span': {
      textDecoration: 'underline',
    },
  },
  iconText: {
    marginLeft: 5,
  },
  attachIcon: {
    transform: 'rotate(225deg)',
  },
  linkesIcon: {
    color: grey[500],
  },
  activeLikedIcon: {
    color: orange[700],
  },
}));
