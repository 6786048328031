import instance from 'modules/rest/axios';

export type LoginData = {
  email: string;
  password: string;
};

export const login = (data: LoginData) => {
  return instance({
    method: 'post',
    url: '/auth/login',
    data,
  });
};
