import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import Input from 'modules/shared/components/Input';
import { useStyles } from './styles';
import { WizzardContext, WizzardHandlersContext } from '../wizzard-context/context';

const MODAL_ID = 'edit-default-value-modal';

const unwrapValue = (
  val: string,
): {
  value: string;
  isUuid: boolean;
  isDate: boolean;
} => {
  const isUuid = val === '<UUID>';
  const isDate = val === '<DATE>';

  return {
    isDate,
    isUuid,
    value: isUuid || isDate ? '' : val,
  };
};

const wrapValue = (val: { value: string; isUuid: boolean; isDate: boolean }): string => {
  if (val.isDate) {
    return '<DATE>';
  }

  if (val.isUuid) {
    return '<UUID>';
  }

  return val.value;
};

const Modal = () => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState({
    value: '',
    isUuid: false,
    isDate: false,
  });

  const { isEditDefaultValueModalOpen, currentDefaultValueConfig, stepData } = useContext(WizzardContext);
  const { closeEditDefaultValueModal, updateDefaultValue } = useContext(WizzardHandlersContext);

  useEffect(() => {
    if (isEditDefaultValueModalOpen) {
      const shape = stepData?.config.board.shapes.find((s) => s.id === currentDefaultValueConfig.shapeId);

      if (!shape) {
        return closeEditDefaultValueModal();
      }

      const currentDefaultValue = shape.metadata.defaultValues[currentDefaultValueConfig.col];

      if (currentDefaultValue === undefined) {
        updateDefaultValue(currentDefaultValueConfig.shapeId, currentDefaultValueConfig.col, '');
      } else {
        setDefaultValue(unwrapValue(currentDefaultValue));
      }
    }
  }, [
    updateDefaultValue,
    closeEditDefaultValueModal,
    currentDefaultValueConfig.col,
    currentDefaultValueConfig.shapeId,
    isEditDefaultValueModalOpen,
    stepData?.config.board.shapes,
  ]);

  const handleSubmit = useCallback(() => {
    updateDefaultValue(currentDefaultValueConfig.shapeId, currentDefaultValueConfig.col, wrapValue(defaultValue));

    closeEditDefaultValueModal();
  }, [
    closeEditDefaultValueModal,
    currentDefaultValueConfig.col,
    currentDefaultValueConfig.shapeId,
    defaultValue,
    updateDefaultValue,
  ]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeEditDefaultValueModal}
      aria-labelledby={MODAL_ID}
      open={isEditDefaultValueModalOpen}
    >
      <Title id={MODAL_ID} onClose={closeEditDefaultValueModal}>
        Default value for {currentDefaultValueConfig.col} column
      </Title>
      <Content className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              removeMargin
              labelText="Value"
              id="value"
              labelProps={{
                shrink: true,
              }}
              formControlProps={{
                fullWidth: true,
                disabled: defaultValue.isUuid || defaultValue.isDate,
              }}
              inputProps={{
                value: defaultValue.value,
                onChange: (e) => setDefaultValue({ value: e.target.value, isDate: false, isUuid: false }),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={defaultValue.isUuid}
                  onClick={() => {
                    const next = !defaultValue.isUuid;

                    setDefaultValue({
                      value: '',
                      isUuid: next,
                      isDate: false,
                    });
                  }}
                />
              }
              label="Use Uuid"
            />
            <div />
            <FormControlLabel
              control={
                <Checkbox
                  checked={defaultValue.isDate}
                  onClick={() => {
                    const next = !defaultValue.isDate;

                    setDefaultValue({
                      value: '',
                      isUuid: false,
                      isDate: next,
                    });
                  }}
                />
              }
              label="Use Date"
            />
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Content>
      <Actions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(Modal);
