export const MODAL_ID = 'register-flow-modal';
export const MODAL_CATEGORY_ID = 'flow-category-modal';
export const INITIAL_VALUES = {
  flowName: '',
  flowDescription: '',
  project: '',
  category: '',
  endpoint: '',
  scheduler: '',
  schedulerValue: null,
  schedulerLabel: '',
  pausable: false,
};

export const INITIAL_FIELDS = {};
