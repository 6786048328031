import { useApolloClient } from '@apollo/client';
import { LOAD_DATA_TEMPLATE_DATA, LOAD_DATA_TEMPLATE_DATA_GROUPED } from '../gql';

export const useLoadDataTemplatesData = (dataTemplateId: string, projectId: string, connection: string) => {
  const client = useApolloClient();

  const handleLoad = async ({ order, desc, limit, offset, search, group }) => {
    if (!dataTemplateId) {
      return {
        data: [],
        totalCount: 0,
      };
    }
    if (group) {
      const response = await client.query({
        query: LOAD_DATA_TEMPLATE_DATA_GROUPED,
        fetchPolicy: 'network-only',
        context: {
          requestId: `load-grouped-template-data-request-${dataTemplateId}`,
          queryDeduplication: false,
        },
        variables: {
          dataTemplateId,
          group,
          projectId,
          connection,
          search: search.trim(),
          limit,
          offset,
        },
      });

      return response.data ? JSON.parse(response.data.dataTemplateDataGrouped) : [];
    }

    const response = await client.query({
      query: LOAD_DATA_TEMPLATE_DATA,
      variables: {
        dataTemplateId,
        limit,
        offset,
        order,
        desc,
        connection,
        projectId,
        search: search.trim(),
      },
      fetchPolicy: 'network-only',
    });

    const { nodes, totalCount } = response.data?.dataTemplateData || { nodes: '[]', totalCount: 0 };

    return {
      data: JSON.parse(nodes),
      totalCount,
    };
  };

  return {
    load: handleLoad,
  };
};
