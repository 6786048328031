import { useState, useCallback } from 'react';

import {
  archive as archiveApi,
  remove as removeApi,
  getUrl as getUrlApi,
  ArchivePayload,
  DeleteArchivePayload,
} from 'modules/rest/api/hdfs';

export const useHdfs = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const archive = useCallback((data: ArchivePayload) => {
    setLoading(true);

    return archiveApi(data).finally(() => setLoading(false));
  }, []);

  const remove = useCallback((body: DeleteArchivePayload) => {
    setLoading(true);

    return removeApi(body).finally(() => setLoading(false));
  }, []);

  const getUrl = useCallback((id: string) => {
    setLoading(true);

    return getUrlApi(id).finally(() => setLoading(false));
  }, []);

  return {
    loading,
    archive,
    remove,
    getUrl,
  };
};
