import { Button, Card, CardActions, Collapse, IconButton, Paper, Typography } from '@material-ui/core';
import { SnackbarContent, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { forwardRef, memo, Ref, useCallback, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getApiBaseUrl } from 'modules/shared/utils';
import { useStyles } from './styles';

type Props = {
  id: SnackbarKey;
  message: SnackbarMessage;
  fileName: string;
  path: string;
};
const DownloadCards = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { id, message, fileName, path } = props;
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleDownload = useCallback(() => {
    const a = document.createElement('a');
    a.href = `${getApiBaseUrl()}/${path}`;
    a.target = '_blank';
    a.download = fileName;
    a.click();
    handleDismiss();
  }, [fileName, handleDismiss, path]);
  return (
    <SnackbarContent ref={ref}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography variant="body2" className={classes.typography}>
            {message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              size="small"
              className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton size="small" className={classes.expand} onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.paper}>
            <Typography gutterBottom variant="caption" style={{ color: '#000', display: 'block' }}>
              {fileName} is ready
            </Typography>
            <Button size="small" color="primary" className={classes.button} onClick={handleDownload}>
              <CheckCircleIcon className={classes.checkIcon} />
              Download now
            </Button>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});
export default memo(DownloadCards);
