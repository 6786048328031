import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '10px',
    width: '100px',
    height: '100px',
  },
  header: {
    color: BorNeoColors.primaryColor4,
    background: BorNeoColors.HeaderColors[1],
  },
  content: {
    height: '100px',
  },
}));
