import { Chip } from '@mui/material';
import { format } from 'date-fns';
import { Title, Dialog, Content } from 'modules/shared/components/Dialog';
import Table from 'modules/shared/components/Table';
import { memo } from 'react';
import { useStyles } from './styles';

const MODAL_ID = 'card-tracking-modal';
const CardTrackingModal = ({ open, onClose, trackings }) => {
  const classes = useStyles();

  const cellRender = ({ data, column: { dataField } }) => {
    switch (dataField) {
      case 'completedAt':
        return format(new Date(data[dataField]), 'MM/dd/yyyy HH:mm');
      case 'status':
        return <Chip label={data[dataField]} className={classes[data[dataField]]} />;
      default:
        return data[dataField] || '';
    }
  };
  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={onClose}
      aria-labelledby={MODAL_ID}
      open={open}
    >
      <Title id={MODAL_ID} onClose={onClose}>
        Status of Card
      </Title>
      <Content dividers style={{ minHeight: 300 }}>
        <Table data={trackings} loading={false} height="100%" editingMode="cell" growToFullHeight>
          <Table.Column
            dataField="status"
            caption="Status"
            alignment="center"
            minWidth={60}
            cellRender={cellRender}
            allowSorting={false}
          />
          <Table.Column
            dataType="date"
            dataField="completedAt"
            caption="Completed at"
            cellRender={cellRender}
            minWidth={100}
            allowSorting={false}
          />
        </Table>
      </Content>
    </Dialog>
  );
};

export default memo(CardTrackingModal);
