import { useApolloClient } from '@apollo/client';
import { LOAD_DATA_TEMPLATES_WITH_VERSION } from '../gql';

export const useLoadDataTemplatesWithVersion = (connection: string, projectId: string) => {
  const client = useApolloClient();

  const handleLoad = async ({ order, desc, limit, offset }) => {
    if (!projectId) {
      return {
        data: [],
        totalCount: 0,
      };
    }
    const response = await client.query({
      query: LOAD_DATA_TEMPLATES_WITH_VERSION,
      variables: {
        order,
        desc,
        limit,
        offset,
        connection,
        projectId,
      },
      fetchPolicy: 'network-only',
    });

    const { nodes, totalCount } = response.data?.dataTemplatesWithVersion || { nodes: [], totalCount: 0 };

    return {
      data: nodes,
      totalCount,
    };
  };

  return {
    load: handleLoad,
  };
};
