import { useQuery } from '@apollo/client';
import { LOAD_DATA_STORES_CONNECTIONS } from '../gql';
import { DataStoreConnection } from '../types';

export const useLoadDataStoresConnections = ({ connection, projectId }) => {
  const { loading, data, error } = useQuery(LOAD_DATA_STORES_CONNECTIONS, {
    variables: {
      connection,
      projectId,
    },
    skip: !connection || !projectId,
  });

  const connections: DataStoreConnection[] = data?.allDataStoresConnections || [];

  return { loading, connections, error };
};
