import { gql } from '@apollo/client';

export const LOAD_NEO_SPACES = gql`
  query LoadNeoSpaces($connection: String!) {
    neoSpaces(connection: $connection) {
      title
      neoSpaceId
    }
  }
`;
