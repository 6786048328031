import { useQuery } from '@apollo/client';
import { LOAD_FLOW_BY_ID } from '../gql';
import { FlowDetails } from '../types';

export const useLoadFlowById = ({ connection, projectId, flowId }) => {
  const { loading, data, error, refetch } = useQuery(LOAD_FLOW_BY_ID, {
    variables: {
      connection,
      flowId,
      projectId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !projectId || !flowId,
  });

  const flow: FlowDetails = data?.flowById || null;

  return {
    loading,
    flow,
    error,
    refetch,
  };
};
