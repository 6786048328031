import { memo, useCallback, useContext } from 'react';
import classnames from 'classnames';
import { Delete, Restore, Edit } from '@mui/icons-material';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useStyles } from './styles';

type Props = {
  title: string;
  deletable?: boolean;
  deleted: boolean;
  added?: boolean;
  onDelete?: (title: string) => void;
  onEdit?: () => void;
};

const Item = ({ title, deletable = false, deleted, onDelete, onEdit, added = false }: Props) => {
  const classes = useStyles();
  const { insidePreview } = useContext(PreviewContext);

  const handleDelete = useCallback(() => {
    onDelete?.(title);
  }, [onDelete, title]);

  return (
    <div className={classes.container}>
      <div
        className={classnames(classes.item, {
          [classes.deleted]: deleted,
          [classes.added]: added,
        })}
        role="presentation"
      >
        <span title={title} className={classes.text}>
          {title}
        </span>

        {!insidePreview && onEdit && (
          <div role="presentation" className={classes.editButton} onClick={onEdit}>
            <Edit className={classes.icon} />
          </div>
        )}
        {!insidePreview && deletable && (
          <div
            role="presentation"
            className={classnames(classes.deleteButton, {
              [classes.restoreButton]: deleted,
            })}
            onClick={handleDelete}
          >
            {deleted ? <Restore className={classes.icon} /> : <Delete className={classes.icon} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Item);
