import { useQuery } from '@apollo/client';
import { LOAD_HDFS_TEMPLATE_DATA } from '../gql';

export const useLoadDataTemplateCurrentData = (
  { connection, projectId, isPrivate, category, dataTemplate },
  skip = false,
) => {
  const { loading, data } = useQuery(LOAD_HDFS_TEMPLATE_DATA, {
    variables: {
      connection,
      projectId,
      isPrivate,
      category,
      dataTemplate,
    },
    skip: !category || !connection || !projectId || !dataTemplate || skip,
    fetchPolicy: 'network-only',
  });

  const items = data?.readHdfsDataTemplateCurrentItems?.folders || [];

  return {
    loading,
    items,
  };
};
