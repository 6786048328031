import { Button, Card, CardContent, Menu, MenuItem, Typography, IconButton } from '@mui/material';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import { WizzardHandlersContext } from 'modules/digital-threads-mapping-wizzard/wizzard-context/context';
import { memo, useCallback, useContext, useRef, useState } from 'react';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';

import { DraggableShapeProps } from '../../types';
import ColumnCard, { Types } from '../ColumnCard';
import { useStyles } from './styles';

export const MIN_CARD_HEIGHT = 63;
export const ITEM_HEIGHT = 32;
export const PADDING_TOP = 5;

const renderConnectionsShape = (id: string, type: string, cols: string[], horizontal: boolean, classes) => {
  if (cols.length) {
    return (
      <div
        className={classNames(classes.connectionsWrapper, {
          [classes.connectionsWrapperHorizontal]: horizontal,
        })}
      >
        {cols.map((label) => {
          return <div id={`${id}-${type}-${label}`} key={label} className={classes.connectionsElement} />;
        })}
      </div>
    );
  }
};

const SourceDtData = ({ width, height, title, metadata, id }: DraggableShapeProps) => {
  const classes = useStyles();

  const { openImportSourceModal, resetInputCard } = useContext(WizzardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);

  const columns = metadata?.columns || [];
  const [isMenuOpen, setMenuOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <Card className={classes.container} style={{ width, height }}>
      <CardContent className={classes.content}>
        <div className={classes.cardHeader}>
          <Typography variant="h6" className={`${classes.headerItem} ${classes.headerItemText}`}>
            {title}
          </Typography>
          <div className={classes.headerItemMenuWrap}>
            <div ref={anchorRef} />
            {!insidePreview && (
              <IconButton disableRipple className={classes.headerItemMenu} size="small" onClick={handleMenuOpen}>
                <MoreVertIcon className={`${classes.headerItem} ${classes.headerItemMenu}`} />
              </IconButton>
            )}
          </div>
        </div>

        {!columns.length ? (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              onClick={() => {
                openImportSourceModal(id);
              }}
            >
              Import
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, position: 'relative' }}>
            {columns.map((col) => {
              return <ColumnCard type={Types.Start} key={`${id}-${col}`} parentId={id} title={col} />;
            })}

            {renderConnectionsShape(id, 'start', columns, true, classes)}
          </div>
        )}
      </CardContent>
      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            resetInputCard(id);
            handleMenuClose();
          }}
        >
          Reset
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default memo(SourceDtData);
