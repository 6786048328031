import { memo, useContext } from 'react';

import DraggableLayout from 'modules/shared/components/DraggableLayout';
import {
  WizzardContext,
  WizzardHandlersContext,
} from 'modules/digital-threads-mapping-wizzard/wizzard-context/context';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import AddColumnDefaultValueModal from './AddColumnDefaultValueModal';

const TransformationStep = () => {
  const { stepData, layoutRef, connection, projectId } = useContext(WizzardContext);
  const { updateShape } = useContext(WizzardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const editable = useAdminOrSuperUser(connection, projectId);

  return (
    <>
      <DraggableLayout
        bgColour="rgb(255, 255, 255)"
        loadingBgColor="rgba(255, 255, 255, 0.2)"
        isLoading={false}
        boardData={stepData!.config.board}
        disableAdaptLayout
        adaptOnMount
        cardDragEnabled={!insidePreview}
        initial={stepData!.config.position}
        layoutRef={layoutRef}
        shapeProps={
          insidePreview || !editable
            ? {}
            : {
                onUpdate: updateShape,
              }
        }
      />
      {!insidePreview && editable && <AddColumnDefaultValueModal />}
    </>
  );
};

export default memo(TransformationStep);
