import { Typography } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { Close, Mode, Error, Visibility } from '@mui/icons-material';
import { memo, useCallback, useContext } from 'react';
import { EditableSteps, StepNames } from 'modules/digital-threads-mapping-wizzard/constants';
import { primaryColor6, primaryColor1 } from 'modules/shared/styles/colors';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useStyles } from './styles';

const Step = ({ type, selected, error, id, onDelete, onUpdate, onSelect, editable = false }) => {
  const classes = useStyles();
  const { insidePreview } = useContext(PreviewContext);

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation();
      onDelete(id);
    },
    [onDelete, id],
  );
  const handleUpdate = useCallback(
    (e) => {
      e.stopPropagation();
      onUpdate(id, type);
    },
    [onUpdate, id, type],
  );
  const handleSelect = useCallback(
    (e) => {
      e.stopPropagation();
      onSelect(id);
    },
    [onSelect, id],
  );

  return (
    <div
      role="presentation"
      onClick={handleSelect}
      className={classes.container}
      style={{ border: `1px solid ${!selected ? primaryColor6 : primaryColor1}` }}
    >
      {!insidePreview && editable && (
        <div className={classes.actions}>
          {selected && EditableSteps.includes(type) && (
            <div role="presentation" className={classes.editButton} onClick={handleUpdate}>
              <Mode className={classes.icon} />
            </div>
          )}
          <div role="presentation" className={classes.deleteButton} onClick={handleDelete}>
            <Close className={classes.icon} />
          </div>
        </div>
      )}

      {insidePreview && (
        <div className={classes.actions}>
          {selected && EditableSteps.includes(type) && (
            <div role="presentation" className={classes.editButton} onClick={handleUpdate}>
              <Visibility className={classes.icon} />
            </div>
          )}
        </div>
      )}

      <div className={classes.content}>
        <Typography className={classes.text} variant="body1" color="textPrimary">
          {StepNames[type]}
        </Typography>

        {error && (
          <Tooltip title={error}>
            <Error className={classes.error} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default memo(Step);
