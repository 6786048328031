import * as React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import Guide from './guide-ui';
import Statuses from './statuses-ui';
import Readiness from './readiness-ui';
import { useCategories } from './hooks/useCategories';
import { useLegends } from './hooks/useLegends';
import Categories from './categories-ui';
import Legends from './legends-ui';
import TabUi from './tab-ui';
import { useStyles } from './styles';

enum Tab {
  STATUSES = 'STATUSES',
  READINESS = 'READINESS',
  GUIDE = 'GUIDE',
}

const breadcrumbs = [
  {
    tab: Tab.STATUSES,
    name: 'Statuses',
  },
  {
    tab: Tab.READINESS,
    name: 'Readiness',
  },

  {
    tab: Tab.GUIDE,
    name: 'Guide',
  },
];

const Dashboard = () => {
  const classes = useStyles();
  const { connection, projectId } = useProjectInfoFromSearch();
  const { categories, loading } = useCategories({ projectId, connection });

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [currentTab, setCurrentTab] = React.useState<Tab>(Tab.STATUSES);
  const category = categories[selectedIndex];

  const [legendSelections, setLegendSelections] = React.useState<string[]>([]);
  const { legends } = useLegends(category?.id, currentTab as any);

  if (!categories.length) {
    return <Typography variant="h6">Nothing to show</Typography>;
  }

  const handleSelectLegend = (legendId: string) => {
    const newArray = [...legendSelections];
    const indexOf = newArray.findIndex((el) => el === legendId);
    if (indexOf > -1) {
      newArray.splice(indexOf, 1);
    } else {
      newArray.push(legendId);
    }
    setLegendSelections([...newArray]);
  };

  const renderTab = () => {
    if (currentTab === Tab.STATUSES) {
      return (
        <TabUi
          breadcrumbs={breadcrumbs}
          tab={<Statuses id={category.id} />}
          currentTab={currentTab}
          onTabChange={setCurrentTab}
          sidebar={
            <Categories
              categories={categories}
              selectedIndex={selectedIndex}
              setSelectedIndex={(index) => setSelectedIndex(index)}
              style={{ marginBottom: 20 }}
            />
          }
        />
      );
    }
    if (currentTab === Tab.READINESS) {
      return (
        <TabUi
          breadcrumbs={breadcrumbs}
          tab={<Readiness categoryId={category.id} legendIds={legendSelections} />}
          currentTab={currentTab}
          onTabChange={setCurrentTab}
          sidebar={
            <>
              <Categories
                categories={categories}
                selectedIndex={selectedIndex}
                setSelectedIndex={(index) => setSelectedIndex(index)}
                style={{ marginBottom: 20 }}
              />
              <Legends legends={legends} legendSelections={legendSelections} onClickLegend={handleSelectLegend} />
            </>
          }
        />
      );
    }
    return (
      <TabUi
        breadcrumbs={breadcrumbs}
        tab={<Guide category={category} legendIds={legendSelections} />}
        currentTab={currentTab}
        onTabChange={setCurrentTab}
        sidebar={
          <>
            <Categories
              categories={categories}
              selectedIndex={selectedIndex}
              setSelectedIndex={(index) => setSelectedIndex(index)}
              style={{ marginBottom: 20 }}
            />
            <Legends legends={legends} legendSelections={legendSelections} onClickLegend={handleSelectLegend} />
          </>
        }
      />
    );
  };

  if (loading) {
    return (
      <div className={classes.center}>
        <CircularProgress />
      </div>
    );
  }

  return renderTab();
};

export default React.memo(Dashboard);
