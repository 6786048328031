import { Button } from '@mui/material';
import { memo, useCallback, useContext } from 'react';

import Menu from 'modules/flow-solution-builder/builder-board-ui/Menu';
import { useBuilderContextMenuState } from 'modules/shared/hooks/base';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { WizzardContext, WizzardHandlersContext } from '../wizzard-context/context';
import Step from './Step';
import { StepNames, StepType } from '../constants';
import { useStyles } from './styles';
import { getAvailableStepsToAdd, getStepError } from '../tools';

const Stepper = () => {
  const classes = useStyles();
  const { selectedStep, steps } = useContext(WizzardContext);
  const { deleteStep, createStep, selectStep, openBlendConfigModal } = useContext(WizzardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);

  const { menuConfig, handleMenuClose, openMenu } = useBuilderContextMenuState();

  const handleUpdate = useCallback(
    (_: string, type: StepType) => {
      if (type === StepType.BLEND) {
        return openBlendConfigModal();
      }
    },
    [openBlendConfigModal],
  );

  return (
    <div className={classes.container}>
      {!insidePreview && (
        <div className={classes.add}>
          <Button
            onClick={(e) => {
              const { clientX, clientY } = e;
              openMenu({
                x: clientY,
                y: clientX,
              });
            }}
            variant="contained"
            fullWidth
            size="small"
          >
            Add step
          </Button>
        </div>
      )}
      <div className={classes.steps}>
        {steps.map((s) => {
          return (
            <Step
              key={s.id}
              id={s.id}
              type={s.type}
              selected={s.id === selectedStep}
              onDelete={deleteStep}
              onSelect={selectStep}
              onUpdate={handleUpdate}
              error={getStepError(s)}
              editable
            />
          );
        })}
      </div>

      <Menu
        {...menuConfig}
        onClose={handleMenuClose}
        items={getAvailableStepsToAdd(steps).map((type) => ({
          title: StepNames[type],
          onClick: () => {
            createStep(type);
            handleMenuClose();
          },
        }))}
      />
    </div>
  );
};

export default memo(Stepper);
