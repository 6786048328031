import { memo, useEffect, useState } from 'react';
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import instance from 'modules/rest/axios';
import { useStyles } from './styles';

const JsonPreview = ({ path, filePath, fileName, body }) => {
  const [jsonData, setJsonData] = useState('');

  useEffect(() => {
    if (filePath && fileName) {
      instance({
        method: 'post',
        url: `${path}/read`,
        data: {
          ...body,
          filePath,
          fileName,
        },
        responseType: 'text',
        transformResponse: [(data) => data],
      })
        .then(({ data }) => {
          setJsonData(JSON.parse(`${data.replace(/}(\s+)?{/g, `},\n{`)}`));
        })
        .catch(() => {});
    }
  }, [path, filePath, fileName, body]);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {jsonData ? (
          <RichTextEditorComponent height="100%">
            <p style={{ whiteSpace: 'pre' }}>{JSON.stringify(jsonData, null, 2)}</p>
            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
          </RichTextEditorComponent>
        ) : null}
      </div>
    </div>
  );
};

export default memo(JsonPreview);
