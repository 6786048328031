import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { COPY_FLOWS } from '../gql';

type Input = {
  connection: string;
  project: string;
};

export const useCopyFlows = () => {
  const [copy, { loading, error }] = useMutation(COPY_FLOWS);

  const handleCopy = useCallback(
    (source: Input, target: Input, flows: string[]) => {
      return copy({
        variables: {
          source,
          target,
          flows,
        },
      });
    },
    [copy],
  );

  return {
    copy: handleCopy,
    loading,
    error,
  };
};
