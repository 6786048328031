import { replace } from 'connected-react-router';
import Cookies from 'js-cookie';
import Storage from 'modules/tokens-storage';

import { client } from 'modules/gql';
import msalInstance from 'configure/azure-ad-b2c';
import * as actions from '../actions';

export const logout = () => async (dispatch) => {
  Storage.removeTokens();

  await msalInstance.logoutRedirect();
  client.resetStore();

  dispatch(actions.authLogout());

  dispatch(replace(''));

  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
};
