import { useCallback, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';

import { displayTimeValue } from 'modules/shared/utils/displayTimeValue';
import TimePicker from '../TimePicker';
import { DaysOfWeek, EVERY_DAY_VALUE } from '../../constants';

import { useStyles } from './styles';

const createLabel = (val) => {
  let startLabel = '';
  if (val[5] === '*' || val[5].split(',').length === 7) {
    startLabel = '@Daily';
  } else {
    startLabel = `@On ${val[5]
      .split(',')
      .map((v) => DaysOfWeek[v])
      .join(', ')}`;
  }

  const endLabel = ` At ${displayTimeValue(val[2])}:${displayTimeValue(val[1])} GMT`;
  return `${startLabel}${endLabel}`;
};

const Weekly = ({ value, onChange, label, disabled }) => {
  const classes = useStyles();
  useEffect(() => {
    if (!value) {
      onChange(EVERY_DAY_VALUE, createLabel(EVERY_DAY_VALUE));
    }
  }, [onChange, value]);

  const onAtHourChange = useCallback(
    ({ target: { value: newValue } }) => {
      const val = [...value];
      val[2] = newValue;

      onChange(val, createLabel(val));
    },
    [onChange, value],
  );

  const onAtMinuteChange = useCallback(
    ({ target: { value: newValue } }) => {
      const val = [...value];
      val[1] = newValue;

      onChange(val, createLabel(val));
    },
    [onChange, value],
  );

  const onCheck = useCallback(
    ({ target }) => {
      const { checked, value: checkboxValue } = target;
      const val = [...value];
      if (checked) {
        if (val[5] === '*') {
          val[5] = checkboxValue;
        } else {
          val[5] = `${val[5]},${checkboxValue}`;
        }
      } else {
        val[5] = val[5]
          .split(',')
          .filter((v) => v !== checkboxValue)
          .join(',');
        if (!val[5]) {
          val[5] = '*';
        }
      }

      onChange(val, createLabel(val));
    },
    [onChange, value],
  );

  if (!value) {
    return null;
  }

  const keys = Object.keys(DaysOfWeek);
  const first = keys.slice(0, 4);
  const second = keys.slice(4);

  return (
    <div>
      <Typography className={classes.title}>Run: {label}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          {first.map((key) => {
            return (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    disabled={disabled}
                    color="primary"
                    value={key}
                    onChange={onCheck}
                    checked={value[5].includes(key)}
                  />
                }
                label={DaysOfWeek[key]}
              />
            );
          })}
        </Grid>
        <Grid item xs={12} sm={4}>
          {second.map((key) => {
            return (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    disabled={disabled}
                    color="primary"
                    value={key}
                    onChange={onCheck}
                    checked={value[5].includes(key)}
                  />
                }
                label={DaysOfWeek[key]}
              />
            );
          })}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TimePicker
            hour={value[2]}
            minute={value[1]}
            onAtHourChange={onAtHourChange}
            onAtMinuteChange={onAtMinuteChange}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Weekly;
