export const MAX_SCALE = 10;
export const MIN_SCALE = 0.5;
export const INITIAL_SCALE = 1;
export const DEFAULT_CANVAS_SIZE = 5000;

export const TRANSFORM_OPTIONS = {
  minScale: MIN_SCALE,
  maxScale: MAX_SCALE,
  limitToBounds: false,
  limitToWrapper: true,
};

export const SCALE_PADDING_CONFIG = {
  disabled: true,
};

export const PAN_CONFIG = {
  animationTime: 0,
  velocity: false,
  paddingSize: 0,
};

export const PINCH_CONFIG = {
  disabled: true,
};

export const DOUBLE_CLICK = {
  animationTime: 0,
  disabled: true,
};
