import { useCallback, useContext, useState } from 'react';
import { Drawer, Divider, List } from '@material-ui/core';
import classnames from 'classnames';

import { authSelectors } from 'modules/auth';
import { useSelector } from 'react-redux';
import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import Toolbar from './Toolbar';
import schema from './schema';
import { Components, PropsExtractors } from './constants';

import { useStyles } from './styles';

const Sidebar = ({ selectedPath }) => {
  const classes = useStyles();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const adminConnections = useSelector(authSelectors.getAdminConnections);

  const { projects } = useContext(ProjectsContext);

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = useCallback(() => {
    setSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  const { connection, projectId } = useProjectInfoFromSearch();

  const isAdminOrSuperUser = useAdminOrSuperUser(connection, projectId);

  const items = schema(isAdmin || adminConnections.length, isAdminOrSuperUser, Boolean(projects.length));

  return (
    <Drawer
      variant="permanent"
      className={classnames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpen,
        [classes.drawerClose]: !isSidebarOpen,
      })}
      classes={{
        paper: classnames({
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        }),
      }}
    >
      <Toolbar isOpen={isSidebarOpen} onClick={toggleSidebar} />
      <Divider />
      <List className={classes.menuList}>
        {items.map((item) => {
          const Component = Components[item.type];
          const props = PropsExtractors[item.type](item, {
            selectedPath,
            isSidebarOpen,
          });

          return (
            <div key={item.key}>
              <div style={{ height: '2px' }} />
              <Component key={item.key} {...props} />
              <div style={{ height: '2px' }} />
            </div>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
