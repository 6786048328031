import { memo } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useLoadDigitalThreadsHistory } from 'modules/digital-threads/hooks';
import Grid from 'modules/shared/components/Grid';

import DataTypeSelect from '../data-type-select';

import { useStyles } from './styles';

const ModalHistory = ({ dataTemplates, projectId, selectedDataTemplate, onChange, connection }) => {
  const classes = useStyles();

  const historyData = useLoadDigitalThreadsHistory(selectedDataTemplate, projectId, connection);

  return (
    <Box className={classes.definitionContainer}>
      <DataTypeSelect dataTemplates={dataTemplates} selectedDataTemplate={selectedDataTemplate} onChange={onChange} />
      {historyData.digitalThreadsHistory ? (
        <Box className={classes.wrapperContainer}>
          <Box className={classes.dataContainer}>
            <Grid
              dataSource={historyData.digitalThreadsHistory}
              gridName="digital-threads-history"
              gridOptions={{
                groupPanel: {
                  visible: false,
                },
                height: '100%',
              }}
              columns={[
                { dataField: 'label', allowSorting: false },
                { dataField: 'comments', allowSorting: false },
                { dataField: 'provider.label', allowSorting: false },
                { dataField: 'timeLogEntry', allowSorting: false },
                { dataField: 'version', allowSorting: false },
              ]}
            />
          </Box>
        </Box>
      ) : (
        <Typography>No Data to Display</Typography>
      )}
    </Box>
  );
};

export default memo(ModalHistory);
