import { gql } from '@apollo/client';

export const USER_DOWNLOADS = gql`
  query UserDownloads {
    userDownloads {
      archiveId
      fileName
    }
  }
`;
