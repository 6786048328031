import React from 'react';
import { Prompt } from 'react-router-dom';
import { history } from 'configure/browserHistory';
import AlertDialog from '../AlertDialog';

type NavigationGuardProps = {
  when: boolean;
  checkHasEditData: () => any;
  onDiscardData: () => void;
};

type NavigationGuardState = {
  showModal: boolean;
  location: any;
  buttons: any;
};

class NavigationGuard extends React.PureComponent<NavigationGuardProps, NavigationGuardState> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      location: null,
      buttons: [
        {
          title: 'Discard Changes',
          onClick: this.handleDiscardDataAndNavigate,
        },
        {
          title: 'Stay',
          onClick: this.closeModal,
        },
      ],
    };
  }

  showModal = () =>
    this.setState({
      showModal: true,
    });

  closeModal = () =>
    this.setState({
      showModal: false,
    });

  handleBlockedNavigation = (location) => {
    const { checkHasEditData } = this.props;
    const hasEditData = checkHasEditData();
    if (hasEditData) {
      this.setState({
        location,
        showModal: true,
      });
      return false;
    }
    return true;
  };

  /* eslint-disable-next-line class-methods-use-this */
  navigate = (location) => {
    if (location && location.pathname) {
      history.push(location.pathname);
    }
  };

  handleDiscardDataAndNavigate = () => {
    const { onDiscardData } = this.props;
    const { location } = this.state;
    if (onDiscardData && typeof onDiscardData === 'function') {
      onDiscardData();
    }
    this.navigate(location);
    this.setState({
      location: null,
      showModal: false,
    });
  };

  render() {
    const { when } = this.props;
    const { showModal, buttons } = this.state;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <AlertDialog
          show={showModal}
          onClose={this.closeModal}
          message="You have unsaved changes on this page.
          Do you want to leave this page and discard your changes or stay on this page?"
          buttons={buttons}
        />
      </>
    );
  }
}

export default NavigationGuard;
