import { format } from 'date-fns';
import { UnparsedUser, User } from '../types';

export const parseUsers = (users: UnparsedUser[]): User[] => {
  return users.map((user) => ({
    userId: user.userId,
    email: user.email,
    company: user.company,
    joinedDate: format(new Date(user.joinedDate), 'yyyy-MM-dd'),
    userName: user.login.username,
    status: user.activeness.activenessStatus.status,
    lastActive: format(new Date(user.activeness.lastActive), 'hh:mm:ss MM-dd-yyyy'),
  }));
};
