export function retryLoader(fn, { retries = 3, interval = 500, exponentialBackoff = true } = {}) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries === 0) {
            // eslint-disable-next-line no-console
            console.error('Error', error);
            window.location.reload();
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryLoader(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
          }).then(resolve, reject);
        }, interval);
      });
  });
}
