import { memo, useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import format from 'date-fns/format';
import { useLoadDataTemplateVersionStatus } from 'modules/digital-threads/hooks';
import { useDataTemplateVersionStatusActions } from 'modules/digital-threads/hooks/useDataTemplateVersionStatusActions';
import Select from 'modules/shared/components/Select';
import { useStyles } from './styles';

const ModalDtStatus = ({ connection, versionStatusId, dtVersionId }) => {
  const classes = useStyles();
  const [versionStatus, setVersionStatus] = useState('');

  const { loading, dataTemplateVersionStatus } = useLoadDataTemplateVersionStatus(connection, versionStatusId);

  const { create, update } = useDataTemplateVersionStatusActions({ connection, versionStatusId, dtVersionId });

  const versionStatusValue = [
    {
      value: 'No Issues',
      label: 'No Issues',
    },
    {
      value: 'Minor Issues',
      label: 'Minor Issues',
    },
    {
      value: 'Major Issues',
      label: 'Major Issues',
    },
    {
      value: 'Escalated',
      label: 'Escalated',
    },
    {
      value: 'No Data',
      label: 'No Data',
    },
  ];

  const handleVersionChange = useCallback(
    (val) => {
      if (versionStatusId) {
        setVersionStatus(val);
        update(val, format(new Date(), 'yyyy-MM-dd'));
      } else if (!versionStatusId) {
        create(val, format(new Date(), 'yyyy-MM-dd'));
        setVersionStatus(val);
      }
    },
    [setVersionStatus, versionStatusId, update, create],
  );

  useEffect(() => {
    if (dataTemplateVersionStatus[0]?.status) {
      setVersionStatus(dataTemplateVersionStatus[0]?.status);
    }
  }, [dataTemplateVersionStatus, setVersionStatus]);

  return (
    <div className={classes.container}>
      <div className={classes.contentWrap} style={{ padding: '20px' }}>
        <Grid item>
          <Select
            value={versionStatus}
            label="Status"
            items={versionStatusValue}
            onChange={handleVersionChange}
            loading={loading}
            disableOpen={loading}
          />
        </Grid>
      </div>
    </div>
  );
};

export default memo(ModalDtStatus);
