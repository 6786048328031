import { OptionsObject } from 'notistack';

export const BASE_ERROR_NOTIFICATION_OPTIONS: OptionsObject = {
  autoHideDuration: 3000,
  variant: 'error',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  style: {
    maxWidth: 450,
    width: '100%',
  },
};

export const BASE_INFO_NOTIFICATION_OPTIONS: OptionsObject = {
  autoHideDuration: 3000,
  variant: 'info',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  style: {
    maxWidth: 450,
    width: '100%',
  },
};

export const BASE_SUCCESS_NOTIFICATION_OPTIONS: OptionsObject = {
  autoHideDuration: 3000,
  variant: 'success',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  style: {
    maxWidth: 450,
    width: '100%',
  },
};
