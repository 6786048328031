import { useEffect } from 'react';

const OnDemand = ({ onChange }) => {
  useEffect(() => {
    onChange(null, '@On Demand');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default OnDemand;
