import {
  CheckingDataItem,
  CheckingItemType,
  CHECKING_EDIT_HANDLE_ID,
  CHECKING_VIEW_HANDLE_ID,
  CustomCheckingItemType,
  DATASOURCE_COLOR,
  StatusColors,
  TextColors,
} from '../constants';

export const getCheckingDataItemTooltip =
  (checkingData: CheckingDataItem[], isCategory: boolean) => (item: CheckingDataItem) => {
    if (item.type === CheckingItemType.Category) {
      return 'Category';
    }

    const parent = checkingData.find((c) => c.itemId === item.parentId)!;

    if (item.type === CheckingItemType.Template) {
      return isCategory ? `Template of ${parent.item}` : 'Template';
    }

    if (item.customType === CustomCheckingItemType.DATA_SOURCE) {
      return `Data Source of ${parent.item}`;
    }

    if (item.type === CheckingItemType.Source) {
      return `Source of ${parent.item}`;
    }

    if (item.type === CheckingItemType.Target) {
      return `Target for ${parent.item}`;
    }

    return '';
  };

export const getCheckingDataItemShape = (item: CheckingDataItem) => {
  if (item.customType === CustomCheckingItemType.DATA_SOURCE) {
    return {
      type: 'Bpmn',
      shape: 'DataSource',
      ratio: 1,
    };
  }

  if (item.type === CheckingItemType.Target) {
    return {
      type: 'Flow',
      shape: 'Data',
      ratio: 0.5,
    };
  }

  if (item.type === CheckingItemType.Template) {
    return {
      type: 'Flow',
      shape: 'Terminator',
      ratio: 0.5,
    };
  }

  if (item.type === CheckingItemType.Source) {
    return {
      type: 'Basic',
      shape: 'Octagon',
      ratio: 1,
    };
  }

  return {
    type: 'Flow',
    shape: 'Process',
    ratio: 0.5,
  };
};

export const getCheckingDataArrows = (targetItem: CheckingDataItem) => {
  if (targetItem.type === CheckingItemType.Source) {
    return {
      target: 'None',
      source: 'IndentedArrow',
    };
  }
  return {
    target: 'IndentedArrow',
    source: 'None',
  };
};

export const getCheckingDataAnnotationMargin = (item: CheckingDataItem) => {
  if (item.customType === CustomCheckingItemType.DATA_SOURCE) {
    return {
      top: 10,
    };
  }

  return {};
};

export const getCheckingDataVisibleHandles = (item: CheckingDataItem) => {
  if (item.customType === CustomCheckingItemType.DATA_SOURCE) {
    return [];
  }

  if (item.isCategory) {
    return [];
  }

  if (item.lvl === 1) {
    return [CHECKING_EDIT_HANDLE_ID];
  }

  return [CHECKING_VIEW_HANDLE_ID, CHECKING_EDIT_HANDLE_ID];
};

export const getCheckingDataShapeLvl = (item: CheckingDataItem) => Math.min(item.lvl, 3);

export const getCheckingDataShapeColor = (item: CheckingDataItem) => {
  if (item.customType === CustomCheckingItemType.DATA_SOURCE) {
    return DATASOURCE_COLOR;
  }

  return StatusColors[item.status];
};

export const getCheckingDataTextColor = (item: CheckingDataItem) => TextColors[item.status] || 'white';
