import { Dialog, Title, Content } from 'modules/shared/components/Dialog';
import { memo, useEffect, useState } from 'react';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { getApiBaseUrl } from 'modules/shared/utils';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useAdminOnlyForConnection } from 'modules/auth/tools';
import Select from 'modules/shared/components/Select2';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useUploaderHooks } from 'modules/shared/hooks/hdfs-upload/useUploaderHooks';
import { useStyles } from './styles';
import { useLoadHdfsCategories, useLoadHdfsTemplatesPerCategory } from '../hooks';

const MODAL_ID = 'upload-card-modal';
const hostUrl = getApiBaseUrl();

const settings = {
  saveUrl: `${hostUrl}/hdfs-access/upload`,
  removeUrl: `${hostUrl}/hdfs-access/delete`,
  chunkSize: 5 * 1024 * 1024, // 5 MB
  retryCount: 0,
};

const MaxUploadSize = 3.5 * 1024 * 1024 * 1024; // 3.5Gb
// Statuses
// 0 - error
// 1 - ready
// 2 - success
// 3 - uploading
// 4 - paused
// 5 - uploading

const PATH_STEP = 'PATH';
const UPLOAD_STEP = 'UPLOAD';

const Modal = ({ isOpen, onClose, projectId, connection, category, template, refresh }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = useAdminOnlyForConnection(connection);
  const [step, setStep] = useState(PATH_STEP);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [selectedTempalate, setSelectedTemplate] = useState(template);

  useEffect(() => {
    setSelectedCategory(category);
    setSelectedTemplate(template);
  }, [category, template]);

  const [privateUpload, setPrivateUpload] = useState(false);

  const {
    handleCanceling,
    handleClearing,
    handleClose,
    handleRemoving,
    handleSuccess,
    handleUploading,
    instance,
    handleBeforeUpload,
    checkFiles,
  } = useUploaderHooks({
    data: {
      category: selectedCategory,
      template: selectedTempalate,
      isPrivate: privateUpload,
    },
    connection,
    projectId,
    onSuccess: refresh,
    onClose: () => {
      setPrivateUpload(false);
      setStep(PATH_STEP);
      onClose();
    },
  });

  const {
    loading: categoriesLoading,
    categories,
    updateCategories,
  } = useLoadHdfsCategories({ connection, projectId, isPrivate: privateUpload }, !isOpen);
  const {
    loading: templatesLoading,
    templates,
    updateTemplates,
  } = useLoadHdfsTemplatesPerCategory(
    { connection, projectId, isPrivate: privateUpload, category: selectedCategory },
    !isOpen,
  );

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={handleClose}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={handleClose}>
        Upload files
      </Title>
      <Content className={classes.dialogContent}>
        {step === PATH_STEP && (
          <>
            {isAdmin && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={privateUpload}
                    onClick={() => {
                      setPrivateUpload(!privateUpload);
                      setSelectedCategory('');
                      setSelectedTemplate('');
                    }}
                  />
                }
                label="Private Upload"
              />
            )}
            <Select
              id="category"
              value={categories.filter((name) => name === selectedCategory)}
              options={categories.map((name) => ({ label: name, value: name }))}
              loading={categoriesLoading}
              className={classes.select}
              isCreatable
              onCreateOption={(option) => {
                if (option.includes('/')) {
                  enqueueSnackbar(
                    'Folder should not have "/" character, please remove it.',
                    BASE_ERROR_NOTIFICATION_OPTIONS,
                  );
                } else {
                  updateCategories((folders) => [...folders, option]);
                  setSelectedCategory(option);
                }
              }}
              disableClear
              onChange={(val) => {
                setSelectedCategory(val[0]);
                setSelectedTemplate('');
              }}
            />
            <Select
              id="template"
              value={[selectedTempalate]}
              options={templates.map((name) => ({ label: name, value: name }))}
              loading={templatesLoading}
              disabled={!selectedCategory}
              className={classes.select}
              onChange={(val) => setSelectedTemplate(val[0])}
              disableClear
              isCreatable
              onCreateOption={(option) => {
                if (option.includes('/')) {
                  enqueueSnackbar(
                    'Folder should not have "/" character, please remove it.',
                    BASE_ERROR_NOTIFICATION_OPTIONS,
                  );
                } else {
                  updateTemplates((folders) => [...folders, option]);
                  setSelectedTemplate(option);
                }
              }}
            />
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedTempalate || !selectedCategory}
                onClick={() => {
                  setStep(UPLOAD_STEP);
                }}
              >
                Next
              </Button>
            </div>
          </>
        )}
        {step === UPLOAD_STEP && (
          <>
            <UploaderComponent
              id="upload"
              type="file"
              ref={(scope) => {
                instance.current = scope;
              }}
              beforeUpload={handleBeforeUpload}
              uploading={handleUploading}
              asyncSettings={settings}
              removing={handleRemoving}
              chunkUploading={handleUploading}
              canceling={handleCanceling}
              autoUpload={false}
              maxFileSize={MaxUploadSize}
              success={handleSuccess}
              clearing={handleClearing}
              enabled={Boolean(selectedCategory && selectedTempalate)}
            />
            <div className={classNames(classes.buttonContainer, classes.marginTop)}>
              <Button
                variant="contained"
                onClick={() => {
                  checkFiles(() => {
                    setStep(PATH_STEP);
                  });
                }}
              >
                Back
              </Button>
            </div>
          </>
        )}
      </Content>
    </Dialog>
  );
};

export default memo(Modal);
