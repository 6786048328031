import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    position: 'relative',
    flex: 1,
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  notesButton: {
    whiteSpace: 'nowrap',
    textTransform: 'initial',
    padding: '3px 5px',
  },
}));
