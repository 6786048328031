import { makeStyles } from '@material-ui/core';
import { dangerColor, infoColor, lightFont, successColor, warningColor } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  definitionContainer: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  wrapperContainer: {
    marginTop: '15px',
    position: 'relative',
    flex: 1,
  },
  dataContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  added: {
    color: lightFont,
    backgroundColor: successColor,
  },
  deleted: {
    color: lightFont,
    backgroundColor: dangerColor,
  },
  revised: {
    color: lightFont,
    backgroundColor: warningColor,
  },
  notchanged: {
    color: lightFont,
    backgroundColor: infoColor,
  },
}));
