import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  definitionContainer: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  wrapperContainer: {
    marginTop: '15px',
    position: 'relative',
    flex: 1,
  },
  dataContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
