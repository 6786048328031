import Menu from 'modules/flow-solution-builder/builder-board-ui/Menu';
import { useBuilderContextMenuState } from 'modules/shared/hooks/base';
import { memo, useContext } from 'react';
import { LayoutContext } from '../../DraggableLayout/context/LayoutContext';
import { DraggableShapeProps } from '../types';
import StartFlowUi from './StartFlowUi';

const StartFlow = ({ id, title, onDelete }: DraggableShapeProps) => {
  const { menuConfig, handleMenuClose, openMenu } = useBuilderContextMenuState();

  const { isBuilder } = useContext(LayoutContext);
  return (
    <>
      <StartFlowUi
        id={id}
        title={title}
        containerStyle={{ pointerEvents: 'none' }}
        onClick={() => {}}
        onContextMenu={(e) => {
          if (isBuilder) {
            e.preventDefault();

            openMenu({ x: '50%', y: '50%' });
          }
        }}
      />
      {isBuilder && (
        <Menu
          absolutePosition
          {...menuConfig}
          onClose={handleMenuClose}
          items={[
            {
              title: 'Delete',
              onClick: () => onDelete?.(id),
            },
          ]}
        />
      )}
    </>
  );
};

export default memo(StartFlow);
