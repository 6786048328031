import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '325px',
    width: '100%',
    margin: '5px',
    height: '330px',
    borderColor: BorNeoColors.accentColor1,
    borderRadius: 8,
    boxShadow: '0 8px 8px 0 #8f8f8f',
    overflow: 'hidden',
    transform: 'scale(0.92)',
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  clickableCard: {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(0.97)',
    },
    '&:focus': {
      transform: 'scale(0.97)',
      outline: 'none',
    },
    '&:focus-within': {
      transform: 'scale(0.97)',
      outline: 'none',
    },
  },
  header: {
    color: BorNeoColors.primaryColor4,
    background: BorNeoColors.accentColor9,
    '& > div': {
      overflow: 'hidden',
      '& > span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  muiContent: {
    flex: 1,
    padding: '16px 16px 8px',
  },
  content: {
    // height: '85px',
    maxHeight: '85px',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    '& p': {
      marginBottom: 0,
    },
  },
  media: {
    height: 120,
  },
}));
