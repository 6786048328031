const tenant = process.env.REACT_APP_AZURE_ADB2C_TENANT!;
const susi = process.env.REACT_APP_AZURE_ADB2C_POLICY!;
const reset = process.env.REACT_APP_AZURE_ADB2C_POLICY_RESET!;
const clientId = process.env.REACT_APP_AZURE_ADB2C_CLIENT_ID!;

const susiAuthority = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${susi}`;

export const resetPasswordAuthority = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${reset}`;

export const msalConfig = {
  auth: {
    clientId,
    authority: susiAuthority,
    knownAuthorities: [`${tenant}.b2clogin.com`],
    redirectUri: `${window.location.origin}/sso`,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};
