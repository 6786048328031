import { gql } from '@apollo/client';

export const LOAD_SPIDER_VIEW = gql`
  query LoadSpiderViewByProject($connection: String!) {
    dtSpiderViewBoard(connection: $connection) {
      boardId
      minScale
      maxScale
      canvasSize
      shapes {
        shapeId
        height
        width
        xCoordinate
        yCoordinate
        metadata
        type
        title
      }
      connections {
        connectionId
        color
        start
        end
        startPosition
        endPosition
      }
    }
  }
`;

export const LOAD_BOARD_LIST = gql`
  query DtDatasetViewShape($connection: String!, $title: String!) {
    dtDatasetViewShape(connection: $connection, title: $title) {
      dtTemplateIdFk
      dtShapeStatus
    }
  }
`;

export const LOAD_DIGITAL_THREADS = gql`
  query LoadDigitalThreads($connection: String!) {
    digitalthreads(connection: $connection) {
      dtId
      title
      type
      isDefault
    }
  }
`;

export const LOAD_DIGITAL_THREAD_TYPES = gql`
  query LoadDigitalThreadTypes($connection: String!) {
    digitalthreadTypes(connection: $connection) {
      type
      templates {
        templateId
        deliverableProjectData
      }
    }
  }
`;

export const LOAD_DIGITAL_THREAD_TYPE = gql`
  query digitalthreadType($connection: String!, $type: String!) {
    digitalthreadType(connection: $connection, type: $type) {
      type
      templates {
        templateId
        deliverableProjectData
      }
    }
  }
`;

export const LOAD_ALL_DATA_TEMPLATES = gql`
  query LoadAllDataTemplates($connection: String!) {
    allDataTemplates(connection: $connection) {
      templateId
      table
      deliverableProjectData
      timing
      dtIdFk
    }
  }
`;

export const LOAD_DATA_TEMPLATES = gql`
  query LoadDataTemplates($connection: String!, $digitalThreadId: String!) {
    dataTemplates(connection: $connection, digitalthread_id: $digitalThreadId) {
      templateId
      table
      deliverableProjectData
      timing
      dtIdFk
    }
  }
`;

export const LOAD_DIGITAL_THREADS_INFO_BY_ID = gql`
  query LoadDigitalThreadsInfoById($digitalThreadId: String!, $connection: String!) {
    digitalthreadsInfoById(digitalthread_id: $digitalThreadId, connection: $connection) {
      dtId
      title
      overview
      workProcesses
      roles
      dataTemplates {
        templateId
        deliverableProjectData
      }
    }
  }
`;

export const LOAD_DATE_TEMPLATE_FIELDS_DEFINITIONS_BY_ID = gql`
  query LoadDataTemplateFieldsDefinitions($dataTemplateId: String!, $connection: String!) {
    dataTemplateFieldsDefinitions(data_template_id: $dataTemplateId, connection: $connection) {
      name
      description
      required
      type
      key
      OrderIndex
    }
  }
`;

export const LOAD_DATA_TEMPLATE_DATA = gql`
  query LoadDataTemplateData(
    $dataTemplateId: String!
    $search: String
    $order: String
    $desc: Boolean
    $limit: Int
    $offset: Int
    $connection: String!
    $projectId: String!
  ) {
    dataTemplateData(
      data_template_id: $dataTemplateId
      limit: $limit
      offset: $offset
      order: $order
      desc: $desc
      search: $search
      connection: $connection
      project_id: $projectId
    ) {
      nodes
      totalCount
    }
  }
`;

export const LOAD_DATA_TEMPLATE_DATA_GROUPED = gql`
  query LoadDataTemplateDataGrouped(
    $dataTemplateId: String!
    $group: String!
    $projectId: String!
    $connection: String!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    dataTemplateDataGrouped(
      data_template_id: $dataTemplateId
      group: $group
      project_id: $projectId
      connection: $connection
      search: $search
      limit: $limit
      offset: $offset
    )
  }
`;

export const LOAD_DIGITAL_THREADS_HISTORY = gql`
  query LoadDigitalThreadsHistory($labelName: String!, $connection: String!, $projectId: String!) {
    digitalThreadsHistory(labelName: $labelName, connection: $connection, project_id: $projectId) {
      itemId
      version
      label
      comments
      timeLogEntry
      provider {
        label
      }
    }
  }
`;

export const LOAD_DIGITAL_THREADS_STATUSES = gql`
  query LoadDigitalThreadsStatuses($connection: String!, $projectId: String!) {
    digitalThreadsLoadedStatus(connection: $connection, project_id: $projectId)
  }
`;

export const LOAD_DATA_TEMPLATES_WITH_VERSION = gql`
  query LoadDataTemplatesWithVersion(
    $order: String
    $desc: Boolean
    $limit: Int
    $offset: Int
    $connection: String!
    $projectId: String!
  ) {
    dataTemplatesWithVersion(
      order: $order
      desc: $desc
      limit: $limit
      offset: $offset
      connection: $connection
      project_id: $projectId
    ) {
      nodes {
        template {
          templateId
          deliverableProjectData
          timing
          digitalthreadInfo {
            title
          }
        }
        versionId
        versionStatusId
        version
        versionStatus
        versionLatestDate
      }
      totalCount
    }
  }
`;

export const LOAD_DATA_TEMPLATE_NOTES = gql`
  query LoadDataTemplateNotes($dtTemplateId: String!, $connection: String!, $projectId: String!) {
    dataTemplateNotes(connection: $connection, dt_template_id: $dtTemplateId, project_id: $projectId) {
      templateNotesId
      dtTemplateIdFk
      notes
      addedDate
      userEmail
      projectId
    }
  }
`;

export const LOAD_DATA_TEMPLATE_VERSION_STATUS = gql`
  query LoadDataTemplateVersionStatus($versionStatusId: String!, $connection: String!) {
    dataTemplateVersionStatus(connection: $connection, version_status_id: $versionStatusId) {
      versionStatusId
      dtVersionIdFk
      status
      addedDate
      updatedDate
    }
  }
`;

export const LOAD_DATA_TEMPLATE_BY_TYPE = gql`
  query LoadDataTemplateByType($connection: String!, $dtType: String!) {
    dtTemplateByType(connection: $connection, dt_type: $dtType) {
      templateId
      deliverableProjectData
    }
  }
`;

export const LOAD_CHECKING_DATA = gql`
  query LoadCheckingData($connection: String!, $projectId: String!, $root: String!, $isCategory: Boolean!) {
    checkingData(connection: $connection, project_id: $projectId, root: $root, is_category: $isCategory) {
      itemId
      isCustom
      customType
      item
      parentId
      status
      isCategory
      lvl
      type
    }
  }
`;

export const LOAD_CHECKING_ITEM = gql`
  query LoadCheckingItem($connection: String!, $projectId: String!, $root: String!) {
    checkingItem(connection: $connection, project_id: $projectId, root: $root) {
      item {
        itemId
        isCustom
        item
        status
        customType
      }
      sources {
        itemId
        isCustom
        item
        status
        customType
      }
      targets {
        itemId
        isCustom
        item
        status
        customType
      }
    }
  }
`;

export const LOAD_CHECKING_ITEM_WITHOUT_RELATIONS = gql`
  query LoadCheckingItemWithoutRelations($connection: String!, $projectId: String!, $root: String!) {
    checkingItemWithoutRelations(connection: $connection, project_id: $projectId, root: $root) {
      itemId
      isCustom
      item
      status
      customType
    }
  }
`;

export const LOAD_CUSTOM_CHECKING_ITEMS = gql`
  query LoadCustomCheckingItems($connection: String!, $projectId: String!) {
    customCheckingItems(connection: $connection, project_id: $projectId) {
      itemId
      isCustom
      item
      status
      customType
    }
  }
`;
