import { Grid } from '@mui/material';
import { memo, useCallback } from 'react';
import Select from 'modules/shared/components/Select2';
import { SchedulerItems } from 'modules/shared/components/Scheduler/constants';
import Input from 'modules/shared/components/Input';
import Scheduler from 'modules/shared/components/Scheduler';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const ModalContent = ({
  scheduler,
  onChange,
  disabled = false,
  customFields = { unzip: false, filter: false, loadDeltaLake: false },
}) => {
  const handleSchedulerTypeChange = useCallback(
    (value) => {
      onChange({
        ...scheduler,
        scheduler: value[0],
        schedulerValue: null,
        schedulerLabel: '',
      });
    },
    [scheduler, onChange],
  );

  const handleSchedulerValueChange = useCallback(
    (value, label) => {
      onChange({
        ...scheduler,
        schedulerValue: value,
        schedulerLabel: label,
      });
    },
    [scheduler, onChange],
  );

  const handleChange = useCallback(
    ({ target: { id, value } }) => {
      onChange({
        ...scheduler,
        [id]: value,
      });
    },
    [scheduler, onChange],
  );

  const handleCheckboxChange = useCallback(
    ({ target }) => {
      const { checked, id } = target;

      onChange({
        ...scheduler,
        [id]: checked,
      });
    },
    [onChange, scheduler],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          labelText="Name"
          id="name"
          removeMargin
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
            required: true,
          }}
          inputProps={{
            value: scheduler.name,
            onChange: handleChange,
          }}
        />
        <div style={{ height: 20 }} />
        {customFields.filter && (
          <>
            <Input
              labelText="Filter Path"
              id="filter"
              removeMargin
              labelProps={{
                shrink: true,
              }}
              formControlProps={{
                fullWidth: true,
                required: false,
              }}
              inputProps={{
                value: scheduler.filter,
                onChange: handleChange,
              }}
            />
            <div style={{ height: 20 }} />
          </>
        )}
        {customFields.unzip && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  color="primary"
                  id="unzip"
                  onChange={handleCheckboxChange}
                  checked={scheduler.unzip}
                />
              }
              label="Unzip"
            />
            <div style={{ height: 20 }} />
          </>
        )}
        {customFields.loadDeltaLake && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  color="primary"
                  id="loadDeltaLake"
                  onChange={handleCheckboxChange}
                  checked={scheduler.loadDeltaLake}
                />
              }
              label="Load Delta Lake"
            />
            <div style={{ height: 20 }} />
          </>
        )}
        <Select
          id="scheduler"
          labelText="Scheduler"
          value={[scheduler.scheduler]}
          options={SchedulerItems.map((c) => ({ label: c.value, value: c.key }))}
          disableClear
          required
          onChange={handleSchedulerTypeChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Scheduler
          type={scheduler.scheduler}
          value={scheduler.schedulerValue}
          label={scheduler.schedulerLabel}
          onChange={handleSchedulerValueChange}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default memo(ModalContent);
