import { gql } from '@apollo/client';

export const LOAD_FLOW_SOLUTIONS_BY_PROJECT = gql`
  query LoadFlowSolutionsByProject($projectId: String!, $connection: String!) {
    flowSolutions(project_id: $projectId, connection: $connection) {
      solutionId
      title
      description
      categoryId
      type
      projectId
      board {
        scheduler {
          schedulerLabel
          isActive
          schedulerType
          schedulerId
          schedulerPattern
        }
      }
    }
  }
`;

export const LOAD_FLOW_SOLUTION_BOARD_BY_SOLUTION_ID = gql`
  query LoadFlowSolutionBoardBySolutionId($solutionId: String!, $projectId: String!, $connection: String!) {
    flowSolutionBoardBySolutionId(solution_id: $solutionId, project_id: $projectId, connection: $connection) {
      boardId
      minScale
      maxScale
      canvasSize
      currentState
      shapes {
        shapeId
        title
        xCoordinate
        yCoordinate
        metadata
        width
        height
        type
      }
      connections {
        connectionId
        start
        end
        startPosition
        endPosition
        color
        type
      }
      threads {
        threadId
        boardId
        executeShapeId
        status
        createdAt
      }
      flowSolution {
        title
        solutionId
      }
      scheduler {
        schedulerId
        schedulerType
        schedulerLabel
        schedulerPattern
        isActive
      }
    }
  }
`;

export const LOAD_BOARD_ADDITIONAL_CONFIG = gql`
  query LoadBoardAdditionalConfig($boardId: String!, $projectId: String!, $connection: String!) {
    boardAdditionalConfig(board_id: $boardId, project_id: $projectId, connection: $connection) {
      checklistItems {
        dataId
        name
        setUp
        signOff
        fkShapeId
        fkBoardId
      }
    }
  }
`;

export const LOAD_FLOW_SOLUTION_BOARD_TRACKING = gql`
  query LoadFlowSolutionBoardTracking(
    $boardId: String!
    $connection: String!
    $projectId: String!
    $date: String
    $state: String
    $status: String
    $orderBy: String
    $orderDirection: String
  ) {
    flowSolutionBoardTracking(
      board_id: $boardId
      project_id: $projectId
      connection: $connection
      date: $date
      state: $state
      status: $status
      order_by: $orderBy
      order_direction: $orderDirection
    ) {
      shapeId
      status
      state
      createdAt
      boardId
      trackingId
      completedAt
      metadata
      checkedChecklistItems {
        status
        setUp
        signOff
        checklistItem {
          name
        }
      }
    }
  }
`;

export const LOAD_FLOW_SOLUTION_CHECKED_CHECKLIST = gql`
  query LoadFlowSolutionsCheckedCheckistItems($trackingId: String!, $connection: String!) {
    flowSolutionsCheckedCheckistItems(tracking_id: $trackingId, connection: $connection) {
      itemId
      checklistItem {
        dataId
        fkShapeId
      }
      status
      setUp
      signOff
    }
  }
`;

export const LOAD_NOTEBOOK_BY_BOARD_ID = gql`
  query LoadNoteBookByBoardId($connection: String!, $boardId: String!, $projectId: String!) {
    notebook(connection: $connection, board_id: $boardId, project_id: $projectId) {
      content
    }
  }
`;

export const LOAD_FLOW_LOGS = gql`
  query LoadFlowLogs($boardId: String!, $connection: String!, $projectId: String!, $state: String) {
    flowLogs(board_id: $boardId, connection: $connection, project_id: $projectId, state: $state) {
      content
      happenedAt
      header
      status
      id
      metadata
    }
  }
`;
