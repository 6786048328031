import { useQuery } from '@apollo/client';
import { Board, Connection, Shape } from 'modules/shared/components/DraggableLayout';
import { darkFont, lightFont, primaryColor1, primaryColor3 } from 'modules/shared/styles/colors';
import { useMemo } from 'react';
import { LOAD_SPIDER_VIEW } from '../gql';
import { DTSpiderViewBoard, DTSpiderViewConnection, DTSpiderViewShape } from '../types';

type ItemsByPosition = {
  top: Array<DTSpiderViewConnection> | undefined;
  right: Array<DTSpiderViewConnection> | undefined;
  left: Array<DTSpiderViewConnection> | undefined;
  bottom: Array<DTSpiderViewConnection> | undefined;
};

type ConnectionsData = Record<string, ItemsByPosition>;

type AdditionalMeta = {
  'dt-text-item'?: Record<string, any> & {
    onClick: (id: string) => void;
  };
  'dt-spider-item'?: Record<string, any>;
};

const parseConnections = (
  connections: DTSpiderViewConnection[],
): {
  connections: Connection[];
  connectionsData: Record<string, ItemsByPosition>;
} => {
  const groupped = connections.reduce((acc, item) => {
    const { start, startPosition } = item;
    return {
      ...acc,
      [start]: {
        ...acc[start],
        [startPosition]: [...(acc[start]?.[startPosition] || []), item],
      },
    };
  }, {} as Record<string, ItemsByPosition>);

  const entries = Object.entries(groupped);

  const parsedConnections: Connection[] = entries.reduce((newConnections, [mainId, mainConnections]) => {
    const positions = Object.keys(mainConnections);

    const connectionsWithComputedId = positions.reduce((acc, position) => {
      const positionConnections = mainConnections[position];

      return [
        ...acc,
        ...positionConnections.map((c, idx) => {
          return {
            ...c,
            start: `${mainId}-${position}-${idx + 1}`,
            curveness: 0.7,
            headSize: 4,
            tailSize: 4,
            uniqKey: Math.random(),
            showTail: true,
            showHead: true,
          };
        }),
      ];
    }, [] as Connection[]);

    return [...newConnections, ...connectionsWithComputedId];
  }, [] as Connection[]);

  return {
    connections: parsedConnections,
    connectionsData: groupped,
  };
};

const parseShapes = (
  shapes: DTSpiderViewShape[],
  connectionsData: ConnectionsData,
  additionalMeta: AdditionalMeta,
): Shape[] => {
  return shapes.map((shape) => {
    const shapeMeta = JSON.parse(shape.metadata);
    const shapeConnectionsInfo = connectionsData[shape.shapeId];

    const additionalMetaByType = additionalMeta[shape.type] || {};

    const dtShapeInfo: Record<string, string> = {};

    if (shape.type === 'dt-spider-item') {
      const statuses = additionalMetaByType?.statuses;
      const dts = additionalMetaByType?.dtsPerType?.[shape.title];

      const hasData = dts?.some((item) => statuses.includes(item));
      const hasDts = dts?.length > 0;

      dtShapeInfo.clickable = hasData || hasDts;

      dtShapeInfo.backgroundColor = '#8f8f8f';
      dtShapeInfo.color = lightFont;
      if (hasDts) {
        dtShapeInfo.backgroundColor = primaryColor3;
        dtShapeInfo.color = darkFont;
      }
      if (hasData) {
        dtShapeInfo.color = lightFont;
        dtShapeInfo.backgroundColor = primaryColor1;
      }
    }

    return {
      id: shape.shapeId,
      xCoordinate: shape.xCoordinate,
      yCoordinate: shape.yCoordinate,
      metadata: {
        ...shapeMeta,
        ...additionalMetaByType,
        ...(shapeConnectionsInfo
          ? {
              topConnectionsCount: shapeConnectionsInfo.top?.length,
              leftConnectionsCount: shapeConnectionsInfo.left?.length,
              rightConnectionsCount: shapeConnectionsInfo.right?.length,
              bottomConnectionsCount: shapeConnectionsInfo.bottom?.length,
            }
          : {}),
        ...dtShapeInfo,
      },
      title: shape.title,
      width: shape.width,
      height: shape.height,
      type: shape.type,
    };
  });
};
const parseBoard = (board: DTSpiderViewBoard, additionalMeta: AdditionalMeta): Board | undefined => {
  if (board) {
    const { connections, connectionsData } = parseConnections(board.connections);
    return {
      boardId: board.boardId,
      maxScale: board.maxScale,
      minScale: board.minScale,
      canvasSize: board.canvasSize,
      shapes: parseShapes(board.shapes, connectionsData, additionalMeta),
      connections,
    };
  }

  return undefined;
};

export const useLoadSpiderView = (connection: string, additionalMeta: AdditionalMeta) => {
  const { data, loading, refetch } = useQuery(LOAD_SPIDER_VIEW, {
    variables: {
      connection,
    },
    notifyOnNetworkStatusChange: true,
    skip: !connection,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const board = useMemo(
    () => parseBoard(data?.dtSpiderViewBoard, additionalMeta),
    [data?.dtSpiderViewBoard, additionalMeta],
  );

  return {
    board,
    loading,
    refetch,
  };
};
