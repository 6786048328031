import { makeStyles } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    background: BorNeoColors.primaryColor6,
    padding: '0 8px',
    height: 40,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 14,
    cursor: 'pointer',
    borderBottom: `1px solid transparent`,
    transition: 'border-bottom-color 0.3s ease',
    userSelect: 'none',
    '&:not(:first-child)': {
      marginLeft: 5,
    },
    '&:hover': {
      borderBottomColor: red[300],
    },
  },
  selected: {
    borderBottomColor: red[700],
    '&:hover': {
      borderBottomColor: red[700],
    },
  },
  disabled: {
    color: grey[300],
    cursor: 'default',
    pointerEvents: 'none',
  },
}));
