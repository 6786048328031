import { memo, useCallback, useMemo } from 'react';

import Draggable from 'modules/libs/react-draggable';
import { noop } from 'modules/shared/utils';

import { useStyles } from './styles';

const DraggableWrapper = ({
  children,
  id,
  idx,
  disabled = false,
  maxScale,
  scale,
  bounds,
  onDrag = noop,
  onDragStart = noop,
  onDragEnd = noop,
  x,
  y,
  onInitPositionFix = noop,
}) => {
  const classes = useStyles();

  const handleDrag = useCallback(
    (_, position) => {
      onDrag(idx, position);
    },
    [onDrag, idx],
  );

  const handleInitPositionFix = useCallback(
    (position) => {
      onInitPositionFix(idx, position);
    },
    [onInitPositionFix, idx],
  );

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const position = useMemo(() => ({ x, y }), [x, y]);

  return (
    <Draggable
      disabled={disabled}
      maxScale={maxScale}
      scale={scale}
      bounds={bounds}
      onMouseDown={stopPropagation}
      onStart={onDragStart}
      onDrag={handleDrag}
      onStop={onDragEnd}
      onInitPositionFix={handleInitPositionFix}
      position={position}
    >
      <div
        id={id}
        className={classes.container}
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </Draggable>
  );
};

export default memo(DraggableWrapper);
