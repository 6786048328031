import { useToggle } from 'modules/shared/hooks/base';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Connection, LayoutRef, Shape } from 'modules/shared/components/DraggableLayout';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import Storage from 'modules/storage';

import { useLoadDataTemplateFieldsDefinitions } from 'modules/digital-threads/hooks';
import { useToggleWithValue } from 'modules/shared/hooks/base/useToggleWithValue';
import { compareObjects } from 'modules/flow-control/utils';
import { noop } from 'modules/shared/utils';
import { WizzardContext, WizzardHandlersContext } from './context';
import { ConnectionPoint, DefaultValueConfig, Step, StepConfig } from '../types';
import {
  StepType,
  DEFAULT_CANVAS_SIZE,
  INITIAL_BOARD_CONFIG,
  MAX_SCALE,
  MIN_SCALE,
  TRANSFORMATION,
  OUTPUT,
  INPUT,
  COMBINE_COLUMN,
  Views,
} from '../constants';
import {
  deleteConnectionChainFromStep,
  generateEndConnectionId,
  generateStartConnectionId,
  getAdditionalConfigByType,
  getBaseFieldsBasedOnSteps,
  getDefaultShapesByType,
  getDefaultSourceShape,
  normalizeSteps,
} from '../tools';
import { useLatestMappings } from '../hooks/useLatestMappings';
import { usePublishSteps } from '../hooks/usePublishSteps';

const generateHex = () => {
  const rand = Math.random().toString(16).substring(2, 8);

  return `#${rand}`;
};

const getCurrentView = (boardConfig, config) => {
  if (boardConfig.steps?.length) {
    return Views.BOARD;
  }
  if (config?.mappings?.length) {
    return Views.JSON;
  }
  return Views.BOARD;
};

const WizzardProvider = ({ children, templateId }) => {
  const { projectId, connection } = useProjectInfoFromSearch();
  const storageKey = `mappings-v2-${projectId}-${templateId}`;
  const { publish, publishing } = usePublishSteps({ connection, projectId });

  const { loading: latestMappingsLoading, latestMappings } = useLatestMappings({
    connection,
    projectId,
    dataTemplate: templateId,
  });

  const latestMappingsBoardConfig = useMemo(() => {
    if (latestMappings?.boardConfig) {
      const board = JSON.parse(latestMappings.boardConfig);

      return board;
    }

    return {
      steps: [],
      selectStep: '',
    };
  }, [latestMappings]);

  const [view, setView] = useState<Views>(Views.BOARD);

  const { fields: baseFields, loading } = useLoadDataTemplateFieldsDefinitions(templateId, false, connection);

  const layoutRef = useRef<LayoutRef>({} as LayoutRef);
  const resolvers = useRef({
    resolveInput: noop,
  });
  const [shouldCreateCombineMappings, setShouldCreateCombineMappings] = useState(false);

  const {
    value: currentDefaultValueConfig,
    isOpen: isEditDefaultValueModalOpen,
    activate: openEditDefaultValueModal,
    deactivate: closeEditDefaultValueModal,
  } = useToggleWithValue<DefaultValueConfig>({ shapeId: '', col: '' });

  const [draft, setDraft] = useState<boolean>(false);
  const [selectedStep, setSelectedStep] = useState<string>('');
  const [steps, setSteps] = useState<Step[]>([]);

  const handleSetSelectedStep = useCallback(
    (step) => {
      closeEditDefaultValueModal();
      setSelectedStep(step);
    },
    [closeEditDefaultValueModal],
  );

  const stepData = steps.find((s) => s.id === selectedStep);

  const [draggableItem, setDraggableItem] = useState<ConnectionPoint>({
    parentId: '',
    title: '',
  });

  const createStep = useCallback(
    (type: StepType) => {
      const newId = uuid();

      handleSetSelectedStep(newId);
      setSteps((s) => {
        const fields = getBaseFieldsBasedOnSteps(baseFields, s, s.length);

        return s.concat({
          id: newId,
          type,
          config: {
            board: {
              boardId: uuid(),
              minScale: MIN_SCALE,
              maxScale: MAX_SCALE,
              canvasSize: DEFAULT_CANVAS_SIZE,
              shapes: getDefaultShapesByType(type, templateId, fields),
              connections: [],
            },
            position: INITIAL_BOARD_CONFIG,
            ...getAdditionalConfigByType(type),
          },
        });
      });
    },
    [handleSetSelectedStep, baseFields, templateId],
  );

  const deleteStep = useCallback(
    (id: string) => {
      setSteps((prev) => {
        if (selectedStep === id) {
          const currentIndex = prev.findIndex((s) => s.id === id);
          const newSteps = prev.filter((s) => s.id !== id);

          const newIndex = Math.min(currentIndex, newSteps.length - 1);

          handleSetSelectedStep(newSteps[newIndex]?.id || '');
          return normalizeSteps(newSteps, baseFields);
        }

        const newSteps = prev.filter((s) => s.id !== id);
        return normalizeSteps(newSteps, baseFields);
      });
    },
    [baseFields, handleSetSelectedStep, selectedStep],
  );

  const selectStep = useCallback(
    (stepId: string) => {
      handleSetSelectedStep(stepId);

      setSteps((prev) => {
        return normalizeSteps(prev, baseFields);
      });
    },
    [baseFields, handleSetSelectedStep],
  );

  useEffect(() => {
    if (stepData) {
      layoutRef.current?.adaptLayout?.(stepData!.config.board);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep]);

  useEffect(() => {
    if (!latestMappingsLoading) {
      setSteps(latestMappingsBoardConfig.steps);
      handleSetSelectedStep(latestMappingsBoardConfig.selectedStep);
      setView(getCurrentView(latestMappingsBoardConfig, latestMappings));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, latestMappingsLoading]);

  const handleShapeUpdate = useCallback(
    (id: string, shape: Partial<Shape>) => {
      setSteps((s) =>
        s.map((step) => {
          if (step.id === selectedStep) {
            return {
              ...step,
              config: {
                ...step.config,
                board: {
                  ...step.config.board,
                  shapes: step.config.board.shapes.map((cs) => {
                    if (cs.id === id) {
                      return {
                        ...cs,
                        ...shape,
                      };
                    }
                    return cs;
                  }),
                },
              },
            };
          }

          return step;
        }),
      );
    },
    [selectedStep],
  );

  const handleSetupInputShape = useCallback(
    async (id: string, shape: Partial<Shape>) => {
      let promise = Promise.resolve();
      setSteps((s) =>
        s.map((step) => {
          if (step.id === selectedStep) {
            const clone: Step = cloneDeep(step);

            if ([StepType.TRANSFORM, StepType.BLEND].includes(clone.type)) {
              const outputShape = clone.config.board.shapes.find((it) => it.type === OUTPUT)!;

              const outputShapeCols: string[] = outputShape.metadata.columns.map((it) => it.name);

              const defaultValues = {};

              const connectionsToCreate = (shape.metadata?.columns || []).reduce((acc, col: string) => {
                const existing = outputShapeCols.find((name) => name.toLowerCase() === col.toLowerCase());

                if (existing) {
                  defaultValues[existing] = '';
                  return acc.concat({
                    from: col,
                    to: existing,
                  });
                }

                return acc;
              }, [] as { from: string; to: string }[]);

              clone.config.board.shapes = clone.config.board.shapes.map((it) => {
                if (it.type === OUTPUT) {
                  return {
                    ...it,
                    metadata: {
                      ...it.metadata,
                      defaultValues,
                    },
                  };
                }

                return it;
              });

              clone.config.board.connections = connectionsToCreate.map((it) => ({
                connectionId: uuid(),
                start: generateStartConnectionId(id, it.from),
                end: generateEndConnectionId(outputShape.id, it.to),
                headSize: 4,
                tailSize: 4,
                curveness: 0.5,
                color: generateHex(),
                endPosition: ['left', 'right'],
                startPosition: ['left', 'right'],
                uniqKey: Math.random(),
                metadata: {
                  hideArrows: false,
                },
              }));
            }

            clone.config.board.shapes = clone.config.board.shapes.map((cs) => {
              if (cs.id === id) {
                return {
                  ...cs,
                  ...shape,
                  metadata: {
                    ...shape.metadata,
                  },
                };
              }
              return cs;
            });

            if (step.type === StepType.COMBINE) {
              promise = new Promise((res) => {
                resolvers.current.resolveInput = () => {
                  res();
                };
              });
              setShouldCreateCombineMappings(true);
            }

            return clone;
          }

          return step;
        }),
      );

      return promise;
    },
    [selectedStep],
  );

  const {
    value: editableCombineColumn,
    isOpen: isUpdateCombineColumnOpen,
    activate: handleOpenUpdateCombineColumn,
    deactivate: handleCloseUpdateCombineColumn,
  } = useToggleWithValue('');

  const addCombineColumn = useCallback(
    ({
      x: shiftX,
      y: shiftY,
      start,
      end,
      title,
      shapeId,
      skipModalOpen = false,
      shiftLeft = false,
      shiftRight = false,
    }) => {
      const { x, y, scale } = layoutRef.current;

      const boardMaxScale = 10;
      const gap = 10;
      const shapeWidth = 130;
      const shapeHeight = 50;

      const shiftSize = shapeWidth + gap;

      const shiftRightVal = shiftRight ? shiftSize * -1 : 0;
      const shift = shiftLeft ? shiftSize : shiftRightVal;

      const translateX = ((shapeWidth / 2 + shift) / boardMaxScale) * scale;
      const translateY = (shapeHeight / 4 / boardMaxScale) * scale;

      setSteps((s) =>
        s.map((step) => {
          if (step.id === selectedStep) {
            const { shapes, connections } = step.config.board;

            const color = generateHex();

            return {
              ...step,
              config: {
                ...step.config,
                board: {
                  ...step.config.board,
                  shapes: shapes.concat({
                    id: shapeId,
                    height: shapeHeight,
                    width: shapeWidth,
                    metadata: {},
                    title,
                    type: COMBINE_COLUMN,
                    xCoordinate: (x * -1 + shiftX - translateX) / scale,
                    yCoordinate: (y * -1 + shiftY - translateY) / scale,
                  }),
                  connections: connections
                    .concat({
                      connectionId: uuid(),
                      start,
                      end: shapeId,
                      headSize: 4,
                      tailSize: 4,
                      curveness: 0.5,
                      color,
                      endPosition: 'auto',
                      startPosition: ['left', 'right'],
                      uniqKey: Math.random(),
                    })
                    .concat({
                      connectionId: uuid(),
                      end,
                      start: shapeId,
                      headSize: 4,
                      tailSize: 4,
                      curveness: 0.5,
                      color,
                      endPosition: ['left', 'right'],
                      startPosition: 'auto',
                      uniqKey: Math.random(),
                      metadata: {
                        switchTail: true,
                      },
                    }),
                },
              },
            };
          }

          return step;
        }),
      );

      if (!skipModalOpen) {
        handleOpenUpdateCombineColumn(shapeId);
      }
    },
    [selectedStep, handleOpenUpdateCombineColumn],
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (shouldCreateCombineMappings) {
      const inputShape = stepData!.config.board.shapes.find((s) => s.type === INPUT)!;
      const outputShape = stepData!.config.board.shapes.find((s) => s.type === OUTPUT)!;
      const outputShapeCols = outputShape.metadata.columns.map((it) => it.name);

      if (inputShape?.metadata.columns.length) {
        const connectionsToCreate = (inputShape.metadata?.columns || []).reduce((acc, col: string) => {
          const existing = outputShapeCols.find((name) => name.toLowerCase() === col.toLowerCase());

          if (existing) {
            return acc.concat({
              from: col,
              to: existing,
            });
          }

          return acc;
        }, [] as { from: string; to: string }[]);

        const fn = () => {
          const container = layoutRef.current.containerEl!.getBoundingClientRect();

          const configs = connectionsToCreate.map(({ from, to }) => {
            const startId = generateStartConnectionId(inputShape.id, from);
            const endId = generateEndConnectionId(outputShape.id, to);
            const startElPos = document.getElementById(startId)!.getBoundingClientRect();
            const endElPos = document.getElementById(endId)!.getBoundingClientRect();

            const leftX = startElPos.x + startElPos.width;
            const leftY = startElPos.y + startElPos.height / 5;
            const rightX = endElPos.x;
            const rightY = endElPos.y + endElPos.height / 5;

            const midX = (leftX + rightX) / 2;
            const midY = (leftY + rightY) / 2;

            return {
              x: midX - container.x,
              y: midY - container.y,
              start: startId,
              end: endId,
              title: to,
            };
          });
          configs.sort((a, b) => a.y - b.y);

          configs.forEach(({ x, y, title, start, end }, idx) => {
            const shapeId = uuid();

            addCombineColumn({
              x,
              y,
              title,
              start,
              end,
              shapeId,
              skipModalOpen: true,
              shiftLeft: idx % 3 === 0,
              shiftRight: idx % 3 === 2,
            });
          });

          resolvers.current.resolveInput();
          resolvers.current.resolveInput = noop;

          setShouldCreateCombineMappings(false);
        };

        if (connectionsToCreate.length) {
          const timerFn = () => {
            const firstDomNode = generateStartConnectionId(inputShape.id, connectionsToCreate[0].from);

            if (firstDomNode) {
              fn();
            } else {
              timer = setTimeout(timerFn, 50);
            }
          };
          timer = setTimeout(timerFn, 50);
        } else {
          fn();
        }
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [addCombineColumn, shouldCreateCombineMappings, stepData]);

  const updateDefaultValue = useCallback(
    (shapeId: string, col: string, value: string) => {
      setSteps((ps) =>
        ps.map((step) => {
          if (step.id === selectedStep) {
            const { shapes } = step.config.board;

            return {
              ...step,
              config: {
                ...step.config,
                board: {
                  ...step.config.board,
                  shapes: shapes.map((s) => {
                    if (s.id === shapeId) {
                      const clone: Shape = cloneDeep(s);

                      clone.metadata.defaultValues = clone.metadata.defaultValues || {};
                      clone.metadata.defaultValues[col] = value;

                      return clone;
                    }

                    return s;
                  }),
                },
              },
            };
          }

          return step;
        }),
      );
    },
    [selectedStep],
  );

  const {
    value: selectedImportShape,
    isOpen: isImportSourceModalOpen,
    activate: openImportSource,
    deactivate: closeImportSource,
  } = useToggleWithValue('');

  const {
    value: editableTansformation,
    isOpen: isUpdateTransformationModalOpen,
    activate: handleOpenUpdateTransformation,
    deactivate: handleCloseUpdateTransformation,
  } = useToggleWithValue('');

  const [isBlendConfigModalOpen, { activate: openBlendConfigModal, deactivate: closeBlendConfigModal }] =
    useToggle(false);

  useEffect(() => {
    const data = {
      steps,
      selectedStep,
    };

    if (!latestMappingsLoading) {
      const theSame = compareObjects(latestMappingsBoardConfig.steps, data.steps);
      setDraft(!theSame);

      if (templateId) {
        Storage.set(storageKey, JSON.stringify(data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, selectedStep, latestMappingsLoading, latestMappingsBoardConfig]);

  const {
    value: currentAddColumn,
    isOpen: isAddColumnTypeModalOpen,
    activate: openAddColumnTypeModal,
    deactivate: closeAddColumnTypeModal,
  } = useToggleWithValue('');

  const validateDefaultValues = useCallback(() => {
    setSteps((cs) => {
      return cs.map((step) => {
        if (step.id === selectedStep) {
          const clone: Step = cloneDeep(step);

          const {
            config: {
              board: { shapes, connections },
            },
          } = clone;

          const inputShapes = shapes.filter((s) => s.type === INPUT);
          const outputShape = shapes.find((s) => s.type === OUTPUT);

          inputShapes.forEach((s) => {
            if (s.metadata.defaultValues) {
              delete s.metadata.defaultValues;
            }
          });

          if (outputShape) {
            const currentKeys = Object.keys(outputShape.metadata.defaultValues || {});

            currentKeys.forEach((k) => {
              if (!connections.find((c) => c.end === generateEndConnectionId(outputShape.id, k))) {
                delete outputShape.metadata.defaultValues[k];
              }
            });
          }

          return clone;
        }

        return step;
      });
    });
  }, [selectedStep]);

  const deleteCombineColumn = useCallback(
    (shapeId: string) => {
      setSteps((ps) =>
        ps.map((step) => {
          if (step.id === selectedStep) {
            const clone: Step = cloneDeep(step);

            clone.config.board.shapes = clone.config.board.shapes.filter((s) => s.id !== shapeId);

            clone.config.board.connections = clone.config.board.connections.filter(
              (c) => c.start !== shapeId && c.end !== shapeId,
            );

            return clone;
          }

          return step;
        }),
      );
    },
    [selectedStep],
  );

  return (
    <WizzardHandlersContext.Provider
      value={useMemo(
        () => ({
          createStep,
          deleteStep,
          selectStep,
          setupInputShape: handleSetupInputShape,
          updateShape: handleShapeUpdate,
          openImportSourceModal: openImportSource,
          closeImportSourceModal: closeImportSource,
          openUpdateTransformationModal: handleOpenUpdateTransformation,
          closeUpdateTransformationModal: handleCloseUpdateTransformation,
          openUpdateCombineModalModal: handleOpenUpdateCombineColumn,
          closeUpdateCombineModalModal: (arg) => {
            if (!arg?.skipDelete) {
              const shape = stepData!.config.board.shapes.find((s) => s.id === editableCombineColumn)!;

              if (!shape.title) {
                deleteCombineColumn(editableCombineColumn);
              }
            }

            handleCloseUpdateCombineColumn();
          },
          updateDraggableItem: setDraggableItem,
          createConnection: (start: ConnectionPoint, end: ConnectionPoint) => {
            const connectionId = uuid();

            if ([StepType.TRANSFORM, StepType.BLEND].includes(stepData!.type)) {
              updateDefaultValue(end.parentId, end.title, '');
            }

            if (stepData!.type === StepType.COMBINE) {
              const startId = generateStartConnectionId(start.parentId, start.title);
              const endId = generateEndConnectionId(end.parentId, end.title);
              const startElPos = document.getElementById(startId)!.getBoundingClientRect();
              const endElPos = document.getElementById(endId)!.getBoundingClientRect();

              const leftX = startElPos.x + startElPos.width;
              const leftY = startElPos.y + startElPos.height / 5;
              const rightX = endElPos.x;
              const rightY = endElPos.y + endElPos.height / 5;

              const midX = (leftX + rightX) / 2;
              const midY = (leftY + rightY) / 2;
              const container = layoutRef.current.containerEl!.getBoundingClientRect();

              const shapeId = uuid();

              addCombineColumn({
                x: midX - container.x,
                y: midY - container.y,
                title: '',
                start: startId,
                end: endId,
                shapeId,
              });
            } else {
              setSteps((s) =>
                s.map((step) => {
                  if (step.id === selectedStep) {
                    return {
                      ...step,
                      config: {
                        ...step.config,
                        board: {
                          ...step.config.board,
                          connections: step.config.board.connections.concat({
                            connectionId,
                            start: generateStartConnectionId(start.parentId, start.title),
                            end: generateEndConnectionId(end.parentId, end.title),
                            headSize: 4,
                            tailSize: 4,
                            curveness: 0.5,
                            color: generateHex(),
                            endPosition: ['left', 'right'],
                            startPosition: ['left', 'right'],
                            uniqKey: Math.random(),
                            metadata: {
                              hideArrows: stepData!.type === StepType.COMBINE,
                            },
                          }),
                        },
                      },
                    };
                  }

                  return step;
                }),
              );
            }
          },
          addTransformation: ({ x: shiftX, y: shiftY, connectionId, type, config }) => {
            const { x, y, scale } = layoutRef.current;

            const boardMaxScale = 10;
            const shapeWidth = 130;
            const shapeHeight = 50;

            const translateX = (shapeWidth / 2 / boardMaxScale) * scale;
            const translateY = (shapeHeight / 4 / boardMaxScale) * scale;

            const newShapeId = uuid();

            setSteps((s) =>
              s.map((step) => {
                if (step.id === selectedStep) {
                  const { shapes, connections } = step.config.board;
                  const currentConnection = connections.find((c) => c.connectionId === connectionId)!;

                  return {
                    ...step,
                    config: {
                      ...step.config,
                      board: {
                        ...step.config.board,
                        shapes: shapes.concat({
                          id: newShapeId,
                          height: shapeHeight,
                          width: shapeWidth,
                          metadata: { type, config },
                          title: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
                          type: TRANSFORMATION,
                          xCoordinate: (x * -1 + shiftX - translateX) / scale,
                          yCoordinate: (y * -1 + shiftY - translateY) / scale,
                        }),
                        connections: connections
                          .filter((c) => c.connectionId !== connectionId)
                          .concat({
                            connectionId: uuid(),
                            start: currentConnection.start,
                            end: newShapeId,
                            headSize: 4,
                            tailSize: 4,
                            curveness: 0.5,
                            color: currentConnection?.color,
                            endPosition: 'auto',
                            startPosition: currentConnection.start.includes('start') ? ['left', 'right'] : 'auto',
                            uniqKey: Math.random(),
                          })
                          .concat({
                            connectionId: uuid(),
                            start: newShapeId,
                            end: currentConnection.end,
                            headSize: 4,
                            tailSize: 4,
                            curveness: 0.5,
                            color: currentConnection?.color,
                            endPosition: currentConnection.end.includes('end') ? ['left', 'right'] : 'auto',
                            startPosition: 'auto',
                            uniqKey: Math.random(),
                          }),
                      },
                    },
                  };
                }

                return step;
              }),
            );
          },
          resetInputCard: () => {
            setSteps((ps) =>
              ps.map((step) => {
                if (step.id === selectedStep) {
                  const clone: Step = cloneDeep(step);

                  clone.config.board.connections = [];
                  clone.config.board.shapes = [
                    clone.config.board.shapes.find((s) => s.type === OUTPUT)!,
                    getDefaultSourceShape(),
                  ];

                  return clone;
                }

                return step;
              }),
            );
          },
          updateTransformation: (shapeId, type, config) => {
            setSteps((ps) =>
              ps.map((step) => {
                if (step.id === selectedStep) {
                  const clone: Step = cloneDeep(step);

                  clone.config.board.shapes = clone.config.board.shapes.map((cs) => {
                    if (cs.id === shapeId) {
                      cs.title = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
                      cs.metadata.type = type;
                      cs.metadata.config = config;

                      return cs;
                    }
                    return cs;
                  });
                  return clone;
                }

                return step;
              }),
            );
          },
          deleteTransformation: (shapeId: string) => {
            setSteps((ps) =>
              ps.map((step) => {
                if (step.id === selectedStep) {
                  const clone: Step = cloneDeep(step);

                  clone.config.board.shapes = clone.config.board.shapes.filter((s) => s.id !== shapeId);

                  let start = '';
                  let startPosition: string | string[] = '';
                  let end = '';
                  let endPosition: string | string[] = '';
                  let color = '';

                  clone.config.board.connections = clone.config.board.connections
                    .reduce((acc, cur) => {
                      if (cur.start === shapeId) {
                        end = cur.end;
                        endPosition = cur.endPosition;
                        return acc;
                      }

                      if (cur.end === shapeId) {
                        start = cur.start;
                        startPosition = cur.startPosition;
                        color = cur.color;

                        return acc;
                      }

                      return acc.concat(cur);
                    }, [] as Connection[])
                    .concat({
                      connectionId: uuid(),
                      start,
                      end,
                      headSize: 4,
                      tailSize: 4,
                      curveness: 0.5,
                      color,
                      endPosition,
                      startPosition,
                      uniqKey: Math.random(),
                    });
                  return clone;
                }

                return step;
              }),
            );
          },
          deleteConnectionChain: (connectionId: string) => {
            setSteps((prevSteps) =>
              prevSteps.map((step) => {
                if (step.id === selectedStep) {
                  return deleteConnectionChainFromStep(cloneDeep(step), connectionId);
                }

                return step;
              }),
            );
            validateDefaultValues();
          },
          updateStepConfig: (stepConfig: Partial<StepConfig>) => {
            setSteps((ps) =>
              ps.map((s) => {
                if (s.id === selectedStep) {
                  return {
                    ...s,
                    config: {
                      ...s.config,
                      ...stepConfig,
                    },
                  };
                }
                return s;
              }),
            );
          },
          updateDefaultValue,
          openBlendConfigModal,
          closeBlendConfigModal,
          openAddColumnTypeModal,
          closeAddColumnTypeModal,
          resetState: () => {
            setSteps(latestMappingsBoardConfig?.steps || []);
            setSelectedStep(latestMappingsBoardConfig?.selectedStep || '');
            Storage.remove(storageKey);
          },
          setState: (nextSteps: Step[], nextSelectedStep: string) => {
            setSteps(nextSteps);
            setSelectedStep(nextSelectedStep);
          },
          openEditDefaultValueModal,
          closeEditDefaultValueModal,

          addCombineColumn,
          updateCombineColumn: (shapeId, title) => {
            setSteps((ps) =>
              ps.map((step) => {
                if (step.id === selectedStep) {
                  const clone: Step = cloneDeep(step);

                  clone.config.board.shapes = clone.config.board.shapes.map((cs) => {
                    if (cs.id === shapeId) {
                      cs.title = title;

                      return cs;
                    }
                    return cs;
                  });
                  return clone;
                }

                return step;
              }),
            );
          },
          deleteCombineColumn,
          changeView: setView,
          publish,
        }),
        [
          publish,
          addCombineColumn,
          closeAddColumnTypeModal,
          closeBlendConfigModal,
          closeEditDefaultValueModal,
          closeImportSource,
          createStep,
          deleteCombineColumn,
          deleteStep,
          editableCombineColumn,
          handleCloseUpdateCombineColumn,
          handleCloseUpdateTransformation,
          handleOpenUpdateCombineColumn,
          handleOpenUpdateTransformation,
          handleSetupInputShape,
          handleShapeUpdate,
          latestMappingsBoardConfig?.selectedStep,
          latestMappingsBoardConfig?.steps,
          openAddColumnTypeModal,
          openBlendConfigModal,
          openEditDefaultValueModal,
          openImportSource,
          selectStep,
          selectedStep,
          stepData,
          updateDefaultValue,
          validateDefaultValues,
          storageKey,
        ],
      )}
    >
      <WizzardContext.Provider
        value={useMemo(
          () => ({
            currentDefaultValueConfig,
            isEditDefaultValueModalOpen,
            baseInfoLoading: loading || latestMappingsLoading,
            isAddColumnTypeModalOpen,
            isImportSourceModalOpen,
            layoutRef,
            projectId,
            connection,
            templateId,
            selectedStep,
            steps,
            stepData,
            selectedImportShape,
            isUpdateTransformationModalOpen,
            editableTansformation,
            draggableItem,
            isBlendConfigModalOpen,
            currentAddColumn,
            isDraft: draft,
            editableCombineColumn,
            isUpdateCombineColumnOpen,
            jsonConfigs: {
              config: latestMappings?.config,
              mappings: latestMappings?.mappings,
            },
            latestMappingsBoardConfig,
            view,
            publishing,
          }),
          [
            view,
            publishing,
            latestMappingsBoardConfig,
            latestMappings?.config,
            latestMappings?.mappings,
            connection,
            currentAddColumn,
            currentDefaultValueConfig,
            draft,
            latestMappingsLoading,
            draggableItem,
            editableCombineColumn,
            editableTansformation,
            isAddColumnTypeModalOpen,
            isBlendConfigModalOpen,
            isEditDefaultValueModalOpen,
            isImportSourceModalOpen,
            isUpdateCombineColumnOpen,
            isUpdateTransformationModalOpen,
            loading,
            projectId,
            selectedImportShape,
            selectedStep,
            stepData,
            steps,
            templateId,
          ],
        )}
      >
        {children}
      </WizzardContext.Provider>
    </WizzardHandlersContext.Provider>
  );
};

export default memo(WizzardProvider);
