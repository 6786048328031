import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';

import { LOAD_BOARD_ADDITIONAL_CONFIG, UPDATE_FLOW_SOLUTION_CHECKLIST_SHAPE } from '../gql';

export const useUpdateFlowSolutionChecklistShape = (boardId: string, projectId: string, connection: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateShapeData, { loading, error }] = useMutation(UPDATE_FLOW_SOLUTION_CHECKLIST_SHAPE, {
    refetchQueries: [LOAD_BOARD_ADDITIONAL_CONFIG],
    update(cache, { data: { updateFlowSolutionChecklistShape } }) {
      cache.modify({
        fields: {
          flowSolutionBoardBySolutionId(currentBoard) {
            if (currentBoard.boardId === boardId) {
              return {
                ...currentBoard,
                shapes: currentBoard.shapes.map((s) => {
                  if (s.shapeId === updateFlowSolutionChecklistShape.shapeId) {
                    return updateFlowSolutionChecklistShape;
                  }

                  return s;
                }),
              };
            }

            return currentBoard;
          },
        },
      });
    },
  });

  const handleUpdate = useCallback(
    (onSuccess, data, { checklistItems }) => {
      return updateShapeData({
        variables: {
          connection,
          projectId,
          boardId,
          shapeId: data.shapeId,
          title: data.title,
          metadata: JSON.stringify(data.metadata),
          checklistItems,
        },
      })
        .then(onSuccess)
        .catch(() => {
          enqueueSnackbar('Unable to update card. Please, try again later', BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [updateShapeData, enqueueSnackbar, boardId, projectId, connection],
  );

  return {
    update: handleUpdate,
    loading,
    error,
  };
};
