import { combineReducers } from 'redux';

import status from './status';
import state from './state';
import user from './user';

export default combineReducers({
  status,
  state,
  user,
});
