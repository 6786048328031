import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { useLoadHdfsCategories, useLoadHdfsTemplatesPerCategory } from 'modules/data-stores/hooks';
import { useLoadDataTemplateCurrentData } from 'modules/data-stores/hooks/useLoadDataTemplateCurrentData';
import { Button, CircularProgress } from '@mui/material';
import {
  ITEM_HEIGHT,
  MIN_CARD_HEIGHT,
  PADDING_TOP,
} from 'modules/shared/components/DraggableShapes/DigitalthreadsWizzard/SourceDtData';
import { useStyles } from './styles';
import { WizzardContext, WizzardHandlersContext } from '../wizzard-context/context';
import { useLoadFileColumns } from '../hooks/useLoadFileColumns';

const MODAL_ID = 'import-source-cols-modal';

const Modal = () => {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  const { isImportSourceModalOpen, projectId, connection, selectedImportShape } = useContext(WizzardContext);
  const { closeImportSourceModal, setupInputShape } = useContext(WizzardHandlersContext);

  useEffect(() => {
    if (isImportSourceModalOpen) {
      setSelectedCategory('');
      setSelectedTemplate('');
      setSelectedItem('');
    }
  }, [isImportSourceModalOpen]);

  const { loading: categoriesLoading, categories } = useLoadHdfsCategories(
    { connection, projectId, isPrivate: true },
    !isImportSourceModalOpen,
  );
  const { loading: templatesLoading, templates } = useLoadHdfsTemplatesPerCategory(
    { connection, projectId, isPrivate: true, category: selectedCategory },
    !isImportSourceModalOpen,
  );

  const { loading: itemsLoading, items } = useLoadDataTemplateCurrentData(
    { connection, projectId, isPrivate: true, category: selectedCategory, dataTemplate: selectedTemplate },
    !isImportSourceModalOpen,
  );

  useEffect(() => {
    setSelectedItem(items?.[0] || '');
  }, [items]);

  const [resolving, setResolving] = useState(false);

  const { load, loading } = useLoadFileColumns({ connection, projectId });

  const update = async () => {
    setResolving(true);
    const cols = await load(selectedCategory, selectedTemplate, selectedItem);

    await setupInputShape(selectedImportShape, {
      title: selectedTemplate,
      height: PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * cols.length,
      metadata: {
        columns: cols,
        category: selectedCategory,
        template: selectedTemplate,
        item: selectedItem,
        defaultValues: {},
      },
    });

    setResolving(false);
    closeImportSourceModal();
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeImportSourceModal}
      aria-labelledby={MODAL_ID}
      open={isImportSourceModalOpen}
    >
      <Title id={MODAL_ID} onClose={closeImportSourceModal}>
        Import columns
      </Title>
      <Content className={classes.dialogContent}>
        <Select
          id="category"
          value={categories.filter((name) => name === selectedCategory)}
          options={categories.map((name) => ({ label: name, value: name }))}
          loading={categoriesLoading}
          className={classes.select}
          disableClear
          onChange={(val) => {
            setSelectedCategory(val[0]);
            setSelectedTemplate('');
            setSelectedItem('');
          }}
        />
        <Select
          id="template"
          value={[selectedTemplate]}
          options={templates.map((name) => ({ label: name, value: name }))}
          loading={templatesLoading}
          disabled={!selectedCategory}
          className={classes.select}
          onChange={(val) => {
            setSelectedTemplate(val[0]);
            setSelectedItem('');
          }}
          disableClear
        />
        <Select
          id="item"
          value={[selectedItem]}
          options={items.map((name) => ({ label: name, value: name }))}
          loading={itemsLoading}
          disabled={!selectedCategory || !selectedTemplate}
          className={classes.select}
          onChange={(val) => setSelectedItem(val[0])}
          disableClear
        />
      </Content>
      <Actions>
        <Button variant="contained" color="primary" onClick={update} disabled={!selectedItem}>
          {loading || resolving ? <CircularProgress className={classes.progress} size={24} /> : 'Import'}
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(Modal);
