import { ApolloLink, Observable } from '@apollo/client';

const connections: { [key: string]: any } = {};

export const createCancelRequestLink = () => {
  const cancelRequestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        const context = operation.getContext();
        let connectionHandle;

        const cleanUp = () => {
          connectionHandle?.unsubscribe();
          delete connections[context.requestId];
        };

        connectionHandle = forward(operation).subscribe({
          next: (...arg) => observer.next(...arg),
          error: (...arg) => {
            cleanUp();
            observer.error(...arg);
          },
          complete: (...arg) => {
            cleanUp();
            observer.complete(...arg);
          },
        });

        if (context.requestId) {
          const controller = new AbortController();
          controller.signal.onabort = cleanUp;
          operation.setContext({
            ...context,
            fetchOptions: {
              signal: controller.signal,
              ...context?.fetchOptions,
            },
          });

          if (connections[context.requestId]) {
            connections[context.requestId]?.abort();
          }

          connections[context.requestId] = controller;
        }

        return connectionHandle;
      }),
  );

  return cancelRequestLink;
};
