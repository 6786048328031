import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  pending: {
    padding: '16px',
  },
  container: {
    flexGrow: 1,
    display: 'inline-block',
  },
  editButton: {
    backgroundColor: BorNeoColors.primaryColor1,
    color: BorNeoColors.primaryColor4,
    float: 'left',
    '&:hover': {
      backgroundColor: BorNeoColors.primaryColor2,
    },
  },
  deleteButton: {
    float: 'right',
    backgroundColor: BorNeoColors.primaryColor1,
    color: BorNeoColors.primaryColor4,
    '&:hover': {
      backgroundColor: BorNeoColors.primaryColor2,
    },
  },
}));
