import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { Interceptors } from './types';

let instance: AxiosInstance;

const configure = (requestConfig: AxiosRequestConfig, interceptors: Interceptors): void => {
  instance = axios.create({
    headers: requestConfig.headers || {},
    baseURL: requestConfig.baseURL || '',
    timeout: requestConfig.timeout || 0,
  });

  instance.interceptors.request.use(interceptors.request.success, interceptors.request.error);
  instance.interceptors.response.use(interceptors.response.success, interceptors.response.error);
};

export { configure, instance as default };
