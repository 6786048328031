import { memo } from 'react';
import { Grid } from '@material-ui/core';

import Logo from 'modules/shared/components/Logo';

import { useStyles } from './styles';

const UnAuthLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.image} />
      <Logo />
      {children}
      <div className={classes.information}>
        <div className={classes.wrapper}>
          <div className={classes.subWrapper}>
            <h2 className={classes.subtitle}>The Workpacks DELTA platform for construction</h2>
            <Grid container spacing={3}>
              <Grid item sm={12} md={4} className={classes.item}>
                <h3 className={classes.itemTitle}>Where awp meets AI</h3>
                <p className={classes.itemContent}>
                  Here's a powerful, easier way to do AWP. Created specifically for the capital projects industry, this
                  pioneering digital toolkit leverages the power of artificial intelligence (AI), helping you accelerate
                  the value of AWP through innovative capabilities.
                </p>
              </Grid>
              <Grid item sm={12} md={4} className={classes.item}>
                <h3 className={classes.itemTitle}>The technology advantage</h3>
                <p className={classes.itemContent}>
                  The Workpacks DELTA Platform for Construction connects people, processes, and technologies through a
                  continually evolving model of AWP best practices, helping you more easily reap the full benefits of
                  your AWP program.
                </p>
              </Grid>
              <Grid item sm={12} md={4} className={classes.item}>
                <h3 className={classes.itemTitle}>Customize playbooks, process maps</h3>
                <p className={classes.itemContent}>
                  This information-rich toolkit makes AWP come alive for your organization. Here you'll find interactive
                  playbooks and detailed work process maps customized by roles for the team right within your project
                  environment.
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(UnAuthLayout);
