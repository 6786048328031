import { useSearch } from 'modules/shared/hooks/url';
import { unwrapProject } from 'modules/shared/utils/project';

export const useProjectInfoFromSearch = () => {
  const { get } = useSearch();

  const project = get('project');
  const { connection, projectId } = unwrapProject(get('project'));

  return {
    projectId,
    connection,
    wrappedProject: project,
  };
};
