import React from 'react';
import classNames from 'classnames';

import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MaterialInput from '@material-ui/core/Input';

import { useStyles } from './styles';

export type InputProps = {
  labelText?: React.ReactNode;
  labelProps?: InputLabelProps;
  id?: string;
  inputProps?: Record<string, any>;
  formControlProps?: FormControlProps;
  error?: boolean;
  success?: boolean;
  white?: boolean;
  helpText?: React.ReactNode;
  removeMargin?: boolean;
};

const Input = ({
  formControlProps,
  labelText,
  id,
  labelProps,
  inputProps,
  error,
  white,
  success,
  helpText,
  removeMargin,
}: InputProps) => {
  const classes = useStyles();

  const labelClasses = classNames({
    [` ${classes.labelRootError}`]: error,
    [` ${classes.labelRootSuccess}`]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.whiteUnderline]: white,
  });

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  const formControlClasses = classNames(
    formControlProps?.className,
    classes.formControl,
    removeMargin ? '' : classes.formControlMargin,
  );

  const helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel className={labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <MaterialInput
        classes={{
          input: inputClasses,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
      />
      {helpText !== undefined ? (
        <FormHelperText id={`${id}-text`} className={helpTextClasses}>
          {helpText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default Input;
