import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { Alert, Box, Button, CircularProgress, Collapse, Grid } from '@mui/material';
import { memo, useContext, useEffect, useState, useMemo } from 'react';
import Select from 'modules/shared/components/Select2';
import Input from 'modules/shared/components/Input';

import { DataStores, Fields } from '../contants';

import { useStyles } from './styles';
import { BnSettingsState, DataStoresState, DataStoresStateHandlers } from '../context/DataStoresState';
import { useCheckDataStoresConnection } from '../hooks/useCheckDataStoresConnection';
import { useDataStoreConnectionActions } from '../hooks/useDataStoreConnectionActions';

const MODAL_ID = 'update-connection-modal';
const FORM_ID = 'update-connection-modal-form';

const UpdateConnectionModal = () => {
  const classes = useStyles();

  const {
    projectId,
    connection,
    updateModalOpen: isOpen,
    updateConnectionId,
    dataStoreConnections,
    dataStoreConnectionsLoading,
  } = useContext(DataStoresState);

  const { settingsLoading } = useContext(BnSettingsState);
  const { setConnection, closeUpdateModal: close, setUpdateConnection } = useContext(DataStoresStateHandlers);

  const loading = settingsLoading || dataStoreConnectionsLoading;

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [validConnection, setValidConnection] = useState<boolean | null>(null);

  const connectionToUpdate = useMemo(
    () => dataStoreConnections.find((c) => c.connectionId === updateConnectionId),
    [dataStoreConnections, updateConnectionId],
  );

  const [config, setConfig] = useState<Record<string, string>>({});

  const { check, loading: checking } = useCheckDataStoresConnection({ projectId, connection });

  const { update, updating } = useDataStoreConnectionActions({ projectId, connection });

  useEffect(() => {
    if (isOpen && connectionToUpdate) {
      setName(connectionToUpdate.name);
      setType(connectionToUpdate.type);
      setConfig(JSON.parse(connectionToUpdate.config));
    }
  }, [isOpen, connectionToUpdate]);

  useEffect(() => {
    setValidConnection(null);
  }, [config]);

  const Component = Fields[type];

  const handleCheck = async () => {
    const isValid = await check(type, JSON.stringify(config));

    setValidConnection(isValid);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={close}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={close}>
        Update {connectionToUpdate ? `"${connectionToUpdate.name}"` : ''}
      </Title>

      <Content className={classes.dialogContent}>
        <Box
          component="form"
          id={FORM_ID}
          onSubmit={(e) => {
            e.preventDefault();

            update(
              (data) => {
                setConnection(data.connectionId);
                close();
                setUpdateConnection('');
              },
              updateConnectionId,
              {
                name,
                type,
                config: JSON.stringify(config),
              },
            );
          }}
        >
          {loading && (
            <div className={classes.loading}>
              <CircularProgress className={classes.spinner} size={50} />
            </div>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                removeMargin
                labelText="Name"
                id="name"
                labelProps={{
                  shrink: true,
                }}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                inputProps={{
                  value: name,
                  required: true,
                  onChange: (e) => setName(e.target.value),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                id="connection-type"
                labelText="Type"
                value={[type]}
                options={DataStores}
                onChange={(value) => {
                  setType(value[0]);
                  setConfig({});
                }}
              />
            </Grid>

            {Component && (
              <Component
                values={config}
                onValueChange={(key: string, value: string) => {
                  setConfig((prev) => ({ ...prev, [key]: value }));
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Collapse in={validConnection === false}>
              <Alert className={classes.alert} severity="error">
                Connection details is not valid
              </Alert>
            </Collapse>
          </Grid>
        </Box>
      </Content>

      <Actions>
        <Button variant="contained" color="primary" onClick={handleCheck} disabled={false}>
          {checking ? <CircularProgress className={classes.progress} size={24} /> : 'Check'}
        </Button>
        <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={!validConnection}>
          {updating ? <CircularProgress className={classes.progress} size={24} /> : 'Update'}
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(UpdateConnectionModal);
