import { useApolloClient } from '@apollo/client';
import { useState } from 'react';

import { CHECK_DATA_STORES_CONNECTION } from '../gql';

export const useCheckDataStoresConnection = ({ projectId, connection }) => {
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);

  const check = async (type: string, config: string) => {
    setLoading(true);
    try {
      const response = await client.query({
        query: CHECK_DATA_STORES_CONNECTION,
        variables: {
          projectId,
          connection,
          type,
          config,
        },
        fetchPolicy: 'network-only',
      });

      const isAvailable = response.data?.checkDataStoresConnection || false;

      return isAvailable;
    } catch (e) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    check,
    loading,
  };
};
