import instance from 'modules/rest/axios';
import { getApiBaseUrl } from 'modules/shared/utils';

export const resetPassword = (email: string) => {
  return instance({
    method: 'post',
    url: `${getApiBaseUrl()}/auth/reset-password`,
    data: {
      email,
    },
  });
};
