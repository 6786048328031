/* eslint-disable import/no-extraneous-dependencies */
import { onError } from 'apollo-link-error';
import { fromPromise } from 'apollo-link';
import { authThunks } from 'modules/auth';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloLink } from '@apollo/client';
import { checkCurrentSession } from 'configure/azure-ad-b2c';

type FuncType = (...args: any[]) => any;

let isRefreshing = false;
let pendingRequests = [] as Array<FuncType>;

const startRefreshing = () => {
  isRefreshing = true;
};

const finishRefreshing = () => {
  isRefreshing = false;
};

const clearPendingRequests = () => {
  pendingRequests = [];
};

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => callback());
  clearPendingRequests();
};

const pushPendingRequest = () => {
  return new Promise((resolve) => {
    pendingRequests.push(() => {
      resolve(null);
    });
  });
};

const loadRefreshToken = async (dispatch) => {
  await checkCurrentSession(dispatch, true);
};

const checkSubscription = (operation) => {
  const definition = getMainDefinition(operation.query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

export const createErrorLink = (dispatch): ApolloLink => {
  const logout = async () => {
    await dispatch(authThunks.logout());
  };

  const errorLink: unknown = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          case 'UNAUTHENTICATED':
            let forward$;

            if (!isRefreshing) {
              startRefreshing();
              forward$ = fromPromise(
                loadRefreshToken(dispatch)
                  .then(() => {
                    resolvePendingRequests();

                    return true;
                  })
                  .catch(async () => {
                    clearPendingRequests();
                    await logout();
                  })
                  .finally(() => {
                    finishRefreshing();
                  }),
              ).filter((value) => Boolean(value));
            } else {
              forward$ = fromPromise(pushPendingRequest());
            }

            return forward$.flatMap(() => {
              const isSubscription = checkSubscription(operation);

              if (isSubscription) {
                const subscriptionOperationObserver = forward(operation);

                subscriptionOperationObserver.subscribe(async ({ errors }) => {
                  if (errors?.find((error) => error.extensions?.code === 'UNAUTHENTICATED')) {
                    clearPendingRequests();
                    await logout();
                  }
                });

                return subscriptionOperationObserver;
              }

              const operationObserver = forward(operation);

              operationObserver.map(async (data) => {
                if (data.errors?.find((error) => error.extensions?.code === 'UNAUTHENTICATED')) {
                  clearPendingRequests();
                  await logout();
                }
              });

              return operationObserver;
            });
          default: {
            return null;
          }
        }
      }
    }
  });

  return errorLink as ApolloLink;
};
