import { gql } from '@apollo/client';

export const DOWNLOAD_SUBSCRIPTION = gql`
  subscription DownloadSubscription($id: String!) {
    downloadSubscription(user_id: $id) {
      archiveId
      fileName
      error
    }
  }
`;
