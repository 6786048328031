import { memo, useCallback, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { AuthRoutesPath } from 'main/routes';
import { useSearch } from 'modules/shared/hooks/url';
import ProjectSelect, { hiddenPaths } from 'modules/shared/components/ProjectSelect';

const additionalChangeFieldsValue = {};
const resetPagePathList = [AuthRoutesPath.ExecutionLog, AuthRoutesPath.DataValidations];

type Props = {
  additionalChangeFields?: Record<string, string>;
  onChange?: (id: string) => void;
};

const UrlProjectSelect = ({ additionalChangeFields = additionalChangeFieldsValue, onChange }: Props) => {
  const { get, set } = useSearch();
  const route = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (hiddenPaths.includes(pathname)) {
      set({
        project: '',
        ...additionalChangeFields,
      });
    }
  }, [pathname, set, additionalChangeFields]);

  const project = get('project');

  const handlePaginationReset = useCallback(() => {
    return resetPagePathList.includes(route.path as AuthRoutesPath) ? { page: '' } : {};
  }, [route.path]);

  const handleProjectChange = useCallback(
    (val) => {
      if (onChange) {
        onChange(val);
      }
      set({
        project: val,
        ...handlePaginationReset(),
        ...additionalChangeFields,
      });
    },
    [onChange, set, handlePaginationReset, additionalChangeFields],
  );

  return <ProjectSelect value={project} onChange={handleProjectChange} />;
};

UrlProjectSelect.defaultProps = {
  additionalChangeFields: additionalChangeFieldsValue,
};

export default memo(UrlProjectSelect);
