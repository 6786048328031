import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { displayTimeValue } from 'modules/shared/utils/displayTimeValue';
import { useStyles } from './styles';

const range = (val: number) => new Array(val).fill(null).map((_, i) => i);
const hours = range(24);
const mins = range(60);

const TimePicker = ({ hour, onAtHourChange, minute, onAtMinuteChange, disabled }) => {
  const classes = useStyles();
  return (
    <>
      <FormControl disabled={disabled} className={classes.select}>
        <InputLabel id="hour-picker-label" shrink>
          Hour
        </InputLabel>
        <Select
          labelId="hour-picker-label"
          MenuProps={{ classes: { paper: classes.menuItems } }}
          value={hour}
          onChange={onAtHourChange}
        >
          {hours.map((val) => (
            <MenuItem key={val} value={val}>
              {displayTimeValue(val)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl disabled={disabled} className={classes.select}>
        <InputLabel id="minute-picker-label" shrink>
          Minute
        </InputLabel>
        <Select
          labelId="minute-picker-label"
          MenuProps={{ classes: { paper: classes.menuItems } }}
          value={minute}
          onChange={onAtMinuteChange}
        >
          {mins.map((val) => (
            <MenuItem key={val} value={val}>
              {displayTimeValue(val)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default TimePicker;
