import { memo, useMemo } from 'react';

import { Box, Chip } from '@material-ui/core';
import CustomStore from 'devextreme/data/custom_store';
import { useSearch } from 'modules/shared/hooks/url';
import EditingWrapper from 'modules/shared/components/Grid/wrappers/EditingWrapper';
import ToolbarWrapper from 'modules/shared/components/Grid/wrappers/ToolbarWrapper';
import Grid from 'modules/shared/components/Grid';
import { upperFirst } from 'lodash';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { GridColumn } from 'modules/shared/components/Grid/types';
import { useLoadDataTemplateByType, useLoadDataTemplateFieldsDefinitions, useLoadDataTemplatesData } from '../hooks';

import { useStyles } from './styles';

const DataGrid = ToolbarWrapper(EditingWrapper(Grid));

const DtDataView = () => {
  const classes = useStyles();
  const { get } = useSearch();
  const { projectId, connection } = useProjectInfoFromSearch();
  const dtType = get('type');

  const { dtTemplateByType } = useLoadDataTemplateByType(connection, dtType);

  const templateId = get('templateId') || dtTemplateByType?.[0]?.templateId;

  const { fields } = useLoadDataTemplateFieldsDefinitions(templateId, false, connection);
  const { load } = useLoadDataTemplatesData(templateId, projectId, connection);

  const dataSource = new CustomStore({
    load: (options) => {
      return load({
        order: options.sort && options.sort[0].selector,
        desc: options.sort && options.sort[0].desc,
        limit: options.take,
        offset: options.skip,
        search: options.filter ? JSON.stringify(options.filter) : '',
        group: options.group ? JSON.stringify(options.group) : '',
      });
    },
  });

  const columns: GridColumn[] = useMemo(() => {
    return [
      {
        dataField: 'row_status',
        caption: 'Row Status',
        cellRender: ({ value }) => {
          if (!value) {
            return null;
          }
          let className = '';
          switch (value) {
            case 'Added':
              className = classes.added;
              break;
            case 'Deleted':
              className = classes.deleted;
              break;
            case 'Revised':
              className = classes.revised;
              break;
            case 'Not Changed':
              className = classes.notchanged;
              break;
            default:
              break;
          }
          return (
            <div style={{ width: 100 }}>
              <Chip style={{ display: 'flex' }} label={value} className={className} />
            </div>
          );
        },
      },
      ...fields.map((f) => ({
        dataField: f.name,
        caption: upperFirst(f.name.replace(/_/g, ' ')),
        key: f.name,
        cellRender: ({ value }) => {
          return (
            <div style={{ position: 'relative', height: 16 }}>
              <div style={{ position: 'absolute' }} title={value}>
                {value}
              </div>
            </div>
          );
        },
      })),
    ];
  }, [fields, classes]);

  return (
    <div className={classes.wrapperContainer}>
      <Box className={classes.dataContainer}>
        <DataGrid
          key={`${connection}-${projectId}-${templateId}`}
          dataSource={dataSource}
          gridName="digital-threads-data"
          gridOptions={{
            height: '100%',
            remoteOperations: {
              filtering: true,
              paging: true,
              sorting: true,
              grouping: true,
            },
            wordWrapEnabled: false,
          }}
          columns={columns}
        />
      </Box>
    </div>
  );
};

export default memo(DtDataView);
