import { Auth } from 'modules/rest';
import * as actions from '../actions';

export const loadProfile = () => async (dispatch) => {
  dispatch(actions.authLoadProfileStart());

  try {
    const { data: user } = await Auth.loadProfile();

    dispatch(
      actions.authLoadProfileSuccess({
        id: user.id,
        username: user.username,
        email: user.email,
        role: user.role,
        connections: user.connections,
        adminConnections: user.adminConnections,
      }),
    );
  } catch (error) {
    dispatch(actions.authLoadProfileFailure(error));
    throw error;
  }
};
