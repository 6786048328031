type Param = {
  connection: string;
  projectId: string;
};

const CONNECTOR = '|';

export const wrapProject = (data: Param): string => {
  return `${data.connection}${CONNECTOR}${data.projectId}`;
};

export const unwrapProject = (val: string): Param => {
  const [connection, projectId] = val.split(CONNECTOR);

  return {
    connection,
    projectId,
  };
};
