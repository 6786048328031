import { configure } from 'modules/rest';
import { getApiBaseUrl } from 'modules/shared/utils';
import { API_CALL_TIMEOUT } from 'project-constants';

import getInterceptors from './interceptors';

const defaultHeaders = {
  ContentType: 'application/json',
};

export default (dispatch) => {
  const interceptors = getInterceptors(dispatch);

  return configure(
    {
      headers: defaultHeaders,
      baseURL: getApiBaseUrl(),
      timeout: API_CALL_TIMEOUT,
    },
    interceptors,
  );
};
