import { memo, useEffect } from 'react';
import { ControlledPageSelector } from 'modules/shared/components/page-selector-ui';
import { useSearch } from 'modules/shared/hooks/url';
import { DigitalThreadsPages } from './constants';
import { useStyles } from './styles';

const DigitalThreads = () => {
  const classes = useStyles();

  const { get, set } = useSearch();

  const page = get('page');

  useEffect(() => {
    if (!page) {
      set({
        page: DigitalThreadsPages[0].id,
      });
    }
  }, [page, set]);

  return (
    <div className={classes.container}>
      <ControlledPageSelector
        selectedPage={DigitalThreadsPages.find((p) => p.id === page) || DigitalThreadsPages[0]}
        onChangePage={(newPage) => {
          set({
            page: newPage.id,
          });
        }}
        Pages={DigitalThreadsPages}
      />
    </div>
  );
};

export default memo(DigitalThreads);
