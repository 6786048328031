import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 16,
    textTransform: 'uppercase',
    // color: '#5a5a5a',
  },
}));
