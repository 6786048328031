import { useQuery } from '@apollo/client';

import { LOAD_PROJECTS } from './gql';
import { MyProject } from './types';

export const useLoadProjects = () => {
  const { loading, data, error, refetch } = useQuery(LOAD_PROJECTS);

  const projects: MyProject[] = data?.myProjects || [];

  return {
    loading,
    projects,
    error,
    refetch,
  };
};
