import OnDemand from './Scheduler/OnDemand';
import Minutely from './Scheduler/Minutely';
import Hourly from './Scheduler/Hourly';
import Daily from './Scheduler/Daily';
import Weekly from './Scheduler/Weekly';
import Monthly from './Scheduler/Monthly';

export const SchedulerComponents = {
  on_demand: OnDemand,
  minutely: Minutely,
  hourly: Hourly,
  daily: Daily,
  weekly: Weekly,
  monthly: Monthly,
};

export const SchedulerItems = [
  { key: 'on_demand', value: 'On Demand' },
  { key: 'minutely', value: 'Minutely' },
  { key: 'hourly', value: 'Hourly' },
  { key: 'daily', value: 'Daily' },
  { key: 'weekly', value: 'Weekly' },
  { key: 'monthly', value: 'Monthly' },
];
export const BoardSchedulerItems = [
  { key: 'on_demand', value: 'On Demand' },
  { key: 'hourly', value: 'Hourly' },
  { key: 'daily', value: 'Daily' },
  { key: 'weekly', value: 'Weekly' },
  { key: 'monthly', value: 'Monthly' },
];

export const DaysOfWeek = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
};

export const EVERY_DAY_VALUE = ['0', '0', '0', '*', '*', '*'];
export const EVERY_HOUR_VALUE = ['0', '0', '*/1', '*', '*', '*'];
export const EVERY_MINUTE_VALUE = ['0', '*', '*', '*', '*', '*'];
