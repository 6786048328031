import { ImageList, Typography } from '@material-ui/core';
import { authSelectors } from 'modules/auth';
import { memo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { getImagePath } from 'modules/shared/utils';

import { useLoadConnectionsInfo } from 'modules/shared/hooks/api/connections-info';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import defaultPicture from './assests/base-plant.jpg';

import { ProjectCard } from '../project-card';
import { ProjectCardActionBar } from '../project-card-action-bar';

const ProjectsUI = ({ projects, loading }) => {
  const isAdmin = useSelector(authSelectors.isAdmin);
  const adminConnections = useSelector(authSelectors.getAdminConnections);
  const { connection } = useProjectInfoFromSearch();
  const { fsPath } = useLoadConnectionsInfo();

  return (
    <div>
      <Fragment key={connection}>
        <Typography variant="h5">{connection}</Typography>
        <ImageList rowHeight={210} cols={4} gap={16}>
          {projects?.map((project) => {
            const { id, projectName, image, description, projectConnection } = project;

            return (
              <ProjectCard
                key={id}
                name={projectName}
                id={id}
                image={image ? getImagePath(image, fsPath[projectConnection]) : defaultPicture}
                defaultImage={defaultPicture}
                clickable
                description={description}
              >
                <ProjectCardActionBar
                  isAdmin={isAdmin || adminConnections.includes(projectConnection)}
                  projectId={id}
                  loading={loading}
                />
              </ProjectCard>
            );
          })}
        </ImageList>
      </Fragment>
    </div>
  );
};

export default memo(ProjectsUI);
