import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback, useState } from 'react';
import { IMPORT_WP_PROJECT } from '../gql';

export const useImportWpProject = ({ onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [importWpProject] = useMutation(IMPORT_WP_PROJECT);

  const handleImportProject = useCallback(
    async ({ client, instance, projectId, meta }) => {
      setLoading(true);
      try {
        await importWpProject({
          variables: {
            client,
            env: instance,
            project: projectId,
            meta,
          },
        });
        onSuccess();
        enqueueSnackbar('We will notify you when project will be created', BASE_SUCCESS_NOTIFICATION_OPTIONS);
      } catch (e) {
        enqueueSnackbar('Unable to start project import', BASE_ERROR_NOTIFICATION_OPTIONS);
      } finally {
        setLoading(false);
      }
    },
    [importWpProject, onSuccess, enqueueSnackbar],
  );
  return {
    importProject: handleImportProject,
    loading,
  };
};
