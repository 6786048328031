import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 80vh)',

    minHeight: 'min(calc(100% - 64px), 50vh)',
    overflow: 'initial',

    maxWidth: '70%',
    minWidth: '70%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
    },
  },
  dialogContent: {
    minHeight: 300,
  },
  createButton: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
