import { Divider } from '@material-ui/core';
import { history } from 'configure/browserHistory';
import MenuButton from './MenuButton';
import MenuSelect from './MenuSelect';
import MenuTitle from './MenuTitle';
import MenuLink from './MenuLink';

export const MENU_LINK = 'menu-link';
export const MENU_BUTTON = 'menu-button';
export const MENU_SELECT = 'menu-select';
export const MENU_DIVIDER = 'menu-divider';
export const MENU_TITLE = 'menu-title';

export const Components = {
  [MENU_LINK]: MenuLink,
  [MENU_BUTTON]: MenuButton,
  [MENU_SELECT]: MenuSelect,
  [MENU_DIVIDER]: Divider,
  [MENU_TITLE]: MenuTitle,
};

export const PropsExtractors = {
  [MENU_BUTTON]: (item, { selectedPath, isSidebarOpen }) => ({
    path: item.path,
    disableClick: selectedPath === item.path,
    isSelected: selectedPath.startsWith(item.path),
    isHighlighted: false,
    icon: item.icon,
    label: item.label,
    isOpen: isSidebarOpen,
    onClick: () => {
      history.push(item.path);
    },
  }),
  [MENU_SELECT]: (item, { selectedPath, isSidebarOpen }) => ({
    item,
    isOpen: isSidebarOpen,
    selectedPath,
  }),
  [MENU_DIVIDER]: () => ({}),
  [MENU_TITLE]: (item, { isSidebarOpen }) => ({
    title: item.title,
    visible: isSidebarOpen,
  }),
  [MENU_LINK]: (item, { isSidebarOpen }) => ({
    path: item.path,
    icon: item.icon,
    label: item.label,
    isOpen: isSidebarOpen,
  }),
};
