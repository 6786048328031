import { gql } from '@apollo/client';

export const CREATE_FLOW_CUSTOMIZE = gql`
  mutation CreateFlowCustomize($flowCustomize: FlowCustomizeDto!, $projectId: String!, $connection: String!) {
    createFlowCustomize(flow_customize: $flowCustomize, project_id: $projectId, connection: $connection) {
      bodyFields
      headers
    }
  }
`;

export const UPDATE_FLOW_CUSTOMIZE = gql`
  mutation UpdateFlowCustomize(
    $flowCustomize: FlowCustomizeDto!
    $projectId: String!
    $flowCustomizeId: String!
    $connection: String!
  ) {
    updateFlowCustomize(
      flow_customize: $flowCustomize
      project_id: $projectId
      flow_customize_id: $flowCustomizeId
      connection: $connection
    ) {
      bodyFields
      headers
    }
  }
`;
