import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  axis: {
    '& circle': {
      fill: 'none',
    },
  },
  tooltip: {
    position: 'absolute',
    display: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 3,
    boxShadow: '-3px 3px 15px #888',
    color: '#FFFFFF',
    padding: 6,
  },
  arcOpacity: {
    opacity: 1,
    transition: 'opacity 300ms ease-in-out',
    '&.arc-semi-opacity': {
      opacity: 0.5,
    },
  },

  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  center: {
    position: 'relative',
    flex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
