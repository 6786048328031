import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  disabled: {
    '&:before': {
      borderColor: 'transparent !important',
    },
  },

  underlineError: {
    '&:after': {
      borderColor: BorNeoColors.dangerColor,
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: BorNeoColors.successColor,
    },
  },
  labelRootError: {
    color: `${BorNeoColors.dangerColor} !important`,
  },
  labelRootSuccess: {
    color: `${BorNeoColors.successColor} !important`,
  },
  formControlMargin: {
    margin: '17px 0',
  },
  formControl: {
    position: 'relative',
    verticalAlign: 'unset',
    '& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
      color: '#495057',
    },
  },
  whiteUnderline: {
    '&:hover:not($disabled):before,&:before': {
      backgroundColor: BorNeoColors.primaryColor4,
    },
    '&:after': {
      backgroundColor: BorNeoColors.primaryColor4,
    },
  },
  input: {
    color: '#495057',
    '&,&::placeholder': {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '1.42857',
      opacity: 1,
    },
    '&::placeholder': {
      color: '#AAAAAA',
    },
  },
  whiteInput: {
    '&,&::placeholder': {
      color: BorNeoColors.primaryColor4,
      opacity: 1,
    },
  },
}));
