import { memo, useEffect, useRef, useState } from 'react';
import { Check, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { accentColor9, dangerColor } from 'modules/shared/styles/colors';
import { useStyles } from './styles';

const AddItem = ({ updateHeight, onSubmit, validateColName }) => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const [val, setVal] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState(false);

  useEffect(() => {
    if (editable) {
      inputRef.current?.focus();
    }
  }, [editable]);

  useEffect(() => {
    if (val) {
      setError(!validateColName(val));
    }
  }, [val, validateColName]);

  return (
    <div className={classes.container}>
      {editable && (
        <div className={classes.editableWrapper}>
          <input
            ref={inputRef}
            style={{ borderColor: error ? dangerColor : accentColor9 }}
            className={classes.input}
            value={val}
            placeholder="Add column name"
            onChange={(e) => setVal(e.target.value.replace(/\s/g, '_'))}
          />
          <div
            role="presentation"
            className={classes.addButton}
            onClick={() => {
              if (!error && val.length) {
                onSubmit(val);
                setEditable(false);
                updateHeight(0);
                setVal('');
                setError(false);
              }
            }}
          >
            <Check className={classes.icon} />
          </div>
          <div
            role="presentation"
            className={classes.cancelButton}
            onClick={() => {
              setEditable(false);
              updateHeight(0);
              setVal('');
              setError(false);
            }}
          >
            <Close className={classes.icon} />
          </div>
        </div>
      )}
      <Button
        onClick={() => {
          setEditable(true);
          updateHeight(34);
        }}
        variant="contained"
        fullWidth
        size="small"
      >
        Add column
      </Button>
    </div>
  );
};

export default memo(AddItem);
