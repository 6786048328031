import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    minWidth: 700,
    width: 700,
    display: 'flex',
    gap: 20,
  },
  connection: {
    minWidth: 350,
  },
  project: {
    minWidth: 350,
  },
}));
