import { gql } from '@apollo/client';

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription notifications($id: String!) {
    notifications(user_id: $id) {
      key
      type
      message
      meta
    }
  }
`;
