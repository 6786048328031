import { gql } from '@apollo/client';

export const LOAD_PROJECTS = gql`
  query MyProjects {
    myProjects {
      project {
        projectId
        projectName
        description
        data {
          key
          value
        }
      }
      permission
      projectConnection
    }
  }
`;

export const LOAD_UNLINKED_WP_PROJECTS = gql`
  query LoadUnlinkedWpProjects($client: String!, $env: String!) {
    loadUnlinkedWpProjects(client: $client, env: $env) {
      projectId
      projectName
    }
  }
`;

export const LOAD_LINKED_WP_PROJECT = gql`
  query LoadUnlinkedWpProjects($connection: String!, $projectId: String!) {
    linkedWpProject(connection: $connection, project_id: $projectId) {
      project {
        projectId
        projectName
      }
    }
  }
`;
