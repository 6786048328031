import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const authLoadProfileStart = createAction(`${NAMESPACE}AUTH_LOAD_PROFILE_START`);
export const authLoadProfileSuccess = createAction(`${NAMESPACE}AUTH_LOAD_PROFILE_SUCCESS`);
export const authLoadProfileFailure = createAction(`${NAMESPACE}AUTH_LOAD_PROFILE_FAILURE`);

export const authLoginStart = createAction(`${NAMESPACE}AUTH_LOGIN_START`);
export const authLoginSuccess = createAction(`${NAMESPACE}AUTH_LOGIN_SUCCESS`);
export const authLoginFailure = createAction(`${NAMESPACE}AUTH_LOGIN_FAILURE`);

export const authLogout = createAction(`${NAMESPACE}AUTH_LOGOUT`);

export const clearRegister = createAction(`${NAMESPACE}CLEAR_REGISTER`);
export const clearLogin = createAction(`${NAMESPACE}CLEAR_LOGIN`);
