import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';

import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import Select from 'modules/shared/components/Select2';

import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useStyles } from './styles';

const MODAL_ID = 'project-select-modal';
const FORM_ID = 'project-select-modal-form';

const ProjectSelectModal = ({ isOpen, onClose, onSubmit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { superUserProjectsPerEnv } = useContext(ProjectsContext);
  const { connection: currentConnection, projectId: currentProject } = useProjectInfoFromSearch();

  const [connection, setConnection] = useState('');
  const [project, setProject] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setConnection('');
      setProject('');
    }
  }, [isOpen]);

  const connectionOptions = useMemo(
    () =>
      Object.keys(superUserProjectsPerEnv).map((c) => ({
        label: c,
        value: c,
      })),
    [superUserProjectsPerEnv],
  );

  const configProjectsOptions = useMemo(() => {
    const projects =
      superUserProjectsPerEnv[connection]?.map((p) => ({
        label: p.projectName,
        value: p.projectId,
      })) || [];

    if (connection === currentConnection) {
      return projects.filter((p) => p.value !== currentProject);
    }

    return projects;
  }, [connection, superUserProjectsPerEnv, currentProject, currentConnection]);

  const handleClose = useCallback(() => {
    setConnection('');
    setProject('');
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      await onSubmit({ connection, project });
      handleClose();
    } catch {
      enqueueSnackbar('Something went wrong. Please, try again later', BASE_ERROR_NOTIFICATION_OPTIONS);
    } finally {
      setLoading(false);
    }
  }, [onSubmit, connection, project, handleClose, enqueueSnackbar]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={handleClose}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={handleClose}>
        Select project
      </Title>
      <Content dividers>
        <form
          id={FORM_ID}
          className={classes.wrapper}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div style={{ flex: 1, display: 'grid', gridTemplateColumns: '2fr 3fr', gap: 16 }}>
            <Select
              id="connection"
              value={[connection]}
              options={connectionOptions}
              disabled={loading}
              className={classes.select}
              onChange={(val) => {
                setConnection(val[0]);
                setProject('');
              }}
              required
            />
            <Select
              id="project"
              value={[project]}
              options={configProjectsOptions}
              disabled={loading}
              className={classes.select}
              onChange={(val) => setProject(val[0])}
              required
            />
          </div>
        </form>
      </Content>
      <Actions>
        <Button disabled={loading} type="submit" form={FORM_ID} color="primary">
          Submit
        </Button>
      </Actions>
    </Dialog>
  );
};

export default ProjectSelectModal;
