import { memo, useEffect, useRef } from 'react';
import { FileManagerComponent } from '@syncfusion/ej2-react-filemanager';

import FileManager from 'modules/shared/components/FileManager';
import { useToggle } from 'modules/shared/hooks/base';
import UploadFolderExplorer from '../UploadFolderExplorer';

const SYNC_BUTTON_TEXT = 'Sync';

const FileExplorer = ({ connection, projectId, dataStoreConnection, onCreate, creating, readOnly }) => {
  const fileManager = useRef<FileManagerComponent | null>(null);

  useEffect(() => {
    fileManager.current?.disableToolbarItems([SYNC_BUTTON_TEXT]);
  }, [dataStoreConnection]);
  const [isUploadToCustomOpen, { activate: openUploadToCustomModal, deactivate: closeUploadToCustomModal }] =
    useToggle();

  const handleCreate = async (data) => {
    // @ts-ignore
    fileManager.current!.showSpinner();
    await onCreate(data);

    fileManager.current!.selectedItems = [];
    // @ts-ignore
    fileManager.current!.hideSpinner();
    closeUploadToCustomModal();
  };

  return (
    <>
      <FileManager
        id="custom-data-source"
        path="/data-stores"
        customToolbarItems={[{ name: SYNC_BUTTON_TEXT, icon: 'e-upload-1', disabled: true }]}
        key={`${projectId}-${dataStoreConnection}`}
        ref={fileManager}
        allowFolderCreate={!readOnly}
        beforeSendBody={{
          connection,
          projectId,
          dataStoreConnection,
        }}
        onSuccess={() => {
          fileManager.current?.disableToolbarItems([SYNC_BUTTON_TEXT]);
        }}
        onFileSelect={() => {
          if (fileManager.current?.selectedItems?.length) {
            fileManager.current.enableToolbarItems([SYNC_BUTTON_TEXT]);
          } else {
            fileManager.current?.disableToolbarItems([SYNC_BUTTON_TEXT]);
          }
        }}
        onToolbarClick={(args) => {
          if (args.item.properties.text === SYNC_BUTTON_TEXT) {
            args.cancel = true;
            openUploadToCustomModal();
          }
        }}
      />
      <UploadFolderExplorer
        connection={connection}
        projectId={projectId}
        isOpen={isUploadToCustomOpen}
        onClose={() => {
          if (!creating) {
            closeUploadToCustomModal();
          }
        }}
        executing={creating}
        onCreate={(data) => {
          const basePath = fileManager.current!.pathNames.slice(1).join('/');

          handleCreate({
            pathes: (fileManager.current!.itemData as Array<{ isFile: boolean; name: string }>).map((f) => ({
              isFile: f.isFile,
              externalPath: `/${basePath}/${f.name}`.replace('//', '/'),
              hdfsPath: data.path,
            })),
            name: data.name,
            scheduler: data.scheduler,
            unzip: data.unzip,
            filter: data.filter,
            loadDeltaLake: data.loadDeltaLake,
          });
        }}
      />
    </>
  );
};

export default memo(FileExplorer);
