import { gql } from '@apollo/client';

export const LOAD_DASHBOARD_DATA_PER_TYPE = gql`
  query LoadDashboardDataPerType($connection: String!, $projectId: String!, $type: String!) {
    dashboardDataPerType(connection: $connection, project_id: $projectId, type: $type) {
      items {
        name
        value
        color
        children {
          name
          value
          color
          children {
            name
            value
            color
            children {
              name
              value
              color
              children {
                name
                value
                color
                children {
                  name
                  value
                  color
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LOAD_DASHBOARD_ITEMS = gql`
  query dashboardItems($connection: String!, $projectId: String!) {
    dashboardItems(connection: $connection, project_id: $projectId) {
      id
      name
    }
  }
`;
