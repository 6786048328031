import { Permissions, SUPER_USER_ACCESS_ROLES } from 'modules/shared/constants';
import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import { ParsedData, Project, useLoadProjects } from 'modules/shared/hooks/api/projects';
import { compareStrings } from 'modules/shared/utils/compareStrings';
import { wrapProject } from 'modules/shared/utils/project';
import { useMemo } from 'react';

const parseProject = (unparsed) => {
  const parsed = unparsed.project.data.reduce((acc, item) => {
    return {
      ...acc,
      [item.key]: item.value,
    };
  }, {} as ParsedData);

  return {
    id: wrapProject({ connection: unparsed.projectConnection, projectId: unparsed.project.projectId }),
    projectId: unparsed.project.projectId,
    projectName: unparsed.project.projectName.trim(),
    description: unparsed.project.description,
    projectConnection: unparsed.projectConnection,
    ...parsed,
  };
};

const ProjectsProvider = ({ children }) => {
  const { loading, projects } = useLoadProjects();

  const { projectsPerEnv, superUserProjectsPerEnv } = useMemo(() => {
    const result = projects.reduce(
      (acc, projectData) => {
        const parsed = parseProject(projectData);
        acc.projectsPerEnv[projectData.projectConnection] = acc.projectsPerEnv[projectData.projectConnection] || [];
        acc.projectsPerEnv[projectData.projectConnection].push(parsed);

        if (SUPER_USER_ACCESS_ROLES.includes(projectData.permission)) {
          acc.superUserProjectsPerEnv[projectData.projectConnection] =
            acc.superUserProjectsPerEnv[projectData.projectConnection] || [];
          acc.superUserProjectsPerEnv[projectData.projectConnection].push(parsed);
        }

        return acc;
      },
      { projectsPerEnv: {} as Record<string, Project[]>, superUserProjectsPerEnv: {} as Record<string, Project[]> },
    );

    return {
      projectsPerEnv: Object.keys(result.projectsPerEnv)
        .sort(compareStrings)
        .reduce((obj, key) => {
          const sorted = result.projectsPerEnv[key]
            .slice()
            .sort((a, b) => compareStrings(a.projectName, b.projectName));
          obj[key] = sorted;
          return obj;
        }, {} as Record<string, Project[]>),
      superUserProjectsPerEnv: Object.keys(result.superUserProjectsPerEnv)
        .sort(compareStrings)
        .reduce((obj, key) => {
          const sorted = result.superUserProjectsPerEnv[key]
            .slice()
            .sort((a, b) => compareStrings(a.projectName, b.projectName));

          obj[key] = sorted;
          return obj;
        }, {} as Record<string, Project[]>),
    };
  }, [projects]);

  const parsedMyProjects: Project[] = useMemo(
    () =>
      Object.values(projectsPerEnv).reduce((acc, cur) => {
        return [...acc, ...cur];
      }, []),
    [projectsPerEnv],
  );

  const projectsWithSuperUserRole: Project[] = useMemo(
    () =>
      Object.values(superUserProjectsPerEnv).reduce((acc, cur) => {
        return [...acc, ...cur];
      }, []),
    [superUserProjectsPerEnv],
  );

  const permissionByProject = useMemo(() => {
    return projects.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.project.projectId]: cur.permission,
      };
    }, {} as Record<string, Permissions>);
  }, [projects]);

  return (
    <ProjectsContext.Provider
      value={useMemo(
        () => ({
          loading,
          projects: parsedMyProjects,
          projectsPerEnv,
          superUserProjectsPerEnv,
          projectsWithSuperUserRole,
          permissionByProject,
        }),
        [
          loading,
          parsedMyProjects,
          permissionByProject,
          projectsPerEnv,
          projectsWithSuperUserRole,
          superUserProjectsPerEnv,
        ],
      )}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsProvider;
