import { makeStyles } from '@material-ui/core';
import { dangerColor, primaryColor1, successColor } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '6px 10px',
  },
  item: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    borderColor: '#8f8f8f',
    borderRadius: 4,
    boxShadow: '0 0px 4px 0 #8f8f8f',
    padding: '0 4px',
  },
  deleted: {
    backgroundColor: 'rgba(244, 67, 54, 0.2)',
  },
  added: {
    backgroundColor: 'rgba(25, 117, 210, 0.2)',
  },
  text: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  deleteButton: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover > *': {
      fill: dangerColor,
    },
  },
  editButton: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover > *': {
      fill: primaryColor1,
    },
  },
  restoreButton: {
    '&:hover > *': {
      fill: successColor,
    },
  },
  icon: {
    width: '100% !important',
    height: '100% !important',
  },
}));
