import { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { authSelectors, authThunks } from 'modules/auth';

import FilesDownload from 'modules/files-download/FilesDownload';
import { useStyles } from './styles';

const UserInfo = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { username, role } = useSelector(authSelectors.getUser);
  const imgPath = 'path'; // TODO from store
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    setOpen(false);
    dispatch(authThunks.logout());
  }, [dispatch]);

  const [logoLoaded, setLogoLoaded] = useState(false);

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const img = new Image();
    img.src = imgPath;

    const onSuccess = () => {
      setLogoLoaded(true);
    };

    img.addEventListener('load', onSuccess);

    return () => {
      img.removeEventListener('load', onSuccess);
    };
  }, [imgPath]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleMenuOpen();
    }
  };

  return (
    <>
      <div
        tabIndex={0}
        role="button"
        className={classes.container}
        onClick={handleMenuOpen}
        onKeyPress={handleKeyPress}
      >
        {logoLoaded && imgPath ? (
          <Avatar src={imgPath} className={classes.avatar} />
        ) : (
          <Avatar className={classes.avatar}>{username.slice(0, 1).toUpperCase()}</Avatar>
        )}
        <div>
          <div className={classes.primary}>{username}</div>
          <div className={classes.secondary}>{role}</div>
          <div ref={ref} />
        </div>
      </div>
      <Menu anchorEl={ref.current} keepMounted open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <FilesDownload />
    </>
  );
};

export default UserInfo;
