import { Card, Typography } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import DraggableVerticalList from 'modules/shared/components/DraggableVerticalList';
import { Draggable } from 'react-beautiful-dnd';
import { noop } from 'modules/shared/utils';
import { useStyles } from './styles';

const ItemComponent = ({ getId, index, item }) => {
  const id = getId(item);
  const classes = useStyles();

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <div style={{ padding: 5 }}>
              <Card
                className={`${classes.card} ${snapshot.isDragging ? classes.draggingListItem : ''}`}
                variant="outlined"
              >
                <div className={classes.dragHandle} {...provided.dragHandleProps}>
                  <DragIndicator style={{ fontSize: 32 }} />
                </div>
                <div className={classes.content}>
                  <Typography>{item.name}</Typography>
                </div>
              </Card>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

const DraggableFlows = ({ onChange, items }) => {
  return (
    <DraggableVerticalList
      getId={(item) => item.id}
      items={items}
      onChange={onChange}
      itemComponent={ItemComponent}
      onComponentChange={noop}
    />
  );
};
export default DraggableFlows;
