import { memo, useCallback, useContext, useRef, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useFlowsByProjectsList } from 'modules/shared/hooks/api/flows-by-project-list';
import { useUpdateFlowSolutionShapeData } from 'modules/project-flow-solution/hooks';

import { useSearch } from 'modules/shared/hooks/url';
import { StepExecuteContext } from 'modules/project-flow-solution/board-ui/contexts/StepExecuteContext';
import { HistoryContext } from 'modules/project-flow-solution/board-ui/contexts/HistoryContext';
import { Actions } from 'modules/project-flow-solution/board-ui/enums';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useAdminOrSuperUser, useEndUserOrHigher } from 'modules/auth/tools';
import EditCardModal from './EditCardModal';
import { DraggableShapeProps } from '../types';

import CardUi from './CardUi';
import useHookStyles from './hooks/useHookStyles';
import CardTrackingModal from './CardTrackingModal';

const POCCard = ({ id, title, scale, metadata = {}, boardId, onUpdate, onDelete }: DraggableShapeProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowTrackingModal, setIsShowTrackingModal] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const { set } = useSearch();

  const { projectId, connection } = useProjectInfoFromSearch();

  const { loading: cardSaving, update } = useUpdateFlowSolutionShapeData(boardId, projectId, connection);

  const {
    loading: flowsLoading,
    flows,
    refetch,
    error: flowsError,
  } = useFlowsByProjectsList(projectId, connection, true);

  const { statusExecute, playBoard, threads, currentState, flowSelection, setFlowSelection, viewInHistory } =
    useContext(StepExecuteContext);
  const context = useContext(HistoryContext);

  // eslint-disable-next-line react/destructuring-assignment
  const listTrackingUsing = viewInHistory ? context?.historyTracking : statusExecute;

  const styleCardUI = useHookStyles(flowSelection, id, currentState, threads, listTrackingUsing ?? [], viewInHistory);

  const {
    dateActual,
    datePlan,
    actionable,
    dataspaceLink = '',
    flowLink = '',
    docLink = '',
    flowsToExecute = [],
    isRunSequentially,
  } = metadata || {};

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
    set({ modalId: '' });
  }, [set]);

  const handleOpenEditModal = useCallback(() => {
    setIsModalOpen(true);
    set({ modalId: id });
    setMenuOpen(false);
  }, [id, set]);

  const handleCloseEditModal = useCallback(() => {
    setIsModalOpen(false);
    set({ modalId: '' });
  }, [set]);

  const handleOpenTrackingModal = useCallback(() => {
    setIsShowTrackingModal(true);
    setMenuOpen(false);
  }, []);
  const handleCloseTrackingModal = useCallback(() => {
    setIsShowTrackingModal(false);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete?.(id);
  }, [id, onDelete]);

  const handleUpdate = useCallback(
    (close, data) => {
      const { shapeId, ...rest } = data;

      onUpdate?.(shapeId, rest);

      close();
    },
    [onUpdate],
  );

  const handleFlowSelect = () => {
    if (flowSelection === id) {
      setFlowSelection?.('');
      return;
    }
    setFlowSelection?.(id);
  };
  const trackings = listTrackingUsing.filter((h) => `${h.shapeId}_shape` === id);

  const editable = useAdminOrSuperUser(connection, projectId);
  const runnable = useEndUserOrHigher(connection, projectId);

  const menu = !viewInHistory ? (
    <>
      <div ref={anchorRef} />
      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenEditModal}>Edit</MenuItem>
        {onDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
      </Menu>
    </>
  ) : (
    <>
      <div ref={anchorRef} />
      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenTrackingModal}>View Status</MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <CardUi
        id={id}
        scale={scale}
        title={title}
        dateActual={dateActual}
        datePlan={datePlan}
        onMenuClick={handleMenuOpen}
        disabledDatePlan={viewInHistory}
        disabledDateActual={viewInHistory}
        menu={editable ? menu : undefined}
        actionable={viewInHistory ? false : actionable}
        actionableProps={{
          dataspaceLink,
          flowLink,
          docLink,
          disableExecFlows:
            !runnable ||
            Boolean(onUpdate || [Actions.PLAY, Actions.PAUSE].includes(currentState as Actions)) ||
            !flowsToExecute.length,
          onFlowsExecute: () => playBoard(id),
        }}
        containerStyle={styleCardUI}
        onClick={handleFlowSelect}
      />
      <EditCardModal
        key={isModalOpen.toString()}
        open={isModalOpen}
        title={title}
        shapeId={id}
        dataspaceLink={dataspaceLink}
        flowLink={flowLink}
        docLink={docLink}
        flowsToExecute={flowsToExecute}
        datePlan={datePlan}
        dateActual={dateActual}
        actionable={actionable}
        flowsLoading={flowsLoading}
        cardSaving={cardSaving}
        onUpdateCard={onUpdate ? handleUpdate : update}
        flows={flows}
        flowsError={flowsError}
        onClose={handleCloseEditModal}
        isRunSequentially={isRunSequentially}
        refetch={refetch}
      />
      <CardTrackingModal open={isShowTrackingModal} onClose={handleCloseTrackingModal} trackings={trackings} />
    </>
  );
};

export default memo(POCCard);
