import { memo } from 'react';
import { CircularProgress } from '@material-ui/core';
import Table from 'modules/shared/components/Table';
import { useLoadDigitalThreadsHistory } from 'modules/digital-threads/hooks';
import { useStyles } from './styles';

const ModalHistory = ({ selectedId, projectId, connection }) => {
  const classes = useStyles();

  const latestVer = useLoadDigitalThreadsHistory(selectedId, projectId, connection);

  return (
    <div className={classes.container}>
      {latestVer?.digitalThreadsHistory ? (
        <Table data={latestVer?.digitalThreadsHistory} wordWrapEnabled pagingEnabled remoteOperations growToFullHeight>
          <Table.Column dataField="label" caption="Label" />
          <Table.Column dataField="version" alignment="center" caption="Version" />
          <Table.Column dataField="provider.label" caption="Provider" />
          <Table.Column dataField="timeLogEntry" caption="Time Log Entry" />
        </Table>
      ) : (
        <div className={classes.loader}>
          <CircularProgress size={50} />
        </div>
      )}
    </div>
  );
};

export default memo(ModalHistory);
