import * as ColorTheme from './colors';

export const container = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  '@media (min-width: 768px)': {
    width: '750px',
  },
  '@media (min-width: 992px)': {
    width: '970px',
  },
  '@media (min-width: 1200px)': {
    width: '1170px',
  },
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
};

export const chartTooltipStyle = {
  background: ColorTheme.primaryColor4,
  borderRadius: 4,
  padding: '3px 5px 2px',
};

export const Header1 = {
  color: ColorTheme.HeaderColors[0],
  fontFamily: ColorTheme.HeaderFont,
  fontSize: '40px',
  fontWeight: 'Bold',
  '& > div': {
    overflow: 'hidden',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
};

export const Header2 = {
  color: ColorTheme.HeaderColors[1],
  fontFamily: ColorTheme.HeaderFont,
  fontSize: '30px',
  fontWeight: 'Bold',
  '& > div': {
    overflow: 'hidden',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
};

export const Header3 = {
  color: ColorTheme.HeaderColors[2],
  fontFamily: ColorTheme.HeaderFont,
  fontSize: '24px',
  fontWeight: 'Bold',
  '& > div': {
    overflow: 'hidden',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
};
