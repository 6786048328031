import * as appActions from './actions';
import * as appSelectors from './selectors';
import * as appConstants from './constants';
import * as appThunks from './thunks';
import reducers from './reducers';

const createReducer = () => {
  return reducers;
};

const { PATH: path } = appConstants;

export { appActions, appConstants, appThunks, appSelectors, createReducer, path };
