import { makeStyles, Theme } from '@material-ui/core';

import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 95vh)',

    minHeight: 'min(calc(100% - 64px), 80vh)',
    overflow: 'initial',

    maxWidth: '80%',
    minWidth: '80%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      minWidth: '90%',
    },
  },
  uploadDialog: {
    maxHeight: 'min(calc(100% - 64px), 95vh)',

    overflow: 'initial',

    maxWidth: '80%',
    minWidth: '80%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      minWidth: '90%',
    },
  },
  uploadDialogContent: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  dialogContent: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
    gap: 20,
  },

  container: { position: 'relative', flex: 1 },
  wrapper: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: BorNeoColors.primaryColor6 },
}));
