import { useMemo, FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { reorder } from './utils';

type Props<T> = {
  items: T[];
  onChange: (items: T[]) => void;
  itemComponent: FunctionComponent<{ item: T; disabled?: boolean; index: number; getId: (item: T) => string }>;
  getId: (item: T) => string;
  [key: string]: any;
  disabled?: boolean;
};

function DraggableVerticalList<T>({
  items,
  getId,
  itemComponent: Component,
  onChange,
  disabled,
  ...componentProps
}: Props<T>) {
  const id = useMemo(() => uuid(), []);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    onChange(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id}>
        {(provided) => (
          <div ref={provided.innerRef} {...(disabled ? provided.droppableProps : {})}>
            {items.map((item, index) => (
              <Component
                item={item}
                disabled={disabled}
                index={index}
                key={getId(item)}
                getId={getId}
                {...componentProps}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableVerticalList;
