import { FileManagerComponent } from '@syncfusion/ej2-react-filemanager';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FileManager from 'modules/shared/components/FileManager';
import { Box, Button, CircularProgress } from '@mui/material';
import ModalContent from 'modules/data-stores-custom/sync-path-scheduler-modal-ui/ModalContent';
import { DataStoreType } from 'modules/data-stores-custom/contants';
import { DataStoresState } from 'modules/data-stores-custom/context/DataStoresState';
import { useStyles } from './styles';

const MODAL_ID = 'custom-connections-upload-folder-explorer-modal';
const FORM_ID = 'custom-connections-upload-folder-explorer-modal-form';

const INITIAL_VALUES = {
  filter: '',
  unzip: false,
  loadDeltaLake: true,
  name: '',
  scheduler: '',
  schedulerValue: null,
  schedulerLabel: '',
};

enum Steps {
  FIRST = 'first',
  SECOND = 'second',
}

const UploadFolderExplorer = ({ isOpen, onClose, projectId, connection, executing, onCreate }) => {
  const classes = useStyles();
  const { dataStoreConnection, dataStoreConnections } = useContext(DataStoresState);
  const [step, setStep] = useState<Steps>(Steps.FIRST);
  const [path, setPath] = useState('/');
  const [values, setValues] = useState<{
    filter: string;
    unzip: boolean;
    loadDeltaLake: boolean;
    name: string;
    scheduler: string;
    schedulerValue: null | string[];
    schedulerLabel: string;
  }>(INITIAL_VALUES);

  const fileManager = useRef<FileManagerComponent | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setStep(Steps.FIRST);
      setPath('/');
      setValues(INITIAL_VALUES);
    }
  }, [isOpen]);

  const c = useMemo(
    () => dataStoreConnections.find((it) => it.connectionId === dataStoreConnection),
    [dataStoreConnections, dataStoreConnection],
  );

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={onClose}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={onClose}>
        {step === Steps.FIRST && 'Select folder'}
        {step === Steps.SECOND && 'Configure Sync'}
      </Title>
      <Content className={classes.dialogContent}>
        {step === Steps.FIRST && isOpen && (
          <div style={{ flex: 1, display: 'flex' }}>
            <FileManager
              id="hdfs-simple"
              path="/hdfs-access"
              allowFolderCreate
              key={`${projectId}-${connection}`}
              ref={fileManager}
              beforeSendBody={{
                connection,
                projectId,
                skipFiles: true,
              }}
            />
          </div>
        )}
        {step === Steps.SECOND && (
          <Box
            component="form"
            id={FORM_ID}
            onSubmit={(e) => {
              e.preventDefault();

              onCreate({
                path,
                name: values.name,
                scheduler: values,
                filter: values.filter,
                unzip: values.unzip,
                loadDeltaLake: values.loadDeltaLake,
              });
            }}
          >
            <ModalContent
              scheduler={values}
              onChange={setValues}
              disabled={executing}
              customFields={{ unzip: true, filter: c?.type === DataStoreType.Sharepoint, loadDeltaLake: true }}
            />
          </Box>
        )}
      </Content>
      {step === Steps.FIRST && (
        <Actions style={{ justifyContent: 'space-between' }}>
          <div className={classes.remarks}>* Your files will be uploaded in current folder</div>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              setPath(`/${fileManager.current!.pathNames.slice(1).join('/')}`);
              setStep(Steps.SECOND);
            }}
          >
            Next
          </Button>
        </Actions>
      )}
      {step === Steps.SECOND && (
        <Actions>
          <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={executing}>
            {executing ? <CircularProgress className={classes.progress} size={24} /> : 'Submit'}
          </Button>
        </Actions>
      )}
    </Dialog>
  );
};

export default memo(UploadFolderExplorer);
