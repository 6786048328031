import { makeStyles } from '@material-ui/core';
import { dangerColor, successColor } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '6px 10px',
  },

  editableWrapper: { display: 'flex', gap: 5, marginBottom: 12 },
  input: { flex: 1, outline: 'none', borderRadius: 4 },
  cancelButton: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover > *': {
      fill: dangerColor,
    },
  },
  addButton: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover > *': {
      fill: successColor,
    },
  },
  icon: {
    width: '100% !important',
    height: '100% !important',
  },
}));
