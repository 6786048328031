import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { LOAD_DIGITAL_THREADS_INFO_BY_ID } from '../gql';
import { DTInfoById, DataTemplateInfo } from '../types';

const parseDataTemplatesId = (templates: DataTemplateInfo[]) => {
  if (templates) {
    return templates.map((t) => {
      return {
        templateId: t.templateId,
        deliverableProjectData: t.deliverableProjectData,
      };
    });
  }
  return undefined;
};

const parseDigitalThreadsInfo = (digitalThreadsInfo: DTInfoById) => {
  if (digitalThreadsInfo) {
    return {
      dtId: digitalThreadsInfo.dtId,
      overview: digitalThreadsInfo.overview,
      workProcesses: digitalThreadsInfo.workProcesses,
      roles: digitalThreadsInfo.roles,
      dataTemplates: parseDataTemplatesId(digitalThreadsInfo.dataTemplates),
    };
  }
  return undefined;
};

export const useLoadDigitalThreadsInfoById = (digitalThreadId: string, connection: string) => {
  const { data, loading } = useQuery(LOAD_DIGITAL_THREADS_INFO_BY_ID, {
    variables: {
      digitalThreadId,
      connection,
    },
    skip: !digitalThreadId,
    notifyOnNetworkStatusChange: true,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const digitalThreadsInfoById = useMemo(
    () => parseDigitalThreadsInfo(data?.digitalthreadsInfoById),
    [data?.digitalthreadsInfoById],
  );

  return {
    digitalThreadsInfoById,
    loading,
  };
};
