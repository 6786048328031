import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  hidden: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: 0,
  },
  visible: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: 1,
  },
}));
