import { memo, useCallback, useState, useEffect } from 'react';
import { AppBar, Button, IconButton, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Content } from 'modules/shared/components/Dialog';
import ModalInformation from './modal-information';
import ModalDefinition from './modal-definition';
import ModalData from './modal-data';
import ModalHistory from './modal-history';
import { useLoadDigitalThreadsInfoById } from '../hooks';
import { useStyles } from './styles';

const MODAL_ID = 'spider-view-modal-title';

const SpiderViewModalUi = ({ open, onClose, modalId, connection, projectId }) => {
  const classes = useStyles();
  // get digital threads data
  const { digitalThreadsInfoById, loading } = useLoadDigitalThreadsInfoById(modalId, connection);

  const [selectedDataTemplate, setSelectedDataTemplate] = useState<string>('');

  useEffect(() => {
    if (digitalThreadsInfoById?.dataTemplates?.length && selectedDataTemplate === '') {
      setSelectedDataTemplate(digitalThreadsInfoById?.dataTemplates[0].templateId);
    }
  }, [digitalThreadsInfoById, loading, selectedDataTemplate]);

  // modal information state
  const [isModalInformation, setModalInformation] = useState(false);
  // modal definition state
  const [isModalDefinition, setModalDefinition] = useState(false);
  // modal data state
  const [isModalData, setModalData] = useState(true);
  // modal history state
  const [isModalHistory, setModalHistory] = useState(false);

  const handleOpenModalInformation = useCallback(() => {
    setModalInformation(true);
    setModalDefinition(false);
    setModalData(false);
    setModalHistory(false);
  }, []);
  const handleOpenModalDefinition = useCallback(() => {
    setModalInformation(false);
    setModalDefinition(true);
    setModalData(false);
    setModalHistory(false);
  }, []);
  const handleOpenModalData = useCallback(() => {
    setModalInformation(false);
    setModalDefinition(false);
    setModalData(true);
    setModalHistory(false);
  }, []);
  const handleOpenModalHistory = useCallback(() => {
    setModalInformation(false);
    setModalDefinition(false);
    setModalData(false);
    setModalHistory(true);
  }, []);

  const close = onClose;

  return (
    <Dialog
      classes={{
        paper: classes.spiderViewModal,
      }}
      fullWidth
      maxWidth="lg"
      onClose={close}
      aria-labelledby={MODAL_ID}
      open={open}
    >
      <AppBar position="static" className={classes.appBarRoot}>
        <Toolbar>
          <Typography variant="h6">{modalId}</Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
              <GetAppIcon />
            </IconButton>
            <IconButton edge="start" color="inherit" onClick={close} aria-label="close" style={{ display: 'flex' }}>
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Content dividers className={classes.content}>
        <div className={classes.buttonRoot}>
          <Button
            variant="outlined"
            color="primary"
            className={
              isModalData ? `${classes.selectedDtButton} ${classes.modalDtButton}` : classes.modalDefaultDtButton
            }
            onClick={handleOpenModalData}
          >
            Data
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={
              isModalHistory ? `${classes.selectedDtButton} ${classes.modalDtButton}` : classes.modalDefaultDtButton
            }
            onClick={handleOpenModalHistory}
          >
            History
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={
              isModalDefinition ? `${classes.selectedDtButton} ${classes.modalDtButton}` : classes.modalDefaultDtButton
            }
            onClick={handleOpenModalDefinition}
          >
            Definition
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={
              isModalInformation ? `${classes.selectedDtButton} ${classes.modalDtButton}` : classes.modalDefaultDtButton
            }
            onClick={handleOpenModalInformation}
          >
            Information
          </Button>
        </div>
        <div className={classes.bodyContainer}>
          {isModalInformation && (
            <ModalInformation
              overviewData={digitalThreadsInfoById?.overview}
              rolesData={digitalThreadsInfoById?.roles}
              workProcessesData={digitalThreadsInfoById?.workProcesses}
              isLoading={loading}
            />
          )}
          {isModalDefinition && (
            <ModalDefinition
              selectedDataTemplate={selectedDataTemplate}
              onChange={setSelectedDataTemplate}
              dataTemplates={digitalThreadsInfoById?.dataTemplates}
              connection={connection}
            />
          )}
          {isModalData && (
            <ModalData
              selectedDataTemplate={selectedDataTemplate}
              onChange={setSelectedDataTemplate}
              dataTemplates={digitalThreadsInfoById?.dataTemplates}
              connection={connection}
              projectId={projectId}
            />
          )}
          {isModalHistory && (
            <ModalHistory
              selectedDataTemplate={selectedDataTemplate}
              onChange={setSelectedDataTemplate}
              dataTemplates={digitalThreadsInfoById?.dataTemplates}
              connection={connection}
              projectId={projectId}
            />
          )}
        </div>
      </Content>
    </Dialog>
  );
};

export default memo(SpiderViewModalUi);
