// @ts-nocheck
import {
  Dialog,
  DialogContent as MuiDialogContent,
  Theme,
  withStyles,
  DialogActions as MuiDialogActions,
} from '@material-ui/core';

import Title from './Title';

const Content = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    position: 'relative',
  },
}))(MuiDialogContent);

const Actions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export { Title, Dialog, Content, Actions };
