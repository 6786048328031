import { CircularProgress, Button, CardActions, Typography } from '@material-ui/core';
import { useHash } from 'modules/shared/hooks/url/useHash';
import { confirm } from 'devextreme/ui/dialog';
import { UPDATE_PROJECT_MODAL_HASH } from '../constants';
import { useDeleteProject } from '../hooks';
import { useStyles } from './styles';

export const ProjectCardActionBar = ({ isAdmin, projectId, loading }) => {
  const classes = useStyles();
  const { deleteProject } = useDeleteProject();
  const { set } = useHash();

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleDelete = (e) => {
    stopPropagation(e);

    confirm('Are you sure you want to delete this project?', 'Project deletion').then((res) => {
      if (res) {
        deleteProject(projectId);
      }
    });
  };

  const handleUpdate = (e) => {
    stopPropagation(e);

    set(`${UPDATE_PROJECT_MODAL_HASH}=${projectId}`);
  };

  if (isAdmin) {
    return (
      <CardActions>
        <Button onClick={handleUpdate} onKeyPress={stopPropagation} className={classes.editButton} size="small">
          Edit
        </Button>
        <Button onClick={handleDelete} onKeyPress={stopPropagation} className={classes.deleteButton} size="small">
          Delete
        </Button>
      </CardActions>
    );
  }

  if (loading) {
    return (
      <div>
        <CircularProgress size={10} />
      </div>
    );
  }

  return (
    <Typography align="center" color="textSecondary" className={classes.pending} variant="h5">
      Active
    </Typography>
  );
};
