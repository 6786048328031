import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';

export const useDebounceSearchValue = (search: string) => {
  const [searchTerm, setSearchTerm] = useState(search);

  const debounceSearchChange = useRef(
    debounce((s: string) => {
      setSearchTerm(s);
    }, 300),
  );

  // Done for debounce search and prevent double requests on clear filters
  const searchToUse = search ? searchTerm : '';
  useEffect(() => {
    const setSearchFn = search ? debounceSearchChange.current : setSearchTerm;
    setSearchFn(search);
  }, [search]);

  return searchToUse;
};
