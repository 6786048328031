import getState from './getState';

export const getStatus = (state) => {
  return getState(state).status;
};

export const getIsInitialized = (state) => {
  return getStatus(state).isInitialized;
};

export const getIsLoading = (state) => {
  return getStatus(state).isLoading;
};
