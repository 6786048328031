import { gql } from '@apollo/client';

export const LOAD_COLUMNS = gql`
  query hdfsFileColumns(
    $connection: String!
    $projectId: String!
    $category: String!
    $dataTemplate: String!
    $file: String!
  ) {
    hdfsFileColumns(
      connection: $connection
      project_id: $projectId
      category: $category
      data_template: $dataTemplate
      file: $file
    ) {
      columns
    }
  }
`;

export const LOAD_LATEST_MAPPINGS = gql`
  query latestMappings($connection: String!, $projectId: String!, $dataTemplate: String!) {
    latestMappings(connection: $connection, project_id: $projectId, data_template: $dataTemplate) {
      boardConfig
      config
      mappings
    }
  }
`;
