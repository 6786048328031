import { makeStyles } from '@material-ui/core';
import { primaryColor1 } from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  draggable: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  editButton: {
    width: 12,
    flexShrink: 0,
    cursor: 'pointer',
    '&:hover > *': {
      fill: primaryColor1,
    },
  },
  editButtonDisabled: {
    width: 12,
    flexShrink: 0,
  },
  icon: {
    width: '100% !important',
    height: '100% !important',
  },
  text: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
