import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    color: 'rgba(255, 255, 255, 0)',
  },
  dialog: {
    maxHeight: 'min(calc(100% - 128px), 70vh)',
    overflow: 'initial',

    maxWidth: '40%',
    minWidth: '40%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '60%',
      minWidth: '60%',
    },
  },
  inputWrapper: {
    display: 'flex',
    gap: 10,
    alignItems: 'flex-end',
  },
  alert: {
    marginTop: 10,
  },
}));
