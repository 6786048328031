import React, { MutableRefObject, useCallback, useMemo } from 'react';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';
import { dxDataGridOptions } from 'devextreme/ui/data_grid';
import { dxElement } from 'devextreme/core/element';
import { Template } from 'devextreme-react';

import { ToolbarItem, ToolbarItemRender } from '../types';

export function useToolbarOptions<T>(
  toolbarItems: (component?: T) => ToolbarItem<T>,
  ref: MutableRefObject<T | undefined>,
  options?: dxDataGridOptions,
) {
  const onToolbarPreparing = useCallback(
    (e: { component?: T; element?: dxElement; model?: any; toolbarOptions?: dxToolbarOptions }) => {
      e.toolbarOptions?.items?.unshift(
        ...Object.entries(toolbarItems(e.component) ?? {}).map(([key, value]) =>
          'render' in value ? { ...value, template: key } : value,
        ),
      );
    },
    [toolbarItems],
  );

  const templates = useMemo(() => {
    return Object.entries(toolbarItems(ref.current) ?? {})
      .filter((set) => 'render' in set[1])
      .map(([key, value]) => (
        <Template
          key={`${key}-template`}
          name={key}
          render={() => (value as ToolbarItemRender<T>).render(ref, options)}
        />
      ));
  }, [options, ref, toolbarItems]);

  return { onToolbarPreparing, templates };
}
