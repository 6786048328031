import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    flexGrow: 1,
    minHeight: 350,
    '& .react-transform-component': {
      width: '100% !important',
      height: '100% !important',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  loading: {
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    top: 0,
    height: '100%',
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noDataToDisplayContainer: {
    zIndex: 10,
    top: 0,
    height: '100%',
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
