import { useApolloClient, useQuery } from '@apollo/client';
import { SnackbarKey, useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LOAD_DATE_TEMPLATE_FIELDS_DEFINITIONS_BY_ID } from '../gql';

export type Field = {
  name: string;
  description: string;
  required: boolean;
  type: string;
};

export const useLoadDataTemplateFieldsDefinitions = (dataTemplateId: string, skip: boolean, connection: string) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const key = useRef<SnackbarKey>();
  const [fnLoading, setFnLoading] = useState(false);
  const [fnError, setFnError] = useState(false);

  const { data, loading, error } = useQuery(LOAD_DATE_TEMPLATE_FIELDS_DEFINITIONS_BY_ID, {
    variables: {
      dataTemplateId,
      connection,
    },
    skip: !dataTemplateId || skip,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const client = useApolloClient();

  useEffect(() => {
    if (!loading && error) {
      key.current = enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
    }

    return () => {
      closeSnackbar(key.current);
    };
  }, [error, loading, enqueueSnackbar, closeSnackbar]);

  const handleLoad = useCallback(async () => {
    if (!dataTemplateId) {
      return {
        data: [],
        totalCount: 0,
      };
    }

    setFnLoading(true);
    setFnError(false);

    const response = await client
      .query({
        query: LOAD_DATE_TEMPLATE_FIELDS_DEFINITIONS_BY_ID,
        variables: {
          dataTemplateId,
          connection,
        },
        fetchPolicy: 'network-only',
      })
      .catch((e) => {
        setFnError(true);
        throw e;
      });

    const values: Field[] = response.data?.dataTemplateFieldsDefinitions || [];

    setFnLoading(false);

    return {
      data: JSON.parse(JSON.stringify(values)),
      totalCount: values.length,
    };
  }, [client, connection, dataTemplateId]);

  return {
    fields: (data?.dataTemplateFieldsDefinitions || []) as Field[],
    error: error || fnError,
    loading: loading || fnLoading,
    load: handleLoad,
  };
};
