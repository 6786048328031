import { uniqBy } from 'lodash';
import { StatusExecute } from 'modules/project-flow-solution/board-ui/enums';
import { FlowSolutionBoardTracking } from 'modules/project-flow-solution/types';
import { Connection } from 'modules/shared/components/DraggableLayout';
import { CSSProperties, useEffect, useState } from 'react';

const useEndStyleHook = (historyTracking: FlowSolutionBoardTracking[], connections: Connection[], shapeId: string) => {
  const [style, setStyle] = useState<CSSProperties | undefined>(undefined);
  useEffect(() => {
    if (!historyTracking.length) {
      setStyle(undefined);
      return;
    }
    const endConnections = connections.filter((c) => c.end === shapeId);
    const startIds = endConnections.map((c) => c.start.replace('_shape', ''));
    const uniqHistoryTrackings = uniqBy(historyTracking, 'shapeId');
    const shapeBelongs = uniqHistoryTrackings.filter((h) => startIds.includes(h.shapeId));

    if (!shapeBelongs.length) {
      setStyle(undefined);
      return;
    }
    const shapeBelongIds = shapeBelongs.map((el) => el.shapeId);
    const nextShapeIds = connections
      .filter((c) => shapeBelongIds.includes(`${c.start.replace('_shape', '')}`))
      .map((c) => c.end.replace('_shape', ''));
    if (uniqHistoryTrackings.some((h) => nextShapeIds.includes(h.shapeId))) {
      setStyle(undefined);
      return;
    }
    if (shapeBelongs.some((h) => h.status === StatusExecute.ERROR)) {
      setStyle({ backgroundColor: '#ff707e', borderColor: 'rgb(227 69 84)' });
      return;
    }
    setStyle({ backgroundColor: 'rgb(167 231 185)', borderColor: 'rgb(76 189 107)' });
  }, [historyTracking, connections, shapeId]);
  return style;
};

export default useEndStyleHook;
