import { Card, CardContent, Tooltip, Typography } from '@mui/material';
import { memo, useCallback, useContext, useMemo, useState } from 'react';

import {
  WizzardContext,
  WizzardHandlersContext,
} from 'modules/digital-threads-mapping-wizzard/wizzard-context/context';
import { StepType } from 'modules/digital-threads-mapping-wizzard/constants';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { DraggableShapeProps } from '../../types';

import { useStyles } from './styles';
import ColItem from './ColItem';
import AddItem from './AddItem';

export const MIN_CARD_HEIGHT = 63;
export const ITEM_HEIGHT = 32;
export const PADDING_TOP = 5;

const DigitalThreadEditColums = ({ width, height, title, metadata, id }: DraggableShapeProps) => {
  const classes = useStyles();

  const [additionalHeight, setAdditionalHeight] = useState(0);

  const columns = metadata?.columns || [];

  const { stepData, steps, selectedStep } = useContext(WizzardContext);
  const { updateStepConfig, openAddColumnTypeModal } = useContext(WizzardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);

  const isAddCol = stepData?.type === StepType.ADD_COLUMNS;
  const isDelCol = stepData?.type === StepType.DELETE_COLUMNS;

  const cardHeight =
    height +
    (isAddCol && !insidePreview ? 36 : 0) +
    ITEM_HEIGHT * (stepData?.config?.addColumns || []).length +
    additionalHeight;

  const addColumns = useMemo(() => stepData?.config.addColumns || [], [stepData?.config.addColumns]);
  const deleteColumns = useMemo(() => stepData?.config.deleteColumns || [], [stepData?.config.deleteColumns]);

  const handleOnDelete = useCallback(
    (name) => {
      if (isDelCol) {
        const index = deleteColumns.findIndex((col) => col === name);

        updateStepConfig({
          deleteColumns: index === -1 ? [...deleteColumns, name] : deleteColumns.filter((c) => c !== name),
        });
      }

      if (isAddCol) {
        updateStepConfig({
          addColumns: addColumns.filter((c) => c.colName !== name),
        });
      }
    },
    [isDelCol, isAddCol, deleteColumns, updateStepConfig, addColumns],
  );

  return (
    <Card className={classes.container} style={{ width, height: cardHeight }}>
      <CardContent className={classes.content}>
        <div className={classes.cardHeader}>
          <Tooltip title={title}>
            <Typography variant="h6" className={`${classes.headerItem} ${classes.headerItemText}`}>
              {title}
            </Typography>
          </Tooltip>
        </div>

        {(Boolean(columns.length) || Boolean(addColumns.length)) && (
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, position: 'relative' }}>
            {columns.map((col) => {
              return (
                <ColItem
                  key={`${id}-${col.name}`}
                  title={col.name}
                  deletable={isDelCol}
                  onDelete={handleOnDelete}
                  deleted={deleteColumns.includes(col.name)}
                />
              );
            })}
            {addColumns.map((col) => {
              return (
                <ColItem
                  key={`${id}-${col.colName}`}
                  title={col.colName}
                  deleted={false}
                  onDelete={handleOnDelete}
                  onEdit={() => {
                    openAddColumnTypeModal(col.colName);
                  }}
                  deletable={isAddCol}
                  added
                />
              );
            })}
          </div>
        )}
        {!insidePreview && stepData?.type === StepType.ADD_COLUMNS && (
          <AddItem
            updateHeight={setAdditionalHeight}
            onSubmit={(newCol) => {
              updateStepConfig({
                addColumns: (addColumns || [])?.concat({ colName: newCol, colType: '', isUuid: false, isDate: false }),
              });
              openAddColumnTypeModal(newCol);
            }}
            validateColName={(colName) => {
              if (addColumns.find((c) => c === colName)) {
                return false;
              }

              if (columns.find((c) => c.name === colName)) {
                return false;
              }

              const stepIndex = steps.findIndex((s) => s.id === selectedStep);

              const nextSteps = steps.slice(stepIndex);

              if (
                nextSteps.find((s) => {
                  if (s.type === StepType.ADD_COLUMNS) {
                    return !!s.config.addColumns?.find((c) => c.colName === colName);
                  }

                  return false;
                })
              ) {
                return false;
              }

              return true;
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default memo(DigitalThreadEditColums);
