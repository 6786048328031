import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 20,
    borderWidth: 0,
  },
  container: {
    width: '100%',
    borderWidth: '0px',
  },
  groups: {
    // background: Colors.TableHeaderGroupColor,
    // color: Colors.TableHeaderGroupFontColor,
  },
  actionsCellWrap: {
    padding: '6px 16px',
  },
  actionsCell: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridGap: '10px',
  },
  tableImg: {
    maxWidth: '100%',
    maxHeight: '60px',
  },
  buttons: {
    marginLeft: -10,
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    '& > *': {
      marginTop: 10,
      marginLeft: 10,
    },
  },
}));
