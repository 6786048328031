import { useQuery } from '@apollo/client';

import { LOAD_DATA_TEMPLATE_VERSION_STATUS } from '../gql';
import { DataTemplateVersionStatus } from '../types';

export const useLoadDataTemplateVersionStatus = (connection: string, versionStatusId: string) => {
  const { data, loading } = useQuery(LOAD_DATA_TEMPLATE_VERSION_STATUS, {
    variables: {
      connection,
      versionStatusId,
    },
    skip: !connection || !versionStatusId,
    notifyOnNetworkStatusChange: true,
  });

  const dataTemplateVersionStatus: DataTemplateVersionStatus[] = data?.dataTemplateVersionStatus || [];

  return {
    dataTemplateVersionStatus,
    loading,
  };
};
