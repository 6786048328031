import { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, Grid, CircularProgress } from '@material-ui/core';

import { authActions } from 'modules/auth';
import { history } from 'configure/browserHistory';

import msalInstance from 'configure/azure-ad-b2c';

import { noop } from 'modules/shared/utils';
import { useStyles } from './styles';

const LoginUi = () => {
  const classes = useStyles();
  const formRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const dispatch = useDispatch();

  const [ssoLoading, setSsoLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(authActions.clearLogin());
    };
  }, [dispatch]);

  const [animation, setAnimation] = useState('cardAnimation');
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation('');
    }, 50); // since modal opens on route change we need to add a little delay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const goToHomeIfClickedOnOverlay = useCallback(({ target }) => {
    if (!formRef.current?.contains(target)) {
      history.replace('/home');
    }
  }, []);

  const startSSO = useCallback(() => {
    setSsoLoading(true);
    msalInstance.loginRedirect();
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      className={classes.container}
      onClick={goToHomeIfClickedOnOverlay}
    >
      <Grid container justifyContent="center" className={classes.gridContainer}>
        <Grid item xs={12} sm={6} md={4}>
          <div ref={formRef} className={classnames(classes.card, classes[animation])}>
            <div className={classes.cardHeader}>
              <h2 className={classes.cardTitle}>Log-In</h2>
            </div>
            <div className={classes.cardBody}>
              <div className={classes.ssoButtonContainer}>
                <Button fullWidth color="primary" variant="contained" onClick={ssoLoading ? noop : startSSO}>
                  {ssoLoading ? <CircularProgress color="inherit" size={20} /> : 'Login'}
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(LoginUi);
