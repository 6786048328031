import { UserHandleModel } from '@syncfusion/ej2-react-diagrams';
import { CHECKING_EDIT_HANDLE_ID, CHECKING_VIEW_HANDLE_ID } from '../constants';

const preview = `M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89
2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm0 16H5V7h14v12zm-5.5-6c0
.83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5zM12
9c-2.73 0-5.06 1.66-6 4 .94 2.34 3.27 4 6 4s5.06-1.66 6-4c-.94-2.34-3.27-4-6-4zm0
6.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z`;

const edit = `M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02
0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z`;

export const CheckingDataHandlesBaseUser: UserHandleModel[] = [
  {
    name: CHECKING_VIEW_HANDLE_ID,
    pathData: preview,
    backgroundColor: 'black',
    pathColor: 'white',
    side: 'Left',
    offset: 1,
    margin: { top: 0, bottom: 0, left: 0, right: 10 },
    horizontalAlignment: 'Left',
    verticalAlignment: 'Top',
  },
];

export const CheckingDataHandlesSuperUser: UserHandleModel[] = [
  {
    name: CHECKING_EDIT_HANDLE_ID,
    pathData: edit,
    backgroundColor: 'black',
    pathColor: 'white',
    side: 'Right',
    offset: 1,
    margin: { top: 0, bottom: 0, left: 10, right: 0 },
    horizontalAlignment: 'Right',
    verticalAlignment: 'Top',
  },

  ...CheckingDataHandlesBaseUser,
];

export const RadialTreeCheckingItemConfig = {
  1: {
    width: 160,
    fontSize: 16,
    characters: 15,
  },
  2: {
    width: 120,
    fontSize: 12,
    characters: 15,
  },
  3: {
    width: 110,
    fontSize: 10,
    characters: 14,
  },
};
