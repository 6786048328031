import { gql } from '@apollo/client';

export const PUBLISH_MAPPINGS = gql`
  mutation publishMappings($connection: String!, $dtTemplateId: String!, $projectId: String!, $config: String!) {
    publishMappings(connection: $connection, data_template: $dtTemplateId, project_id: $projectId, config: $config) {
      boardConfig
      config
      mappings
    }
  }
`;
