import { Types } from 'modules/digital-threads-mapping-wizzard/constants';

export const configPerType = {
  [Types.Mid]: {
    startChar: '',
    endChar: '',
  },
  [Types.Concat]: {
    type: '',
    value: '',
  },
  [Types.Replace]: {
    find: '',
    replace: '',
  },
  [Types.Split]: {
    delimiter: '',
    return: '',
  },
  [Types.Combine]: {
    delimiter: '',
  },
  [Types.Insert]: {
    insert: '',
    index: '',
  },
  [Types.Case]: {
    type: '',
  },
  [Types.Right]: {
    noChar: '',
  },
  [Types.Add]: {
    val1: '',
  },
  [Types.Subtract]: {
    val1: '',
  },
  [Types.Divide]: {
    val1: '',
  },
  [Types.Multiply]: {
    val1: '',
  },
  [Types.Round]: {
    noPlace: '',
  },
};

const BASE_TEXT_FIELD = {
  type: 'text',
  filterValue: (val: string) => val,
};

const BASE_NUMBER_FIELD = {
  type: 'number',
  filterValue: (val: string) => val,
};

export const fieldsConfigs = {
  [Types.Mid]: {
    startChar: BASE_NUMBER_FIELD,
    endChar: BASE_NUMBER_FIELD,
  },
  [Types.Concat]: {
    type: {
      isSelect: true,
      options: [
        { value: 'start', label: 'Start' },
        { value: 'end', label: 'End' },
      ],
    },
    value: BASE_TEXT_FIELD,
  },
  [Types.Replace]: {
    find: BASE_TEXT_FIELD,
    replace: BASE_TEXT_FIELD,
  },
  [Types.Split]: {
    delimiter: BASE_TEXT_FIELD,
    return: {
      type: 'text',
      filterValue: (val) => val.replace(/[^0-9,]/g, ''),
    },
  },
  [Types.Combine]: {
    delimiter: BASE_TEXT_FIELD,
  },
  [Types.Insert]: {
    insert: BASE_TEXT_FIELD,
    index: BASE_NUMBER_FIELD,
  },
  [Types.Case]: {
    type: {
      isSelect: true,
      options: [
        { value: 'upper', label: 'Upper' },
        { value: 'lower', label: 'Lower' },
        { value: 'title', label: 'Title' },
      ],
    },
  },
  [Types.Right]: {
    noChar: BASE_NUMBER_FIELD,
  },
  [Types.Add]: {
    val1: BASE_NUMBER_FIELD,
  },
  [Types.Subtract]: {
    val1: BASE_NUMBER_FIELD,
  },
  [Types.Multiply]: {
    val1: BASE_NUMBER_FIELD,
  },
  [Types.Divide]: {
    val1: BASE_NUMBER_FIELD,
  },
  [Types.Round]: {
    noPlace: BASE_NUMBER_FIELD,
  },
};
