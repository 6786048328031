import getState from './getState';

export const getUser = (state) => {
  return getState(state).user;
};

export const isAdmin = (state) => {
  return getUser(state).role === 'Admin';
};

export const getConnections = (state) => {
  return getUser(state).connections;
};

export const getAdminConnections = (state) => {
  return getUser(state).adminConnections;
};

export const getUserId = (state) => {
  return getUser(state).id;
};

export const getUserEmail = (state) => {
  return getUser(state).email;
};
