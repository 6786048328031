import { gql } from '@apollo/client';

export const LOAD_BN_SETTINGS = gql`
  query LoadBnSettings($connection: String!, $projectId: String!) {
    allBnSettings(connection: $connection, project_id: $projectId) {
      name
      value
      fkCategory
    }
  }
`;

export const LOAD_BN_SETTINGS_CATEGORIES = gql`
  query LoadBnSettingsCategories($connection: String!) {
    allBnSettingsCategories(connection: $connection) {
      id
      name
    }
  }
`;

export const LOAD_BN_SETTINGS_CATEGORY_BY_ID = gql`
  query LoadBnSettingsCategoryById($connection: String!, $id: String!) {
    bnSettingsCategoryById(connection: $connection, id: $id) {
      id
      name
    }
  }
`;
