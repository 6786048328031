import { memo } from 'react';

import DataStoresUI from 'modules/data-stores';
import CustomDataStoresStateProvider from 'modules/data-stores-custom/context/DataStoresState';

import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import { useStyles } from './styles';

const DataStores = () => {
  const classes = useStyles();

  const { connection, projectId } = useProjectInfoFromSearch();

  const adminOrSuperUserOnly = useAdminOrSuperUser(connection, projectId);

  return (
    <div className={classes.page}>
      <CustomDataStoresStateProvider>
        <DataStoresUI readOnly={!adminOrSuperUserOnly} />
      </CustomDataStoresStateProvider>
    </div>
  );
};

export default memo(DataStores);
