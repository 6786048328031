import { memo, useContext, useMemo } from 'react';
import { Alert, Grid } from '@mui/material';
import { getApiBaseUrl } from 'modules/shared/utils';
import { BnSettingsState } from '../context/DataStoresState';

import SelectOrCreateBnSettingInput from '../SelectOrCreateBnSettingInput';
import SelectOrCreateBnSettingFromDropbox from '../SelectOrCreateBnSettingsFromDropboxToken';

const CLIENT_ID = 'clientId';
const CLIENT_SECRET = 'clientSecret';
const TOKEN = 'token';

const apiUrl = getApiBaseUrl();

const DropboxFieldsUi = ({ values, onValueChange }) => {
  const { settings, settingsLoading } = useContext(BnSettingsState);

  const items = useMemo(
    () =>
      settings.map((s) => ({
        value: s.name,
        label: s.name,
      })),
    [settings],
  );

  const clientId = values[CLIENT_ID];
  const clientSecret = values[CLIENT_SECRET];
  const token = values[TOKEN];

  return (
    <>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={CLIENT_ID}
          label="Client Id"
          value={clientId}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(CLIENT_ID, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingInput
          id={CLIENT_SECRET}
          label="Client Secret"
          value={clientSecret}
          items={items}
          loading={settingsLoading}
          onChange={(value) => {
            onValueChange(CLIENT_SECRET, value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="warning">
          Add <span style={{ fontWeight: 'bold' }}>{apiUrl}/oauth-dropbox/code</span> as{' '}
          <span style={{ fontWeight: 'bold' }}>Redirect URI</span> for{' '}
          <span style={{ fontWeight: 'bold' }}>OAuth 2</span>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <SelectOrCreateBnSettingFromDropbox
          id={TOKEN}
          label="Token"
          value={token}
          items={items}
          params={{
            clientId,
            clientSecret,
          }}
          loading={settingsLoading}
          disabled={!clientId || !clientSecret}
          onChange={(value) => {
            onValueChange(TOKEN, value);
          }}
        />
      </Grid>
    </>
  );
};

export default memo(DropboxFieldsUi);
