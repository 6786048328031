import { useCallback, useState } from 'react';

export const useToggle = (
  init = false,
): [boolean, { toggle: () => void; activate: () => void; deactivate: () => void }] => {
  const [value, setValue] = useState(init);

  const toggle = useCallback(() => {
    setValue((v) => !v);
  }, []);

  const activate = useCallback(() => {
    setValue(true);
  }, []);

  const deactivate = useCallback(() => {
    setValue(false);
  }, []);

  return [value, { toggle, activate, deactivate }];
};
