import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { CREATE_DATA_TEMPLATE_VERSION_STATUS, UPDATE_DATA_TEMPLATE_VERSION_STATUS } from '../gql';

export const useDataTemplateVersionStatusActions = ({ connection, versionStatusId, dtVersionId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createFn] = useMutation(CREATE_DATA_TEMPLATE_VERSION_STATUS, {
    update(cache, { data: { createDtTemplateVersionStatus } }) {
      cache.modify({
        fields: {
          dataTemplateVersionStatus(existing) {
            return existing.map((s) => {
              if (s.versionStatusId !== createDtTemplateVersionStatus.versionStatusId) {
                return [...existing, createDtTemplateVersionStatus];
              }
              return s;
            });
          },
        },
      });
    },
  });

  const [updateFn] = useMutation(UPDATE_DATA_TEMPLATE_VERSION_STATUS, {
    update(cache, { data: { updateDtTemplateVersionStatus } }) {
      cache.modify({
        fields: {
          dataTemplateVersionStatus(existing) {
            return existing.map((s) => {
              if (s.versionStatusId === updateDtTemplateVersionStatus.versionStatusId) {
                return updateDtTemplateVersionStatus;
              }
              return s;
            });
          },
        },
      });
    },
  });

  const create = useCallback(
    (status: string, addedDate: string) => {
      return createFn({
        variables: {
          connection,
          dtVersionIdFk: dtVersionId,
          status,
          addedDate,
          updatedDate: addedDate,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [createFn, enqueueSnackbar, connection, dtVersionId],
  );

  const update = useCallback(
    (status: string, updatedDate: string) => {
      return updateFn({
        variables: {
          connection,
          versionStatusId,
          status,
          updatedDate,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [updateFn, enqueueSnackbar, connection, versionStatusId],
  );

  return { create, update };
};
