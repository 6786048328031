import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  loading: {
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    top: 0,
    height: '100%',
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    display: 'flex',
    padding: '0',
    margin: '0',
    textAlign: 'left',
  },
  dtTableContainer: {
    marginRight: '10px',
  },
  masterIndex: {
    marginBottom: '20px',
  },
  accordionContainer: {
    flex: 1,
    margin: '5px !important',
    textAlign: 'justify',
  },
  overviewTitle: {
    background: 'linear-gradient(60deg, #274070, #203864)',
    padding: '1px 5px',
    color: '#fff',
  },
  mainData: {
    margin: '5px',
  },
}));
