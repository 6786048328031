import { memo, useRef } from 'react';
import { Menu as MuiMenu, MenuItem } from '@material-ui/core';

const Menu = ({ isOpen, left, top, onClose, items, absolutePosition = false }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={menuRef}
        style={{
          width: 1,
          height: 1,
          position: absolutePosition ? 'absolute' : 'fixed',
          pointerEvents: 'none',
          top,
          left,
        }}
      />
      <MuiMenu anchorEl={menuRef.current} open={isOpen} onClose={onClose}>
        {items.map((item) => {
          return (
            <MenuItem key={item.title} onClick={item.onClick}>
              {item.title}
            </MenuItem>
          );
        })}
      </MuiMenu>
    </>
  );
};

export default memo(Menu);
