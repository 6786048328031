import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    background: BorNeoColors.primaryColor6,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chartContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  chartWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  selectsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0 10px',
    borderBottom: `1px solid ${grey[400]}`,
    margin: '0 10px 10px',
  },
  select: {
    width: '10%',
    minWidth: 100,
    flex: '0 0',
    marginLeft: 10,
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: 'min(calc(90% - 10px), calc(100% - 110px))',
    paddingRight: 5,
  },
  tab: {
    minWidth: 'auto',
  },
}));
