import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 640px)',
  },
  globalLoading: {
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    top: 0,
    height: '100%',
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellPlaceholderValue: {
    color: 'grey',
  },
}));
