import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  xlsContainer: {
    background: BorNeoColors.primaryColor6,
    height: '73vh',
  },
  filtersConstainer: {
    marginBottom: 20,
  },
  filters: {
    display: 'flex',
    gap: 20,
  },
  filtersMain: {
    flex: 1,
    display: 'flex',
    gap: 20,
  },
  selectContainer: {
    maxWidth: 300,
    flex: 1,
  },
}));
