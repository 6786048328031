import { gql } from '@apollo/client';

export const CREATE_BN_SETTING = gql`
  mutation CreateBnSetting(
    $connection: String!
    $projectId: String!
    $name: String!
    $value: String!
    $category: String
  ) {
    createBnSetting(connection: $connection, project_id: $projectId, name: $name, value: $value, category: $category) {
      name
      value
      fkCategory
    }
  }
`;

export const UPDATE_BN_SETTING = gql`
  mutation UpdateBnSetting(
    $connection: String!
    $projectId: String!
    $name: String!
    $value: String
    $category: String
  ) {
    updateBnSetting(connection: $connection, project_id: $projectId, name: $name, value: $value, category: $category) {
      name
      value
      fkCategory
    }
  }
`;

export const DELETE_BN_SETTING = gql`
  mutation DeleteBnSetting($connection: String!, $projectId: String!, $name: String!) {
    deleteBnSetting(connection: $connection, project_id: $projectId, name: $name)
  }
`;

export const CREATE_BN_SETTING_CATEGORY = gql`
  mutation CreateBnSettingCategory($connection: String!, $name: String!) {
    createBnSettingCategory(connection: $connection, name: $name) {
      name
      id
    }
  }
`;

export const UPDATE_BN_SETTING_CATEGORY = gql`
  mutation UpdateBnSettingCategory($connection: String!, $id: String!, $name: String!) {
    updateBnSettingCategory(connection: $connection, id: $id, name: $name) {
      name
      id
    }
  }
`;

export const DELETE_BN_SETTING_CATEGORY = gql`
  mutation DeleteBnSettingCategory($connection: String!, $id: String!) {
    deleteBnSettingCategory(connection: $connection, id: $id)
  }
`;
