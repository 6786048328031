import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppBar, Box, Toolbar } from '@material-ui/core';

import Sidebar from '../Sidebar';
import UserInfo from '../UserInfo';
import AppLink from '../AppLinks';
import ProjectsProvider from '../ProjectsProvider';
import UrlProjectSelect from '../UrlProjectSelect';

import { useDownloadUserArchives } from '../../hooks/api/download-subscription/useDownloadUserArchives';

import { useStyles } from './styles';

const AuthLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [path, setPath] = useState(history.location.pathname);

  useDownloadUserArchives();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setPath(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <ProjectsProvider>
      <div className={classes.container}>
        <Sidebar selectedPath={path} />
        <div className={classes.layout}>
          <AppBar position="static" elevation={0} color="primary" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <Box className={classes.select}>
                <UrlProjectSelect />
              </Box>
              <Box className={classes.control}>
                <AppLink />
                <UserInfo />
              </Box>
            </Toolbar>
          </AppBar>
          <div className={classes.pageWrap}>{children}</div>
        </div>
      </div>
    </ProjectsProvider>
  );
};

export default AuthLayout;
