import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: 'min(calc(100% - 64px), 80vh)',
    overflow: 'initial',

    maxWidth: '70%',
    minWidth: '70%',
  },

  dialogContent: {
    overflowY: 'auto',
    paddingBottom: '0',
    paddingTop: 0,
    minHeight: 400,
  },
  loading: {
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    top: 0,
    height: '100%',
    right: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  setupContainer: {
    display: 'flex',
    gap: 20,
    marginTop: 20,
  },
}));
