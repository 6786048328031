import { gql } from '@apollo/client';

export const LOAD_FLOWS = gql`
  query LoadFlows(
    $projectId: String!
    $categoryId: String
    $statusId: String
    $isActive: Boolean
    $connection: String!
    $order: String
    $desc: Boolean
    $limit: Int
    $offset: Int
    $search: String
  ) {
    flows(
      search: $search
      order: $order
      desc: $desc
      limit: $limit
      offset: $offset
      project_id: $projectId
      category_id: $categoryId
      status_id: $statusId
      is_active: $isActive
      connection: $connection
    ) {
      totalCount
      nodes {
        flowId
        flowName
        flowDescription
        project {
          projectId
        }
        category {
          categoryId
          categoryName
        }
        status {
          statusId
          statusName
        }
        isActive
        executedAt
        scheduler {
          schedulerLabel
        }
        pausable
      }
    }
  }
`;

export const LOAD_STATUSES = gql`
  query LoadFlowStatuses($connection: String!) {
    flowStatuses(connection: $connection) {
      statusId
      statusName
    }
  }
`;

export const LOAD_FLOW_BY_ID = gql`
  query LoadFlowById($connection: String!, $projectId: String!, $flowId: String!) {
    flowById(connection: $connection, project_id: $projectId, flow_id: $flowId) {
      flowId
      flowName
      flowDescription
      project {
        projectId
        projectName
      }
      category {
        categoryId
        categoryName
      }
      status {
        statusId
        statusName
      }
      isActive
      executedAt
      scheduler {
        schedulerId
        schedulerLabel
        schedulerPattern
        schedulerType
      }
      pausable
      endpoint
      bodyFields
      headers
    }
  }
`;
