import { makeStyles } from '@material-ui/core';
import { container } from 'modules/shared/styles/global-classes';
import image from './assets/bor-neo-bg.jpg';

export const useStyles = makeStyles(() => ({
  container: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    backgroundSize: 'cover',
    width: '100%',
    backgroundImage: `url('${image}')`,
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0',
    flexDirection: 'column',
  },
  logo: {
    marginBottom: 10,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    fontWeight: 800,
    textAlign: 'center',
    marginBottom: 10,
    lineHeight: 1.2,
    fontColor: 'white',
  },
  information: {
    backgroundColor: '#404850',
    flexGrow: 0,
    flexShrink: 0,
  },
  wrapper: {
    ...container,
    paddingTop: 30,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  subWrapper: {
    maxWidth: 970,
    flex: '1',
  },
  subtitle: {
    color: '#ececec',
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: 20,
  },
  item: {
    textAlign: 'center',
  },
  itemTitle: {
    color: '#ececec',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    fontWeight: 300,
    marginBottom: 10,
    lineHeight: 1.1,
  },
  itemContent: {
    color: '#ececec',
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: 1.14,
  },
}));
