import { useApolloClient, useSubscription } from '@apollo/client';
import { authSelectors } from 'modules/auth';
import { useAdminOnly } from 'modules/auth/tools';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { useSelector } from 'react-redux';

import { PROJECT_CREATED_SUBSCRIPTION } from './gql';

export const useCreateProjectSubscription = (skip: boolean) => {
  const userId = useSelector(authSelectors.getUserId);
  const isAdmin = useAdminOnly();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  useSubscription(PROJECT_CREATED_SUBSCRIPTION, {
    skip: skip || !isAdmin,
    variables: {
      id: userId,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const {
        projectCreatedSubscription: { type, project },
      } = subscriptionData.data;

      if (type === 'created') {
        client.cache.modify({
          fields: {
            myProjects(existing) {
              return [...existing, project];
            },
          },
        });

        enqueueSnackbar(
          `Project ${project.project.projectName} was created successfully`,
          BASE_SUCCESS_NOTIFICATION_OPTIONS,
        );
      } else {
        enqueueSnackbar('Error while creating a project', BASE_ERROR_NOTIFICATION_OPTIONS);
      }
    },
  });
};
