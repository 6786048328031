import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { CREATE_DATA_STORE_CONNECTION, DELETE_DATA_STORE_CONNECTION, UPDATE_DATA_STORE_CONNECTION } from '../gql';
import { DataStoreConnection } from '../types';

export const useDataStoreConnectionActions = ({ connection, projectId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createMutation, { loading: creating }] = useMutation(CREATE_DATA_STORE_CONNECTION, {
    update(cache, { data: { createDataStoresConnection: createDataStoresConnectionData } }) {
      cache.modify({
        fields: {
          allDataStoresConnections(existing) {
            return [...existing, createDataStoresConnectionData];
          },
        },
      });
    },
  });

  const [updateMutation, { loading: updating }] = useMutation(UPDATE_DATA_STORE_CONNECTION, {
    update(cache, { data: { updateDataStoresConnection: updateDataStoresConnectionData } }) {
      cache.modify({
        fields: {
          allDataStoresConnections(existing) {
            return existing.map((e) => {
              if (e.connectionId === updateDataStoresConnectionData.connectionId) {
                return updateDataStoresConnectionData;
              }

              return e;
            });
          },
        },
      });
    },
  });

  const [removeMutation] = useMutation(DELETE_DATA_STORE_CONNECTION, {
    update(cache, { data: { deleteDataStoresConnection } }) {
      cache.modify({
        fields: {
          allDataStoresConnections(existing) {
            return existing.filter((e) => e.connectionId !== deleteDataStoresConnection);
          },
        },
      });
    },
  });

  const create = useCallback(
    (onSuccess: (data: DataStoreConnection) => void, { name, type, config }) => {
      return createMutation({
        variables: {
          name,
          connection,
          projectId,
          type,
          config,
        },
      })
        .then(({ data: { createDataStoresConnection } }) => {
          onSuccess(createDataStoresConnection);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [createMutation, enqueueSnackbar, connection, projectId],
  );

  const update = useCallback(
    (onSuccess: (data: DataStoreConnection) => void, connectionId: string, { name, type, config }) => {
      return updateMutation({
        variables: {
          name,
          connection,
          connectionId,
          projectId,
          type,
          config,
        },
      })
        .then(({ data: { updateDataStoresConnection } }) => {
          onSuccess(updateDataStoresConnection);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [updateMutation, enqueueSnackbar, connection, projectId],
  );

  const remove = useCallback(
    (dataStoreconnection: string) => {
      removeMutation({
        variables: {
          connection,
          projectId,
          dataStoreconnection,
        },
        optimisticResponse: {
          deleteDataStoresConnection: dataStoreconnection,
        },
      }).catch((err) => {
        enqueueSnackbar(err.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [removeMutation, enqueueSnackbar, projectId, connection],
  );

  return { create, creating, remove, update, updating };
};
