import { InputBase } from '@material-ui/core';
import { memo } from 'react';
import { useStyles, usePlaceholderStyles } from './styles';

const SelectInput = ({ placeholder, ...props }) => {
  const classes = useStyles();
  const placeholderClasses = usePlaceholderStyles();

  return (
    <>
      <InputBase {...props} classes={classes} />
      {placeholder && <div className={placeholderClasses.placeholder}>{placeholder}</div>}
    </>
  );
};

export default memo(SelectInput);
