import { memo, ReactNode, useCallback } from 'react';
import { Grid, Box } from '@material-ui/core';

import Select from 'modules/shared/components/Select2';
import { useSearch } from 'modules/shared/hooks/url';
import { useLoadDigitalthreadTypes } from 'modules/digital-threads/hooks';
import { DTTabId } from 'pages/DigitalThreads/enums';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import Button from './Button';
import { PageType } from './types';
import { useStyles } from './styles';
import StatusDiamond from '../StatusDiamond';
import { statusCode } from './statusCode';

export type ControlledPageSelectorProps = {
  Pages: Array<PageType>;
  children?: ReactNode;
  selectedPage: PageType | undefined;
  onChangePage: (page: PageType) => void;
  [key: string]: any;
  withMargin?: boolean;
};

const ControlledPageSelector = ({
  Pages,
  selectedPage,
  onChangePage,
  children,
  withMargin,
  ...componentProps
}: ControlledPageSelectorProps) => {
  const classes = useStyles();
  const { get, set } = useSearch();
  const { connection } = useProjectInfoFromSearch();
  const dtType = get('type');

  const Component = selectedPage?.item;

  const { loading: dtTypeLoading, digitalthreadTypes } = useLoadDigitalthreadTypes(connection);

  const dtTemplatesByType = digitalthreadTypes.find((item) => item.type === dtType)?.templates || [];

  const dtThreadsDataSet = dtTemplatesByType.map((dt) => ({
    label: `${dt.templateId} (${dt.deliverableProjectData})`,
    value: dt.templateId,
  }));
  const templateId = get('templateId') || dtThreadsDataSet?.[0]?.value;

  const handleDigitalThreadsDataCategoryChange = useCallback(
    (value) => {
      set({
        type: value,
        templateId: '',
        boardType: 'category',
      });
    },
    [set],
  );

  const handleDigitalThreadsDataSetChange = useCallback(
    (value) => {
      set({
        templateId: value,
        boardType: 'template',
      });
    },
    [set],
  );
  const handleBack = useCallback(() => {
    set({
      page: 'spider',
      type: '',
      templateId: '',
      boardType: '',
    });
  }, [set]);

  const isBelongGroupTabRoot = (id) => [DTTabId.INDEX, DTTabId.SPIDER].includes(id);
  const isBelongGroupTabChild = (id) => [DTTabId.DATA, DTTabId.CHECKING, DTTabId.MAPPING].includes(id);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: withMargin ? 20 : 0 }}>
        <Grid container spacing={2}>
          <Box clone order={{ xs: 2, md: 1 }}>
            <Grid item xs={12} md={12} lg={12}>
              {
                <div className={classes.buttons}>
                  {isBelongGroupTabChild(selectedPage?.id) && (
                    <Button id="back" disabled={false} onClick={handleBack} selected label="Back" />
                  )}
                  {Pages.map((p) => {
                    if (isBelongGroupTabRoot(p.id)) {
                      if (isBelongGroupTabChild(selectedPage?.id)) {
                        return;
                      }
                    } else if (isBelongGroupTabChild(p.id)) {
                      if (isBelongGroupTabRoot(selectedPage?.id)) {
                        return;
                      }
                    }
                    return (
                      <Button
                        key={p.id}
                        id={p.id}
                        label={p.label}
                        disabled={p.disabled}
                        onClick={() => {
                          onChangePage(p);
                        }}
                        selected={p.id === selectedPage?.id || p.id === 'btn_back'}
                      />
                    );
                  })}
                  {selectedPage?.id === 'dt-index' ? (
                    <div>
                      {statusCode.map((sc) => (
                        <StatusDiamond
                          key={sc.color}
                          // @ts-ignore
                          color={sc.color}
                          style={{
                            verticalAlign: 'middle',
                            margin: '10px 5px',
                            content: sc.description,
                          }}
                        />
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                  {isBelongGroupTabChild(selectedPage?.id) ? (
                    <div style={{ display: 'flex', width: '500px' }}>
                      <Select
                        id="dt-category-selector"
                        className={classes.dtSelector}
                        placeholder="Digital Threads Category"
                        value={[dtType || '']}
                        options={digitalthreadTypes.map((dt) => ({
                          label: `${dt.type}`,
                          value: dt.type,
                        }))}
                        onChange={handleDigitalThreadsDataCategoryChange}
                        loading={dtTypeLoading}
                        disableClear
                      />
                      <div style={{ width: '250px' }}>
                        <Select
                          id="dt-data-set-selector"
                          className={classes.dtSelector}
                          placeholder="Digital Threads Data Set"
                          value={[templateId || '']}
                          options={dtThreadsDataSet}
                          onChange={handleDigitalThreadsDataSetChange}
                          loading={dtTypeLoading}
                          disableClear
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {children}
                </div>
              }
            </Grid>
          </Box>
        </Grid>
      </div>
      {Component && (
        <div className={classes.container}>
          <Component {...componentProps} templateId={templateId} />
        </div>
      )}
    </>
  );
};
ControlledPageSelector.defaultProps = {
  children: null,
  withMargin: false,
};

export default memo(ControlledPageSelector);
