import { createUploadLink as createApolloUploadLink } from 'apollo-upload-client';
import { getApiBaseUrl } from 'modules/shared/utils';

const getGqlBaseURL = () => {
  const baseUrl = getApiBaseUrl();

  return `${baseUrl}/graphql`;
};

export const createHttpLink = () => {
  return createApolloUploadLink({
    uri: getGqlBaseURL(),
  });
};
