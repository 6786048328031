/* eslint-disable import/no-extraneous-dependencies */
import { from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { configure } from 'modules/gql';

import { createAuthLink, createHttpLink, createCancelRequestLink, createErrorLink, createWsLink } from './links';

export const configureGql = (dispatch) => {
  const httpLink = createHttpLink();
  const authLink = createAuthLink();
  const cancelRequestLink = createCancelRequestLink();
  const errorLink = createErrorLink(dispatch);
  const wsLink = createWsLink();

  const httpRequestLink = from([authLink, cancelRequestLink, httpLink]);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpRequestLink,
  );

  return configure(from([errorLink, splitLink]));
};
