import { useQuery } from '@apollo/client';

import { LOAD_DATA_TEMPLATE_BY_TYPE } from '../gql';
import { DataTemplateInfo } from '../types';

export const useLoadDataTemplateByType = (connection: string, dtType: string) => {
  const { data, loading } = useQuery(LOAD_DATA_TEMPLATE_BY_TYPE, {
    variables: {
      connection,
      dtType,
    },
    skip: !connection,
    notifyOnNetworkStatusChange: true,
  });

  const dtTemplateByType: DataTemplateInfo[] = data?.dtTemplateByType || [];

  return {
    dtTemplateByType,
    loading,
  };
};
