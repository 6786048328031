import { FieldsType } from '../shared/FlowModal/types';

export const parseFields = (fields: FieldsType) => {
  const fieldsValues = Object.values(fields);
  const values = fieldsValues.reduce((acc, val) => {
    return {
      ...acc,
      [val.key]: val.value,
    };
  }, {});

  return {
    fieldsValues,
    values,
  };
};
