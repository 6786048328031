import { memo } from 'react';

import SpiderViewItem from 'modules/shared/components/DraggableShapes/SpiderViewItem';
import POCCard from 'modules/shared/components/DraggableShapes/PocCard';
import DraggableWrapper from 'modules/shared/components/DraggableWrapper';
import CheckistCard from 'modules/shared/components/DraggableShapes/ChecklistCard';

import classNames from 'classnames';
import { noop } from 'modules/shared/utils';
import {
  INPUT,
  OUTPUT,
  TRANSFORMATION,
  EDITABLE_COLUMNS,
  COMBINE_COLUMN,
} from 'modules/digital-threads-mapping-wizzard/constants';
import { CHECKLIST, END, START } from 'modules/flow-solution-builder/constants';
import { Shape } from '../types';
import SourceDtData from '../../DraggableShapes/DigitalthreadsWizzard/SourceDtData';
import DigitalthreadsColumns from '../../DraggableShapes/DigitalthreadsWizzard/DigitalThreadColumns';
import DigitalThreadEditColums from '../../DraggableShapes/DigitalthreadsWizzard/DigitalThreadEditColums';
import TransformationCard from '../../DraggableShapes/DigitalthreadsWizzard/TransformationCard';
import { useStyles } from './styles';
import StartFlow from '../../DraggableShapes/StartFlow';
import EndFlow from '../../DraggableShapes/EndFlow';
import CombineColumnCard from '../../DraggableShapes/DigitalthreadsWizzard/CombineColumnCard';

export type SelectedConnectionType = {
  startId: string | null;
  startPosition: string | null;

  onDotClick: (shapeId: string, position: string, cardType: string, coords: { x: string; y: string }) => void;
};

type Props = {
  boardId: string;
  shapes: Shape[];
  scale: number;
  currentMaxScale: number;
  shapeScale: number;
  disableDrag: boolean;
  updateCardPosition: (idx: number, { x, y }: { x: number; y: number }) => void;
  onInitPositionFix: (idx: number, { x, y }: { x: number; y: number }) => void;
  onUpdate?: (id: string, shape: Partial<Shape>) => void;
  onDelete?: (id: string) => void;
  editableConnections?: boolean;
  selectedConnectionDotProps?: SelectedConnectionType;
};

const ShapeByType = {
  'poc-card': POCCard,
  'dt-spider-item': SpiderViewItem,
  'dt-dataset-item': SpiderViewItem,
  [CHECKLIST]: CheckistCard,
  [START]: StartFlow,
  [INPUT]: SourceDtData,
  [OUTPUT]: DigitalthreadsColumns,
  [EDITABLE_COLUMNS]: DigitalThreadEditColums,
  [TRANSFORMATION]: TransformationCard,
  [END]: EndFlow,
  [COMBINE_COLUMN]: CombineColumnCard,
};

const DisplayDotsByShapeHandler = {
  [START]: (shapeId, startId) => {
    if (shapeId === startId) {
      return true;
    }

    return !startId;
  },
  [END]: (_, startId) => {
    return Boolean(startId);
  },
};

const shouldShowDots = (type, shapeId, startId) => {
  const check = DisplayDotsByShapeHandler[type];

  return check ? check(shapeId, startId) : true;
};

const ShapesRenderer = ({
  boardId,
  shapes,
  scale,
  currentMaxScale,
  updateCardPosition,
  onInitPositionFix,
  shapeScale,
  disableDrag,
  onUpdate,
  onDelete,
  editableConnections = false,
  selectedConnectionDotProps = {} as SelectedConnectionType,
}: Props) => {
  const classes = useStyles();

  const { startId, startPosition, onDotClick = noop } = selectedConnectionDotProps;

  return (
    <>
      {shapes.map((shape, idx) => {
        const Component = ShapeByType[shape.type];

        if (!Component) {
          return null;
        }

        const showDows = shouldShowDots(shape.type, shape.id, startId);

        return (
          <DraggableWrapper
            key={shape.id}
            id={shape.id}
            idx={idx}
            x={shape.xCoordinate}
            y={shape.yCoordinate}
            scale={scale}
            maxScale={currentMaxScale}
            disabled={disableDrag}
            onDrag={updateCardPosition}
            bounds="parent"
            onInitPositionFix={onInitPositionFix}
          >
            <div style={{ position: 'relative' }}>
              {editableConnections && (
                <>
                  <div className={classes.overlay} />
                  {showDows && (
                    <>
                      <div
                        className={classNames(classes.top, {
                          [classes.selected]: startId === shape.id && startPosition === 'top',
                        })}
                        role="button"
                        aria-label="top connection button"
                        tabIndex={0}
                        onKeyDown={noop}
                        onClick={(e) => {
                          const { clientX, clientY } = e;

                          onDotClick(shape.id, 'top', shape.type, { x: clientY, y: clientX });
                        }}
                      />
                      <div
                        className={classNames(classes.bottom, {
                          [classes.selected]: startId === shape.id && startPosition === 'bottom',
                        })}
                        role="button"
                        aria-label="bottom connection button"
                        tabIndex={0}
                        onKeyDown={noop}
                        onClick={(e) => {
                          const { clientX, clientY } = e;

                          onDotClick(shape.id, 'bottom', shape.type, { x: clientY, y: clientX });
                        }}
                      />
                      <div
                        className={classNames(classes.left, {
                          [classes.selected]: startId === shape.id && startPosition === 'left',
                        })}
                        role="button"
                        aria-label="left connection button"
                        tabIndex={0}
                        onKeyDown={noop}
                        onClick={(e) => {
                          const { clientX, clientY } = e;

                          onDotClick(shape.id, 'left', shape.type, { x: clientY, y: clientX });
                        }}
                      />
                      <div
                        className={classNames(classes.right, {
                          [classes.selected]: startId === shape.id && startPosition === 'right',
                        })}
                        role="button"
                        aria-label="right connection button"
                        tabIndex={0}
                        onKeyDown={noop}
                        onClick={(e) => {
                          const { clientX, clientY } = e;

                          onDotClick(shape.id, 'right', shape.type, { x: clientY, y: clientX });
                        }}
                      />
                    </>
                  )}
                </>
              )}
              <div className={classes.component}>
                <Component
                  boardId={boardId}
                  id={shape.id}
                  title={shape.title}
                  scale={shapeScale}
                  metadata={shape.metadata}
                  width={shape.width}
                  height={shape.height}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                />
              </div>
            </div>
          </DraggableWrapper>
        );
      })}
    </>
  );
};

export default memo(ShapesRenderer);
