import { setContext } from '@apollo/client/link/context';
import Storage from 'modules/tokens-storage';

export const createAuthLink = () => {
  const authLink = setContext((_, { headers }) => {
    const idToken = Storage.getInmemoryIdToken();

    return {
      headers: {
        ...headers,
        authorization: idToken ? `Bearer ${idToken}` : '',
      },
    };
  });

  return authLink;
};
