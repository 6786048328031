import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MsalProvider } from '@azure/msal-react';

import configureRest from 'configure/rest';
import { configureGql } from 'configure/gql';
import { history } from 'configure/browserHistory';
import configureStore from 'store';
import App from 'main';
import { client } from 'modules/gql';
import msalInstance from 'configure/azure-ad-b2c';

import './styles/index.scss';
import Notifications from 'modules/new-dashboard/components/Notifications';
import ConnectionsProvider from 'modules/shared/contexts/ConnectionContext';

export const store = configureStore(window.__PRELOADED_STATE__);
const { dispatch } = store;

configureRest(dispatch);
configureGql(dispatch);

msalInstance.initialize().then(() => {
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ConnectedRouter history={history}>
            <SnackbarProvider TransitionComponent={Slide as FunctionComponent<TransitionProps>}>
              <ConnectionsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <App />
                  <Notifications />
                </MuiPickersUtilsProvider>
              </ConnectionsProvider>
            </SnackbarProvider>
          </ConnectedRouter>
        </ApolloProvider>
      </Provider>
    </MsalProvider>,
    document.getElementById('root'),
  );
});
