import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { CREATE_DATA_TEMPLATE_NOTES, UPDATE_DATA_TEMPLATE_NOTES, DELETE_DATA_TEMPLATE_NOTES } from '../gql';

export const useDataTemplateNotesActions = ({ connection, selectedId, projectId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createFn] = useMutation(CREATE_DATA_TEMPLATE_NOTES, {
    update(cache, { data: { createDtTemplateNote } }) {
      cache.modify({
        fields: {
          dataTemplateNotes(existing) {
            return [...existing, createDtTemplateNote];
          },
        },
      });
    },
  });

  const [updateFn] = useMutation(UPDATE_DATA_TEMPLATE_NOTES, {
    update(cache, { data: { updateDtTemplateNote } }) {
      cache.modify({
        fields: {
          dataTemplateNotes(existing) {
            return existing.map((s) => {
              if (
                s.templateNotesId === updateDtTemplateNote.templateNotesId &&
                s.projectId === updateDtTemplateNote.projectId
              ) {
                return updateDtTemplateNote;
              }

              return s;
            });
          },
        },
      });
    },
  });

  const [deleteFn] = useMutation(DELETE_DATA_TEMPLATE_NOTES, {
    update(cache, { data: { deleteDtTemplateNote: deleteDtTemplateNoteData } }) {
      cache.modify({
        fields: {
          dataTemplateNotes(existing) {
            return existing.filter((s) => s.templateNotesId !== deleteDtTemplateNoteData);
          },
        },
      });
    },
  });

  const create = useCallback(
    (addedDate: string, userEmail: string, notes: string) => {
      return createFn({
        variables: {
          connection,
          dtTemplateId: selectedId,
          notes,
          addedDate,
          userEmail,
          projectId,
        },
        optimisticResponse: {
          createDtTemplateNote: {
            __typename: 'DataTemplateNotes',
            addedDate,
            dtTemplateIdFk: selectedId,
            notes,
            projectId,
            userEmail,
          },
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [createFn, enqueueSnackbar, connection, selectedId, projectId],
  );

  const update = useCallback(
    (templateNotesId: string, addedDate: string, userEmail: string, notes: string) => {
      return updateFn({
        variables: {
          connection,
          templateNotesId,
          notes,
          addedDate,
          userEmail,
          projectId,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [updateFn, enqueueSnackbar, connection, projectId],
  );

  const remove = useCallback(
    (templateNotesId: string) => {
      return deleteFn({
        variables: {
          connection,
          templateNotesId,
          projectId,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [deleteFn, enqueueSnackbar, connection, projectId],
  );

  return { create, update, remove };
};
