import { CircularProgress } from '@mui/material';
import { memo } from 'react';

import styles from '../../main/styles.module.scss';

const SsoCallback = () => {
  return (
    <div className={styles.loading}>
      <CircularProgress size={50} />
    </div>
  );
};

export default memo(SsoCallback);
