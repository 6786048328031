import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: false,
  isLoggedIn: false,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${combineActions(actions.authLoginStart, actions.authLoadProfileStart)}`]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [`${combineActions(actions.authLoginSuccess, actions.authLoadProfileSuccess)}`]: (state) => {
    return {
      ...state,
      isLoading: false,
      isLoggedIn: true,
    };
  },
  [`${combineActions(actions.authLoginFailure, actions.authLoadProfileFailure)}`]: () => {
    return defaultState;
  },
  [`${actions.authLogout}`]: () => ({
    ...defaultState,
  }),
};

export default handleActions(reducerMap, defaultState);
