export const warningColor = '#ff9800';
export const dangerColor = '#f44336';
export const successColor = '#4caf50';
export const infoColor = '#00acc1';
export const TableHeaderGroupColor = '#053668';
export const TableHeaderColor = '#757171';
export const TableHeaderGroupFontColor = '#FFFFFF';
export const TableHeaderFontColor = '#FFFFFF';
export const TableFocusCellColor = '#B5C6E6';
export const TableFocusCellFontColor = '#131416';
export const primaryColor1 = '#1c71b6';
export const primaryColor2 = '#2d87d5';
export const primaryColor3 = '#bdd4e7';
export const primaryColor4 = '#dce3eb';
export const primaryColor5 = '#f0f0f0';
export const primaryColor6 = '#fcfcfc';
export const accentColor1 = '#101b1e';
export const accentColor2 = '#244b5a';
export const accentColor3 = '#0f1b1e';
export const accentColor4 = '#f7dc68';
export const accentColor5 = '#14bcc4';
export const accentColor6 = '#0bf0cd';
export const accentColor7 = '#f36944';
export const accentColor8 = '#aa216b';
export const accentColor9 = '#053668';
export const HeaderFont = 'JOSEFINSANS';
export const HeaderColors = ['#006098', '#244b5a', '#057ebb'];
export const BodyFont = 'COMFORTAA';
export const darkFont = '#000';
export const lightFont = '#fff';
