import * as React from 'react';
import { useSnackbar } from 'notistack';
import { appSelectors } from 'modules/app';
import { useSelector } from 'react-redux';

const Notifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const notifications = useSelector(appSelectors.getList);

  const fireNotify = React.useCallback(
    (index: number) => {
      const notification = notifications[index];
      if (!notification) {
        return;
      }
      enqueueSnackbar(notification.message, {
        variant: notification.variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      setTimeout(() => {
        index++;
        fireNotify(index);
      }, 300);
    },
    [enqueueSnackbar, notifications],
  );
  React.useEffect(() => {
    fireNotify(0);
  }, [fireNotify, notifications]);

  return null;
};

export default React.memo(Notifications);
