import { gql } from '@apollo/client';

export const LOAD_CONNECTIONS_INFO = gql`
  query ConnectionsInfo {
    connectionsInfo {
      name
      fsUrl
    }
  }
`;
