import { gql } from '@apollo/client';

export const LINK_WP_PROJECT = gql`
  mutation linkWpProject($connection: String!, $projectId: String!, $wpProjectId: String!) {
    linkWpProject(connection: $connection, project_id: $projectId, wp_project_id: $wpProjectId)
  }
`;

export const UNLINK_PROJECT = gql`
  mutation unlinkProjects($connection: String!, $projectId: String!) {
    unlinkProjects(connection: $connection, project_id: $projectId)
  }
`;
