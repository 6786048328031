import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import dxDataGrid from 'devextreme/ui/data_grid';

import { ReactComponent as ExcelIcon } from './svgs/excel.svg';
import { ReactComponent as ClearFilter } from './svgs/clear-filter.svg';
import { ReactComponent as CollapseAll } from './svgs/collapse-all.svg';
import { ReactComponent as ExpandAll } from './svgs/expand-all.svg';

import { ToolbarItem } from '../types';

export const DEFAULT_GRID_TOOLBAR_ITEMS: ToolbarItem<dxDataGrid> = {
  'Collapse All': {
    location: 'before',
    render: (grid) => {
      return (
        <Tooltip title="Collapse all groups" placement="bottom">
          <IconButton onClick={() => grid.current?.collapseAll()}>
            <CollapseAll width={20} height={20} fill="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        </Tooltip>
      );
    },
  },
  'Expand All': {
    location: 'before',
    render: (grid) => {
      return (
        <Tooltip title="Expand all groups" placement="bottom">
          <IconButton onClick={() => grid.current?.expandAll()}>
            <ExpandAll width={20} height={20} fill="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        </Tooltip>
      );
    },
  },
  PreBar: {
    location: 'before',
    render: () => () => {
      return (
        <div
          className="toolbar-bar-item"
          style={{
            width: 1,
            height: 40,
            backgroundColor: 'lightgray',
            margin: '0px 8px',
          }}
        />
      );
    },
  },
  'Export Excel': {
    location: 'after',
    render: (grid) => {
      return (
        <Tooltip title="Export to Excel" placement="bottom">
          <IconButton
            onClick={() => {
              grid.current?.exportToExcel(false);
            }}
          >
            <ExcelIcon width={20} height={20} fill="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        </Tooltip>
      );
    },
  },
  'Clear Filters': {
    location: 'after',
    render: (grid, options) => {
      if (!options?.headerFilter?.visible) {
        return null;
      }
      return (
        <Tooltip title="Clear all filters" placement="bottom">
          <IconButton onClick={() => grid.current?.clearFilter()}>
            <ClearFilter width={20} height={20} fill="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        </Tooltip>
      );
    },
  },
};
