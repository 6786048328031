import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

import { replace } from 'connected-react-router';
import { history } from 'configure/browserHistory';

import { useContext, useEffect } from 'react';
import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import { useSelector } from 'react-redux';
import { Permissions, SUPER_USER_ACCESS_ROLES } from 'modules/shared/constants';
import * as authSelectors from '../selectors';

export const locationHelper = locationHelperBuilder({});

export const getRedirectPath = (ownProps = history) => {
  const path: string = locationHelper.getRedirectQueryParam(ownProps);

  return path ?? '/projects';
};

export const userIsNotAuthenticated = connectedReduxRedirect({
  redirectPath: (state, ownProps) => getRedirectPath(ownProps),
  allowRedirectBack: false,
  authenticatedSelector: (state) => !authSelectors.getIsLoggedIn(state),
  wrapperDisplayName: 'UserIsNotAuthenticated',
  redirectAction: replace,
});

export const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => authSelectors.getIsLoggedIn(state),
  wrapperDisplayName: 'UserIsAuthenticated',
  redirectAction: replace,
});

export const globalAdminOnly = (Component, FailureComponent?: any) => {
  return connectedAuthWrapper({
    authenticatedSelector: (state) => {
      const isAdmin = authSelectors.isAdmin(state);
      return isAdmin;
    },
    wrapperDisplayName: 'globalAdminOnly',
    FailureComponent: FailureComponent || (() => null),
  })(Component);
};

export const adminOnly = (Component, FailureComponent?: any) => {
  return connectedAuthWrapper({
    authenticatedSelector: (state) => {
      const isAdmin = authSelectors.isAdmin(state);
      const isConnectionAdmin = Boolean(authSelectors.getAdminConnections(state).length);
      return isAdmin || isConnectionAdmin;
    },
    wrapperDisplayName: 'adminOnly',
    FailureComponent: FailureComponent || (() => null),
  })(Component);
};

export const useAdminOnly = () => {
  const isAdmin = useSelector(authSelectors.isAdmin);

  const adminConnections = useSelector(authSelectors.getAdminConnections);

  return isAdmin || Boolean(adminConnections.length);
};

export const useAdminOnlyForConnection = (connection: string) => {
  const isAdmin = useSelector(authSelectors.isAdmin);

  const adminConnections = useSelector(authSelectors.getAdminConnections);

  return isAdmin || adminConnections.includes(connection);
};

export const adminOrAtleastOneProjectWithRedirect =
  (Component, redirectPath = '/dashboard') =>
  (props) => {
    const { projects } = useContext(ProjectsContext);

    const redirect = !projects.length;
    useEffect(() => {
      if (redirect) {
        history.replace(redirectPath);
      }
    }, [redirect]);

    if (redirect) {
      return null;
    }

    return <Component {...props} />;
  };

export const adminOrSuperUserOnly = (Component, FailureComponent?: any) => (props) => {
  const { projectsWithSuperUserRole } = useContext(ProjectsContext);

  if (projectsWithSuperUserRole.length) {
    return <Component {...props} />;
  }

  if (FailureComponent) {
    return <FailureComponent {...props} />;
  }

  return null;
};

export const useAdminOrSuperUser = (connection: string, projectId: string) => {
  const isAdmin = useAdminOnlyForConnection(connection);
  const { permissionByProject } = useContext(ProjectsContext);

  if (isAdmin) {
    return true;
  }

  if (!projectId) {
    return false;
  }

  const permission = permissionByProject[projectId];

  return SUPER_USER_ACCESS_ROLES.includes(permission);
};

export const useEndUserOrHigher = (connection: string, projectId: string) => {
  const isAdmin = useAdminOnlyForConnection(connection);
  const { permissionByProject } = useContext(ProjectsContext);

  if (isAdmin) {
    return true;
  }

  if (!projectId) {
    return false;
  }

  const permission = permissionByProject[projectId];

  return permission === Permissions.END_USER || SUPER_USER_ACCESS_ROLES.includes(permission);
};
