import { FieldsType } from 'modules/flow-control/shared/FlowModal/types';
import { useEffect, useState } from 'react';
import { parseJson } from '../utils';

export const useHeadersHook = (flowExecute, flowExecuteCustomize) => {
  const [headers, setHeaders] = useState<FieldsType>({});
  const [originalHeader, setOriginalHeader] = useState<FieldsType>({});

  useEffect(() => {
    const listKeys = {};

    const flowFields = parseJson(flowExecute.headers);

    const customFields = flowExecuteCustomize ? parseJson(flowExecuteCustomize.headers) : {};

    Object.keys(flowFields).forEach((k) => {
      listKeys[k] = {
        key: k,
        value: customFields[k] || flowFields[k] || '',
        default: true,
      };
      return undefined;
    });

    Object.keys(customFields).forEach((k) => {
      listKeys[k] = {
        key: k,
        value: customFields[k],
        default: false,
      };
      return undefined;
    });

    if (JSON.stringify(listKeys) !== JSON.stringify(headers)) {
      setHeaders({ ...listKeys });
      setOriginalHeader({ ...listKeys });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowExecuteCustomize, flowExecute]);

  return {
    headers,
    setHeaders,
    originalHeader,
    setOriginalHeader,
  };
};
