import { gql } from '@apollo/client';

export const NOTIFY_FILE_DOWNLOAD = gql`
  subscription NotifyFilesDownloadSubscription($connection: String!, $userId: String!) {
    notifyFilesDownloadSubscription(connection: $connection, user_id: $userId) {
      path
      name
    }
  }
`;
