import { gql } from '@apollo/client';

export const LOAD_DATA_STORES_CONNECTIONS = gql`
  query AllDataStoresConnections($connection: String!, $projectId: String!) {
    allDataStoresConnections(connection: $connection, project_id: $projectId) {
      connectionId
      name
      type
      config
    }
  }
`;

export const LOAD_SHAREPOINT_DRIVES = gql`
  query LoadSharepointDrives(
    $connection: String!
    $projectId: String!
    $clientId: String!
    $clientSecret: String!
    $tenantId: String!
    $site: String!
  ) {
    loadSharepointDrives(
      connection: $connection
      project_id: $projectId
      client_id: $clientId
      client_secret: $clientSecret
      tenant_id: $tenantId
      site: $site
    ) {
      id
      name
    }
  }
`;

export const LOAD_ADLS_CONTAINERS = gql`
  query adlsContainers($connection: String!, $projectId: String!, $accountName: String!, $accountKey: String!) {
    adlsContainers(
      connection: $connection
      project_id: $projectId
      account_name: $accountName
      account_key: $accountKey
    ) {
      name
    }
  }
`;

export const CHECK_DATA_STORES_CONNECTION = gql`
  query CheckDataStoresConnection($connection: String!, $projectId: String!, $type: String!, $config: String!) {
    checkDataStoresConnection(connection: $connection, project_id: $projectId, type: $type, config: $config)
  }
`;

export const LOAD_SYNC_PATHES = gql`
  query LoadSyncPathes($connection: String!, $dataStoreConnection: String!, $type: String!, $projectId: String!) {
    loadSyncPathes(
      connection: $connection
      data_store_connection: $dataStoreConnection
      type: $type
      project_id: $projectId
    ) {
      pathId
      path
      hdfsLocation
      isFile
      filter
      unzip
      loadDeltaLake
      dataStoreConnectionId
      dataStoreConnection {
        name
      }
      name
      scheduler {
        label
        type
        pattern
        lastExecTime
        isRunning
      }
    }
  }
`;
