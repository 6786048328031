import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  formControl: {
    margin: '0 0 17px',
  },
  delete: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
