import { makeStyles } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/styles';

const dot: BaseCSSProperties = {
  position: 'absolute',
  width: 15,
  height: 15,
  background: '#6e7f80',
  zIndex: 2,
  borderRadius: '50%',
  cursor: 'pointer',
  pointerEvents: 'all',
};

export const useStyles = makeStyles(() => ({
  component: {
    pointerEvents: 'all',
  },
  overlay: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(2px)',
    borderRadius: 8,
  },
  top: {
    ...dot,
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  bottom: {
    ...dot,
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  left: {
    ...dot,
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  right: {
    ...dot,
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  selected: {
    background: '#4B9FFE',
  },
}));
