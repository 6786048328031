import { useQuery } from '@apollo/client';
import { LOAD_LATEST_MAPPINGS } from '../gql';
import { LatestMappings } from '../types';

export const useLatestMappings = ({ connection, projectId, dataTemplate }) => {
  const { loading, data } = useQuery(LOAD_LATEST_MAPPINGS, {
    variables: {
      connection,
      projectId,
      dataTemplate,
    },
    skip: !connection || !projectId || !dataTemplate,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const latestMappings: LatestMappings = data?.latestMappings;

  return {
    loading,
    latestMappings,
  };
};
